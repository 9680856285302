import { Flex, Text } from "@chakra-ui/react";
import { Deal, DealCalendarType, formatNumberWithCommas } from "@elphi/types";
import { DATES_FORMATS, elphiDate } from "@elphi/utils";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { CustomCollapse, RightSideBar } from "../../../common-components";
import { useDealCalendarHooks } from "../../../hooks/dealCalendar.hooks";
import { useOrgHooks } from "../../../hooks/org.hooks";
import { NavigateToDeal } from "../../deal/table/v2/DealTableRow";
import { HandHouseBlue100Icon } from "../../icons";
import { findDeal } from "../dealCalendar.utils";
import { DealDataSection, KCTsSection, PartiesSection } from "./sections";

type DealCalendarSidebarProps = {};

const SideBarTitle = ({
  loanIdentifier,
  id
}: {
  loanIdentifier?: string;
  id: string;
}) => {
  const { selectedOrgId } = useOrgHooks();

  return (
    <Flex flexDir={"row"} alignItems={"center"}>
      <HandHouseBlue100Icon w={"32px"} h={"32px"} mr={"3px"} />
      <NavigateToDeal
        selectedOrgId={selectedOrgId}
        rowData={{
          deal: {
            LoanIdentifier: loanIdentifier,
            id: id
          } as Partial<Deal>
        }}
      >
        <Text fontWeight={700} fontSize={"16px"}>
          {loanIdentifier}
        </Text>
      </NavigateToDeal>
    </Flex>
  );
};

const SideBarFooter = ({
  requestedLoanAmount
}: {
  requestedLoanAmount?: string;
}) => (
  <Flex flexDir={"row"} justifyContent={"space-between"} alignItems="flex-end">
    <Text fontSize={"16px"} fontWeight={"700"}>
      Total Loan Amount
    </Text>
    <Text fontSize={"16px"} fontWeight={"700"}>
      {!!requestedLoanAmount
        ? `$${formatNumberWithCommas(requestedLoanAmount)}`
        : "--"}
    </Text>
  </Flex>
);

export const DealCalendarSidebar = ({}: DealCalendarSidebarProps) => {
  const {
    dates,
    selectedDateType,
    setSideBarDealCalendar,
    sideBarDealCalendar
  } = useDealCalendarHooks();
  const { dealCalendarId, date } = sideBarDealCalendar || {};
  const [dealCalendar, setDealCalendar] = useState<DealCalendarType>();

  const getDealCalendar = () => {
    const currentDate = elphiDate(date)?.format(DATES_FORMATS.YYYY_MM_DD);
    const currentDealCalendar: DealCalendarType =
      dates?.[selectedDateType]?.[currentDate]?.deals?.find(
        (deal) => deal.id == dealCalendarId
      ) || {};
    return currentDealCalendar;
  };

  useEffect(() => {
    if (dealCalendarId) {
      const currentDealCalendar: DealCalendarType = getDealCalendar();
      if (isEmpty(currentDealCalendar)) {
        const dealCalendarItem = findDeal(
          dates,
          dealCalendarId,
          selectedDateType
        );
        setDealCalendar(dealCalendarItem);
      } else {
        setDealCalendar(currentDealCalendar);
      }
    }
  }, [dates, dealCalendarId]);

  return (
    dealCalendar && (
      <RightSideBar
        options={{ width: "440px" }}
        title={
          <SideBarTitle
            id={dealCalendarId!}
            loanIdentifier={dealCalendar.loanIdentifier}
          />
        }
        footer={
          <SideBarFooter
            requestedLoanAmount={dealCalendar.requestedLoanAmount}
          />
        }
        show={!!sideBarDealCalendar}
        onClose={() => setSideBarDealCalendar(undefined)}
        enableCloseClickOutside={false}
      >
        <Flex flexDir={"column"} justifyContent={"space-between"}>
          <Flex flexDir={"column"}>
            <CustomCollapse title={"Deal Data"} options={{ mb: "10px" }}>
              <DealDataSection dealCalendar={dealCalendar} />
            </CustomCollapse>
            <CustomCollapse
              title={"Parties"}
              options={{ mb: "10px" }}
              customBody={<PartiesSection dealCalendar={dealCalendar} />}
            />
            <CustomCollapse
              title={"Key Closing Tasks (KCTs)"}
              options={{ mb: "10px", childrenContainerColor: "gray.100" }}
            >
              <KCTsSection dealCalendar={dealCalendar} />
            </CustomCollapse>
          </Flex>
        </Flex>
      </RightSideBar>
    )
  );
};
