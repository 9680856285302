import {
  DealPartyRelation,
  DealPartyRelationType,
  DealToEntityRelation,
  DealToIndividualRelation
} from "../dealParty.types";

export const getDealPartyTemplate = (t: DealPartyRelationType | undefined) => {
  if (!t) return {} as DealPartyRelation;
  const dealEntityRelation: DealToEntityRelation = {
    type: DealPartyRelationType.DealEntity,
    id: "",
    index: "",
    createdAt: "",
    modifiedAt: "",
    dealId: "",
    partyId: "",
    relationRoleType: []
  };
  const dealIndividualRelation: DealToIndividualRelation = {
    type: DealPartyRelationType.DealIndividual,
    id: "",
    index: "",
    createdAt: "",
    modifiedAt: "",
    dealId: "",
    partyId: "",
    relationRoleType: []
  };
  switch (t) {
    case DealPartyRelationType.DealEntity:
      return dealEntityRelation;
    case DealPartyRelationType.DealIndividual:
      return dealIndividualRelation;
  }
};
