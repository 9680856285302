import { Box, Heading } from "@chakra-ui/react";
import { ChangeReasonMilestones, FieldType } from "@elphi/types";
import { useEffect, useMemo } from "react";
import { EMPTY } from "../../../../constants/common";
import StyledInputBuilder from "../../../form-builder/InputBuilder";
import { createOptionsFromEnum } from "../../../utils/formUtils";
import {
  genericReasonMap,
  isChangeReasonMilestoneGuard,
  specificReasonMap
} from "../changeReasonRequiredForMilestones.utils";
import { DealState } from "./DeadDealChangeReasonLiveStateContainer";

export const ChangeReasonFormComponent = (props: {
  dealId: string;
  milestone: ChangeReasonMilestones | undefined;
  form: {
    state: DealState;
    isLoading: boolean;
    onChange: () => void;
    genericReasonState: string;
    setGenericReasonState: React.Dispatch<React.SetStateAction<string>>;
    specificReasonState: string;
    setSpecificReasonState: React.Dispatch<React.SetStateAction<string>>;
  };
}) => {
  const { milestone } = props;

  const specificReasonOptions = useMemo(() => {
    const genericReasonType = props.form.genericReasonState;
    const specificReasons = milestone
      ? specificReasonMap[milestone][genericReasonType]
      : undefined;
    return specificReasons ? createOptionsFromEnum(specificReasons) : [];
  }, [props.form.genericReasonState]);

  useEffect(() => {
    if (props.dealId) {
      const dealMetadata = props?.form?.state[props.dealId]?.DealMetadata;
      const isDealMetadata =
        dealMetadata?.milestone === milestone && dealMetadata?.milestoneData;

      const genericReasonValue =
        isDealMetadata && isChangeReasonMilestoneGuard(dealMetadata.milestone)
          ? dealMetadata.milestoneData?.[dealMetadata.milestone]
              ?.genericReason || EMPTY
          : EMPTY;

      const specificReasonValue =
        isDealMetadata && isChangeReasonMilestoneGuard(dealMetadata.milestone)
          ? dealMetadata.milestoneData?.[dealMetadata?.milestone]
              ?.specificReason || EMPTY
          : EMPTY;

      props.form.setGenericReasonState(genericReasonValue);
      props.form.setSpecificReasonState(specificReasonValue);
    }
  }, [props.dealId, props.form.state]);

  const genericReasons = milestone
    ? createOptionsFromEnum(genericReasonMap[milestone])
    : [];
  return (
    <>
      <Box w="100%">
        <Box w="100%">
          <Heading as="h6" size="xs" mb="8px" pb="10px" color={"#505872"}>
            Generic Reason
          </Heading>
        </Box>
        <StyledInputBuilder
          options={genericReasons}
          label="Generic Reason"
          onChange={(e) => {
            if (props.form.genericReasonState !== e.target.value) {
              props.form.setSpecificReasonState(EMPTY);
            }
            props.form.setGenericReasonState(e.target.value);
          }}
          chakraStyles={{
            dropdownIndicator: (provided, state) => ({
              ...provided,
              background: state.isFocused ? "#DBE7FF" : "white",

              p: 0,
              w: "20px"
            }),
            clearIndicator: (provided) => ({
              ...provided,
              p: 0
            }),
            control: (provided, _) => ({
              ...provided,
              height: "10px",

              p: 0
            }),
            container: (provided, _) => ({
              ...provided,
              bgColor: "#F2F7FF",
              p: 0
            }),
            valueContainer: (provided, _) => ({
              ...provided,
              fontSize: "12px",
              background: "white",
              p: "2px"
            })
          }}
          isValid={true}
          fieldType={FieldType.SingleSelect}
          currentValue={!!props.dealId ? props.form.genericReasonState : EMPTY}
        />
      </Box>
      <Box w="100%" pt="20px">
        <Box w="100%">
          <Heading as="h6" size="xs" mb="8px" pb="10px" color={"#505872"}>
            Specific Reason
          </Heading>
        </Box>
        <StyledInputBuilder
          options={!!props.form.genericReasonState ? specificReasonOptions : []}
          label="Specific Reason"
          isDisabled={props.form.genericReasonState === EMPTY}
          onChange={(e) => {
            props.form.setSpecificReasonState(e.target.value);
          }}
          chakraStyles={{
            dropdownIndicator: (provided, state) => ({
              ...provided,
              background: state.isFocused ? "#DBE7FF" : "white",

              p: 0,
              w: "20px"
            }),
            clearIndicator: (provided) => ({
              ...provided,
              p: 0
            }),
            control: (provided, _) => ({
              ...provided,
              height: "10px",

              p: 0
            }),
            container: (provided, _) => ({
              ...provided,
              bgColor: "#F2F7FF",
              p: 0
            }),
            valueContainer: (provided, _) => ({
              ...provided,
              fontSize: "12px",
              background: "white",
              p: "2px"
            })
          }}
          isValid={true}
          fieldType={FieldType.SingleSelect}
          currentValue={!!props.dealId ? props.form.specificReasonState : EMPTY}
        />
      </Box>
    </>
  );
};
