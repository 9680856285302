import { Box, BoxProps, Flex, Heading } from "@chakra-ui/react";

import { Deal, DealMilestoneType, MilestoneData } from "@elphi/types";
import { useCallback, useEffect, useState } from "react";
import { AppConfig } from "../../config/appConfig";
import { EMPTY } from "../../constants/common";
import firebase from "../../firebase/firebaseConfig";
import useDealHooks from "../../hooks/deal.hooks";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";
import { FieldType } from "../form-builder/fieldFormat.types";
import { SnapshotModalContainer } from "../snapshots/SnapshotModal";
import { useElphiToast } from "../toast/toast.hook";
import { MilestoneChangeReasonContainer } from "./milestoneChangeReason/MilestoneChangeReasonContainer";
import {
  isChangeReasonMilestoneDataGuard,
  isChangeReasonMilestoneGuard
} from "./milestoneChangeReason/changeReasonRequiredForMilestones.utils";
import { MilestoneSelectionContainer } from "./milestoneSelection/MilestoneSelectionContainer";
type DealState = {
  [x: string]: { id: string } & Partial<Deal>;
};

type OptionProps = {
  showTitle?: boolean;
  boxContainer?: BoxProps;
  showSnapshot?: boolean;
};

export type UpdateMilestoneComponentProps = {
  dealId: string;
  snapshotId?: string;
  options?: OptionProps;
};

export const UpdateMilestoneComponent = (
  props: UpdateMilestoneComponentProps
) => {
  const { options } = props;
  const { showTitle = true, boxContainer, showSnapshot = true } = options || {};

  const { errorToast, successToast } = useElphiToast();
  const { updateDeal, selectedDeal, updateDealApiResponse } = useDealHooks();

  const [newMilestoneState, setNewMilestoneState] = useState("");
  const [prevMilestoneState, setPrevMilestoneState] = useState("");

  const revertToLastMilestone = useCallback(() => {
    if (selectedDeal) {
      setNewMilestoneState(
        prevMilestoneState
          ? prevMilestoneState
          : selectedDeal?.DealMetadata?.milestone
      );
    }
  }, [prevMilestoneState]);

  const updateDealHandler = async (v: Partial<DealState>) => {
    const deal = { ...v[props.dealId], id: props.dealId };
    if (!deal) return null;

    return await updateDeal(deal).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "Milestone Updated",
          description: `Milestone updated for Deal: ${deal.id}`
        });
      }
      r.status === 400 &&
        errorToast({
          title: "Failed to update milestone",
          description: r.data.description
        }) &&
        revertToLastMilestone();
      return r;
    });
  };

  const {
    onChange,
    syncState,
    state: formState
  } = useFormBuilderStateHandler({
    initialState: {} as DealState,
    callback: updateDealHandler,
    callbackOptions: {
      debounceRate: AppConfig.debounceRate,
      clearDiff: true
    }
  });

  useEffect(() => {
    if (selectedDeal) {
      syncState({
        shouldSync: !!selectedDeal,
        state: selectedDeal,
        statePath: () => {
          if (selectedDeal) {
            return [selectedDeal.id];
          }
        }
      });
    }
  }, [selectedDeal, props.dealId]);

  useEffect(() => {
    if (props.dealId && selectedDeal) {
      setNewMilestoneState(selectedDeal?.DealMetadata?.milestone ?? "");
    }
  }, [selectedDeal]);

  const onMilestoneChanged = useCallback(
    <T extends DealMilestoneType>(params: MilestoneData<T>) => {
      const { type: milestone } = params;
      const milestoneDataValue = getMilestoneData(params);

      onChange({
        fieldType: FieldType.SingleSelect,
        fieldKey: [props.dealId, "DealMetadata", "milestone"],
        value: milestone
      });

      onChange({
        fieldType: FieldType.SingleSelect,
        fieldKey: [props.dealId, "DealMetadata", "milestoneData", milestone],
        value: milestoneDataValue
      });

      setPrevMilestoneState(milestone || EMPTY);
    },
    [props.dealId]
  );

  return (
    <Box pl="20px" mt="8px" pr="14px" {...boxContainer}>
      {showTitle && (
        <Heading as="h6" size="sm" mb="8px" pb="10px" color={"#505872"}>
          Milestone
        </Heading>
      )}
      <Box>
        <Flex w="100%">
          <Box w="100%" maxWidth="190px">
            {selectedDeal && (
              <MilestoneSelectionContainer
                snapshotId={props.snapshotId}
                dealId={props.dealId}
                deal={selectedDeal}
                newMilestoneState={newMilestoneState ?? ""}
                setNewMilestoneState={setNewMilestoneState}
                prevMilestoneState={prevMilestoneState}
                setPrevMilestoneState={setPrevMilestoneState}
                revertToLastMilestone={revertToLastMilestone}
                onMilestoneChanged={onMilestoneChanged}
              />
            )}
            {props.dealId &&
              isChangeReasonMilestoneGuard(newMilestoneState) && (
                <Box w="100%" pt="20px">
                  <MilestoneChangeReasonContainer
                    newDealMilestone={newMilestoneState}
                    lastDealMilestone={prevMilestoneState ?? ""}
                    dealId={props.dealId}
                    milestoneChanged={{
                      onMilestoneChanged: onMilestoneChanged,
                      isLoading: updateDealApiResponse.isLoading,
                      formState: formState,
                      syncState: syncState
                    }}
                    revertToLastMilestone={revertToLastMilestone}
                    snapshotId={props.snapshotId}
                  />
                </Box>
              )}
          </Box>
          {showSnapshot && <SnapshotModalContainer dealId={props.dealId} />}
        </Flex>
      </Box>
    </Box>
  );
};

const getMilestoneData = <T extends DealMilestoneType>(
  params: MilestoneData<T>
) => {
  const { type: milestone } = params;
  const timestamp = firebase.firestore.Timestamp.now().toDate().toISOString();

  const milestoneDataValue = {
    type: milestone,
    timestamp,
    ...(isChangeReasonMilestoneDataGuard(params) && {
      genericReason: params.genericReason,
      specificReason: params.specificReason
    })
  };
  return milestoneDataValue;
};
