import { Divider } from "@chakra-ui/react";
import { Statement } from "@elphi/types";
import { statementFieldSpecs } from "../../form-builder/field-specs/statement/statement.fields";
import { OnChangeInput, Section } from "../../form-builder/FormBuilder";
import { buildInputs } from "../../form-builder/formBuilder.utils";
import {
  dateValidation,
  moneyValidation,
  percentValidation
} from "../../utils/validationUtils";
import {
  endFieldPath,
  percentConsideredFieldPath,
  startFieldPath,
  statementBalanceFieldPath,
  unverifiedDepositsFieldPath
} from "../field-paths/statement.fieldPaths";

export const createStatementSection = (r: {
  state: Partial<Statement>;
  onChange?: (v: OnChangeInput) => void;
  options: { hideAttachedComponent: boolean };
}): Section => {
  const { state, onChange, options } = r;
  return {
    header: <Divider />,
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: statementFieldSpecs,
      specs: [
        { path: startFieldPath, validation: dateValidation },
        { path: endFieldPath, validation: dateValidation },
        { path: statementBalanceFieldPath, validation: moneyValidation },
        { path: unverifiedDepositsFieldPath, validation: moneyValidation },
        { path: percentConsideredFieldPath, validation: percentValidation }
      ],
      hideAttachedComponent: options.hideAttachedComponent
    })
  };
};
