import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import {
  IntegrationType,
  TaskTemplate,
  TaskType,
  TaskTypeValues
} from "@elphi/types";
import { responseHandler } from "../../../apis/rtk/response.handler";
import elphiTheme from "../../../assets/themes/elphi.theme.default";
import { useTaskTemplateHooks } from "../../../hooks/taskTemplate.hooks";
import { taskTemplateApi } from "../../../redux/v2/task-template/taskTemplate.service";
import FormBuilder from "../../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../../form-builder/InputBuilder";
import { FieldType } from "../../form-builder/fieldFormat.types";
import { useElphiToast } from "../../toast/toast.hook";
import { createSelectOptions } from "../../utils/formUtils";
import { mapIntegrationTypeLabels } from "../taskPrint.utils";
import {
  getTaskTemplate,
  taskTemplateTypeOptions
} from "./templates/task.template";

const CreateTaskTemplateModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [createTemplateApi, createTemplateResponse] =
    taskTemplateApi.useCreateMutation();
  const { selectedTaskTemplate, setSelectedTaskTemplate } =
    useTaskTemplateHooks();
  const { onChange, state } = useFormBuilderStateHandler({
    initialState: {
      name: "",
      taskType: "" as TaskType,
      integrationType: "" as IntegrationType
    }
  });

  const toastHandler = (
    d:
      | {
          status: 400;
          data: {
            error: any;
            description: string;
          };
        }
      | {
          status: 200;
          data: {
            id: string;
          };
        }
  ) => {
    if (d.status === 200) {
      successToast({
        title: "New Template Created",
        description: `task template: ${d.data.id}`
      });

      onClose();
    } else if (d.status === 400) {
      errorToast({
        title: "Failed to create template",
        description: d?.data?.description || "something went wrong"
      });
    }
  };
  const { successToast, errorToast } = useElphiToast();
  const createTemplateHandler = () => {
    createTemplateApi({
      template: {
        ...getTaskTemplate({
          type: state.taskType,
          integrationType: state.integrationType
        }),
        templateName: state.name
      }
    } as TaskTemplate)
      .then(responseHandler)
      .then((r) => {
        if (r.status === 200) {
          setSelectedTaskTemplate(r.data.id);
        }
        return r;
      })
      .then(toastHandler);
  };
  const cloneTemplateHandler = () => {
    if (!selectedTaskTemplate) return;
    createTemplateApi({
      template: {
        ...selectedTaskTemplate.template,
        templateName: state.name
      }
    } as TaskTemplate)
      .then(responseHandler)
      .then((r) => {
        if (r.status === 200) {
          setSelectedTaskTemplate(r.data.id);
        }
        return r;
      })
      .then(toastHandler);
  };

  const integrationTypeOptions = Object.values(IntegrationType).map((type) => {
    return { value: type, label: mapIntegrationTypeLabels(type) };
  });
  return (
    <Box>
      <Box>
        <Button
          float={"right"}
          {...elphiTheme.components.light.button.primary}
          onClick={onOpen}
        >
          Create Template
        </Button>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"} width="100%">
          <ModalHeader>Task Template</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedTaskTemplate && (
              <Text>Cloning: {selectedTaskTemplate.template.templateName}</Text>
            )}
            <FormBuilder
              customKey="createTaskTemplateForm"
              elphiView="form"
              onChange={onChange}
              sections={[
                {
                  inputs: [
                    {
                      label: "name",
                      labelPosition: "up",
                      fieldType: FieldType.String,
                      fieldKey: ["name"],
                      currentValue: state.name,
                      isValid: state.name > ""
                    },
                    {
                      label: "task type",
                      isHidden: !!selectedTaskTemplate,
                      labelPosition: "up",
                      fieldType: FieldType.SingleSelect,
                      fieldKey: ["taskType"],
                      options: createSelectOptions(
                        TaskType,
                        taskTemplateTypeOptions
                      ),
                      currentValue: state.taskType,
                      isValid: TaskTypeValues.includes(state.taskType)
                    },
                    {
                      label: "integration type",
                      isHidden:
                        !!selectedTaskTemplate ||
                        state.taskType !== TaskType.Integration,
                      labelPosition: "up",
                      fieldType: FieldType.SingleSelect,
                      fieldKey: ["integrationType"],
                      options: integrationTypeOptions,
                      currentValue: state.integrationType,
                      isValid: state.integrationType > ""
                    }
                  ]
                }
              ]}
            />
            <Flex float="right">
              <Box p="5px">
                <Button
                  isLoading={createTemplateResponse.isLoading}
                  onClick={cloneTemplateHandler}
                  isDisabled={!state.name || !selectedTaskTemplate}
                  float="right"
                  {...elphiTheme.components.light.button.secondary}
                >
                  Clone
                </Button>
              </Box>
              <Box p="5px">
                <Button
                  isLoading={createTemplateResponse.isLoading}
                  onClick={createTemplateHandler}
                  isDisabled={
                    !!selectedTaskTemplate ||
                    !state.name ||
                    !state.taskType ||
                    (state.taskType === TaskType.Integration &&
                      !state.integrationType)
                  }
                  float="right"
                  {...elphiTheme.components.light.button.primary}
                >
                  Submit New
                </Button>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CreateTaskTemplateModal;
