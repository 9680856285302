import { ElphiCellType, ElphiTableProps } from "../../../table/table.types";
import {
  actionStyles,
  lenderStyles,
  loanProgramStyles,
  milestoneStyles,
  rowIndexStyles
} from "../../styles/table";

export const headerCells: ElphiTableProps["header"] = [
  {
    index: 0,
    data: "",
    type: ElphiCellType.String,
    maxWidth: rowIndexStyles.maxWidth,
    minWidth: rowIndexStyles.minWidth
  },
  {
    index: 1,
    data: "Actions",
    type: ElphiCellType.String,
    maxWidth: actionStyles.maxWidth,
    minWidth: actionStyles.minWidth
  },
  {
    index: 2,
    data: "Loan Number",
    type: ElphiCellType.String,
    maxWidth: "200px",
    minWidth: "200px"
  },
  {
    index: 3,
    data: "Borrowing Entity",
    type: ElphiCellType.String,
    maxWidth: "200px",
    minWidth: "200px"
  },
  {
    index: 4,
    data: "Sponsor",
    type: ElphiCellType.String,
    maxWidth: "200px",
    minWidth: "200px"
  },
  {
    index: 5,
    data: "Loan Program",
    type: ElphiCellType.String,
    minWidth: loanProgramStyles.maxWidth,
    maxWidth: loanProgramStyles.maxWidth
  },

  {
    index: 6,
    data: "User Assignment",
    type: ElphiCellType.String,
    maxWidth: "360px",
    minWidth: "360px"
  },
  {
    index: 7,
    data: "Closing Date",
    type: ElphiCellType.String,
    maxWidth: "350px",
    minWidth: "350px"
  },
  {
    index: 8,
    data: "Properties",
    type: ElphiCellType.String,
    maxWidth: "200px",
    minWidth: "200px"
  },
  {
    index: 9,
    data: "Lender",
    type: ElphiCellType.String,
    maxWidth: lenderStyles.maxWidth,
    minWidth: lenderStyles.minWidth
  },
  {
    index: 10,
    data: "Milestone",
    type: ElphiCellType.String,
    minWidth: milestoneStyles.maxWidth,
    maxWidth: milestoneStyles.maxWidth
  }
];
