import { Deal } from "@elphi/types";
import { ObjectKeyValidation } from "../../form-builder/field-specs/objectKeyValidation.types";

export const titleCompanyAttorneyNameFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "Attorney", "FullName"]
> = ["TitleCompany", "Attorney", "FullName"];

export const titleCompanyNameFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "FullName"]
> = ["TitleCompany", "FullName"];

export const titleCompanyClosingAgentFirstNameFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "ClosingAgentFirstName"]
> = ["TitleCompany", "ClosingAgentFirstName"];

export const titleCompanyClosingAgentLastNameFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "ClosingAgentLastName"]
> = ["TitleCompany", "ClosingAgentLastName"];

export const titleCompanyClosingAgentContactPointTelephoneFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "ClosingAgentContactPointTelephoneValue"]
> = ["TitleCompany", "ClosingAgentContactPointTelephoneValue"];

export const titleCompanyClosingAgentContactPointEmailFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "ClosingAgentContactPointEmailValue"]
> = ["TitleCompany", "ClosingAgentContactPointEmailValue"];

export const titleCompanyAddressLineTextFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "AddressLineText"]
> = ["TitleCompany", "AddressLineText"];

export const titleCompanyAddressUnitIdentifierFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "AddressUnitIdentifier"]
> = ["TitleCompany", "AddressUnitIdentifier"];

export const titleCompanyCityNameFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "CityName"]
> = ["TitleCompany", "CityName"];

export const titleCompanyStateNameFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "StateName"]
> = ["TitleCompany", "StateName"];

export const titleCompanyStateCodeFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "StateCode"]
> = ["TitleCompany", "StateCode"];

export const titleCompanyPostalCodeFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "PostalCode"]
> = ["TitleCompany", "PostalCode"];

export const generalContractorFullNameFieldPath: ObjectKeyValidation<
  Deal,
  ["GeneralContractor", "FullName"]
> = ["GeneralContractor", "FullName"];

export const generalContractorRepresentativeFirstNameFieldPath: ObjectKeyValidation<
  Deal,
  ["GeneralContractor", "RepresentativeFirstName"]
> = ["GeneralContractor", "RepresentativeFirstName"];

export const generalContractorRepresentativeLastNameFieldPath: ObjectKeyValidation<
  Deal,
  ["GeneralContractor", "RepresentativeLastName"]
> = ["GeneralContractor", "RepresentativeLastName"];

export const generalContractorContactPointTelephoneFieldPath: ObjectKeyValidation<
  Deal,
  ["GeneralContractor", "ContactPointTelephoneValue"]
> = ["GeneralContractor", "ContactPointTelephoneValue"];

export const generalContractorContactPointEmailFieldPath: ObjectKeyValidation<
  Deal,
  ["GeneralContractor", "ContactPointEmailValue"]
> = ["GeneralContractor", "ContactPointEmailValue"];
export const brokerRepresentativeFirstNameFieldPath: ObjectKeyValidation<
  Deal,
  ["Broker", "RepresentativeFirstName"]
> = ["Broker", "RepresentativeFirstName"];

export const brokerRepresentativeLastNameFieldPath: ObjectKeyValidation<
  Deal,
  ["Broker", "RepresentativeLastName"]
> = ["Broker", "RepresentativeLastName"];

export const brokerFullNameFieldPath: ObjectKeyValidation<
  Deal,
  ["Broker", "FullName"]
> = ["Broker", "FullName"];

export const brokerContactPointEmailValueFieldPath: ObjectKeyValidation<
  Deal,
  ["Broker", "ContactPointEmailValue"]
> = ["Broker", "ContactPointEmailValue"];

export const brokerContactPointTelephoneFieldPath: ObjectKeyValidation<
  Deal,
  ["Broker", "ContactPointTelephoneValue"]
> = ["Broker", "ContactPointTelephoneValue"];

export const nmlsIdentifierFieldPath: ObjectKeyValidation<
  Deal,
  ["Broker", "NMLSIdentifier"]
> = ["Broker", "NMLSIdentifier"];

export const brokerWiringInstructionsIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["Broker", "BrokerWiringInstructionsIndicator"]
> = ["Broker", "BrokerWiringInstructionsIndicator"];
