import { Box, Text } from "@chakra-ui/react";
import { Deal } from "@elphi/types";
import { dealMilestoneColor } from "../../utils/milestone.color";

export const DealMilestoneCell = (props: { deal?: Deal }) => {
  const milestone = props.deal?.DealMetadata?.milestone || "N/A";
  return (
    <Box pl="8px" pr="8px">
      {props.deal && (
        <Box bgColor={dealMilestoneColor[milestone]} paddingTop="4px" paddingBottom="4px" borderRadius="2px">
          <Text w="100%" align="center" fontSize="14px" fontWeight={"bold"}>
            {milestone}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export const DealLenderCell = (props: { deal?: Deal }) => {
  return (
    <Box pl="8px" pr="8px">
      {props.deal && (
        <Box>
          <Text
            w="100%"
            align="center"
            fontSize="14px"
            borderBottomWidth={
              props.deal && !props.deal?.LenderIdentifier ? "1px" : ""
            }
            borderColor={
              props.deal && !props.deal?.LenderIdentifier ? "red.400" : ""
            }
          >
            {(props.deal && props.deal?.LenderIdentifier) || "Missing Lender"}
          </Text>
        </Box>
      )}
    </Box>
  );
};
