import { Box, useDisclosure } from "@chakra-ui/react";
import { NotificationConfiguration } from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { uniq } from "lodash";
import { useEffect, useState } from "react";
import { AppConfig } from "../../../../config/appConfig";
import { EMPTY } from "../../../../constants/common";
import { auth } from "../../../../firebase/firebaseConfig";
import { useAuthStateChangeHook } from "../../../../hooks/authStateChange.hooks";
import {
  LIMIT,
  useNotificationConfigurationHooks,
  useNotificationConfigurationTableHooks
} from "../../../../hooks/notificationConfiguration.hooks";
import useWindowDimensions from "../../../../hooks/windowDimensions";
import { baseCellStyle } from "../../../custom/chakra/Cell";
import { GroupModal } from "../../../custom/modal/GroupModal";
import { ElphiPaginationList } from "../../../elphi-list/ElphiList";
import { useFormBuilderStateHandler } from "../../../form-builder/InputBuilder";
import { ElphiCellType, ElphiTableProps } from "../../../table/table.types";
import {
  SIZE_FIELD,
  TableRowSizeComponent
} from "../../../table/TableRowSizeComponent";
import { NotificationConfigurationRow } from "./NotificationConfigurationRow";

export const NotificationConfigurationTable = () => {
  const { heightOffsetInPx } = useWindowDimensions();
  const { isOpen: isOpenCloneModal, onToggle: onToggleCloneModal } =
    useDisclosure();
  const {
    notificationConfigurationState,
    updateConfigurationBatch,
    cloneConfiguration,
    createResponse,
    getAuditEventGroupBatchApi,
    getAuditEventGroupBatchResponse,
    getRoleGroupBatchApi,
    getRoleGroupBatchResponse,
    getFieldGroupBatchApi,
    getFieldGroupBatchResponse
  } = useNotificationConfigurationHooks();
  const { nextPage, paginateApiResponse, filters, currentCombination } =
    useNotificationConfigurationTableHooks();
  const [selectedRow, setSelectedRow] = useState<NotificationConfiguration>();

  useAuthStateChangeHook({
    onAuthStateChange: (_) => {
      if (auth.currentUser) {
        nextPage();
      }
    },
    deps: [filters]
  });

  useEffect(() => {
    const selected =
      notificationConfigurationState.entities[selectedRow?.id || EMPTY];
    if (selected) {
      setSelectedRow(selected);
    }
  }, [notificationConfigurationState.entities[selectedRow?.id || EMPTY]]);

  useEffect(() => {
    if (auth.currentUser) {
      const page = paginateApiResponse.currentData?.page || [];
      const auditEventGroupIds = uniq(page.map((x) => x?.auditGroupId)).filter(
        removeEmpty
      );

      const roleGroupIds = uniq(page.map((x) => x?.roleGroupId)).filter(
        removeEmpty
      );

      const fieldGroupIds = uniq(page.map((x) => x?.fieldGroupId)).filter(
        removeEmpty
      );

      auditEventGroupIds.length &&
        getAuditEventGroupBatchApi(auditEventGroupIds);

      roleGroupIds.length && getRoleGroupBatchApi(roleGroupIds);

      fieldGroupIds.length && getFieldGroupBatchApi(fieldGroupIds);
    }
  }, [auth.currentUser, paginateApiResponse.currentData?.page]);

  const { syncState, state, onChange } = useFormBuilderStateHandler<{
    [id: string]: Partial<NotificationConfiguration>;
  }>({
    initialState: {},
    callback: updateConfigurationBatch,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  const handleOnCloneClick = (id: string) => {
    setSelectedRow(notificationConfigurationState.entities[id]);
    onToggleCloneModal();
  };
  return (
    <>
      <ElphiPaginationList
        height={heightOffsetInPx(320)}
        tableName={"notification-configuration"}
        isLoading={paginateApiResponse.isLoading}
        rowStyle={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none"
        }}
        header={
          <TableRowSizeComponent
            bgColor="gray.100"
            row={headerCells}
            withBorderRightWidth={false}
            justify="space-between"
          />
        }
        pageSize={LIMIT}
        items={
          !notificationConfigurationState.selectedId
            ? currentCombination.ids
            : [notificationConfigurationState.selectedId]
        }
        next={nextPage}
        hasMore={paginateApiResponse.currentData?.page?.length === LIMIT}
        options={{
          showEndMessage: false,
          showWatching: false,
          showLoader: true
        }}
        rowBuilder={(id, index) => {
          const data = notificationConfigurationState.entities[id];
          if (!data) {
            return <></>;
          }
          return (
            <NotificationConfigurationRow
              index={Number(index) + 1}
              data={data}
              onChange={onChange}
              syncState={syncState}
              state={state}
              onClone={handleOnCloneClick}
              loading={{
                auditGroups: getAuditEventGroupBatchResponse.isLoading,
                fieldGroups: getFieldGroupBatchResponse.isLoading,
                roleGroups: getRoleGroupBatchResponse.isLoading
              }}
            />
          );
        }}
      />
      {selectedRow && (
        <GroupModal
          key={`clone-${selectedRow.id}-${isOpenCloneModal}`}
          header={"Clone notification configuration"}
          action={"clone"}
          isShow={isOpenCloneModal}
          onClose={onToggleCloneModal}
          isLoading={createResponse.isLoading}
          onSubmit={cloneConfiguration}
          cloneGroup={selectedRow}
        >
          <Box as="p">{`Clone ${selectedRow.id} - ${selectedRow.name}`}</Box>
        </GroupModal>
      )}
    </>
  );
};

const headerCells: ElphiTableProps["header"] = [
  {
    index: 0,
    data: "index",
    type: ElphiCellType.String,
    size: SIZE_FIELD.T,
    ...baseCellStyle
  },
  {
    index: 1,
    data: "name",
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    ...baseCellStyle
  },
  {
    index: 2,
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    data: "Role Group",
    ...baseCellStyle
  },
  {
    index: 3,
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    data: "Audit Event Group",
    ...baseCellStyle
  },
  {
    index: 4,
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    data: "Field Group",
    ...baseCellStyle
  },
  {
    index: 5,
    type: ElphiCellType.String,
    size: SIZE_FIELD.S,
    data: "Status",
    ...baseCellStyle
  }
];
