import { uniq } from "lodash";
import { CloneConfigurationType } from "../../../../types";
import { removeEmpty } from "../../../../utils/src/common.utils";

export const orgConfigDependenciesMap: Partial<{
  [key in CloneConfigurationType]: CloneConfigurationType[];
}> = {
  [CloneConfigurationType.taskManagement]: [CloneConfigurationType.role],
  [CloneConfigurationType.userManagement]: [CloneConfigurationType.role],
  [CloneConfigurationType.notificationManagement]: [
    CloneConfigurationType.role,
    CloneConfigurationType.taskManagement
  ]
};

export const checkConfigsDependencies = (
  configurationTypes: CloneConfigurationType[]
): CloneConfigurationType[] => {
  return uniq(
    configurationTypes
      .map((type) => {
        const isMissing = !orgConfigDependenciesMap[type]?.every((v) =>
          configurationTypes.includes(v)
        );
        return isMissing ? orgConfigDependenciesMap[type] : undefined;
      })
      .filter(removeEmpty)
      .flat()
  );
};
