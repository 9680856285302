import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Input, InputProps } from "@chakra-ui/react";
import { DATES_FORMATS, elphiDate } from "@elphi/utils";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { DatePickerContainer } from "./DatePickerContainer";

export type SingleDatePickerProps = {
  value: string;
  onChange?: (e: {
    target: {
      value?: string;
    };
  }) => void;
  isRequired?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  dateFormat?: string;
  inputDateFormat?: string;
  showMonthYearPicker?: boolean;
} & InputProps;

export const SingleDatePicker = ({
  value,
  onChange,
  isRequired,
  isReadOnly,
  isDisabled,
  placeholder,
  dateFormat = DATES_FORMATS.YYYY_MM_DD,
  inputDateFormat = DATES_FORMATS.dd_MM_yyyy_WITH_SLASH,
  showMonthYearPicker = false,
  ...inputProps
}: SingleDatePickerProps) => {
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);

  useEffect(() => {
    const valueDate = elphiDate(value).get();
    setSelectedDate(valueDate);
  }, [value]);

  const handleChange = (date?: Date | null) => {
    setIsDatePickerOpen(!isDatePickerOpen);
    if (!date) return;
    setSelectedDate(date);
    const formattedDate = elphiDate(date).format(dateFormat);
    onChange?.({ target: { value: formattedDate } });
  };

  return (
    <Box
      zIndex={10}
      _hover={{ bgColor: "gray.200", cursor: "pointer", borderRadius: "5px" }}
      onClick={() => !isDatePickerOpen && setIsDatePickerOpen(true)}
    >
      <DatePickerContainer
        inputRightIcon={
          <ChevronDownIcon
            onClick={() => !isDatePickerOpen && setIsDatePickerOpen(true)}
            w={4}
            h={4}
          />
        }
      >
        <DatePicker
          readOnly={isReadOnly || isDisabled}
          required={isRequired}
          open={isDatePickerOpen}
          onInputClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
          onClickOutside={() => setIsDatePickerOpen(false)}
          selected={selectedDate}
          dateFormat={inputDateFormat}
          onChange={(date) => handleChange(date)}
          placeholderText={placeholder || "Select dates"}
          showMonthYearPicker={showMonthYearPicker}
          portalId="single-date-picker"
          todayButton="Today"
          customInput={
            <Input
              {...inputProps}
              w={"115px"}
              fontWeight={"400"}
              border={0}
              fontSize={14}
              fontFamily={"inherit"}
              _hover={{ cursor: "pointer" }}
            />
          }
        />
      </DatePickerContainer>
    </Box>
  );
};
