import { Box, Flex } from "@chakra-ui/react";
import {
  DealCalendarDealListDateType,
  formatNumberWithCommas
} from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { sumBy, values } from "lodash";
import { useEffect, useState } from "react";
import { useDealCalendarHooks } from "../../hooks/dealCalendar.hooks";

const totalDealsCalendarPath: DotNestedKeys<DealCalendarDealListDateType> =
  "totalDealsCalendar";
const totalRequestedLoanAmountPath: DotNestedKeys<DealCalendarDealListDateType> =
  "totalRequestedLoanAmount";

export const DealCalendarTotalMonth = () => {
  const { dates, selectedDateType } = useDealCalendarHooks();
  const [totalDeals, setTotalDeals] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const calculateTotals = (dates) => {
    const selectedDateTypeDates = dates?.[selectedDateType] || {};
    const sumTotalDeals = sumBy(
      values(selectedDateTypeDates),
      totalDealsCalendarPath
    );
    const sumTotalRequestedLoanAmount = sumBy(
      values(selectedDateTypeDates),
      totalRequestedLoanAmountPath
    );

    return { sumTotalDeals, sumTotalRequestedLoanAmount };
  };

  useEffect(() => {
    const { sumTotalDeals, sumTotalRequestedLoanAmount } =
      calculateTotals(dates);
    setTotalDeals(sumTotalDeals);
    setTotalAmount(sumTotalRequestedLoanAmount);
  }, [dates, selectedDateType]);

  return (
    <Flex flexDir={"column"} fontSize={"10px"} fontWeight={"700"}>
      <Box>
        Total Deals:
        {!!totalDeals ? ` ${totalDeals.toString()}` : " --"}
      </Box>
      <Box>
        Total Amount:
        {!!totalAmount
          ? ` $${formatNumberWithCommas(totalAmount.toFixed(2))}`
          : " --"}
      </Box>
    </Flex>
  );
};
