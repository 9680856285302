import { AggregationType, Deal } from "@elphi/types";
import { QuoteType } from "@elphi/types/entities/deal.quote.types";
import { OnChangeInput, Section } from "../../form-builder/FormBuilder";
import { dealFieldSpecs } from "../../form-builder/field-specs/deal/deal.fields";
import { buildInputs } from "../../form-builder/formBuilder.utils";
import {
  booleanValidation,
  dateValidation,
  emailValidation,
  moneyValidation,
  numberValidation,
  percentValidation,
  singleSelectValidation,
  stringValidation
} from "../../utils/validationUtils";
import {
  amortizationTypeFieldPath,
  armFinalAdjustableRateTermMonthCountFieldPath,
  armInitialFixedTermMonthCountFieldPath,
  armRepriceFrequencyTypeAggregationFieldPath,
  armRepriceLookbackTypeFieldPath,
  armRepriceRoundingMethodTypeFieldPath,
  armRepriceRoundingRatePercentTypeFieldPath,
  capitalStructureTypeFieldPath,
  channelFieldPath,
  chargentCollectionAmountFieldPath,
  chargentGatewayIdentifierFieldPath,
  chargentTransactionIdentifierFieldPath,
  debtServiceCoverageRatioPercentFieldPath,
  employeeLoanIndicatorFieldPath,
  estimatedClosingDateFieldPath,
  executionTypeFieldPath,
  firstRateChangeDateFieldPath,
  grossSpreadPercentFieldPath,
  indexSourceTypeFieldPath,
  initialAdvancedFundAmountFieldPath,
  initialLTVRatePercentFieldPath,
  initialMonthlyInterestOnlyPaymentAmountFieldPath,
  interestAccrualMethodTypeFieldPath,
  lateFeePercentFieldPath,
  lenderIdentifierFieldPath,
  letterOfIntentSignedDateFieldPath,
  loanAfterInterestOnlyPeriodMonthCountFieldPath,
  loanAmortizationPeriodMonthCountFieldPath,
  loanInterestOnlyPeriodMonthCountFieldPath,
  loanMaturityDateFieldPath,
  loanPaymentTypeFieldPath,
  loanProductTypeFieldPath,
  loanProgramTypeFieldPath,
  loanTermPeriodMonthCountFieldPath,
  monthlyHomeownersAssociationAmountFieldPath,
  monthlyPrincipalInterestPaymentAmountFieldPath,
  monthlyTaxInsurancePaymentAmountFieldPath,
  noteRatePercentFieldPath,
  paymentDueDayFieldPath,
  prepaymentPenaltyExpirationDateFieldPath,
  prepaymentPenaltyTypeFieldPath,
  propertyManagementCommentsFieldPath,
  recourseTypeFieldPath,
  requestedLoanAmountFieldPath,
  scheduledFirstPaymentDateFieldPath,
  totalAsIsAppraisedValueAmountFieldPath,
  totalBudgetAmountFieldPath,
  totalLTARVRatePercentFieldPath,
  totalLTVRatePercentFieldPath,
  totalLoanFeesAndClosingCostAmountFieldPath,
  totalMonthlyPITIPaymentAmountFieldPath,
  totalOutstandingLoanPayoffAmountFieldPath,
  totalPurchasePriceAmountFieldPath,
  totalSubjectToAppraisedValueAmountFieldPath,
  uccExpirationDateFieldPath,
  uccFiledIndicatorFieldPath,
  valuationFeesCollectedBySalesIndicatorFieldPath,
  wireReleaseDateFieldPath
} from "../field-paths/closing.fieldPaths";
import {
  appraisalNotesFieldPath,
  cashFromBorrowerAmountFieldPath,
  cashToBorrowerAmountFieldPath,
  crmIdFieldPath,
  floorPercentFieldPath,
  fullyDrawnInterestOnlyPaymentFieldPath,
  lastDayOfClosingMonthFieldPath,
  lastPaymentDueDateFieldPath,
  lesserOfLotOrPurchaseFieldPath,
  lifetimeCapPercentFieldPath,
  loanNameFieldPath,
  loanPurposeFieldPath,
  marginFieldPath,
  monthlyPaymentOfCapitalExpenditureFieldPath,
  operationsDepartmentFieldPath,
  portfolioLoanIndicatorFieldPath,
  propertyManagementContactPointEmailValueFieldPath,
  propertyManagementContactPointTelephoneValueFieldPath,
  propertyManagementFullAddressNameFieldPath,
  propertyManagementFullNameFieldPath,
  propertyManagementRepresentativeFullNameFieldPath,
  rateLockEndDateFieldPath,
  rateLockStartDateFieldPath,
  totalAdjustedMonthlyRentAmountFieldPath,
  totalAnnualCapitalExpenditureFieldPath,
  totalCostAmountFieldPath,
  totalFloodInsurancePremiumAmountFieldPath,
  totalHOAFeesAmountFieldPath,
  totalNumberOfPropertiesFieldPath,
  totalPropertyInsurancePremiumAmountFieldPath,
  totalTaxAmountFieldPath,
  totalValuationOrPurchasePriceAmountFieldPath
} from "../field-paths/deal.fieldPaths";
import {
  hideFixNFlip,
  hideFixNFlipOrEmpty,
  hideRental30,
  isEmptyLoanProgramType,
  isRental30Premier
} from "./sections.utils";
export const loanInformationSection = (
  state: Partial<Deal>,
  onChange: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Loan Information",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.LoanProgramType?.focused !== "override",
          path: loanProgramTypeFieldPath,
          validation: singleSelectValidation
        },
        {
          path: loanPurposeFieldPath,
          validation: singleSelectValidation
        },
        { path: loanNameFieldPath, validation: stringValidation },
        {
          path: portfolioLoanIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.EstimatedClosingDate?.focused !== "override",
          path: estimatedClosingDateFieldPath,
          validation: dateValidation
        },
        { path: channelFieldPath, validation: singleSelectValidation },
        { path: employeeLoanIndicatorFieldPath, validation: booleanValidation },
        {
          path: chargentTransactionIdentifierFieldPath,
          validation: stringValidation
        },
        {
          path: chargentGatewayIdentifierFieldPath,
          validation: stringValidation
        },
        {
          path: chargentCollectionAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: valuationFeesCollectedBySalesIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: appraisalNotesFieldPath
        },
        {
          path: crmIdFieldPath,
          validation: stringValidation
        },
        {
          path: operationsDepartmentFieldPath,
          validation: singleSelectValidation
        },
        { path: lenderIdentifierFieldPath, validation: singleSelectValidation }
      ]
    })
  };
};

export const dealTotalsSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Deal Totals",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.TotalPurchasePriceAmount?.focused !==
            "override",
          path: totalPurchasePriceAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.TotalBudgetAmount?.focused !== "override",
          path: totalBudgetAmountFieldPath,
          validation: moneyValidation,
          isHidden: hideFixNFlip(state)
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.TotalAsIsAppraisedValueAmount?.focused !==
            "override",
          path: totalAsIsAppraisedValueAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.TotalSubjectToAppraisedValueAmount?.focused !==
            "override",
          path: totalSubjectToAppraisedValueAmountFieldPath,
          validation: moneyValidation,
          isHidden: hideFixNFlip(state)
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.TotalLTVRatePercent?.focused !== "override",
          path: totalLTVRatePercentFieldPath,
          validation: percentValidation
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.TotalLTARVRatePercent?.focused !== "override",
          path: totalLTARVRatePercentFieldPath,
          validation: percentValidation,
          isHidden: hideFixNFlip(state)
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.TotalTaxAmount?.focused !== "override",
          path: totalTaxAmountFieldPath,
          validation: moneyValidation,
          isHidden: hideRental30(state)
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.TotalHOAFeesAmount?.focused !== "override",
          path: totalHOAFeesAmountFieldPath,
          validation: moneyValidation,
          isHidden: hideRental30(state)
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.TotalPropertyInsurancePremiumAmount?.focused !==
            "override",
          path: totalPropertyInsurancePremiumAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.TotalFloodInsurancePremiumAmount?.focused !==
            "override",
          path: totalFloodInsurancePremiumAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.TotalCostAmount?.focused !== "override",
          path: totalCostAmountFieldPath,
          validation: moneyValidation,
          isHidden: hideFixNFlip(state)
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.RequestedLoanAmount?.focused !== "override",
          path: requestedLoanAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: initialLTVRatePercentFieldPath,
          validation: percentValidation,
          isHidden: hideFixNFlip(state)
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.TotalOutstandingLoanPayoffAmount?.focused !==
            "override",
          path: totalOutstandingLoanPayoffAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: initialAdvancedFundAmountFieldPath,
          validation: moneyValidation,
          isHidden: hideFixNFlip(state)
        },
        {
          path: totalLoanFeesAndClosingCostAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.quote?.aggregations?.DebtServiceCoverageRatioPercent
              ?.focused !== "override",
          path: debtServiceCoverageRatioPercentFieldPath,
          validation: percentValidation,
          isHidden: hideRental30(state)
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state?.quote?.quoteType === QuoteType.Rental &&
            state?.quote?.aggregations?.TotalValuationOrPurchasePriceAmount
              ?.focused !== "override",
          path: totalValuationOrPurchasePriceAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.quote?.aggregations?.LesserOfLotOrPurchase?.focused !==
            "override",
          path: lesserOfLotOrPurchaseFieldPath,
          validation: moneyValidation,
          isHidden: hideFixNFlip(state)
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state?.aggregations?.TotalNumberOfProperties?.focused !==
            "override",
          path: totalNumberOfPropertiesFieldPath,
          validation: numberValidation
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state?.aggregations?.TotalAdjustedMonthlyRentAmount?.focused !==
            "override",
          path: totalAdjustedMonthlyRentAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state?.aggregations?.TotalAnnualCapitalExpenditure?.focused !==
            "override",
          path: totalAnnualCapitalExpenditureFieldPath,
          validation: moneyValidation,
          isHidden: !isRental30Premier(state) && !isEmptyLoanProgramType(state)
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state?.aggregations?.MonthlyPaymentOfCapitalExpenditure?.focused !==
            "override",
          path: monthlyPaymentOfCapitalExpenditureFieldPath,
          validation: moneyValidation,
          isHidden: !isRental30Premier(state) && !isEmptyLoanProgramType(state)
        },
        {
          path: cashFromBorrowerAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: cashToBorrowerAmountFieldPath,
          validation: moneyValidation
        }
      ]
    })
  };
};

export const loanTermsSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Loan Terms",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          isAggregation: AggregationType.ThirdParty,
          path: loanProductTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: !hideFixNFlipOrEmpty(state),
          isReadOnly:
            state.aggregations?.LoanProductType?.focused !== "override"
        },
        { path: amortizationTypeFieldPath, validation: singleSelectValidation },
        {
          isAggregation: AggregationType.ThirdParty,
          path: noteRatePercentFieldPath,
          isReadOnly:
            state.quote?.aggregations?.NoteRatePercent?.focused !== "override",
          validation: percentValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.EstimatedClosingDate?.focused !== "override",
          path: estimatedClosingDateFieldPath,
          validation: dateValidation
        },
        { path: rateLockStartDateFieldPath, validation: dateValidation },
        { path: rateLockEndDateFieldPath, validation: dateValidation },
        { path: wireReleaseDateFieldPath, validation: dateValidation },
        {
          isAggregation: AggregationType.Aggregation,
          path: scheduledFirstPaymentDateFieldPath,
          isReadOnly:
            state.aggregations?.ScheduledFirstPaymentDate?.focused !==
            "override",
          validation: dateValidation
        },
        { path: paymentDueDayFieldPath, validation: singleSelectValidation },
        { path: lastPaymentDueDateFieldPath, validation: dateValidation },
        {
          isAggregation: AggregationType.Aggregation,
          path: lastDayOfClosingMonthFieldPath,
          validation: dateValidation,
          isReadOnly:
            state.aggregations?.LastDayOfClosingMonth?.focused !== "override"
        },
        {
          isAggregation: AggregationType.Aggregation,
          path: loanMaturityDateFieldPath,
          validation: dateValidation,
          isReadOnly:
            state.aggregations?.LoanMaturityDate?.focused !== "override"
        },
        {
          path: loanTermPeriodMonthCountFieldPath,
          validation: numberValidation
        },
        {
          path: loanAmortizationPeriodMonthCountFieldPath,
          validation: numberValidation,
          isHidden: hideRental30(state)
        },
        {
          path: loanInterestOnlyPeriodMonthCountFieldPath,
          validation: numberValidation,
          isHidden: hideRental30(state)
        },

        {
          path: loanAfterInterestOnlyPeriodMonthCountFieldPath,
          validation: numberValidation,
          isHidden: hideRental30(state)
        },
        {
          path: interestAccrualMethodTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state)
        },
        {
          path: loanPaymentTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state)
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: totalMonthlyPITIPaymentAmountFieldPath,
          validation: moneyValidation,
          isHidden: hideRental30(state),
          isReadOnly:
            state?.quote?.aggregations?.TotalMonthlyPITIPaymentAmount
              ?.focused !== "override"
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.MonthlyPrincipalInterestPaymentAmount
              ?.focused !== "override",
          path: monthlyPrincipalInterestPaymentAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.InitialMonthlyInterestOnlyPaymentAmount
              ?.focused !== "override",
          path: initialMonthlyInterestOnlyPaymentAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.FullyDrawnInterestOnlyPayment
              ?.focused !== "override",
          path: fullyDrawnInterestOnlyPaymentFieldPath,
          validation: moneyValidation,
          isHidden: hideFixNFlip(state)
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: monthlyTaxInsurancePaymentAmountFieldPath,
          validation: moneyValidation,
          isHidden: hideRental30(state),
          isReadOnly:
            state?.quote?.aggregations?.MonthlyTaxInsurancePaymentAmount
              ?.focused !== "override"
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: monthlyHomeownersAssociationAmountFieldPath,
          validation: moneyValidation,
          isHidden: hideRental30(state),
          isReadOnly:
            state?.quote?.aggregations?.MonthlyHomeownersAssociationAmount
              ?.focused !== "override"
        },
        {
          path: executionTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: hideFixNFlip(state)
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: prepaymentPenaltyTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state),
          isReadOnly:
            state?.quote?.aggregations?.PrepaymentPenaltyType?.focused !==
            "override"
        },
        {
          isAggregation: AggregationType.Aggregation,
          path: prepaymentPenaltyExpirationDateFieldPath,
          validation: dateValidation,
          isReadOnly:
            state.aggregations?.PrepaymentPenaltyExpirationDate?.focused !==
            "override",
          isHidden: hideRental30(state)
        },
        {
          path: recourseTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: hideFixNFlip(state)
        }
      ]
    })
  };
};

export const loanTermsARMSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    isHidden: hideRental30(state),
    header: "Loan Terms - ARM",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          isAggregation: AggregationType.Aggregation,
          path: firstRateChangeDateFieldPath,
          validation: dateValidation,
          isReadOnly:
            state.aggregations?.FirstRateChangeDate?.focused !== "override",
          isHidden: hideRental30(state)
        },
        {
          path: indexSourceTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state)
        },
        {
          path: capitalStructureTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state)
        },
        {
          path: armRepriceRoundingRatePercentTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state)
        },
        {
          path: armRepriceRoundingMethodTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state)
        },
        {
          path: armRepriceLookbackTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state)
        },
        {
          isAggregation: AggregationType.Aggregation,
          path: armInitialFixedTermMonthCountFieldPath,
          validation: numberValidation,
          isReadOnly:
            state.aggregations?.ARMInitialFixedTermMonthCount?.focused !==
            "override",
          isHidden: hideRental30(state)
        },
        {
          isAggregation: AggregationType.Aggregation,
          path: armFinalAdjustableRateTermMonthCountFieldPath,
          isReadOnly:
            state.aggregations?.ARMFinalAdjustableRateTermMonthCount
              ?.focused !== "override",
          validation: numberValidation,
          isHidden: hideRental30(state)
        },
        {
          isAggregation: AggregationType.Aggregation,
          path: armRepriceFrequencyTypeAggregationFieldPath,
          validation: singleSelectValidation,
          isReadOnly:
            state.aggregations?.ARMRepriceFrequencyType?.focused !== "override", //"focused"]) !== "override",
          isHidden: hideRental30(state)
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: marginFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state),
          isReadOnly: state?.quote?.aggregations?.Margin?.focused !== "override"
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: floorPercentFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state),
          isReadOnly: state?.quote?.aggregations?.Margin?.focused !== "override"
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: lifetimeCapPercentFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state),
          isReadOnly:
            state?.quote?.aggregations?.LifetimeCapPercent?.focused !==
            "override"
        }
      ]
    })
  };
};

export const loanTermsCustomSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Loan Terms - Custom",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        { path: uccFiledIndicatorFieldPath, validation: booleanValidation },
        { path: uccExpirationDateFieldPath, validation: dateValidation },
        // {
        //   path: defaultInterestRatePercentFieldPath,
        //   validation: percentValidation
        // },
        { path: lateFeePercentFieldPath, validation: percentValidation },
        // { path: releasePercentFieldPath, validation: percentValidation },
        { path: grossSpreadPercentFieldPath, validation: percentValidation },
        //{ path: gracePeriodDaysFieldPath, validation: numberValidation }
        { path: letterOfIntentSignedDateFieldPath, validation: dateValidation }
      ]
    })
  };
};

export const propertyManagementQuestionnaireSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    isHidden: hideRental30(state),
    header: "Property Management Questionnaire",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          path: propertyManagementFullNameFieldPath,
          validation: stringValidation,
          isHidden: hideRental30(state)
        },
        {
          path: propertyManagementRepresentativeFullNameFieldPath,
          validation: stringValidation,
          isHidden: hideRental30(state)
        },
        {
          path: propertyManagementFullAddressNameFieldPath,
          validation: stringValidation,
          isHidden: hideRental30(state)
        },
        {
          path: propertyManagementContactPointTelephoneValueFieldPath,
          validation: stringValidation,
          isHidden: hideRental30(state)
        },
        {
          path: propertyManagementContactPointEmailValueFieldPath,
          validation: emailValidation,
          isHidden: hideRental30(state)
        },
        {
          path: propertyManagementCommentsFieldPath,
          validation: stringValidation,
          isHidden: hideRental30(state)
        }
      ]
    })
  };
};

export const applicationDealSections = (r: {
  state: Partial<Deal>;
  onChange: (v: OnChangeInput) => void;
}): Section[] => {
  const { state, onChange } = r;
  return [
    loanInformationSection(state, onChange),
    dealTotalsSection(state, onChange),
    loanTermsSection(state, onChange),
    loanTermsARMSection(state, onChange),
    loanTermsCustomSection(state, onChange),
    propertyManagementQuestionnaireSection(state, onChange)
  ];
};
