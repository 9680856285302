import { Flex } from "@chakra-ui/react";
import { TABLE_MIN_WIDTH } from "../../constants/common";
import { auth } from "../../firebase/firebaseConfig";
import useDealHooks from "../../hooks/deal.hooks";
import { DealTable } from "./table/v2/DealTable";

const DealTableContainer = (props: { myDealsFlag: boolean }) => {
  const { myDealsFlag } = props;
  const pageSize = 10;

  const { selectedDeal, dealTableFilter } = useDealHooks();

  return (
    <Flex overflowX={"scroll"} minW={TABLE_MIN_WIDTH}>
      <DealTable
        pageSize={pageSize}
        userId={
          myDealsFlag
            ? auth?.currentUser?.uid
            : dealTableFilter?.selectedUserId || undefined
        }
        dealId={selectedDeal ? selectedDeal.id : undefined}
        lenderIdentifierFilter={dealTableFilter.lenderIdentifier || []}
        dealMilestoneFilter={dealTableFilter.dealMilestone || []}
        lenderIdentifierFilterOp={dealTableFilter.lenderIdentifierOp}
        dealMilestoneFilterOp={dealTableFilter.dealMilestoneOp}
        dealEstimatedClosingDateRange={
          dealTableFilter.estimatedClosingDateRange
        }
        offsetH={300}
      />
    </Flex>
  );
};

export default DealTableContainer;
