import { Box, Checkbox, Flex } from "@chakra-ui/react";
import {
  FieldType,
  PartyValidationConfiguration,
  PartyValidationType,
  Status
} from "@elphi/types";
import { useEffect } from "react";
import { AppConfig } from "../../../config/appConfig";
import { auth } from "../../../firebase/firebaseConfig";
import { useAuthStateChangeHook } from "../../../hooks/authStateChange.hooks";
import { usePartyValidationConfig } from "../../../hooks/partyValidationConfig.hooks";
import { useFormBuilderStateHandler } from "../../form-builder/InputBuilder";

type Options = {
  label: string;
  isMandatory: boolean;
};
export const configurationOptions: { [key in PartyValidationType]: Options } = {
  [PartyValidationType.TaxpayerIdentifierDuplication]: {
    label: "Taxpayer Identifier (SSN/EIN/TIN)",
    isMandatory: true
  },
  [PartyValidationType.PartyGroupDuplication]: {
    label: "Party Group",
    isMandatory: false
  }
};

export const PartyValidationConfigurationPage = () => {
  const {
    getPartyValidationConfig,
    partyValidationConfigResponse,
    setConfiguration,
    updateHandler
  } = usePartyValidationConfig();

  const { state, onChange, syncState } = useFormBuilderStateHandler<
    Partial<PartyValidationConfiguration>
  >({
    initialState: {},
    callback: updateHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  useAuthStateChangeHook({
    onAuthStateChange: (_) => {
      getPartyValidationConfig();
    },
    deps: [auth.currentUser]
  });

  useEffect(() => {
    if (partyValidationConfigResponse.data) {
      setConfiguration();
    }
  }, [partyValidationConfigResponse.data]);

  useEffect(() => {
    syncState({
      shouldSync: !!partyValidationConfigResponse.data?.validations,
      state: partyValidationConfigResponse.data
    });
  }, [partyValidationConfigResponse.data?.validations]);

  const handleCheck = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const validationName = e.target.value as PartyValidationType;
    onChange({
      fieldKey: ["validations", validationName],
      fieldType: FieldType.String,
      value: e.target.checked ? Status.Active : Status.Disabled
    });
  };

  return (
    <Flex gap={"15px"} marginTop={"50px"} flexDirection={"column"}>
      {Object.entries(configurationOptions).map(
        (
          [validationType, options]: [PartyValidationType, Options],
          index: number
        ) => {
          const status = state.validations?.[validationType];
          return (
            <Flex key={index} alignItems={"center"} gap={"10px"}>
              <Checkbox
                isChecked={status === Status.Active}
                isDisabled={configurationOptions[validationType].isMandatory}
                value={validationType}
                onChange={handleCheck}
              />

              <Box fontWeight={400} as={"p"}>
                {options.label}
              </Box>
            </Flex>
          );
        }
      )}
    </Flex>
  );
};
