import { Box, Text, Tooltip } from "@chakra-ui/react";
import { FieldType, Organization } from "@elphi/types";
import { chakraComponents } from "chakra-react-select";
import {
  DropDownIndicatorFirstControl,
  IndicatorsContainer
} from "../../../../form-builder/InputBuilder";
import { OrganizationSearch } from "../OrganizationSearch";
import { OptionType } from "../utils/Organization.utils";

export type OrganizationDropDownProps = {
  label: string;
  isDisabled?: boolean;
  currentValue: string;
  onChange: (v: string) => void;
  buildOption: (o: Organization) => OptionType;
};
export const OrganizationDropDown = (props: OrganizationDropDownProps) => {
  return (
    <>
      <Text pl="3px" pb={"3px"} fontWeight={"bold"}>
        {props.label}
      </Text>
      <OrganizationSearch
        isDisabled={props.isDisabled}
        currentValue={props.currentValue}
        fieldType={FieldType.SingleSelect}
        onSelect={props.onChange}
        buildOption={props.buildOption}
        customComponent={{
          Option: DisabledOptionTooltip,
          Control: DropDownIndicatorFirstControl,
          IndicatorsContainer: IndicatorsContainer
        }}
      />
    </>
  );
};

export const DisabledOptionTooltip = (props: any) => {
  return props.data.isDisabled ? (
    <Tooltip label={props.data.reason} hasArrow placement="left">
      <Box
        {...props.innerProps}
        color={"gray"}
        opacity={0.5}
        cursor={"not-allowed"}
        pl={"12px"}
        pt={"6px"}
        pb={"6px"}
      >
        {props.children}
      </Box>
    </Tooltip>
  ) : (
    <chakraComponents.Option {...props} />
  );
};
