import {
  AggregationType,
  IntegrationType,
  Property,
  PropertyReportVendorType
} from "@elphi/types";
import { propertySpecFields } from "../../../../form-builder/field-specs/property/property.fields";
import { OnChangeInput, Section } from "../../../../form-builder/FormBuilder";
import { buildInputs } from "../../../../form-builder/formBuilder.utils";
import { stringValidation } from "../../../../utils/validationUtils";
import {
  propertySiteXAddressLineTextFieldPath,
  propertySiteXAddressUnitIdentifierFieldPath,
  propertySiteXAssessorParcelNumberFieldPath,
  propertySiteXCityNameFieldPath,
  propertySiteXCountyNameFieldPath,
  propertySiteXFipsFieldPath,
  propertySiteXPostalCodeFieldPath,
  propertySiteXStateCodeFieldPath
} from "../../../field-paths/property.fieldPaths";

export const siteXVerifySection = (
  state: Partial<Property>,
  onChange: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Property Report: Verify",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: propertySpecFields,
      specs: [
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.PropertyReport
            ]?.[PropertyReportVendorType.SiteX].addressLineText?.focused !==
            "override",
          path: propertySiteXAddressLineTextFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.PropertyReport
            ]?.[PropertyReportVendorType.SiteX].addressUnitIdentifier
              ?.focused !== "override",
          path: propertySiteXAddressUnitIdentifierFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.PropertyReport
            ]?.[PropertyReportVendorType.SiteX].cityName?.focused !==
            "override",
          path: propertySiteXCityNameFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.PropertyReport
            ]?.[PropertyReportVendorType.SiteX].stateCode?.focused !==
            "override",
          path: propertySiteXStateCodeFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.PropertyReport
            ]?.[PropertyReportVendorType.SiteX].postalCode?.focused !==
            "override",
          path: propertySiteXPostalCodeFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.PropertyReport
            ]?.[PropertyReportVendorType.SiteX].countyName?.focused !==
            "override",
          path: propertySiteXCountyNameFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.PropertyReport
            ]?.[PropertyReportVendorType.SiteX].assessorParcelNumber
              ?.focused !== "override",
          path: propertySiteXAssessorParcelNumberFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.PropertyReport
            ]?.[PropertyReportVendorType.SiteX].fips?.focused !== "override",
          path: propertySiteXFipsFieldPath,
          validation: stringValidation
        }
      ]
    })
  };
};
