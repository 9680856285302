import { ChevronDownIcon } from "@chakra-ui/icons";
import { Button, Menu, MenuButton, MenuButtonProps } from "@chakra-ui/react";

export type CustomMenuProps = {
  icon?: JSX.Element;
  rightTitleIcon?: JSX.Element;
  title?: string;
  children: React.ReactNode;
} & MenuButtonProps;

export const CustomMenu = ({
  icon,
  rightTitleIcon,
  title = "",
  children,
  ...menuButtonProps
}: CustomMenuProps) => {
  return (
    <Menu size={"500px"}>
      <MenuButton
        as={Button}
        aria-label="Menu"
        onClick={(e) => e.stopPropagation()}
        rightIcon={icon || <ChevronDownIcon w={4} h={4} />}
        background={"transparent"}
        fontSize={14}
        fontWeight={"medium"}
        alignItems={"center"}
        {...menuButtonProps}
      >
        {title}
        {!!rightTitleIcon && rightTitleIcon}
      </MenuButton>
      {children}
    </Menu>
  );
};
