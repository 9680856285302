import { Button, Flex } from "@chakra-ui/react";
import { RoleGroup, Status } from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { isEmpty, uniqBy } from "lodash";
import { useMemo } from "react";
import { useFilterMenuHook } from "../../../common-components/menu-options/filter-menu-hook";
import { FilterMenuOptions } from "../../../common-components/menu-options/FilterMenuOptions";
import {
  useRoleGroupFilterHooks,
  useRoleGroupHooks
} from "../../../hooks/roleGroup.hooks";
import { FieldGroupSliceState } from "../../../redux/v2/field-group";

const namePath: DotNestedKeys<RoleGroup> = "name";
export const RoleGroupFilters = () => {
  const filterMenuHook = useFilterMenuHook();
  const { roleGroupState, searchApi } = useRoleGroupHooks();
  const { setCurrentFilter } = useRoleGroupFilterHooks();
  const { clearFilters } = filterMenuHook;

  const handleClearClick = () => {
    clearFilters();
    setCurrentFilter({
      values: {
        name: [],
        status: []
      }
    });
  };

  const isDisable = !roleGroupState.filters?.current
    ? true
    : Object.keys(roleGroupState.filters?.current).every((key) =>
        isEmpty(roleGroupState.filters?.current[key])
      );

  const nameOptions = useMemo(() => {
    const fgNames = uniqBy(Object.values(roleGroupState.entities), namePath);
    return fgNames.map((rg: RoleGroup) => ({
      value: rg.name,
      label: rg.name
    }));
  }, [roleGroupState.entities]);

  const onFilterChange = (
    filter: FieldGroupSliceState["filters"]["current"]
  ) => {
    setCurrentFilter({
      values: {
        ...roleGroupState.filters.current,
        ...filter
      }
    });
  };
  return (
    <Flex>
      <FilterMenuOptions
        customKey={"namesFilter"}
        maxHeight={"300px"}
        scrollHeight={"300px"}
        filterMenuHook={filterMenuHook}
        title={"Name"}
        options={nameOptions}
        searchApi={searchApi}
        showSearchBar={true}
        onChange={(items: string[]) => onFilterChange({ name: items })}
        selected={roleGroupState.filters.current.name}
        showClearAll
      />
      <FilterMenuOptions
        customKey={"statusFilter"}
        maxHeight={"300px"}
        scrollHeight={"300px"}
        filterMenuHook={filterMenuHook}
        title={"Status"}
        options={[
          { label: "Active", value: Status.Active },
          { label: "Disabled", value: Status.Disabled }
        ]}
        onChange={(items: string[]) => onFilterChange({ status: items })}
        selected={roleGroupState.filters.current.status}
      />
      <Button
        mr={1}
        background={"transparent"}
        fontSize={14}
        fontWeight={"normal"}
        onClick={handleClearClick}
        isDisabled={isDisable}
      >
        Clear filters
      </Button>
    </Flex>
  );
};
