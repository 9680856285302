import { Property } from "@elphi/types";
import { OnChangeInput, Section } from "../../../../form-builder/FormBuilder";
import { propertySpecFields } from "../../../../form-builder/field-specs/property/property.fields";
import { buildInputs } from "../../../../form-builder/formBuilder.utils";
import {
  numberValidation,
  singleSelectValidation,
  stringValidation
} from "../../../../utils/validationUtils";
import {
  propertyAddressLineTextFieldPath,
  propertyAddressUnitIdentifierFieldPath,
  propertyAreaValueFieldPath,
  propertyCityNameFieldPath,
  propertyPostalCodeFieldPath,
  propertyStateCodeFieldPath,
  propertyStructureBuiltYearFieldPath,
  propertyTypeFieldPath,
  totalBathroomCountFieldPath,
  totalBedroomCountFieldPath
} from "../../../field-paths/property.fieldPaths";

export const rentRangeOrderSection = (
  state: Partial<Property>,
  onChange: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Integration Rental Data: Order",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: propertySpecFields,
      specs: [
        {
          path: propertyAddressLineTextFieldPath,
          isRequired: true,
          validation: (x) => stringValidation(x, true)
        },
        {
          path: propertyAddressUnitIdentifierFieldPath,
          validation: stringValidation
        },
        {
          path: propertyCityNameFieldPath,
          isRequired: true,
          validation: (x) => stringValidation(x, true)
        },
        {
          path: propertyStateCodeFieldPath,
          isRequired: true,
          validation: (x) => singleSelectValidation(x, true)
        },
        {
          path: propertyPostalCodeFieldPath,
          isRequired: true,
          validation: (x) => stringValidation(x, true)
        },
        {
          path: propertyTypeFieldPath,
          validation: singleSelectValidation
        },
        {
          path: totalBedroomCountFieldPath,
          validation: numberValidation
        },
        {
          path: totalBathroomCountFieldPath,
          validation: numberValidation
        },
        {
          path: propertyStructureBuiltYearFieldPath,
          validation: numberValidation
        },
        {
          path: propertyAreaValueFieldPath,
          validation: numberValidation
        }
      ]
    })
  };
};
