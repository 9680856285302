import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import { Asset, Statement } from "@elphi/types";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { useAssetHooks } from "../../hooks/asset.hooks";

import { getStatementTemplate } from "@elphi/types";
import { createStatementSection } from "../application/sections/statement.sections";
import { ButtonIconAdd } from "../button-icon/ButtonIcon";
import FormBuilder from "../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";
import { useElphiToast } from "../toast/toast.hook";

export const CreateStatementForm = (props: {
  onSuccess?: () => void;
  isLoading?: boolean;
  selectedAsset: Partial<Asset>;
}) => {
  const { selectedAsset } = props;
  const { addStatementToAsset, addStatementToAssetResponse } = useAssetHooks();
  const { errorToast, successToast } = useElphiToast();

  const { onChange: statementOnChange, state: statementState } =
    useFormBuilderStateHandler<Statement>({
      initialState: getStatementTemplate()
    });

  const currentState = statementState;

  const isStateValidForCreation = !statementState.end;

  const createStatementHandler = async () => {
    const newStatement = { ...currentState };
    const selectedAssetId = (selectedAsset && selectedAsset?.id) || "";
    return (
      selectedAssetId &&
      (await addStatementToAsset({
        assetId: selectedAssetId,
        statement: newStatement
      }).then((r) => {
        if (r.status === 200) {
          successToast({
            title: "Statement Created",
            description: `new statement: ${r.data.statement.id}`
          });
          props?.onSuccess && props?.onSuccess();
        }
        r.status === 400 &&
          errorToast({
            title: "Failed to create statement",
            description: r.data.description
          });
        return r;
      }))
    );
  };
  return (
    <>
      <Box>
        <FormBuilder
          customKey="createStatementSection"
          elphiView={"form"}
          onChange={statementOnChange}
          sections={[
            createStatementSection({
              state: statementState,
              options: { hideAttachedComponent: true }
            })
          ]}
        />
      </Box>
      <Button
        {...elphiTheme.components.light.button.primary}
        onClick={() => createStatementHandler()}
        isDisabled={isStateValidForCreation}
        isLoading={addStatementToAssetResponse.isLoading || props?.isLoading}
      >
        Create
      </Button>
    </>
  );
};

export const CreateStatementModal = (props: {
  selectedAsset: Partial<Asset>;
  isDisabled?: boolean;
}) => {
  const { selectedAsset } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <>
        <ButtonIconAdd isDisabled={props.isDisabled} handleOpen={onOpen} />
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent minWidth={"600px"}>
            <ModalHeader>New Statement</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <CreateStatementForm
                onSuccess={onClose}
                selectedAsset={selectedAsset}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    </Box>
  );
};
