import { Box } from "@chakra-ui/react";
import DocumentRuleBuilder from "./document-rule/DocumentRuleBuilder";

const DocumentRuleTab = () => {
  return (
    <Box h="100%" w="100%">
      <DocumentRuleBuilder />
    </Box>
  );
};

export default DocumentRuleTab;
