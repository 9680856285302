import { Flex, Text } from "@chakra-ui/react";
import { DealCalendarParty, PartyType } from "@elphi/types";
import { CustomCollapse } from "../../../../../common-components";
import { AttachedFieldStatusComponet } from "../../../../form-builder/AttachedFieldStatusComponent";
import {
  HumanCyan100Icon,
  HumanPurple100Icon,
  MaximizeIcon,
  MinimizeIcon
} from "../../../../icons";
import { getFullName } from "../../../dealCalendar.utils";
import { PartyEditSection } from "./PartyEditSection";

type PartySectionProps = {
  dealId: string;
  dealCalendarParty: DealCalendarParty;
};

const CollapsedBody = (p: { party: DealCalendarParty }) => (
  <Flex flexDir={"column"} p={"6px"}>
    {p?.party?.relationRoleType && (
      <Flex flexDir={"row"} alignItems={"center"}>
        <AttachedFieldStatusComponet
          containerStyle={{ pt: "0px", pl: "0px" }}
          fieldPath={["dealPartyFieldMeta", "relationRoleType"]}
          prefix={[""]}
          state={p.party}
          status={p.party?.["dealPartyFieldMeta"]?.["relationRoleType"]?.status}
        />
        <Flex>
          <Text fontWeight={"700"} mr={"2px"}>
            Deal-Party Role:
          </Text>
          <Text>{p?.party?.relationRoleType?.join(", ")}</Text>
        </Flex>
      </Flex>
    )}
    <Flex mt="5px" alignItems={"center"}>
      <AttachedFieldStatusComponet
        containerStyle={{ pt: "0px", pl: "0px" }}
        fieldPath={
          p.party.partyType === PartyType.Individual
            ? ["partyFieldMeta", "LOCStatusType"]
            : ["partyFieldMeta", "PartyMilestone"]
        }
        prefix={[""]}
        state={p.party}
        status={
          p.party.partyType === PartyType.Individual
            ? p.party?.["partyFieldMeta"]?.["LOCStatusType"]?.status
            : p.party?.["partyFieldMeta"]?.["PartyMilestone"]?.status
        }
      />
      <Text fontWeight={"700"} mr={"2px"}>
        {p?.party?.partyType === PartyType.Entity
          ? "Party Milestone:"
          : "Line of Credit (LOC) Status:"}
      </Text>
      <Text>{p?.party?.partyMilestone || p?.party?.locStatusType}</Text>
    </Flex>
  </Flex>
);

export const PartySection = ({
  dealCalendarParty,
  dealId
}: PartySectionProps) => {
  const getTitle = (party: DealCalendarParty) => {
    const type = party.partyType?.at?.(0) || "";
    const fullName = getFullName(party);
    return `${type} | ${fullName}`;
  };

  return (
    <CustomCollapse
      title={getTitle(dealCalendarParty)}
      options={{
        containerColor: "white",
        mb: "5px",
        rightHeaderIcon:
          dealCalendarParty.partyType === PartyType.Entity ? (
            <HumanPurple100Icon w={"20px"} h={"20px"} ml={"3px"} />
          ) : (
            <HumanCyan100Icon w={"20px"} h={"20px"} ml={"3px"} />
          ),
        collapseIcon: <MinimizeIcon w={4} h={4} />,
        expandIcon: <MaximizeIcon w={4} h={4} />,
        titleContainer: {
          fontSize: 14,
          fontWeight: 700
        }
      }}
      collapsedBody={<CollapsedBody party={dealCalendarParty} />}
    >
      <PartyEditSection
        dealCalendarParty={dealCalendarParty}
        dealId={dealId!}
      />
    </CustomCollapse>
  );
};
