import { Box } from "@chakra-ui/react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import StyledInputBuilder, {
  valueContainerBySize
} from "../form-builder/InputBuilder";
import { FieldType } from "../form-builder/fieldFormat.types";
import { isMobileDevice } from "../utils/mobileUtils";

const PartyUserAssignment = (props: {
  state: { relations: string[] };
  onChange: any;
  hasMore: boolean | undefined;
  fetchMore: (() => void) | undefined;
  partyId: string;
  size: "s" | "m" | "l";
}) => {
  const losUserState = useSelector((state: RootState) => state.losUser);

  const maxW = isMobileDevice() ? "310px" : "900px";

  const options = useMemo(() => {
    return losUserState.ids
      .map((userId) => {
        return {
          label: `${losUserState?.entities?.[userId]?.name || ""} (${
            losUserState?.entities?.[userId]?.email || ""
          })`,
          value: userId as string
        };
      })
      .filter((option) => !!option.label);
  }, [losUserState]);

  const onChangeBatch = (v) => {
    props.onChange({
      fieldType: FieldType.MultiSelect,
      fieldKey: ["relations"],
      value: v.target.value
    });
  };

  const currentState = useMemo(() => {
    const newRelations = props.state.relations.map((userId) => {
      return userId as string;
    });
    return newRelations;
  }, [props.state.relations, losUserState]);

  return (
    <Box maxW={maxW}>
      <StyledInputBuilder
        currentValue={currentState}
        onChange={onChangeBatch}
        options={options}
        fieldType={FieldType.MultiSelect}
        hasMore={props.hasMore}
        fetchMore={props.fetchMore}
        label={"search"}
        isValid={true}
        customComponent={{
          ValueContainer: valueContainerBySize[props.size || "m"]
        }}
        chakraStyles={{
          dropdownIndicator: (prev, { selectProps: _ }) => ({
            ...prev,
            w: "20px",
            p: "0px"
          })
        }}
        hideSelectedOptions={false}
      />
    </Box>
  );
};

export default PartyUserAssignment;
