import { Flex } from "@chakra-ui/react";
import { LabelValue } from "@elphi/types";
import { DATES_FORMATS, elphiDate } from "@elphi/utils";
import { SelectOptions } from "../../common-components/select-options/SelectOptions";
import { useDealCalendarHooks } from "../../hooks/dealCalendar.hooks";
import { DateType } from "../../shared/types/deal-calendar.types";
import { SingleDatePicker } from "../custom/date-picker/SingleDatePicker";

export type DealCalendarMenuProps = {};

const DateTypeOptions: LabelValue<string, DateType>[] = [
  { value: "closingDate", label: "Closing Date" },
  { value: "createdAt", label: "Created At" }
];

export const DealCalendarMenu = ({}) => {
  const {
    setSelectedCalendarViewDate,
    selectedCalendarViewDate,
    setSelectedDateType,
    selectedDateType
  } = useDealCalendarHooks();
  const { selectedMonth, selectedYear } = selectedCalendarViewDate;
  const selectedDate = elphiDate()
    .year(selectedYear)
    .month(selectedMonth)
    .format(DATES_FORMATS.MMM_YYYY);

  const handleSelectedDateChange = (event) => {
    const [selectedYear, selectedMonth] = event.target.value.split("-");
    setSelectedCalendarViewDate({
      year: parseInt(selectedYear),
      month: parseInt(selectedMonth) - 1
    });
  };

  const handleSelectedDateTypeChange = (option) => {
    const newDateType = option.value;
    setSelectedDateType({ dateType: newDateType });
  };

  return (
    <Flex ml={"2px"}>
      <SingleDatePicker
        value={selectedDate}
        onChange={handleSelectedDateChange}
        showMonthYearPicker
        dateFormat={DATES_FORMATS.YYYY_MM}
        inputDateFormat={DATES_FORMATS.MMM_YYYY}
      />
      <SelectOptions
        value={selectedDateType}
        options={DateTypeOptions}
        onSelect={handleSelectedDateTypeChange}
      />
    </Flex>
  );
};
