import { Box, Text } from "@chakra-ui/react";
import { Property } from "@elphi/types";

import { useEffect, useMemo } from "react";
import { AppConfig } from "../../config/appConfig";
import { usePropertyFormHandler } from "../../hooks/propertyform.hooks";

import { AggregationType } from "@elphi/types";
import { RowLabels } from "../../redux/v2/table/taxAssessmentsTable.types";
import { OnChangeInput } from "../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";
import { propertySpecFields } from "../form-builder/field-specs/property/property.fields";
import { getFocusedAggregationSpecs } from "../form-builder/field-specs/utils/aggregation.utils";
import { ElphiTable } from "../table/ElphiTableComponent";
import { ElphiCellType, ElphiTableProps } from "../table/table.types";
import { TableInput } from "../utils/tableUtils";
import AggregationFooter, { AggregationColumnTypes } from "./AggregationFooter";

const TaxAssessmentsTable = (props: {
  selectedProperty?: Property;
  snapshotId?: string;
}) => {
  const cellMinWidth = "150px";
  const cellMaxWidth = "150px";
  const isLoading = false;

  const { selectedProperty } = props;
  const { updatePropertyHandler } = usePropertyFormHandler();

  const { onChange, state, syncState } = useFormBuilderStateHandler({
    initialState: { properties: {} },
    callback: props.snapshotId ? undefined : updatePropertyHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  useEffect(() => {
    if (selectedProperty) {
      syncState({
        shouldSync: !!selectedProperty,
        state: selectedProperty,
        statePath: () => {
          if (selectedProperty) {
            return ["properties", selectedProperty.id];
          }
        }
      });
    }
  }, [selectedProperty, props.snapshotId]);

  const propertyState =
    selectedProperty && state.properties[selectedProperty.id];

  const propertyOnChangeBatch = (v: OnChangeInput) => {
    selectedProperty &&
      !props.snapshotId &&
      onChange({
        fieldType: v.fieldType,
        fieldKey: ["properties", selectedProperty.id, ...v.fieldKey],
        value: v.value
      });
  };

  const taxAssessmentsTableHeader: ElphiTableProps["header"] = useMemo(() => {
    return [
      {
        index: 0,
        data: "",
        type: ElphiCellType.String,
        maxWidth: cellMaxWidth,
        minWidth: cellMinWidth
      },
      {
        index: 1,
        data: "Annual Taxes",
        type: ElphiCellType.String,
        maxWidth: cellMaxWidth,
        minWidth: cellMinWidth
      },
      {
        index: 2,
        data: "Initial Tax Escrow (Months)",
        type: ElphiCellType.String,
        maxWidth: cellMaxWidth,
        minWidth: cellMinWidth
      },
      {
        index: 3,
        data: "Initial Tax Escrow",
        type: ElphiCellType.String,
        maxWidth: cellMaxWidth,
        minWidth: cellMinWidth
      },
      {
        index: 3.5,
        data: "Tax Due Date",
        type: ElphiCellType.String,
        maxWidth: cellMaxWidth,
        minWidth: cellMinWidth
      },
      {
        index: 4,
        data: "Tax Billing Frequency",
        type: ElphiCellType.String,
        maxWidth: cellMaxWidth,
        minWidth: cellMinWidth
      },
      {
        index: 5,
        data: "Taxing Authority",
        type: ElphiCellType.String,
        maxWidth: cellMaxWidth,
        minWidth: cellMinWidth
      }
    ];
  }, []);

  const taxAssessmentsTableFullDataRows: ElphiTableProps["rows"] =
    useMemo(() => {
      return propertyState && selectedProperty
        ? [
            {
              index: "1",
              cells: [
                {
                  index: 0,
                  type: ElphiCellType.Element,
                  data: <Text fontWeight="bold">{RowLabels.City.label}</Text>
                },
                {
                  index: 1,
                  type: ElphiCellType.Element,
                  data: (
                    <Box>
                      <TableInput
                        specFields={propertySpecFields.CityAnnualTaxAmount}
                        prefix={[]}
                        state={propertyState}
                        currentEntity={selectedProperty}
                        onChange={propertyOnChangeBatch}
                      />
                    </Box>
                  ),
                  maxWidth: "250px",
                  minWidth: "250px"
                },
                {
                  index: 2,
                  type: ElphiCellType.Element,
                  data: (
                    <Box w="100%">
                      <TableInput
                        isAggregation={AggregationType.Aggregation}
                        specFields={getFocusedAggregationSpecs({
                          focused:
                            propertyState.aggregations
                              ?.CityInitialTaxEscrowMonthCount?.focused,
                          spec: propertySpecFields!.aggregations!
                            .CityInitialTaxEscrowMonthCount
                        })}
                        prefix={[]}
                        state={propertyState}
                        currentEntity={selectedProperty}
                        onChange={propertyOnChangeBatch}
                      />
                    </Box>
                  ),
                  maxWidth: "300px",
                  minWidth: "300px"
                },
                {
                  index: 3,
                  type: ElphiCellType.Element,
                  data: (
                    <Box>
                      <TableInput
                        isAggregation={AggregationType.Aggregation}
                        specFields={getFocusedAggregationSpecs({
                          focused:
                            propertyState.aggregations
                              ?.CityInitialTaxEscrowAmount?.focused,
                          spec: propertySpecFields.aggregations!
                            .CityInitialTaxEscrowAmount
                        })}
                        prefix={[]}
                        state={propertyState}
                        currentEntity={selectedProperty}
                        onChange={propertyOnChangeBatch}
                      />
                    </Box>
                  ),
                  maxWidth: "400px",
                  minWidth: "400px"
                },
                {
                  index: 4,
                  type: ElphiCellType.Element,
                  data: (
                    <Box>
                      <TableInput
                        specFields={propertySpecFields.CityTaxDueDate}
                        prefix={[]}
                        state={propertyState}
                        currentEntity={selectedProperty}
                        onChange={propertyOnChangeBatch}
                      />
                    </Box>
                  ),
                  maxWidth: "250px",
                  minWidth: "250px"
                },
                {
                  index: 5,
                  type: ElphiCellType.Element,
                  data: (
                    <Box>
                      <TableInput
                        specFields={
                          propertySpecFields.CityTaxBillingFrequencyType
                        }
                        prefix={[]}
                        state={propertyState}
                        currentEntity={selectedProperty}
                        onChange={propertyOnChangeBatch}
                      />
                    </Box>
                  ),
                  maxWidth: "280px",
                  minWidth: "280px"
                },
                {
                  index: 6,
                  type: ElphiCellType.Element,
                  data: (
                    <Box>
                      <TableInput
                        specFields={propertySpecFields.CityTaxingAuthority}
                        prefix={[]}
                        state={propertyState}
                        currentEntity={selectedProperty}
                        onChange={propertyOnChangeBatch}
                      />
                    </Box>
                  ),
                  maxWidth: "250px",
                  minWidth: "250px"
                }
              ]
            },
            {
              index: "2",
              cells: [
                {
                  index: 0,
                  type: ElphiCellType.Element,
                  data: <Text fontWeight="bold">{RowLabels.County.label}</Text>
                },
                {
                  index: 1,
                  type: ElphiCellType.Element,
                  data: (
                    <Box>
                      <TableInput
                        specFields={propertySpecFields.CountyAnnualTaxAmount}
                        prefix={[]}
                        state={propertyState}
                        currentEntity={selectedProperty}
                        onChange={propertyOnChangeBatch}
                      />
                    </Box>
                  ),
                  maxWidth: "250px",
                  minWidth: "250px"
                },
                {
                  index: 2,
                  type: ElphiCellType.Element,
                  data: (
                    <Box>
                      <TableInput
                        isAggregation={AggregationType.Aggregation}
                        specFields={getFocusedAggregationSpecs({
                          focused:
                            propertyState.aggregations
                              ?.CountyInitialTaxEscrowMonthCount?.focused,
                          spec: propertySpecFields.aggregations!
                            .CountyInitialTaxEscrowMonthCount
                        })}
                        prefix={[]}
                        state={propertyState}
                        currentEntity={selectedProperty}
                        onChange={propertyOnChangeBatch}
                      />
                    </Box>
                  ),
                  maxWidth: "100px",
                  minWidth: "100px"
                },
                {
                  index: 3,
                  type: ElphiCellType.Element,
                  data: (
                    <Box>
                      <TableInput
                        isAggregation={AggregationType.Aggregation}
                        specFields={getFocusedAggregationSpecs({
                          focused:
                            propertyState.aggregations
                              ?.CountyInitialTaxEscrowAmount?.focused,
                          spec: propertySpecFields.aggregations!
                            .CountyInitialTaxEscrowAmount
                        })}
                        prefix={[]}
                        state={propertyState}
                        currentEntity={selectedProperty}
                        onChange={propertyOnChangeBatch}
                      />
                    </Box>
                  ),
                  maxWidth: "250px",
                  minWidth: "250px"
                },
                {
                  index: 4,
                  type: ElphiCellType.Element,
                  data: (
                    <Box>
                      <TableInput
                        specFields={propertySpecFields.CountyTaxDueDate}
                        prefix={[]}
                        state={propertyState}
                        currentEntity={selectedProperty}
                        onChange={propertyOnChangeBatch}
                      />
                    </Box>
                  ),
                  maxWidth: "250px",
                  minWidth: "250px"
                },
                {
                  index: 5,
                  type: ElphiCellType.Element,
                  data: (
                    <Box>
                      <TableInput
                        specFields={
                          propertySpecFields.CountyTaxBillingFrequencyType
                        }
                        prefix={[]}
                        state={propertyState}
                        currentEntity={selectedProperty}
                        onChange={propertyOnChangeBatch}
                      />
                    </Box>
                  ),
                  maxWidth: "250px",
                  minWidth: "250px"
                },
                {
                  index: 6,
                  type: ElphiCellType.Element,
                  data: (
                    <Box>
                      <TableInput
                        specFields={propertySpecFields.CountyTaxingAuthority}
                        prefix={[]}
                        state={propertyState}
                        currentEntity={selectedProperty}
                        onChange={propertyOnChangeBatch}
                      />
                    </Box>
                  ),
                  maxWidth: "250px",
                  minWidth: "250px"
                }
              ]
            },
            {
              index: "3",
              cells: [
                {
                  index: 0,
                  type: ElphiCellType.Element,
                  data: (
                    <Text fontWeight="bold">
                      {RowLabels.SpecialAssessment.label}
                    </Text>
                  )
                },
                {
                  index: 1,
                  type: ElphiCellType.Element,
                  data: (
                    <Box>
                      <TableInput
                        specFields={
                          propertySpecFields.SpecialAssessmentAnnualTaxAmount
                        }
                        prefix={[]}
                        state={propertyState}
                        currentEntity={selectedProperty}
                        onChange={propertyOnChangeBatch}
                      />
                    </Box>
                  ),
                  maxWidth: "280px",
                  minWidth: "280px"
                },
                {
                  index: 2,
                  type: ElphiCellType.Element,
                  data: (
                    <Box>
                      <TableInput
                        isAggregation={AggregationType.Aggregation}
                        specFields={getFocusedAggregationSpecs({
                          focused:
                            propertyState.aggregations
                              ?.SpecialAssessmentInitialTaxEscrowMonthCount
                              ?.focused,
                          spec: propertySpecFields.aggregations
                            ?.SpecialAssessmentInitialTaxEscrowMonthCount
                        })}
                        prefix={[]}
                        state={propertyState}
                        currentEntity={selectedProperty}
                        onChange={propertyOnChangeBatch}
                      />
                    </Box>
                  ),
                  maxWidth: "100px",
                  minWidth: "100px"
                },
                {
                  index: 3,
                  type: ElphiCellType.Element,
                  data: (
                    <Box>
                      <TableInput
                        isAggregation={AggregationType.Aggregation}
                        specFields={getFocusedAggregationSpecs({
                          focused:
                            propertyState.aggregations
                              ?.SpecialAssessmentInitialTaxEscrowAmount
                              ?.focused,
                          spec: propertySpecFields.aggregations
                            ?.SpecialAssessmentInitialTaxEscrowAmount
                        })}
                        prefix={[]}
                        state={propertyState}
                        currentEntity={selectedProperty}
                        onChange={propertyOnChangeBatch}
                      />
                    </Box>
                  ),
                  maxWidth: "250px",
                  minWidth: "250px"
                },
                {
                  index: 4,
                  type: ElphiCellType.Element,
                  data: (
                    <Box>
                      <TableInput
                        specFields={
                          propertySpecFields.SpecialAssessmentTaxDueDate
                        }
                        prefix={[]}
                        state={propertyState}
                        currentEntity={selectedProperty}
                        onChange={propertyOnChangeBatch}
                      />
                    </Box>
                  ),
                  maxWidth: "250px",
                  minWidth: "250px"
                },
                {
                  index: 5,
                  type: ElphiCellType.Element,
                  data: (
                    <Box>
                      <TableInput
                        specFields={
                          propertySpecFields.SpecialAssessmentTaxBillingFrequencyType
                        }
                        prefix={[]}
                        state={propertyState}
                        currentEntity={selectedProperty}
                        onChange={propertyOnChangeBatch}
                      />
                    </Box>
                  ),
                  maxWidth: "250px",
                  minWidth: "250px"
                },
                {
                  index: 6,
                  type: ElphiCellType.Element,
                  data: (
                    <Box>
                      <TableInput
                        specFields={
                          propertySpecFields.SpecialAssessmentTaxingAuthority
                        }
                        prefix={[]}
                        state={propertyState}
                        currentEntity={selectedProperty}
                        onChange={propertyOnChangeBatch}
                      />
                    </Box>
                  ),
                  maxWidth: "250px",
                  minWidth: "250px"
                }
              ]
            }
          ]
        : [];
    }, [propertyState, selectedProperty, propertyOnChangeBatch]);

  const taxAssessmentsTableIsLoading = false;

  const taxAssessmentsTableProps: ElphiTableProps = useMemo(() => {
    return {
      header: taxAssessmentsTableHeader,
      rows: taxAssessmentsTableFullDataRows,
      aggregationFooter: selectedProperty && propertyState && (
        <AggregationFooter
          columns={[
            {
              columnType: AggregationColumnTypes.String,
              input: "Total Tax Escrow"
            },
            {
              columnType: AggregationColumnTypes.Empty
            },
            {
              columnType: AggregationColumnTypes.Empty
            },
            {
              columnType: AggregationColumnTypes.Aggregation,
              input: (
                <Box>
                  <TableInput
                    isAggregation={AggregationType.Aggregation}
                    specFields={getFocusedAggregationSpecs({
                      focused:
                        propertyState.aggregations?.TotalTaxEscrowAmount
                          ?.focused,
                      spec: propertySpecFields.aggregations
                        ?.TotalTaxEscrowAmount
                    })}
                    prefix={[]}
                    state={propertyState}
                    currentEntity={selectedProperty}
                    onChange={propertyOnChangeBatch}
                  />
                </Box>
              )
            },
            {
              columnType: AggregationColumnTypes.Empty
            }
          ]}
          width={"200px"}
        />
      ),
      isLoading: taxAssessmentsTableIsLoading,
      rowsCount: 1
    };
  }, [taxAssessmentsTableFullDataRows, isLoading]);

  return (
    <>
      <ElphiTable
        minHeight={"350px"}
        maxHeight={"350px"}
        header={taxAssessmentsTableProps.header}
        rows={taxAssessmentsTableProps.rows}
        aggregationFooter={taxAssessmentsTableProps.aggregationFooter}
        rowsCount={taxAssessmentsTableProps.rowsCount}
        isLoading={taxAssessmentsTableProps.isLoading}
      />
    </>
  );
};

export default TaxAssessmentsTable;
