import { DocumentConfiguration } from "@elphi/types";
import { CellsFilterStateFlat } from "../../../table/hooks/filter.hook";

export const filterState: CellsFilterStateFlat<DocumentConfiguration> = {
  createdAt: {
    column: "Created At",
    orderBy: "asc",
    query: []
  },
  name: {
    column: "Document Name",
    orderBy: "asc",
    query: [],
    getData: ({ task }) => task?.name
  },
  documentEntity: {
    column: "Document Entity",
    orderBy: "asc",
    query: []
  },
  documentRuleTemplateId: {
    column: "Rule",
    orderBy: "asc",
    query: []
  },
  documentPackageType: {
    column: "Document Package",
    orderBy: "asc",
    query: []
  },
  autoGenerate: {
    column: "Auto Generate",
    orderBy: "asc",
    query: []
  },
  filePath: {
    column: "Document Template",
    orderBy: "asc",
    query: []
  },
  status: {
    column: "Status",
    orderBy: "asc",
    query: []
  }
};
