import { Flex, Text } from "@chakra-ui/react";
import MenuComponent from "../../../../../../common-components/menu-options/Menu";
import { OnChangeInput } from "../../../../../form-builder/FormBuilder";
import { MoreHorizontalIcon } from "../../../../../icons";
import { StepForm } from "../components/StepForm";
import { PropertyInsurancePolicyState } from "../types/insurancePolicySteps.types";
import { buildPropertyBuildingsSectionFields } from "./sections";

type BuildingContainerProps = {
  state: PropertyInsurancePolicyState;
  propertyId: string;
  isDisabled: boolean;
  index: number;
  sectionName: string;
  onDelete: (r: { propertyId: string; index: number }) => void;
  onChange: (r: OnChangeInput) => void;
};

export const BuildingContainer = (props: BuildingContainerProps) => {
  const {
    propertyId,
    index,
    onChange,
    onDelete,
    sectionName,
    isDisabled,
    state
  } = props;
  const buildingSection = buildPropertyBuildingsSectionFields({
    propertyId,
    buildingNumber: index.toString(),
    state,
    onChange,
    isReadOnly: isDisabled
  });
  return (
    <Flex
      borderRadius={6}
      borderWidth={1}
      borderColor={"gray.200"}
      p="16px"
      direction="column"
    >
      <Flex justifyContent="space-between" alignItems="center" w="100%">
        <Text p="8px" borderBottomWidth={1} borderColor={"gray.200"}>
          {sectionName}: Building {index + 1}
        </Text>
        {!isDisabled && (
          <MenuComponent
            icon={<MoreHorizontalIcon width={6} height={6} />}
            items={[
              {
                label: "Delete",
                onSelect: () =>
                  onDelete({
                    propertyId,
                    index
                  })
              }
            ]}
          />
        )}
      </Flex>
      <StepForm
        isDisabled={isDisabled}
        customKey={`insurancePolicyForm-${propertyId}`}
        onChange={onChange}
        sections={[buildingSection]}
      />
    </Flex>
  );
};
