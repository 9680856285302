import {
  closestCorners,
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { useState } from "react";
import { EMPTY } from "../../constants/common";
import { DNDPickerProps, DNDRow } from "./dnd.types";

export const useDNDPickerHooks = <T extends DNDRow<object>>(
  props: Pick<DNDPickerProps<T>, "items" | "handleOnPick">
) => {
  const { items, handleOnPick } = props;
  const [activeRow, setActiveRow] = useState<T>();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const findContainer = (id?: UniqueIdentifier) => {
    if (!id) {
      return undefined;
    }
    if (items?.[id]) {
      return id;
    }
    return Object.keys(items).find((key) =>
      items[key].some((item) => item?.id === id)
    );
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const container = active.data?.current?.sortable?.containerId || EMPTY;
    const row = items[container].find((item) => item?.id === active.id);
    setActiveRow(row);
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    const { id } = active;
    const overId = over?.id || EMPTY;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(over?.id);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    const activeItems = items[activeContainer];
    const overItems = items[overContainer];

    const activeIndex = activeItems.findIndex((x) => x?.id === id);
    const overIndex = overItems.findIndex((x) => x?.id === id);
    if (overIndex > -1) {
      return;
    }

    const newIndex = items?.[overId]
      ? overItems.length + 1
      : overIndex >= 0
      ? overIndex + (over && overIndex === overItems.length - 1 ? 1 : 0)
      : overItems.length + 1;

    const newState = {
      ...items,
      [activeContainer]: [
        ...items[activeContainer].filter((item) => item?.id !== active.id)
      ],
      [overContainer]: [
        ...items[overContainer].slice(0, newIndex),
        items[activeContainer][activeIndex],
        ...items[overContainer].slice(newIndex, items[overContainer].length)
      ]
    };
    handleOnPick(newState);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const { id } = active;
    const overId = over?.id || EMPTY;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }

    const activeItems = items[activeContainer];
    const overItems = items[overContainer];

    const activeIndex = activeItems.findIndex((x) => x?.id === id);
    const overIndex = overItems.findIndex((x) => x?.id === overId);

    if (activeIndex !== overIndex) {
      const newState = {
        ...items,
        [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex)
      };
      handleOnPick(newState);
    }
    setActiveRow(undefined);
  };

  return {
    activeRow,
    setActiveRow,
    sensors,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    closestCorners
  };
};
