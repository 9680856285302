import { Box, Button, Flex } from "@chakra-ui/react";
import { FieldType, TaskInstruction } from "@elphi/types";
import { useEffect } from "react";
import elphiTheme from "../../../assets/themes/elphi.theme.default";
import StyledInputBuilder, {
  useFormBuilderStateHandler
} from "../../form-builder/InputBuilder";
import { MenuRowAction } from "../../table/MenuRowAction";
import {
  SIZE_FIELD,
  TableRowSizeComponent,
  TableTextCell
} from "../../table/TableRowSizeComponent";

type TaskInstructionRowProps = {
  index: number;
  data: TaskInstruction;
  onClone: (id: string) => void;
  onConfigure: (id: string) => void;
  onChange: ReturnType<typeof useFormBuilderStateHandler>["onChange"];
  syncState: ReturnType<typeof useFormBuilderStateHandler>["syncState"];
  state: {
    [id: string]: Partial<TaskInstruction>;
  };
};

export const TaskInstructionRow = ({
  index,
  data,
  onChange,
  state,
  syncState,
  onClone,
  onConfigure
}: TaskInstructionRowProps) => {
  const { id } = data;

  useEffect(() => {
    syncState({
      state: data,
      shouldSync: true,
      statePath: () => {
        return [id];
      }
    });
  }, [data]);

  return (
    <Flex w="100%" justify="space-between">
      <TableRowSizeComponent size={SIZE_FIELD.T} withBorderRightWidth={false}>
        <TableTextCell text={index} />
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        <StyledInputBuilder
          currentValue={state[id]?.name}
          fieldType={FieldType.String}
          onChange={(e) => {
            onChange({
              fieldKey: [id, "name"],
              fieldType: FieldType.String,
              value: e.target.value
            });
          }}
          isValid={true}
        />
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        <Flex alignItems={"center"} justifyContent={"center"}>
          <Button
            {...elphiTheme.components.light.button.secondary}
            onClick={() => onConfigure(id)}
          >
            CONFIGURE INSTRUCTIONS
          </Button>
        </Flex>
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.S} withBorderRightWidth={false}>
        <Flex alignItems={"center"} justifyContent={"center"} gap={"18px"}>
          <Box w="fit-content">
            <StyledInputBuilder
              currentValue={state[id]?.status}
              fieldType={FieldType.Switch}
              onChange={(e) => {
                onChange({
                  fieldKey: [id, "status"],
                  fieldType: FieldType.Switch,
                  value: e.target.value
                });
              }}
            />
          </Box>
          <MenuRowAction
            onClone={() => {
              onClone(id);
            }}
          />
        </Flex>
      </TableRowSizeComponent>
    </Flex>
  );
};
