import { PartyValidationConfiguration, StatusCode } from "@elphi/types";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RTKResponse } from "../apis/rtk/response.types";
import { useElphiToast } from "../components/toast/toast.hook";
import { RootState } from "../redux/store";
import {
  partyValidationConfigApi,
  partyValidationConfigSlice
} from "../redux/v2/party-validation-configuration";

export const usePartyValidationConfig = () => {
  const dispatch = useDispatch();
  const { errorToast, successToast } = useElphiToast();

  const partyValidationConfigState = useSelector(
    (state: RootState) => state.partyValidationConfig
  );

  const [getPartyValidationConfig, partyValidationConfigResponse] =
    partyValidationConfigApi.useLazyGetQuery();
  const [updateApi] = partyValidationConfigApi.useUpdateMutation();

  const updateHandler = async (r: Partial<PartyValidationConfiguration>) => {
    return await updateApi(r).then((response: RTKResponse<{ id: string }>) => {
      const handlerResponse = responseHandler(response);
      const statusCode = handlerResponse.status;
      if (statusCode === StatusCode.OK) {
        successToast({
          title: "Party validation configuration updated",
          description: JSON.stringify(handlerResponse.data)
        });
      } else if (statusCode === StatusCode.BadRequest) {
        errorToast({
          title: "Failed to update party validation configuration",
          description: handlerResponse.data.description
        });
      }
      return handlerResponse;
    });
  };

  const setConfiguration = async () => {
    const { isError, error, data } = partyValidationConfigResponse;
    if (isError) {
      errorToast({
        title: "Failed to fetch party validation configuration",
        description: JSON.stringify(error)
      });
    }

    dispatch(partyValidationConfigSlice.actions.add(data || {}));
  };

  return {
    partyValidationConfigState,
    updateHandler,
    getPartyValidationConfig,
    partyValidationConfigResponse,
    setConfiguration,
    updateApi
  };
};
