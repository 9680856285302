import { DealMilestoneType, MilestoneData } from "@elphi/types";
import { isChangeReasonMilestoneGuard } from "../milestoneChangeReason/changeReasonRequiredForMilestones.utils";
import { MilestoneSelectionComponent } from "./MilestoneSelectionComponent";
import { MilestoneSelectionContainerProps } from "./MilestoneSelectionContainer";
export const MilestoneSelectionLiveStateContainer = <
  T extends DealMilestoneType
>(
  props: MilestoneSelectionContainerProps<T>
) => {
  return (
    <>
      {!!props.deal && (
        <MilestoneSelectionComponent
          dealId={props.dealId}
          deal={{
            ...props.deal,
            DealMetadata: {
              ...props.deal?.DealMetadata,
              milestone: props.newMilestoneState as DealMilestoneType
            }
          }}
          isDisabled={false}
          onChange={(e) => {
            const value = e.target.value;
            props.setPrevMilestoneState &&
              props.setPrevMilestoneState(
                props.deal?.DealMetadata?.milestone ?? ""
              );
            props.setNewMilestoneState && props.setNewMilestoneState(value);
            !!props.dealId &&
              !isChangeReasonMilestoneGuard(value) &&
              props.onMilestoneChanged &&
              props.onMilestoneChanged({
                type: value
              } as MilestoneData<T>);
          }}
          tooltipLabel={
            !!props.dealId ? props.deal?.DealMetadata?.milestone ?? "" : ""
          }
        />
      )}
    </>
  );
};
