import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
import { ChangeReasonMilestones } from "@elphi/types";
import { useCallback } from "react";
import elphiTheme from "../../../../assets/themes/elphi.theme.default";
import { EMPTY } from "../../../../constants/common";
import { ChangeReasonFormComponent } from "./DeadDealChangeReasonFormComponent";
import { DealState } from "./DeadDealChangeReasonLiveStateContainer";

export const ChangeReasonModalComponent = (props: {
  dealId: string;
  newDealMilestone: ChangeReasonMilestones;
  lastDealMilestone: string;
  disclosureModal: {
    isOpen: boolean;
    onClose: () => void;
  };
  form: {
    state: DealState;
    isLoading: boolean;
    onChange: () => void;
    genericReasonState: string;
    setGenericReasonState: React.Dispatch<React.SetStateAction<string>>;
    specificReasonState: string;
    setSpecificReasonState: React.Dispatch<React.SetStateAction<string>>;
  };
  revertToLastMilestone: () => void;
}) => {
  const { newDealMilestone, lastDealMilestone } = props;
  const milestone = newDealMilestone || lastDealMilestone;
  const dealMetadata = props?.form?.state[props.dealId]?.DealMetadata;
  const lastChangeReasonMilestoneData =
    dealMetadata && dealMetadata.milestoneData
      ? dealMetadata.milestoneData[milestone]
      : undefined;

  const closeModal = useCallback(() => {
    props.disclosureModal.onClose();
    props.revertToLastMilestone();
  }, [dealMetadata]);

  return (
    <Modal isOpen={props.disclosureModal.isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent minWidth={"600px"}>
        <ModalHeader pt="20px">
          <Box>You are about to set a {milestone.toLowerCase()} milestone,</Box>
          <Box pt="10px">please insert your reasons:</Box>
        </ModalHeader>
        <ModalCloseButton onClick={closeModal} />
        <ModalBody>
          <ChangeReasonFormComponent
            dealId={props.dealId}
            form={props.form}
            milestone={milestone}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            {...elphiTheme.components.light.button.primary}
            mr={3}
            onClick={() => {
              props.disclosureModal.onClose();
              props.form.onChange();
            }}
            isDisabled={
              props.form.genericReasonState === EMPTY ||
              props.form.specificReasonState === EMPTY ||
              (lastChangeReasonMilestoneData?.genericReason ===
                props.form.genericReasonState &&
                lastChangeReasonMilestoneData?.specificReason ===
                  props.form.specificReasonState &&
                (lastDealMilestone === EMPTY ||
                  newDealMilestone === lastDealMilestone))
            }
          >
            Save
          </Button>
          <Button variant="ghost" onClick={closeModal}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
