import { PartyRelation } from "@elphi/types";
import { elphiDecimal } from "@elphi/utils/src/elphiDecimal";
import { useCallback } from "react";
import { usePartyRelationHooks } from "../../../hooks/partyrelation.hooks";
import { removeNulls } from "../../../utils/filter.utils";

export const useCalculateUeoHook = () => {
  const { partyRelationState } = usePartyRelationHooks();
  const buildTree = useCallback(
    (r: {
      relations: PartyRelation[]; //{ [id: string]: PartyRelation };
      rootPartyId: string;
      calculate: (pc: number, cc: number) => number;
    }): { parties: { [id: string]: number } } => {
      const ueoMap: { [id: string]: number } = {
        [r.rootPartyId]: r.relations[r.rootPartyId]?.ownershipPercentage
          ? Number(r.relations[r.rootPartyId]?.ownershipPercentage)
          : 0
      };
      const root: UEONode<number> = {
        parent: undefined,
        calculated: 100,
        partyId: r.rootPartyId
      };
      const que: UEONode<number>[] = [root];
      while (que.length) {
        const currentNode = que.pop();
        if (currentNode) {
          r.relations
            .filter((rel) => rel.parentId === currentNode.partyId)
            .forEach((child) => {
              const calculated = child?.ownershipPercentage
                ? r.calculate(
                    currentNode?.calculated,
                    Number(child?.ownershipPercentage)
                  )
                : 0;
              const childNode: UEONode<number> = {
                parent: currentNode,
                calculated,
                partyId: child.childId
              };
              que.push(childNode);
              if (!(child.childId in ueoMap)) ueoMap[child.childId] = 0;
              ueoMap[child.childId] += calculated;
            });
        }
      }
      return { parties: ueoMap };
    },
    []
  );
  const calculatePartiesUEO = useCallback(
    (partyId: string) => {
      return buildTree({
        relations: Object.values(partyRelationState.entities).filter(
          removeNulls
        ),
        rootPartyId: partyId,
        calculate: (pc, cc) => {
          return elphiDecimal(pc)
            .mul(elphiDecimal(cc).div(100).toString())
            .toNumber();
        }
      });
    },
    [partyRelationState.entities]
  );
  return {
    calculatePartiesUEO
  };
};

export type UEONodeQue<V> = UEONode<V>[];
export type UEONode<V> = {
  parent: UEONode<V> | undefined;
  calculated: V;
  partyId: string;
};
