import { Property, RentalDataVendorType } from "@elphi/types";
import { OnChangeInput, Section } from "../../../form-builder/FormBuilder";
import { rentRangeSections } from "./rent-range";

export const rentalDataSections = (
  state: Partial<Property>,
  onChange: (v: OnChangeInput) => void
): { [key in RentalDataVendorType]: Section[] } => ({
  [RentalDataVendorType.RentRange]: rentRangeSections(state, onChange)
});
