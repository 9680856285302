import { Section } from "../../../../../../form-builder/FormBuilder";
import {
  basePropertyInsurancePolicyFieldSpecs,
  propertyInsurancePolicyCoveragesFieldBaseSpecs
} from "../../../../../../form-builder/field-specs/insurance-policy/property";
import { buildInputs } from "../../../../../../form-builder/formBuilder.utils";
import { stringValidation } from "../../../../../../utils/validationUtils";
import { PropertyInsurancePolicyState } from "../../types/insurancePolicySteps.types";
import { buildInput, entitiesPath } from "../../utils/formUtils";
import { getPropertyCoveragesSection } from "../utils/coveragesSection";

type PropertySectionProps = {
  onChange: (e: any) => void;
  propertyId: string;
  state: PropertyInsurancePolicyState;
  isReadOnly: boolean;
};

export const buildPropertySectionFields = (
  props: PropertySectionProps
): Section => {
  const { state, onChange, isReadOnly } = props;
  const prefix = [entitiesPath, props.propertyId];
  const {
    cAFairPlanCoverageAmount,
    hO6CoverageAmount,
    buildersRiskCoverageAmount,
    condoMasterFloodCoverageAmount,
    condoMasterHazardCoverageAmount,
    earthquakeCoverageAmount,
    excessFlood,
    floodCoverageAmount,
    hazardCoverageAmount,
    hazardReplacementCoverage,
    lavaCoverageAmount,
    liabilityCoverageAmount,
    rentLossCoverageAmount,
    windCoverageAmount
  } = getPropertyCoveragesSection({ ...props });

  const policyPremiumAmount = buildInput({
    fieldSpec: basePropertyInsurancePolicyFieldSpecs.policyPremiumAmount,
    isReadOnly,
    validation: stringValidation
  });
  return {
    inputs: buildInputs<Partial<PropertyInsurancePolicyState>>({
      hideAttachedComponent: true,
      state: state,
      prefixPathToValue: prefix,
      onChange,
      fieldSpecs: {
        ...propertyInsurancePolicyCoveragesFieldBaseSpecs,
        ...basePropertyInsurancePolicyFieldSpecs
      },
      specs: [
        policyPremiumAmount,
        cAFairPlanCoverageAmount,
        hO6CoverageAmount,
        buildersRiskCoverageAmount,
        condoMasterFloodCoverageAmount,
        condoMasterHazardCoverageAmount,
        earthquakeCoverageAmount,
        excessFlood,
        floodCoverageAmount,
        hazardCoverageAmount,
        hazardReplacementCoverage,
        lavaCoverageAmount,
        liabilityCoverageAmount,
        rentLossCoverageAmount,
        windCoverageAmount
      ]
    })
  };
};
