import {
  OnChangeInput,
  Section
} from "../../../../../../form-builder/FormBuilder";
import {
  propertyBuildingsFieldSpecs,
  propertyInsurancePolicyCoveragesFieldBaseSpecs
} from "../../../../../../form-builder/field-specs/insurance-policy/property";
import { buildInputs } from "../../../../../../form-builder/formBuilder.utils";
import { stringValidation } from "../../../../../../utils/validationUtils";
import { PropertyInsurancePolicyState } from "../../types/insurancePolicySteps.types";
import { buildInput, entitiesPath } from "../../utils/formUtils";
import {
  buildingsPath,
  getPropertyCoveragesSection
} from "../utils/coveragesSection";

type PropertyBuildingsSectionProps = {
  propertyId: string;
  buildingNumber: string;
  onChange: (v: OnChangeInput) => void;
  state: PropertyInsurancePolicyState;
  isReadOnly: boolean;
};
export const buildPropertyBuildingsSectionFields = (
  props: PropertyBuildingsSectionProps
): Section => {
  const { state, onChange, isReadOnly, buildingNumber } = props;
  const prefix = [
    entitiesPath,
    props.propertyId,
    buildingsPath,
    buildingNumber
  ];
  const {
    cAFairPlanCoverageAmount,
    hO6CoverageAmount,
    buildersRiskCoverageAmount,
    condoMasterFloodCoverageAmount,
    condoMasterHazardCoverageAmount,
    earthquakeCoverageAmount,
    excessFlood,
    floodCoverageAmount,
    hazardCoverageAmount,
    hazardReplacementCoverage,
    lavaCoverageAmount,
    liabilityCoverageAmount,
    rentLossCoverageAmount,
    windCoverageAmount
  } = getPropertyCoveragesSection({ ...props });

  const buildingMailingAddress = buildInput({
    fieldSpec: propertyBuildingsFieldSpecs.buildingMailingAddress,
    isReadOnly,
    validation: stringValidation
  });

  const buildingType = buildInput({
    fieldSpec: propertyBuildingsFieldSpecs.buildingType,
    isReadOnly,
    validation: stringValidation
  });

  return {
    inputs: buildInputs<Partial<PropertyInsurancePolicyState>>({
      hideAttachedComponent: true,
      state: state,
      prefixPathToValue: prefix,
      onChange,
      fieldSpecs: {
        ...propertyInsurancePolicyCoveragesFieldBaseSpecs,
        ...propertyBuildingsFieldSpecs
      },
      specs: [
        buildingMailingAddress,
        buildingType,
        cAFairPlanCoverageAmount,
        hO6CoverageAmount,
        buildersRiskCoverageAmount,
        condoMasterFloodCoverageAmount,
        condoMasterHazardCoverageAmount,
        earthquakeCoverageAmount,
        excessFlood,
        floodCoverageAmount,
        hazardCoverageAmount,
        hazardReplacementCoverage,
        lavaCoverageAmount,
        liabilityCoverageAmount,
        rentLossCoverageAmount,
        windCoverageAmount
      ]
    })
  };
};
