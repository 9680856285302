import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
import { PropertyTableContainer } from "./PropertyTableContainer";

export const PropertyModal = (props: {
  isOpen: boolean;
  onClose: () => void;
  header: string;
  dealId?: string;
}) => {
  const { isOpen, onClose } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minWidth={"1250px"} width="100%">
        <ModalHeader>{props.header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          maxWidth={"1250px"}
          minHeight={"700px"}
          maxHeight={"700px"}
          overflowX="scroll"
        >
          <Flex>
            <PropertyTableContainer
              isDisabled={true}
              h={"100%"}
              pageSize={10}
              dealId={props.dealId}
              propertyId={undefined}
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
