import { Box, Flex } from "@chakra-ui/react";
import { useTaskTemplateHooks } from "../../../hooks/taskTemplate.hooks";
import CreateTaskTemplateModal from "./CreateTaskTemplateModal";
import { TaskTemplateSearch } from "./TaskTemplateSearch";
const TaskTemplateHeader = () => {
  const { selectedTaskTemplate, setSelectedTaskTemplate } =
    useTaskTemplateHooks();
  return (
    <Box w="100%">
      <Flex w="100%">
        <Box w="50%">
          <Box w="600px">
            <TaskTemplateSearch
              onSelect={setSelectedTaskTemplate}
              currentValue={selectedTaskTemplate ? selectedTaskTemplate.id : ""}
            />
          </Box>
        </Box>

        <Box p="10px" w="50%">
          <CreateTaskTemplateModal />
        </Box>
      </Flex>
    </Box>
  );
};

export default TaskTemplateHeader;
