import { BoxProps, Flex, useDisclosure } from "@chakra-ui/react";
import { Deal } from "@elphi/types";
import { isEmpty } from "lodash";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { auth } from "../../../../firebase/firebaseConfig";
import { useAuthStateChangeHook } from "../../../../hooks/authStateChange.hooks";
import useDealHooks from "../../../../hooks/deal.hooks";
import useWindowDimensions from "../../../../hooks/windowDimensions";
import { RootState } from "../../../../redux/store";
import { DealTableCursorType, dealApi } from "../../../../redux/v2/deal";
import {
  sortFilteredIds,
  sortFilteredReadonlyClosingDate
} from "../../../../redux/v2/deal/deal.selectors";
import { uniqCursorId } from "../../../../redux/v2/deal/utils";
import { usePaginationHooksV2 } from "../../../../redux/v2/hooks/pagination.hooks.v2";
import { removeNulls } from "../../../../utils/filter.utils";
import { ElphiPaginationList } from "../../../elphi-list/ElphiList";
import { PropertyModal } from "../../../property/table/v2/PropertyModal";
import { headerCells } from "./DealTableHeader";
import { DealRow } from "./DealTableRow";
import { useDealTableState } from "./hooks";
export type DealTableProps = {
  pageSize: number;
  userId?: string;
  dealId?: string;
  dealMilestoneFilter?: Deal["DealMetadata"]["milestone"][];
  dealMilestoneFilterOp: "in" | "not-in";
  lenderIdentifierFilter?: Deal["LenderIdentifier"][];
  lenderIdentifierFilterOp: "in" | "not-in";
  dealEstimatedClosingDateRange?: { from?: string; to?: string };
  w?: BoxProps["w"];
  h?: BoxProps["h"];
  offsetH?: number;
};

export const DealTable = (props: DealTableProps) => {
  const { dealTableSelectedDealId } = useDealHooks();
  const OFFSET_HEIGHT = props.offsetH || 600;
  const dealTableState = useDealTableState();
  const {
    setDealTableCursor,

    dealUserRelationState,
    dealState
  } = dealTableState;
  const { isOpen, onOpen, onClose } = useDisclosure();

  // const setDealTableCursorCBRef = useCallbackRef(setDealTableCursor);

  const { nextPage, hasMore, paginateApiResponse } = usePaginationHooksV2({
    paginationProvider: dealApi.useLazyPaginateV2Query,
    limit: props.pageSize,
    input: {
      userId: props.userId,
      dealId: props.dealId,
      lender: props.lenderIdentifierFilter,
      lenderOp: props.lenderIdentifierFilterOp,
      milestone: props.dealMilestoneFilter,
      milestoneOp: props.dealMilestoneFilterOp,
      estimatedClosingDateRange: props.dealEstimatedClosingDateRange
    },
    setHasMore: async (hasMore) => {
      dealTableState.setCurrentCursorHasMore(hasMore);
    },
    setCursor: async (cursor) => {
      dealTableState.setCurrentCursorId(
        uniqCursorId({
          dealId: props.dealId,
          userId: props.userId,
          lender: props.lenderIdentifierFilter,
          milestone: props.dealMilestoneFilter,
          milestoneOp: props.dealMilestoneFilterOp,
          estimatedClosingDateRange: props.dealEstimatedClosingDateRange
        }) || "none"
      );
      setDealTableCursor({
        cursor,
        cursorType: DealTableCursorType.Agnostic,
        dealId: props.dealId,
        userId: props.userId,
        lender: props.lenderIdentifierFilter,
        milestone: props.dealMilestoneFilter,
        milestoneOp: props.dealMilestoneFilterOp,
        estimatedClosingDateRange: props.dealEstimatedClosingDateRange
      });
    },
    currentCursor: dealTableState.currentCursor
  });

  const isLoading =
    paginateApiResponse.isFetching || paginateApiResponse.isLoading;

  useEffect(() => {
    const uniqId = uniqCursorId({
      dealId: props.dealId,
      userId: props.userId,
      lender: props.lenderIdentifierFilter,
      milestone: props.dealMilestoneFilter,
      milestoneOp: props.dealMilestoneFilterOp,
      estimatedClosingDateRange: props.dealEstimatedClosingDateRange
    });
    dealTableState.setCurrentCursorId(uniqId);
    dealTableState.setCurrentCursorHasMore(true);
  }, [
    props.dealMilestoneFilter,
    props.dealMilestoneFilterOp,
    props.lenderIdentifierFilter,
    props.lenderIdentifierFilterOp,
    props.dealEstimatedClosingDateRange,
    props.userId,
    props.dealId
  ]);

  useAuthStateChangeHook({
    onAuthStateChange: (_) => !items?.length && nextPage(),
    deps: [auth.currentUser]
  });

  useEffect(() => {
    if (
      props.dealId ||
      props.userId ||
      (filteredItems.length === 1 && !props.dealId)
    ) {
      !isLoading && nextPage();
    }
  }, [props.dealId, props.userId]);

  const { heightOffsetInPx } = useWindowDimensions();

  const items = useMemo(() => {
    if (!props.dealId && !props.userId) {
      //all
      const res = dealState.paginationResultsIds
        .filter(removeNulls)
        .map((dealId) => {
          return {
            dealId: dealId?.toString()
          };
        });
      return res;
    } else if (!props.dealId && props.userId) {
      //user
      const userDealsIds = dealUserRelationState.ids.filter(
        (duid) =>
          removeNulls(duid) &&
          dealUserRelationState.entities?.[duid]?.userId === props.userId
      );

      const dealStateDealIds = dealState.paginationResultsIds
        .filter(removeNulls)
        .map((dealId) => dealId?.toString());

      const dealIds: string[] = userDealsIds
        .map((duid) => dealUserRelationState.entities?.[duid]?.dealId)
        .filter(
          (dealId) => !!dealId && dealStateDealIds.includes(dealId.toString())
        )
        .filter(removeNulls);

      const res = dealIds.map((dealId) => {
        return {
          dealId: dealId.toString()
        };
      });
      return res;
    } else if (props.dealId && !props.userId) {
      //dealId
      const res = [{ dealId: props.dealId }];
      return res;
    } else if (props.dealId && props.userId) {
      //both
      const dealId = props.dealId;
      const userDealsIds = dealUserRelationState.ids
        .filter(removeNulls)
        .filter(
          (duid) =>
            dealUserRelationState.entities?.[duid]?.userId === props.userId &&
            dealUserRelationState.entities?.[duid]?.dealId === dealId
        )
        .filter(removeNulls);
      const dealIds = userDealsIds
        .map((duid) => dealUserRelationState.entities?.[duid]?.dealId)
        .filter(removeNulls);

      const res = dealIds
        .map((dealId) => {
          return {
            dealId: dealId.toString()
          };
        })
        .flat();
      return res;
    }
    return [];
  }, [props.dealId, props.userId, dealTableState]);

  const filterHandler = (r: {
    value: string;
    op: "in" | "not-in";
    array: (
      | string
      | Deal["LenderIdentifier"]
      | Deal["DealMetadata"]["milestone"]
    )[];
  }) => {
    if (!r.array.length) return true;
    if (!r.value && r.value !== "" && r.array.length && r.op === "in")
      return false;
    const isInclude = r.array.includes(r.value);
    return r.op === "in" ? isInclude : !isInclude;
  };

  const getFilteredItems = (
    items: {
      dealId: string;
    }[]
  ) => {
    return items
      .filter((d) => {
        const deal = dealState.entities?.[d.dealId];
        if (!deal) return false;

        const userFilter =
          !props.userId ||
          (props.userId && deal?.assignedUsers?.includes(props.userId));
        return (
          userFilter &&
          filterHandler({
            value: deal?.LenderIdentifier || "",
            op: props?.lenderIdentifierFilterOp,
            array: props?.lenderIdentifierFilter || []
          }) &&
          filterHandler({
            value: deal?.DealMetadata?.milestone || "",
            op: props?.dealMilestoneFilterOp,
            array: props?.dealMilestoneFilter || []
          })
        );
      })
      .filter((item) => {
        if (isEmpty(props.dealEstimatedClosingDateRange)) return true;
        const { from, to } = props.dealEstimatedClosingDateRange;
        if (!from || !to) return true;
        const fromDate = new Date(from);
        const toDate = new Date(to);
        const deal = dealState.entities?.[item.dealId];
        const fieldValue =
          deal && deal.readonlyFields?.["EstimatedClosingDate"];
        if (
          fieldValue &&
          new Date(fieldValue) >= fromDate &&
          new Date(fieldValue) <= toDate
        ) {
          return true;
        }
        return false;
      })
      .filter(removeNulls);
  };

  const filteredItems = useMemo(() => {
    return getFilteredItems(items).map((v) => v.dealId) || [];
  }, [
    props?.dealMilestoneFilter,
    props?.dealMilestoneFilterOp,
    props?.lenderIdentifierFilter,
    props?.lenderIdentifierFilterOp,
    props?.dealEstimatedClosingDateRange,
    props?.userId,
    items
  ]);

  const sortByLoanIdentifier = useSelector((state: RootState) =>
    isEmpty(props.dealEstimatedClosingDateRange)
      ? sortFilteredIds(state, filteredItems)
      : sortFilteredReadonlyClosingDate(state, filteredItems)
  );

  return (
    <>
      <Flex width="100%" mt="3">
        <ElphiPaginationList
          tableName={"Deal"}
          isLoading={isLoading}
          height={heightOffsetInPx(OFFSET_HEIGHT)}
          headerCells={headerCells}
          items={sortByLoanIdentifier}
          orderableRowBuilder={({ item, index, tableName, isOrderable }) => {
            return (
              <DealRow
                dealId={item}
                dealTableState={dealTableState}
                index={(Number(index) + 1).toString()}
                onOpen={onOpen}
                tableName={tableName}
                isOrderable={isOrderable}
              />
            );
          }}
          pageSize={10}
          hasMore={hasMore || dealTableState.currentCursorIdHasMore}
          next={nextPage}
          isOrderable={true}
        />
      </Flex>
      <PropertyModal
        header={`Loan Number ${
          dealTableState.selectedDealTableData
            ? dealTableState.selectedDealTableData?.LoanIdentifier
            : "N/A"
        }`}
        isOpen={isOpen}
        onClose={onClose}
        dealId={dealTableSelectedDealId}
      />
    </>
  );
};

//trigger
