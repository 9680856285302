import { AggregationType, Deal } from "@elphi/types";
import { OnChangeInput, Section } from "../../../form-builder/FormBuilder";
import { dealFieldSpecs } from "../../../form-builder/field-specs/deal/deal.fields";
import { buildInputs } from "../../../form-builder/formBuilder.utils";
import { singleSelectValidation } from "../../../utils/validationUtils";
import { wireInsuranceCertificateFundingShieldTransactionStatus } from "../../field-paths/deal.fieldPaths";

export const wireInsuranceOrderStatusSection = (
  state: Partial<Deal>,
  onChange: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Integration Wire Insurance Cert: Order Status",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.aggregations?.Integrations?.WireInsuranceCertificate
              ?.FundingShield?.TransactionStatus?.focused !== "override",
          path: wireInsuranceCertificateFundingShieldTransactionStatus,
          validation: singleSelectValidation
        }
      ]
    })
  };
};
