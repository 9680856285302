import { TaskConfiguration } from "@elphi/types";
import { CellsFilterStateFlat } from "../../../table/hooks/filter.hook";

export const filterState: CellsFilterStateFlat<TaskConfiguration> = {
  createdAt: {
    column: "Created At",
    orderBy: "asc",
    query: []
  },
  name: {
    column: "Configuration Name",
    orderBy: "asc",
    query: [],
    getData: ({ task }) => task?.name
  },
  autoGenerate: {
    column: "Auto Generate",
    orderBy: "asc",
    query: []
  },
  taskEntity: {
    column: "Task Entity",
    orderBy: "asc",
    query: [],
    getData: ({ task }) => task?.entityType
  },
  keyClosingTaskType: {
    column: "KCT?",
    orderBy: "asc",
    query: []
  },
  taskTemplateId: {
    column: "Template",
    orderBy: "asc",
    query: []
  },
  taskRuleTemplateId: {
    column: "Rule",
    orderBy: "asc",
    query: []
  },
  internalInstructionId: {
    column: "Internal Instructions",
    orderBy: "asc",
    query: []
  },
  externalInstructionId: {
    column: "External Instructions",
    orderBy: "asc",
    query: []
  },
  dataToVerify: {
    column: "Data to Verify",
    orderBy: "asc",
    query: []
  },
  dataToOrder: {
    column: "Data to Order",
    orderBy: "asc",
    query: []
  },
  status: {
    column: "Status",
    orderBy: "asc",
    query: []
  }
};
