import { Box, Progress } from "@chakra-ui/react";
import { Deal } from "@elphi/types";
import { ExpandAllButton } from "../../common-components/expandAllButton/ExpandAllButton";
import { NavigationPath } from "../../shared/types/navigation.types";
import FormBuilder, { FormBuilderProps } from "../form-builder/FormBuilder";

import {
  applicationPostClosingAfterSections,
  applicationPostClosingBeforeSections,
  applicationPostClosingUserAssignmentSection
} from "./sections/postClosing.sections";
export type ClosingTabFormProps = Omit<FormBuilderProps, "sections"> & {
  isLoading: boolean;
  dealId: string;
  dealState?: Deal;
  navigationPath?: NavigationPath;
};

const ClosingTabForm = (props: ClosingTabFormProps) => {
  // TODO: check for regression,
  // const dealSlice = useSelector((state: RootState) => state.deal);
  const { navigationPath } = props;
  return (
    <Box h="100%" w="100%">
      {props.isLoading && (
        <Box w="100%">
          <Progress size="xs" isIndeterminate />
        </Box>
      )}

      {/* props.dealId && dealSlice.entities[props.dealId] */}
      {props.dealState && (
        <Box h="100%" w="100%" overflow="scroll">
          {!!navigationPath && (
            <Box>
              <ExpandAllButton navigationPath={navigationPath} />
            </Box>
          )}
          <FormBuilder
            customKey="applicationPostClosingBeforeSections"
            onChange={props.onChange}
            sections={applicationPostClosingBeforeSections({
              state: props.dealState, //was used here as dealSlice
              onChange: props.onChange
            })}
            navigationPath={navigationPath}
          />
          <FormBuilder
            customKey="userAssignment"
            onChange={props.onChange}
            sections={applicationPostClosingUserAssignmentSection({
              state: props.dealState,
              onChange: props.onChange
            })}
            navigationPath={navigationPath}
          />
          <FormBuilder
            customKey="applicationPostClosingAfterSections"
            onChange={props.onChange}
            sections={applicationPostClosingAfterSections({
              state: props.dealState,
              onChange: props.onChange
            })}
            navigationPath={navigationPath}
          />
        </Box>
      )}
    </Box>
  );
};

export default ClosingTabForm;
