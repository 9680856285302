import { useCallbackRef } from "@chakra-ui/react";
import { StatusCode } from "@elphi/types";
import { EntityId } from "@reduxjs/toolkit";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { AppResponse } from "../../apis/appResponse.types";
import { AppConfig } from "../../config/appConfig";
import { auth } from "../../firebase/firebaseConfig";
import { usePartyUserRelationHooks } from "../../hooks/partyuser.hooks";
import { RootState } from "../../redux/store";
import { useRTKPagination } from "../../redux/v2/hooks/pagination.hook";
import { losUserApi } from "../../redux/v2/los-user/losUser.service";
import { removeNulls } from "../../utils/filter.utils";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";
import PartyUserAssignment from "../party/PartyUserAssignment";
import { useElphiToast } from "../toast/toast.hook";

const PartyUserAssignmentContainer = (props: { partyId: EntityId }) => {
  const { successToast, errorToast } = useElphiToast();
  const losUserState = useSelector((state: RootState) => state.losUser);
  const partyUserRelationState = useSelector(
    (state: RootState) => state.partyUserRelation
  );
  const { next: getLOSUsers, pageResponse: losUserPageResponse } =
    useRTKPagination({
      useLazyPaginateQuery: losUserApi.useLazyPaginateQuery,
      entityState: losUserState,
      options: {
        limit: 600
      }
    });

  const { addUserAssignment, removeUserAssignment } =
    usePartyUserRelationHooks();

  const addUserAssignmentHandler = async (userIds: string[]) => {
    const partyId = String(props.partyId);
    const partyUserIds = userIds.map((id) => {
      return `${props.partyId}_${id}`;
    });
    return await addUserAssignment({ partyId, partyUserIds }).then((r) => {
      if (r.status === StatusCode.OK) {
        if (r.data.ids) {
          successToast({
            title: "Relations Created",
            description: `${r.data.ids.length} new relations created`
          });
        }
      }
      r.status === StatusCode.BadRequest &&
        errorToast({
          title: "Failed to create relation",
          description: r.data.description
        });
      return r;
    });
  };

  const removeUserAssignmentHandler = async (userIds: string[]) => {
    const partyId = String(props.partyId);
    const partyUserIds = userIds.map((id) => {
      return `${partyId}_${id}`;
    });
    return await removeUserAssignment({ partyId, partyUserIds }).then((r) => {
      if (r.status === StatusCode.OK) {
        if (r.data.ids) {
          successToast({
            title: "Relations Deleted",
            description: `total relations deleted: ${r.data.ids.length}`
          });
        }
      }
      r.status === StatusCode.BadRequest &&
        errorToast({
          title: "Failed to create relation",
          description: r.data.description
        });
      return r;
    });
  };

  const reduxIds = useMemo(() => {
    return partyUserRelationState.ids
      .filter((id) => String(id).split("_")[0] === props.partyId)
      .map((id) => String(id).split("_")[1]);
  }, [partyUserRelationState, props.partyId]);

  const updateLOSUser = useCallbackRef(
    async (newState: { relations: string[] }) => {
      const { relations } = newState;
      const addedValues = relations.filter((r) => !reduxIds.includes(r));
      const removedValues = reduxIds.filter((r) => !relations.includes(r));

      const promises: Promise<AppResponse<{ ids: string[] }>>[] = [];
      if (addedValues.length > 0) {
        promises.push(addUserAssignmentHandler(addedValues));
      }

      if (removedValues.length > 0) {
        promises.push(removeUserAssignmentHandler(removedValues));
      }

      const results = await Promise.all(promises);

      const allSucceeded = results.every((r) => r.status === StatusCode.OK);

      const succeededResponse: AppResponse<{ ids: string[] }> = {
        status: StatusCode.OK,
        data: {
          ids: results.flatMap((r) => {
            return r.status === StatusCode.OK ? r.data.ids : ([] as string[]);
          })
        }
      };

      if (allSucceeded) {
        return succeededResponse;
      }

      const [failed] = [
        ...results
          .filter((result) => result.status === StatusCode.BadRequest)
          .map((x) => (x.status === StatusCode.BadRequest ? x.data : null))
          .filter(removeNulls)
      ];

      const failedResponse: AppResponse<{ ids: string[] }> = {
        status: StatusCode.BadRequest,
        data: {
          error: failed?.error || "Unknown error",
          description:
            failed?.description || "Failed add/remove user assignment"
        }
      };
      return failedResponse;
    }
  );

  const { onChange, state, syncState } = useFormBuilderStateHandler({
    initialState: { relations: [] as string[] },
    callback: updateLOSUser,
    callbackOptions: {
      debounceRate: AppConfig.debounceRate
      // clearDiff: true
    }
  });

  useEffect(() => {
    if (partyUserRelationState) {
      syncState({
        shouldSync: !!partyUserRelationState,
        state: {
          relations: reduxIds
        }
      });
    }
  }, [partyUserRelationState, props.partyId, reduxIds]);
  useEffect(() => {
    if (auth.currentUser) {
      getLOSUsers();
    }
  }, [auth.currentUser]);

  return (
    <PartyUserAssignment
      state={state}
      onChange={onChange}
      partyId={String(props.partyId)}
      hasMore={!!losUserPageResponse.data?.nextCursor}
      fetchMore={getLOSUsers}
      size="m"
    />
  );
};

export default PartyUserAssignmentContainer;
