import { Box } from "@chakra-ui/react";
import { useSnapshotHooks } from "../../../hooks/snapshot.hooks";
import { MilestoneSelectionComponent } from "./MilestoneSelectionComponent";
export const MilestoneSelectionSnapshotContainer = (props: {
  dealId: string;
  snapshotId: string;
}) => {
  const { snapshotDataState } = useSnapshotHooks();
  const snapshot = snapshotDataState({ snapshotId: props.snapshotId ?? "" });
  const dealSnapshot = snapshot?.dealState?.entities[props.dealId];

  return (
    <>
      {!!dealSnapshot && (
        <MilestoneSelectionComponent
          dealId={props.dealId}
          deal={dealSnapshot}
          isDisabled={true}
          tooltipLabel={
            <>
              <Box mb="5px" whiteSpace="nowrap">
                Milestone:{" "}
                {!!props.dealId
                  ? dealSnapshot?.DealMetadata?.milestone ?? ""
                  : ""}
                .
              </Box>
              <Box whiteSpace="nowrap">
                Can't update Milestones while viewing snapshots.
              </Box>
            </>
          }
        />
      )}
    </>
  );
};
