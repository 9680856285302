import { Button } from "@chakra-ui/react";
import { CloneConfigurationType, Organization } from "@elphi/types";
import { useEffect, useRef } from "react";
import elphiTheme from "../../../../../assets/themes/elphi.theme.default";
import { LoadingContent } from "../../../../../common-components";
import { useOrgHooks } from "../../../../../hooks/org.hooks";
import { useFormBuilderStateHandler } from "../../../../form-builder/InputBuilder";
import ModalContainer from "../../../../modal-container/ModalContainer";
import { buildDisabledActionOption } from "../utils/Organization.utils";
import { OrganizationForm } from "./OrganizationForm";
import { useCloneConfigurationHooks } from "./clone.configuration.hooks";

export const ActionOrganizationModal = (props: {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  canSubmit: boolean;
  isLoading: boolean;
  label: string;
  children: React.ReactNode;
}) => {
  return (
    <ModalContainer
      isShow={props.show}
      onCloseModal={props.onClose}
      header={props.label}
      body={<>{props.children}</>}
      footer={
        <>
          <Button
            {...elphiTheme.components.light.button.primary}
            mr={"15px"}
            isDisabled={props.canSubmit}
            isLoading={props.isLoading}
            onClick={props.onSubmit}
          >
            Submit
          </Button>

          <Button
            {...elphiTheme.components.light.button.secondary}
            onClick={props.onClose}
          >
            Cancel
          </Button>
        </>
      }
    />
  );
};

export const CreateOrganizationModal = (props: {
  show: boolean;
  onClose: () => void;
}) => {
  const { createHandler, createApiResponse } = useOrgHooks();
  const { selectConfiguration, setSelectConfiguration } =
    useCloneConfigurationHooks();

  const defaultInitialState: Partial<Organization> = {
    name: "",
    description: "",
    isSubscribed: undefined,
    subscribeToOrganization: "",
    cloneConfiguration: selectConfiguration
  };

  const { state, onChange, setState } = useFormBuilderStateHandler<
    Partial<Organization>
  >({
    initialState: defaultInitialState
  });

  const onResetModal = () => {
    setSelectConfiguration([]);
    setState(defaultInitialState);
  };

  const onChangeSelectConfiguration = (v: CloneConfigurationType[]) => {
    setSelectConfiguration(v);
  };

  useEffect(() => {
    if (props.show) {
      onResetModal();
    }
  }, [props.show]);

  useEffect(() => {
    setState({
      ...state,
      cloneConfiguration: selectConfiguration
    });
  }, [selectConfiguration]);

  const canSubmit = !(
    state.description &&
    state.name &&
    (state.isSubscribed !== "active" ||
      (state.isSubscribed === "active" &&
        state.subscribeToOrganization &&
        state.cloneConfiguration &&
        state.cloneConfiguration.length > 0))
  );

  return (
    <ActionOrganizationModal
      label="Create Organization"
      onClose={props.onClose}
      show={props.show}
      canSubmit={canSubmit}
      isLoading={createApiResponse.isLoading}
      onSubmit={() => {
        createHandler(state).then(() => props.onClose());
      }}
    >
      <OrganizationForm
        state={state}
        onChange={onChange}
        selectConfiguration={selectConfiguration}
        onChangeSelectConfiguration={onChangeSelectConfiguration}
        buildOption={buildDisabledActionOption}
      />
    </ActionOrganizationModal>
  );
};

export const UpdateOrganizationModal = (props: {
  show: boolean;
  onClose: () => void;
  orgId: string;
}) => {
  const {
    orgState,
    updateHandler,
    updateApiResponse,
    getOrgApi,
    getOrgApiResponse
  } = useOrgHooks();
  const { selectConfiguration, setSelectConfiguration } =
    useCloneConfigurationHooks();
  const { state, onChange, setState } = useFormBuilderStateHandler<
    Partial<Organization>
  >({
    initialState: { ...orgState.entities[props.orgId] }
  });

  const previousStateRef = useRef<Partial<Organization>>(state);

  const shouldFetchMissingOrg = (missingOrgId: string | undefined) => {
    return missingOrgId && !orgState.ids.includes(missingOrgId);
  };
  useEffect(() => {
    if (props.show) {
      const { subscribeToOrganization } = state;
      if (shouldFetchMissingOrg(subscribeToOrganization)) {
        getOrgApi(subscribeToOrganization!);
      }
      setSelectConfiguration(
        (orgState.entities[props.orgId]?.cloneConfiguration ||
          []) as CloneConfigurationType[]
      );
      setState(state);
      previousStateRef.current = state;
    }
  }, [props.show]);

  const onCancelModal = () => {
    setState(previousStateRef.current);
    props.onClose();
  };

  const shouldDisableSubscription = Object.values(orgState?.entities).some(
    (x) =>
      x?.subscribeToOrganization === props.orgId && x.isSubscribed === "active"
  );

  const onChangeSelectConfiguration = (v: CloneConfigurationType[]) => {
    setSelectConfiguration(v);
  };

  useEffect(() => {
    setState({ ...state, cloneConfiguration: selectConfiguration });
  }, [selectConfiguration]);

  const canSubmit = !(
    state.description &&
    state.name &&
    (state.isSubscribed !== "active" ||
      (state.isSubscribed === "active" &&
        state.subscribeToOrganization &&
        state.cloneConfiguration &&
        state.cloneConfiguration.length > 0))
  );

  return (
    <LoadingContent isLoading={getOrgApiResponse.isFetching}>
      <ActionOrganizationModal
        label={`Update ${state?.id} Organization`}
        onClose={onCancelModal}
        show={props.show}
        canSubmit={canSubmit}
        isLoading={updateApiResponse.isLoading}
        onSubmit={() => {
          updateHandler({
            id: props.orgId,
            ...state
          }).then(() => props.onClose());
        }}
      >
        <OrganizationForm
          state={state}
          onChange={onChange}
          selectConfiguration={
            (state.cloneConfiguration || []) as CloneConfigurationType[]
          }
          onChangeSelectConfiguration={onChangeSelectConfiguration}
          buildOption={(x) => buildDisabledActionOption(x, props.orgId)}
          disableSubscription={shouldDisableSubscription}
        />
      </ActionOrganizationModal>
    </LoadingContent>
  );
};
