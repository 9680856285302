import { Button, Flex, Spacer, Text } from "@chakra-ui/react";
import { FieldType } from "@elphi/types";
import { isEmpty } from "lodash";
import { useEffect, useMemo } from "react";
import { useFilterMenuHook } from "../../../common-components/menu-options/filter-menu-hook";
import { FilterMenuOptions } from "../../../common-components/menu-options/FilterMenuOptions";
import {
  useNotificationFilterHooks,
  useNotificationUserHooks
} from "../../../hooks/notificationUser.hooks";
import { NotificationUserSliceState } from "../../../redux/v2/notification-user";
import {
  auditFieldOptions,
  entityFields,
  entityOptions
} from "../../audit-log/modal/list/eventList.utils";
import StyledInputBuilder from "../../form-builder/InputBuilder";

export const NotificationFilter = () => {
  const filterMenuHook = useFilterMenuHook();
  const { setCurrentFilter, resetFiltersCombinations, userOptions } =
    useNotificationFilterHooks();
  const { notificationUserState, markAllAsReadApi } =
    useNotificationUserHooks();
  const { clearFilters } = filterMenuHook;

  useEffect(() => {
    return () => {
      resetFiltersCombinations();
    };
  }, []);

  const handleClearClick = () => {
    clearFilters();
    setCurrentFilter({
      values: {}
    });
  };

  const isDisable = !notificationUserState.filters?.current
    ? true
    : Object.keys(notificationUserState.filters?.current).every((key) =>
        isEmpty(notificationUserState.filters?.current[key])
      );

  const onFilterChange = (
    filter: NotificationUserSliceState["filters"]["current"]
  ) => {
    setCurrentFilter({
      values: {
        ...notificationUserState.filters.current,
        ...filter
      }
    });
  };

  const haveSelectedEntityFilter =
    notificationUserState.filters.current.entityType?.length === 1;

  const fieldOptions = useMemo(() => {
    const entityType = notificationUserState.filters.current.entityType?.at(0);

    if (!haveSelectedEntityFilter || !entityType) {
      return [];
    }
    return entityFields[entityType];
  }, [
    notificationUserState.filters.current?.entityType,
    haveSelectedEntityFilter
  ]);

  return (
    <Flex gap={2} mb={4}>
      <FilterMenuOptions
        customKey={"entityFilter"}
        maxHeight={"300px"}
        scrollHeight={"300px"}
        filterMenuHook={filterMenuHook}
        title={"Entities"}
        options={entityOptions}
        showSearchBar={true}
        onChange={(item: string[]) => onFilterChange({ entityType: item })}
        selected={notificationUserState.filters.current.entityType}
        showClearAll
        isSingle
      />
      <FilterMenuOptions
        customKey={"fieldFilter"}
        buttonTooltipLabel={
          !haveSelectedEntityFilter ? "Select entity first" : undefined
        }
        isDisabled={!haveSelectedEntityFilter}
        maxHeight={"300px"}
        scrollHeight={"300px"}
        filterMenuHook={filterMenuHook}
        title={"Field"}
        options={fieldOptions}
        showSearchBar={true}
        onChange={(items: string[]) => onFilterChange({ fieldPath: items })}
        selected={notificationUserState.filters.current.fieldPath}
        showClearAll
        showDisabledTooltip
      />
      <FilterMenuOptions
        customKey={"auditTypeFilter"}
        maxHeight={"300px"}
        scrollHeight={"300px"}
        filterMenuHook={filterMenuHook}
        title={"Audit Type"}
        options={auditFieldOptions}
        showSearchBar={true}
        onChange={(items: string[]) => onFilterChange({ auditType: items })}
        selected={notificationUserState.filters.current.auditType}
        showClearAll
      />
      <FilterMenuOptions
        customKey={"userFilter"}
        maxHeight={"300px"}
        scrollHeight={"300px"}
        filterMenuHook={filterMenuHook}
        title={"User"}
        options={userOptions}
        showSearchBar={true}
        onChange={(items: string[]) => onFilterChange({ createdUID: items })}
        selected={notificationUserState.filters.current.createdUID}
        showClearAll
      />
      <Button
        mr={1}
        background={"transparent"}
        fontSize={14}
        fontWeight={"normal"}
        onClick={handleClearClick}
        isDisabled={isDisable}
      >
        Clear filters
      </Button>
      <Spacer />
      <Flex alignItems={"center"} gap={2}>
        <Flex alignItems={"center"}>
          <Text fontSize={14} fontWeight={"normal"} mr={2}>
            Show only unread
          </Text>
          <StyledInputBuilder
            currentValue={notificationUserState.filters?.current?.unread?.at(0)}
            fieldType={FieldType.Switch}
            onChange={(e) => {
              onFilterChange({
                unread: [e.target.value]
              });
            }}
          />
        </Flex>
        <Flex alignItems={"center"}>
          <Text
            cursor={"pointer"}
            variant={"unstyled"}
            fontSize={14}
            fontWeight={"normal"}
            onClick={() => markAllAsReadApi({})}
            _hover={{
              borderBottom: "1px solid"
            }}
          >
            Mark all as read
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
