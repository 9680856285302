import { IndividualParty } from "@elphi/types";
import { AggregationType } from "@elphi/types/aggregations/aggregation.types";
import { CreditScore } from "@elphi/types/entities/creditReport.types";
import { OnChangeInput, Section } from "../../form-builder/FormBuilder";
import { creditReportFieldSpecs } from "../../form-builder/field-specs/credit-report/credit-report.fields";
import { individualPartyFieldSpecs } from "../../form-builder/field-specs/party/party.fields";
import { buildInputs } from "../../form-builder/formBuilder.utils";
import { ssnInputFormatter } from "../../form-builder/formatters/inputs.formatter";
import {
  dateValidation,
  singleSelectValidation,
  ssnValidation,
  stringValidation
} from "../../utils/validationUtils";
import {
  creditReportPullTypeFieldPath,
  equifaxDateFieldPath,
  equifaxScoreFieldPath,
  experianDateFieldPath,
  experianScoreFieldPath,
  transunionDateFieldPath,
  transunionScoreFieldPath
} from "../field-paths/creditReport.fieldPaths";
import { taxpayerIdentifierValueFieldPath } from "../field-paths/entity.fieldPaths";
import {
  creditReportPullTypeFieldPath as creditReportPullTypeIndividualFieldPath,
  individualBirthDateFieldPath,
  individualFirstNameFieldPath,
  individualLastNameFieldPath,
  individualMiddleNameFieldPath
} from "../field-paths/individual.fieldPaths";
import {
  partyAddressLineTextFieldPath,
  partyAddressUnitIdentifierFieldPath,
  partyCityNameFieldPath,
  partyPostalCodeFieldPath,
  partyStateCodeFieldPath,
  partyStateNameFieldPath
} from "../field-paths/party.fieldPaths";

export const creditReportSection = (
  state: Partial<CreditScore>,
  onChange: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Integration Credit Report: Verify",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: creditReportFieldSpecs,
      specs: [
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly: state.experian?.score?.focused !== "override",
          path: experianScoreFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly: state.experian?.date?.focused !== "override",
          path: experianDateFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly: state.equifax?.score?.focused !== "override",
          path: equifaxScoreFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly: state.equifax?.date?.focused !== "override",
          path: equifaxDateFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly: state.transunion?.score?.focused !== "override",
          path: transunionScoreFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly: state.transunion?.date?.focused !== "override",
          path: transunionDateFieldPath,
          validation: stringValidation
        },
        {
          path: creditReportPullTypeFieldPath,
          validation: singleSelectValidation
        }
      ]
    })
  };
};

export const creditReportOrderSection = (
  state: Partial<IndividualParty>,
  onChange: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Integration Credit Report: Order",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: individualPartyFieldSpecs,
      specs: [
        {
          path: creditReportPullTypeIndividualFieldPath,
          validation: singleSelectValidation
        },
        { path: individualFirstNameFieldPath, validation: stringValidation },
        { path: individualMiddleNameFieldPath, validation: stringValidation },
        { path: individualLastNameFieldPath, validation: stringValidation },
        { path: individualBirthDateFieldPath, validation: dateValidation },
        {
          path: taxpayerIdentifierValueFieldPath,
          validation: ssnValidation,
          formatter: ssnInputFormatter
        },
        { path: partyAddressLineTextFieldPath, validation: stringValidation },
        {
          path: partyAddressUnitIdentifierFieldPath,
          validation: stringValidation
        },
        { path: partyCityNameFieldPath, validation: stringValidation },
        { path: partyStateNameFieldPath, validation: singleSelectValidation },
        { path: partyStateCodeFieldPath, validation: singleSelectValidation },
        { path: partyPostalCodeFieldPath, validation: stringValidation }
      ]
    })
  };
};
