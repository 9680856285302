import { TaskConfiguration, TaskConfigurationDataToType } from "@elphi/types";
import {
  ConfigurationModalHeaderTitle,
  FieldSelectionConfigurationModal
} from "../field-selection/FieldSelectionConfigurationForm";

const fieldSelection: TaskConfigurationDataToType = "dataToVerify";
const dataToVerifyTitle = "Data To Verify";

export const DataToVerifyConfigurationModal = (props: {
  taskConfiguration: TaskConfiguration;
}) => {
  return (
    <FieldSelectionConfigurationModal
      taskConfiguration={props.taskConfiguration}
      fieldSelection={fieldSelection}
      headerTitle={
        <ConfigurationModalHeaderTitle
          title={dataToVerifyTitle}
          taskConfigurationName={props.taskConfiguration.name}
        />
      }
    />
  );
};
