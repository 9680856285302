import { Box, Button } from "@chakra-ui/react";
import { FieldType, Role } from "@elphi/types";
import { useEffect } from "react";
import elphiTheme from "../../../assets/themes/elphi.theme.default";
import FormBuilder from "../../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../../form-builder/InputBuilder";

export type RoleFormState = Partial<Role>;
export const RoleForm = (props: {
  onSubmit: (role: RoleFormState) => void;
  initialState?: RoleFormState;
  specs?: {
    label?: {
      isReadOnly?: boolean;
      toolTip?: {
        label: string;
      };
    };
  };
  isLoading?: boolean;
}) => {
  const { onChange, state, syncState, diffState } = useFormBuilderStateHandler({
    initialState: props.initialState || ({} as RoleFormState)
  });
  useEffect(() => {
    syncState({
      shouldSync: !!props.initialState,
      state: props.initialState
    });
  }, [props.initialState]);

  const isValid =
    state && state.label && state.permissions && state.permissions?.length > 0;
  return (
    <Box>
      <FormBuilder
        customKey="roleForm"
        elphiView="form"
        size={{
          minW: "300px"
        }}
        onChange={onChange}
        sections={[
          {
            inputs: [
              {
                currentValue: state.label,
                fieldKey: ["label"],
                fieldType: FieldType.String,
                label: "name",
                isReadOnly: props.specs?.label?.isReadOnly,
                toolTipLabel: props.specs?.label?.toolTip?.label
              },
              {
                currentValue: state.description || "",
                fieldKey: ["description"],
                fieldType: FieldType.RichText,
                label: "description"
              },
              {
                currentValue: state.permissions,
                fieldKey: ["permissions"],
                fieldType: FieldType.MultiSelect,
                label: "permissions",

                options:
                  [
                    {
                      label: "read",
                      value: "read"
                    },
                    {
                      label: "create",
                      value: "create"
                    },
                    {
                      label: "update",
                      value: "update"
                    },
                    {
                      label: "delete",
                      value: "delete"
                    }
                  ] || []
              }
            ]
          }
        ]}
      />

      <Button
        isLoading={props.isLoading}
        isDisabled={!isValid}
        {...elphiTheme.components.light.button.primary}
        onClick={() => props.onSubmit(diffState)}
      >
        Submit
      </Button>
    </Box>
  );
};
