import { EditIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Tooltip } from "@chakra-ui/react";
import { Organization } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { printDateTime } from "../../../../../firebase/firebase.utils";
import {
  LargeBoxCell,
  MediumBoxCell,
  SmallBoxCell,
  TextCell
} from "../../../../custom/chakra/Cell";
import { useFormBuilderStateHandler } from "../../../../form-builder/InputBuilder";
import { ItemsMenuCopyWidget } from "../../../../party/table/v2/ItemsMenuCopyWidget";

export type OrgRowProps = {
  index: string | number;
  orgId: string;
  onEdit: (orgId: string) => void;
  orgState: EntityState<Organization>;
  syncState: ReturnType<typeof useFormBuilderStateHandler>["syncState"];
};
export const OrgRow = (props: OrgRowProps) => {
  const { orgId, index, syncState, orgState } = props;

  useEffect(() => {
    syncState({
      state: orgState.entities?.[orgId],
      shouldSync: !!orgState.entities?.[orgId],
      statePath: () => {
        return [orgId.toString()];
      }
    });
  }, [orgState.entities?.[orgId]]);

  return (
    <Flex w="100%" key={index}>
      <SmallBoxCell>
        <TextCell>{index}</TextCell>
      </SmallBoxCell>
      <MediumBoxCell>
        <Flex>
          <Tooltip label={orgId}>
            <TextCell>{orgId}</TextCell>
          </Tooltip>
          <Box>
            <ItemsMenuCopyWidget
              items={[
                {
                  label: "elphi-org-id",
                  value: orgId
                }
              ]}
            />
          </Box>
        </Flex>
      </MediumBoxCell>
      <MediumBoxCell>
        <TextCell>{orgState?.entities?.[orgId]?.name}</TextCell>
      </MediumBoxCell>
      <MediumBoxCell>
        <TextCell>{orgState?.entities?.[orgId]?.description}</TextCell>
      </MediumBoxCell>
      <LargeBoxCell>
        <TextCell>
          {printDateTime(orgState?.entities?.[orgId]?.createdAt)}
        </TextCell>
      </LargeBoxCell>
      <LargeBoxCell>
        <TextCell>
          {printDateTime(orgState?.entities?.[orgId]?.modifiedAt)}
        </TextCell>
      </LargeBoxCell>
      <MediumBoxCell>
        <Tooltip aria-label="edit-org" label={`edit ${orgId}`}>
          <Box>
            <IconButton
              disabled={!orgId}
              bgColor="transparent"
              aria-label="edit-org"
              icon={<EditIcon />}
              size="m"
              onClick={() => props.onEdit(orgId)}
            />
          </Box>
        </Tooltip>
      </MediumBoxCell>
    </Flex>
  );
};
