import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { ChangeReasonMilestones, Deal } from "@elphi/types";
import { useMemo } from "react";
import elphiTheme from "../../../../assets/themes/elphi.theme.default";

const GENERIC_REASON = "Generic Reason";
const SPECIFIC_REASON = "Specific Reason";
const CHOOSE_GENERIC_REASON = "Choose a Generic Reason";
const CHOOSE_SPECIFIC_REASON = "Choose a Specific Reason";

export const ChangeReasonViewDetailsComponent = (props: {
  dealId: string;
  deal: Deal | undefined;
  newDealMilestone: ChangeReasonMilestones;
  lastDealMilestone: string;
  options: {
    disabledButton: boolean;
  };
  onButtonClicked: () => void;
}) => {
  const { newDealMilestone, lastDealMilestone } = props;
  const fields: { name: string; data: string }[] = useMemo(() => {
    const dealMetadata = props.deal?.DealMetadata;
    const milestoneCondition =
      dealMetadata?.milestone &&
      dealMetadata?.milestoneData &&
      dealMetadata?.milestone === newDealMilestone &&
      (!lastDealMilestone || newDealMilestone === lastDealMilestone);

    return [
      {
        name: GENERIC_REASON,
        data: milestoneCondition
          ? dealMetadata?.milestoneData?.[newDealMilestone]?.genericReason ||
            CHOOSE_GENERIC_REASON
          : CHOOSE_GENERIC_REASON
      },
      {
        name: SPECIFIC_REASON,
        data: milestoneCondition
          ? dealMetadata?.milestoneData?.[newDealMilestone]?.specificReason ||
            CHOOSE_SPECIFIC_REASON
          : CHOOSE_SPECIFIC_REASON
      }
    ];
  }, [props.dealId, props.deal?.DealMetadata, props.newDealMilestone]);

  return (
    <Box w="100%">
      <Box w="100%" mb="20px">
        {fields.map((f, i) => (
          <Box w="100%" mb="20px" key={i}>
            <Heading as="h6" size="sm" pb="10px" color={"#505872"}>
              {f.name}
            </Heading>
            <Box w="100%">
              <Text fontSize="sm" w="100%" color={"#505872"}>
                {f.data}
              </Text>
            </Box>
          </Box>
        ))}
      </Box>
      <Box w="100%">
        <Button
          {...elphiTheme.components.light.button.primary}
          size="sm"
          onClick={() => {
            props.onButtonClicked();
          }}
          isDisabled={props.options.disabledButton}
        >
          Update {newDealMilestone} Reasons
        </Button>
      </Box>
    </Box>
  );
};
