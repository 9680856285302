import { Box, useCallbackRef } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { usePartyHooks } from "../../hooks/party.hooks";
import { RootState } from "../../redux/store";
import Tree from "../tree/Tree";
import { loadingSkeleton } from "../tree/TreeUtils";

import useTree from "../tree/tree.hooks";

import { Party } from "@elphi/types";
import { ElphiNode } from "../tree/types/Tree.types";
import { generateTree, nameLogic, partyTypeLogic } from "./PartyChartUtils";
import AddNodeButton from "./actions/AddNodeButton";
import DeleteNodeButton from "./actions/DeleteNodeButton";

const PartyChart = () => {
  const {
    selectedParty,
    getTreeApi,
    getTreeApiResponse,

    setSelectedPartyParent,
    setHighlightedParty,
    setSelectedTreeNodeKey
  } = usePartyHooks();

  const partyState = useSelector((state: RootState) => state.party);
  const partyRelationState = useSelector(
    (state: RootState) => state.partyRelation
  );

  const initialState = useMemo(() => {
    const newTree = {
      initialState: generateTree?.(null, partyState, partyRelationState) || {
        id: "$",
        children: [],
        data: null,
        label: nameLogic(selectedParty),
        sublabel: partyTypeLogic(selectedParty),
        nodeKey: "$"
      }
    };

    return newTree;
  }, []);
  const resetHighlighted = () => {
    setHighlightedParty("");
    setSelectedTreeNodeKey("");
    setSelectedPartyParent("");
    elphiTreeOperations.setSelectedNode(null);
  };
  const { treeState, elphiTreeOperations, setTreeState } =
    useTree<Party>(initialState);
  const nodeActions = {
    addNode: AddNodeButton(),
    removeNode: DeleteNodeButton({
      callback: () => resetHighlighted()
    })
  };
  useEffect(() => {
    const newState = generateTree?.(
      selectedParty || null,
      partyState,
      partyRelationState,
      {
        ...nodeActions
      }
    );
    newState && setTreeState({ ...newState });
  }, [partyState.entities, partyRelationState, selectedParty]);

  useEffect(() => {
    if (selectedParty) {
      const asyncEffect = async () => {
        await getTreeApi([selectedParty.id], true);
      };
      const selectedNode = elphiTreeOperations.findNode(
        treeState,
        selectedParty.id
      );
      !selectedNode && asyncEffect();
      resetHighlighted();
    }
  }, [selectedParty && selectedParty.id]);

  const handleNodeSelect = useCallbackRef((node: ElphiNode<Party>) => {
    setHighlightedParty(node.id);
    setSelectedTreeNodeKey(node.nodeKey);
    if (node.nodeKey) {
      const parent = elphiTreeOperations.findParentNode(
        treeState,
        node.nodeKey
      );
      parent && parent.id
        ? setSelectedPartyParent(parent.id)
        : setSelectedPartyParent("");
    }
  });

  const loadingSkeletonMemo = useMemo(() => loadingSkeleton, []);

  return (
    <Box>
      {getTreeApiResponse.isLoading
        ? loadingSkeletonMemo
        : selectedParty && (
            <Tree
              handleNodeSelect={handleNodeSelect}
              treeState={treeState}
              elphiTreeOperations={elphiTreeOperations}
              nodeActions={[nodeActions.addNode, nodeActions.removeNode]}
            />
          )}
    </Box>
  );
};
export default PartyChart;
