import { ButtonIconDelete } from "../../button-icon/ButtonIcon";

import { DeletePartyRelationModal } from "../DeletePartyRelationComponent";

const DeleteNodeButton = (props: { callback?: () => void }) => {
  const DeleteModal = (
    <DeletePartyRelationModal
      callback={() => props.callback && props.callback()}
      buttonFn={ButtonIconDelete}
    />
  );
  return {
    component: DeleteModal,
    tooltip: "Delete this Party Relation"
  };
};

export default DeleteNodeButton;
