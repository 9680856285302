import { Box } from "@chakra-ui/react";
import { AdminHomeTabs } from "./AdminHomeTabs";

export const AdminPage = () => {
  return (
    <Box>
      <AdminHomeTabs />
    </Box>
  );
};

export default AdminPage;
