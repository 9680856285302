import {
  DealCalendarDealListDateType,
  DealCalendarParty,
  DealCalendarPartyById,
  Party
} from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { find, flatMap, get, isEmpty, some } from "lodash";
import { Dates } from "../../redux/v2/deal-calendar";
import { DateType } from "../../shared";
import { partyName } from "../party/utils/printUtils";

export const partyFullName: DotNestedKeys<DealCalendarParty> = "fullName";
export const dealsProp: DotNestedKeys<DealCalendarDealListDateType> = "deals";

export const getFullName = (item: DealCalendarParty): string => {
  const party: Partial<Party> = {
    PartyType: item?.partyType,
    FirstName: item?.firstName,
    MiddleName: item?.middleName,
    LastName: item?.lastName,
    FullName: item?.fullName
  };
  return partyName(party);
};

export const findDeal = (
  dates: Dates,
  targetId: string,
  selectedDateType: DateType
) => {
  const dateTypeDates = get(dates, selectedDateType);
  if (!dates) {
    return undefined;
  }
  return find(flatMap(dateTypeDates, dealsProp), { id: targetId }) || undefined;
};

export const getPartyIdsByRoleTypes = (
  data: DealCalendarPartyById,
  roles: string[]
): string[] => {
  return Object.values(data).reduce(
    (acc: string[], value: DealCalendarParty) => {
      if (
        value.partyId &&
        !isEmpty(value.relationRoleType) &&
        some(value.relationRoleType, (role: string) => roles.includes(role))
      ) {
        acc.push(value.partyId);
      }
      return acc;
    },
    []
  );
};
