import { Box, Button, Flex, Spacer, useBoolean } from "@chakra-ui/react";
import {
  AggregationField,
  EntityParty,
  IndividualParty,
  Party,
  PartyRelation,
  PartyType
} from "@elphi/types";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { ExpandAllButton } from "../../common-components/expandAllButton/ExpandAllButton";
import { AppConfig } from "../../config/appConfig";
import { auth } from "../../firebase/firebaseConfig";
import { usePartyHooks } from "../../hooks/party.hooks";
import { usePartyRelationHooks } from "../../hooks/partyrelation.hooks";
// import useWindowDimensions from "../../hooks/windowDimensions";
import { RootState } from "../../redux/store";
import {
  NavigationItemType,
  NavigationPages,
  NavigationPath,
  NavigationTabs
} from "../../shared/types/navigation.types";
import { removeNulls } from "../../utils/filter.utils";

import { AccordionSections } from "../ScrollableSections";
import {
  entitySections,
  individualPartyDeclarationsSections,
  individualPartyStructureSections,
  partyMilestoneSection,
  partyRelationSection,
  partyShortTermExperienceSection
} from "../application/sections/party.sections";
import { CreditReportHistoryTableContainer } from "../credit-report/CreditReportHistoryTable";
import FormBuilder, { SectionHeader } from "../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";
import { ServiceProviderPartyContainer } from "../rolodex";
import { useElphiToast } from "../toast/toast.hook";
import AssetPageContainer from "./AssetPageContainer";
import { CreatePartyModal } from "./CreatePartyComponent";
import { DeletePartyModal } from "./DeletePartyComponent";
import PartyChart from "./PartyChart";
import PartyIndividualTable, {
  PartyIndividualTableState
} from "./PartyIndividualTable";
import PartySearch from "./PartySearch";
import { PartyTaskChecklist } from "./PartyTaskChecklist";
import { useCalculateUeoHook } from "./hooks/useCalculateUEO.hook";
import { PartyTable } from "./table/v2/PartyTable";
import { partyName } from "./utils/printUtils";

export type PartyIndividualData = {
  id: string;
  individual: string;
  locStatus: string;
  shortTermTier: string;
  totalQualifyingBalance?: AggregationField;
  approvedLOC: string;
  locExpirationDate: string;
};
export type PartyRelationStructureState = {
  relations: { [id: string]: PartyRelation };
};

export type PartyStructureState<T> = {
  parties: { [id: string]: T };
};

const SECTION_HEADER_OFFSET = "60px";
const PartyStructure = () => {
  const [isMyPartyFlag, setIsMyPartyFlag] = useBoolean();
  const [isLoading, _] = useState(false);
  const {
    highlightedParty,
    selectedParty: selectedPartyState,
    setSelectedParty: setSelectedPartyState,
    selectedParent,
    updateBatchParties
  } = usePartyHooks();

  const navigationPath: NavigationPath = [
    { type: NavigationItemType.Page, value: NavigationPages.Parties },
    {
      type: NavigationItemType.Tab,
      value: NavigationTabs.PartyStructure
    }
  ];
  const [selectedParty, setSelectedParty] =
    useState<typeof selectedPartyState>();

  useEffect(() => {
    const party = selectedPartyState
      ? highlightedParty
        ? highlightedParty
        : selectedPartyState
      : undefined;
    setSelectedParty(party);
  }, [highlightedParty, selectedPartyState]);

  const { updateBatch: updatePartyRelationBatch } = usePartyRelationHooks();
  const selectedPartyRelationState = useSelector(
    (state: RootState) =>
      state.partyRelation.entities[
        state.party.selectedParentId + "_" + state.party.highlightedPartyId
      ]
  );

  const { errorToast, successToast } = useElphiToast();

  const updatePartyRelationHandler = async (
    diff: Partial<PartyRelationStructureState>
  ) => {
    if (!diff.relations) return null;
    const relations = Object.keys(diff.relations)
      .map((id) => {
        if (diff.relations) {
          return {
            ...diff.relations[id],
            id
          };
        }
      })
      .filter((v) => v !== undefined);
    if (!relations.length) return null;

    return await updatePartyRelationBatch({ relations } as {
      relations: ({
        id: string;
      } & Partial<PartyRelation>)[];
    }).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "Party Relations Updated",
          description: `${r.data.batch.length} relations updated`
        });
      }
      if (r.status === 400) {
        errorToast({
          title: "Failed to update party relations",
          description: r.data.description
        });
      }
      return r;
    });
  };
  const updatePartiesHandler = async (
    diff: Partial<PartyStructureState<Party>>
  ) => {
    if (!diff.parties) return null;
    const parties = Object.keys(diff.parties)
      .map((id) => {
        if (diff.parties) {
          return {
            ...diff.parties[id],
            id
          };
        }
      })
      .filter((v) => v !== undefined);
    if (!parties.length) return null;
    return await updateBatchParties({ parties } as {
      parties: ({
        id: string;
      } & Partial<Party>)[];
    }).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "Parties Updated",
          description: `${r.data.batch.length} parties updated`
        });
      }
      if (r.status === 400) {
        errorToast({
          title: "Failed to update parties",
          description: r.data.description
        });
      }
      return r;
    });
  };

  useEffect(() => {
    if (selectedParty) {
      if (selectedParty?.PartyType === PartyType.Individual) {
        syncIndividualState({
          shouldSync: !!selectedParty,
          state: selectedParty,
          statePath: () => ["parties", selectedParty.id]
        });
      }
      if (selectedParty?.PartyType === PartyType.Entity) {
        syncEntityState({
          shouldSync: !!selectedParty,
          state: selectedParty,
          statePath: () => ["parties", selectedParty.id]
        });
      }
    }
  }, [selectedParty]);

  const {
    onChange: individualOnChange,
    state: individualState,
    syncState: syncIndividualState
  } = useFormBuilderStateHandler<PartyStructureState<IndividualParty>>({
    initialState: { parties: {} } as PartyStructureState<IndividualParty>,
    callback: updatePartiesHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });
  const {
    onChange: entityOnChange,
    state: entityState,
    syncState: syncEntityState
  } = useFormBuilderStateHandler<PartyStructureState<EntityParty>>({
    initialState: { parties: {} } as PartyStructureState<EntityParty>,
    callback: updatePartiesHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  const {
    onChange: relationOnChange,
    state: relationState,
    syncState
  } = useFormBuilderStateHandler<PartyRelationStructureState>({
    initialState: { relations: {} } as PartyRelationStructureState,
    callback: updatePartyRelationHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });
  const { partyRelationState } = usePartyRelationHooks();
  const { partyState, partyTreeIds,resetPartySelections } = usePartyHooks();
  const { calculatePartiesUEO } = useCalculateUeoHook();

  const currentPartyIndividualIds = useMemo(() => {
    if (selectedParty && selectedParty.id) {
      return partyTreeIds(
        partyRelationState,
        selectedParty.id ? [selectedParty.id] : []
      )
        .filter((partyId) => partyId !== selectedParty.id)
        .filter(removeNulls);
    }
    return [];
  }, [partyRelationState, selectedParty]);

  const partiesCalculateUEO = useMemo(() => {
    const ueo = selectedParty
      ? calculatePartiesUEO(selectedParty.id)
      : { parties: {} };
    return ueo;
  }, [selectedParty, partyRelationState.entities]);
  const partiesIndividualState: PartyStructureState<PartyIndividualTableState> =
    currentPartyIndividualIds.reduce(
      (acc, id) => {
        const party = partyState.entities?.[id];
        if (party?.PartyType === PartyType.Individual) {
          const ultimateEquitableOwnership = partiesCalculateUEO?.parties
            ? partiesCalculateUEO.parties[party.id] || 0
            : 0;
          acc.parties[party.id] = {
            ...party,
            ultimateEquitableOwnership: ultimateEquitableOwnership
          };
        }
        return acc;
      },
      { parties: {} }
    );

  const currentPartyRelation = selectedPartyRelationState
    ? relationState.relations[selectedPartyRelationState!.id]
    : ({} as PartyRelation);
  const fullPartyRelationOnChange = (v) => {
    selectedPartyRelationState &&
      relationOnChange({
        fieldType: v.fieldType,
        fieldKey: ["relations", selectedPartyRelationState.id, ...v.fieldKey],
        value: v.value
      });
  };

  useEffect(() => {
    syncState({
      shouldSync: !!selectedPartyRelationState,
      state: selectedPartyRelationState,
      statePath: () => {
        if (selectedPartyRelationState) {
          return ["relations", selectedPartyRelationState.id];
        }
      }
    });
  }, [selectedPartyRelationState]);

  useEffect(() => {
    return () => {
      resetPartySelections();
    };
  }, []);

  const relationSection = useMemo(() => {
    return (
      selectedParty &&
      selectedPartyRelationState && (
        <FormBuilder
          customKey="partyRelationSection"
          top={SECTION_HEADER_OFFSET}
          isDisabled={!selectedPartyRelationState}
          onChange={fullPartyRelationOnChange}
          sections={[
            {
              ...partyRelationSection({
                state: currentPartyRelation,
                onChange: fullPartyRelationOnChange,
                options: {
                  hideAttachedComponent: !selectedPartyRelationState
                }
              }),
              header:
                selectedParent && selectedPartyRelationState
                  ? ` Relation: ${partyName(selectedParent)} ~ ${partyName(
                      selectedParty
                    )}`
                  : "Relation"
            }
          ]}
          navigationPath={navigationPath}
        />
      )
    );
  }, [selectedParty, relationState, selectedPartyRelationState]);

  // const { heightOffsetInPx } = useWindowDimensions();

  const entityOnChangeFull = (v) =>
    selectedParty &&
    entityOnChange({
      ...v,
      fieldKey: ["parties", selectedParty?.id, ...v.fieldKey]
    });
  const individualOnChangeFull = (v) =>
    selectedParty &&
    individualOnChange({
      ...v,
      fieldKey: ["parties", selectedParty?.id, ...v.fieldKey]
    });

  return (
    <>
      <Box minH="90px" maxH="90px" p="10px">
        <Box pl="10px" bgColor="white" position="sticky">
          <Flex w={"100%"}>
            <Box w="500px">
              {/* <Text fontWeight={"light"}>Search Party</Text> */}
              <PartySearch
                currentValue={selectedPartyState ? selectedPartyState.id : ""}
                onSelect={setSelectedPartyState}
                label={"search party"}
                labelPosition={"placeHolder"}
              />
            </Box>
            <Box pr="10px">
              <Button
                {...elphiTheme.components.light.button[
                  isMyPartyFlag ? "generate" : "primary"
                ]}
                onClick={() => {
                  setIsMyPartyFlag.toggle();
                }}
                mt={"10px"}
              >
                {isMyPartyFlag ? "Go To All Parties" : "Go to My Parties"}
              </Button>
            </Box>
            <Spacer />
            <Box>
              <Flex>
                <Box p="10px">
                  <DeletePartyModal />
                </Box>
                <Box p="10px">
                  <CreatePartyModal />
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Box>
      <Box>
        <ExpandAllButton navigationPath={navigationPath} />
      </Box>
      <Box h={"calc(100% - 200px)"} overflow="scroll" w="100%">
        {selectedParty && (
          <Box bgColor={"white"} position="sticky" top="0" zIndex="5">
            <AccordionSections
              customKey="partyTree"
              lazyLoad={false}
              sections={[
                {
                  header: (
                    <SectionHeader
                      header={`Party Tree ${
                        (highlightedParty && partyName(highlightedParty)) ||
                        (selectedParty && partyName(selectedParty)) ||
                        ""
                      }`}
                    />
                  ),
                  body: (
                    <Box overflow="scroll" resize="vertical" bgColor={"white"}>
                      <Box h={"50%"}>
                        <PartyChart />
                      </Box>
                    </Box>
                  )
                }
              ]}
              navigationPath={navigationPath}
            />
          </Box>
        )}
        {selectedParty && selectedParty.PartyType === PartyType.Entity ? (
          <FormBuilder
            customKey="partyMilestoneSection"
            top={SECTION_HEADER_OFFSET}
            onChange={entityOnChangeFull}
            sections={[
              partyMilestoneSection({
                state: entityState.parties[selectedParty.id],
                onChange: entityOnChangeFull
              })
            ]}
            navigationPath={navigationPath}
          />
        ) : null}

        {relationSection}
        {selectedParty && selectedParty.PartyType === PartyType.Entity ? (
          <FormBuilder
            customKey="entitySections"
            top={SECTION_HEADER_OFFSET}
            onChange={entityOnChangeFull}
            sections={entitySections({
              state: entityState.parties[selectedParty.id],
              onChange: entityOnChangeFull
            })}
            navigationPath={navigationPath}
          />
        ) : null}
        {selectedParty && selectedParty.PartyType === PartyType.Individual ? (
          <FormBuilder
            customKey="individualPartyStructureSections"
            top={SECTION_HEADER_OFFSET}
            onChange={individualOnChangeFull}
            sections={individualPartyStructureSections({
              state: individualState.parties[selectedParty.id],
              onChange: individualOnChangeFull
            })}
            navigationPath={navigationPath}
          />
        ) : null}
        {selectedParty && selectedParty.PartyType === PartyType.Individual ? (
          <FormBuilder
            customKey="partyShortTermExperienceSection"
            onChange={individualOnChangeFull}
            sections={[
              partyShortTermExperienceSection({
                state: individualState.parties?.[selectedParty.id],
                onChange: individualOnChangeFull
              })
            ]}
            navigationPath={navigationPath}
          />
        ) : null}
        {selectedParty && selectedParty.PartyType === PartyType.Individual && (
          <AccordionSections
            customKey="serviceProvider"
            top={SECTION_HEADER_OFFSET}
            sections={[
              {
                header: <SectionHeader header={"Service Providers"} />,
                body: (
                  <ServiceProviderPartyContainer partyId={selectedParty.id} />
                )
              }
            ]}
            navigationPath={navigationPath}
          />
        )}
        {selectedParty && selectedParty.PartyType === PartyType.Individual && (
          <>
            <AccordionSections
              customKey="assets"
              top={SECTION_HEADER_OFFSET}
              sections={[
                {
                  header: <SectionHeader header={"Assets"} />,
                  body: <AssetPageContainer selectedParty={selectedParty} />
                }
              ]}
              navigationPath={navigationPath}
            />
          </>
        )}
        {selectedParty && selectedParty.PartyType === PartyType.Individual ? (
          <>
            <AccordionSections
              customKey="creditReportHistory"
              top={SECTION_HEADER_OFFSET}
              sections={[
                {
                  header: <SectionHeader header="Credit Report History" />,
                  body: (
                    <CreditReportHistoryTableContainer
                      selectedParty={selectedParty}
                      includeManualCreate={true}
                    />
                  )
                }
              ]}
              navigationPath={navigationPath}
            />
            <FormBuilder
              customKey="individualPartyDeclarationsSections"
              top={SECTION_HEADER_OFFSET}
              onChange={individualOnChangeFull}
              sections={individualPartyDeclarationsSections({
                state: individualState.parties[selectedParty.id],
                onChange: individualOnChangeFull
              })}
              navigationPath={navigationPath}
            />
          </>
        ) : null}
        <AccordionSections
          customKey="partyDeals"
          top={selectedParty ? SECTION_HEADER_OFFSET : "0px"}
          sections={[
            {
              index: 0,
              header: <SectionHeader header={"Party ~ Deals"} />,
              body: (
                <Box overflowX={"scroll"}>
                  <PartyTable
                    pageSize={10}
                    userId={isMyPartyFlag ? auth?.currentUser?.uid : undefined}
                    partyId={selectedParty ? selectedParty.id : undefined}
                  />
                </Box>
              )
            },
            {
              index: 1,
              isHidden:
                !selectedParty || selectedParty.PartyType !== PartyType.Entity,
              header: <SectionHeader header={"Entity ~ Individuals"} />,
              body: (
                <Box>
                  {selectedParty &&
                  selectedParty.PartyType === PartyType.Entity ? (
                    <PartyIndividualTable
                      formBuilderState={{
                        onChange: individualOnChange,
                        state: partiesIndividualState
                      }}
                      isLoading={isLoading}
                    />
                  ) : (
                    "please select an entity party"
                  )}
                </Box>
              )
            },
            {
              index: 2,
              isHidden: !selectedPartyState,
              header: <SectionHeader header={"Checklist"} />,
              body: (
                <Box>
                  {selectedPartyState ? (
                    <Box>
                      <PartyTaskChecklist partyId={selectedPartyState.id} />
                    </Box>
                  ) : (
                    "please select a party"
                  )}
                </Box>
              )
            }
          ]}
          navigationPath={navigationPath}
        />
      </Box>
    </>
  );
};

export default PartyStructure;
