import {
  ElphiEntityType,
  PartyType,
  ServiceProviderType
} from "../../entities";

type InnerTypeMapping = {
  [ElphiEntityType.party]: PartyType;
  [ElphiEntityType.serviceProvider]: ServiceProviderType;
};

type AuditEventLinkItem<T extends ElphiEntityType = ElphiEntityType> = {
  entityIdentifier?: string;
  entityId: string;
  entityType: T;
  innerType?: T extends keyof InnerTypeMapping ? InnerTypeMapping[T] : never;
};

export type AuditEventLinkResponse = {
  [K in ElphiEntityType]: AuditEventLinkItem<K>;
}[ElphiEntityType][];

export type AuditEventLink = AuditEventLinkResponse[0];
