import { TaskConfiguration } from "@elphi/types";
import { useTaskConfigurationHooks } from "../../../hooks/taskConfiguration.hooks";
import { SearchComponentProps } from "../../SearchComponent";
import { SearchHandler } from "../../search/SearchHandler";

const buildOption = (taskConfiguration: TaskConfiguration) => {
  const label = `${taskConfiguration?.name}`;
  const value = taskConfiguration.id;
  return {
    label,
    value
  };
};
export const TaskConfigurationSearch = (
  props: {
    filter?: (a: TaskConfiguration) => boolean;
    label?: string;
  } & Pick<
    SearchComponentProps,
    "onSelect" | "currentValue" | "fieldType" | "labelPosition" | "label"
  >
) => {
  const {
    searchTaskConfigurationApi,
    rankedSort,
    taskConfigurationState,
    searchResponse
  } = useTaskConfigurationHooks();
  return (
    <SearchHandler
      {...props}
      version="v2"
      searchApi={searchTaskConfigurationApi}
      searchResponse={searchResponse}
      rankedSort={rankedSort}
      state={taskConfigurationState}
      buildOption={buildOption}
    />
  );
};

export default TaskConfigurationSearch;
