import { Property } from "@elphi/types";
import { OnChangeInput, Section } from "../../../../form-builder/FormBuilder";
import { siteXOrderSection } from "./siteXOrderSection";
import { siteXOrderStatusSection } from "./siteXOrderStatusSection";
import { siteXVerifySection } from "./siteXVerifySection";

export const siteXSections = (
  state: Partial<Property>,
  onChange: (v: OnChangeInput) => void
): Section[] => {
  return [
    siteXOrderSection(state, onChange),
    siteXVerifySection(state, onChange),
    siteXOrderStatusSection(state, onChange)
  ];
};
