import { ArrowRightIcon } from "@chakra-ui/icons";
import { createIcon, IconButtonProps, IconProps } from "@chakra-ui/react";
import { WorksheetTab } from "../../features/worksheet/worksheet.types";

export const EditIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 7L17 3L14 6L8 12V16H12L18 10L21 7Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 6L18 10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 4H4V20H20V14" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const defaultProps: IconProps = {
  stroke: "#505872",
  fill: "none",
  strokeWidth: "2"
};
export const ApplicationIcon = createIcon({
  defaultProps: defaultProps,
  path: (
    <svg xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 7L17 3L14 6L8 12V16H12L18 10L21 7Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14 6L18 10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 4H4V20H20V14" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
});
export const ListIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20 4H4V20H20V4Z" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 8H17" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 12H17" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 16H13" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const ChecklistIcon = createIcon({
  defaultProps: defaultProps,
  path: (
    <svg xmlns="http://www.w3.org/2000/svg">
      <path d="M20 4H4V20H20V4Z" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 8H17" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 12H17" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 16H13" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
});

export const PackageIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 13H14" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19 9V20H5V9" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M19 4H5C4.44772 4 4 4.44772 4 5V8C4 8.55228 4.44772 9 5 9H19C19.5523 9 20 8.55228 20 8V5C20 4.44772 19.5523 4 19 4Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PortfolioIcon = createIcon({
  defaultProps: defaultProps,
  path: (
    <svg xmlns="http://www.w3.org/2000/svg">
      <path d="M10 13H14" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19 9V20H5V9" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M19 4H5C4.44772 4 4 4.44772 4 5V8C4 8.55228 4.44772 9 5 9H19C19.5523 9 20 8.55228 20 8V5C20 4.44772 19.5523 4 19 4Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
});

export const SuitcaseIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 12V14V16" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M4 12V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V12"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 6H6C4.89543 6 4 6.89543 4 8V12C4 13.1046 4.89543 14 6 14H12H18C19.1046 14 20 13.1046 20 12V8C20 6.89543 19.1046 6 18 6Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 6V5C15 3.89543 14.1046 3 13 3H11C9.89543 3 9 3.89543 9 5V6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ClosingDocumentIcon = createIcon({
  defaultProps: defaultProps,
  path: (
    <svg xmlns="http://www.w3.org/2000/svg">
      <path d="M12 12V14V16" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M4 12V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V12"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 6H6C4.89543 6 4 6.89543 4 8V12C4 13.1046 4.89543 14 6 14H12H18C19.1046 14 20 13.1046 20 12V8C20 6.89543 19.1046 6 18 6Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 6V5C15 3.89543 14.1046 3 13 3H11C9.89543 3 9 3.89543 9 5V6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
});

const PricingEngineIcon = createIcon({
  defaultProps: defaultProps,
  path: (
    <svg xmlns="http://www.w3.org/2000/svg">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 2.25C6.61523 2.25 2.25 6.61523 2.25 12C2.25 17.3848 6.61523 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61523 17.3848 2.25 12 2.25ZM3.75 12C3.75 7.44365 7.44365 3.75 12 3.75C16.5564 3.75 20.25 7.44365 20.25 12C20.25 16.5564 16.5564 20.25 12 20.25C7.44365 20.25 3.75 16.5564 3.75 12ZM9.75 10C9.75 9.44443 9.96468 9.1696 10.1882 9.00865C10.45 8.82017 10.7867 8.75 11 8.75H12H15C15.4142 8.75 15.75 8.41421 15.75 8C15.75 7.58579 15.4142 7.25 15 7.25H12.75V6C12.75 5.58579 12.4142 5.25 12 5.25C11.5858 5.25 11.25 5.58579 11.25 6V7.25H11C10.5466 7.25 9.8833 7.37983 9.31176 7.79135C8.70197 8.2304 8.25 8.95557 8.25 10C8.25 11.0444 8.70197 11.7696 9.31176 12.2086C9.8833 12.6202 10.5466 12.75 11 12.75H13C13.2133 12.75 13.55 12.8202 13.8118 13.0086C14.0353 13.1696 14.25 13.4444 14.25 14C14.25 14.5556 14.0353 14.8304 13.8118 14.9914C13.55 15.1798 13.2133 15.25 13 15.25H12H9C8.58579 15.25 8.25 15.5858 8.25 16C8.25 16.4142 8.58579 16.75 9 16.75H11.25V18C11.25 18.4142 11.5858 18.75 12 18.75C12.4142 18.75 12.75 18.4142 12.75 18V16.75H13C13.4534 16.75 14.1167 16.6202 14.6882 16.2086C15.298 15.7696 15.75 15.0444 15.75 14C15.75 12.9556 15.298 12.2304 14.6882 11.7914C14.1167 11.3798 13.4534 11.25 13 11.25H11C10.7867 11.25 10.45 11.1798 10.1882 10.9914C9.96468 10.8304 9.75 10.5556 9.75 10Z"
      />
    </svg>
  )
});

export const WorksheetIcon = (props: {
  tab: WorksheetTab;
  color?: IconButtonProps["color"];
}) => {
  switch (props.tab) {
    case WorksheetTab.Application:
      return <ApplicationIcon stroke={props.color} h="20px" w="20px" />;

    case WorksheetTab.Checklist:
      return <ChecklistIcon stroke={props.color} h="20px" w="20px" />;

    case WorksheetTab.Portfolio:
      return <PortfolioIcon stroke={props.color} h="20px" w="20px" />;

    case WorksheetTab.Closing:
      return <ClosingDocumentIcon stroke={props.color} h="20px" w="20px" />;
    case WorksheetTab.PricingEngine:
      return <PricingEngineIcon stroke={props.color} h="20px" w="20px" />;

    default:
      return <ArrowRightIcon h="20px" w="20px" color={props.color} />;
  }
};
