import { Asset } from "@elphi/types";
import { ObjectKeyValidation } from "../../form-builder/field-specs/objectKeyValidation.types";

export const typeFieldPath: ObjectKeyValidation<Asset, ["type"]> = ["type"];
export const estimatedValueAmountPath: ObjectKeyValidation<
  Asset,
  ["estimatedValueAmount"]
> = ["estimatedValueAmount"];
export const estimatedValueConsideredPercentagePath: ObjectKeyValidation<
  Asset,
  ["estimatedValueConsideredPercentage"]
> = ["estimatedValueConsideredPercentage"];
export const appraisalValueAmountPath: ObjectKeyValidation<
  Asset,
  ["appraisalValueAmount"]
> = ["appraisalValueAmount"];
export const finalEstimatedValueAmountPath: ObjectKeyValidation<
  Asset,
  ["finalEstimatedValueAmount"]
> = ["finalEstimatedValueAmount"];
export const fullNameOnAccountPath: ObjectKeyValidation<
  Asset,
  ["fullNameOnAccount"]
> = ["fullNameOnAccount"];
export const accountNamePath: ObjectKeyValidation<Asset, ["accountName"]> = [
  "accountName"
];
export const accountTypePath: ObjectKeyValidation<Asset, ["accountType"]> = [
  "accountType"
];
export const financialInstitutionPath: ObjectKeyValidation<
  Asset,
  ["financialInstitution"]
> = ["financialInstitution"];
export const last4DigitsPath: ObjectKeyValidation<Asset, ["last4Digits"]> = [
  "last4Digits"
];
