import { DevFeatureFlag } from "@elphi/utils";
import { useEffect } from "react";
import { auth } from "../firebase/firebaseConfig";
import { dealApi } from "../redux/v2/deal";
import useDealHooks from "./deal.hooks";
import { useDealPropertyRelationHooks } from "./dealPropertyRelation.hooks";
import { useDealPartyRelationHooks } from "./dealpartyrelation.hooks";
import { useInsurancePolicyHooks } from "./insurance-policy/insurancePolicy.hooks";
import { usePartyHooks } from "./party.hooks";
import { useRolodexBranchRepRelation } from "./rolodexBranchRepRelation.hooks";
import { useStatsigGate } from "./statsig.hooks";

export const worksheetHeaderLiveStateContainerHooks = () => {
  const [getDealProperties, dealPropertiesResponse] =
    dealApi.useLazyDealPropertiesQuery();
  const [getDeal, dealResponse] = dealApi.useLazyGetQuery();
  const [getDealParties, dealPartiesResponse] =
    dealApi.useLazyDealPartiesQuery();

  const { dealState, selectedIdString: dealId } = useDealHooks();
  const { dealPropertyRelationState } = useDealPropertyRelationHooks();
  const { partyState } = usePartyHooks();
  const { dealPartyRelationState } = useDealPartyRelationHooks();
  const { getBranchRepByDealIdHandler } = useRolodexBranchRepRelation();
  const { getInsurancePoliciesByDealId } = useInsurancePolicyHooks();

  //Remove after feature ready
  const { value: isRolodexEnabled } = useStatsigGate(
    DevFeatureFlag.ESD_2789_Rolodex_End_User
  );
  //Remove after feature ready
  const { value: isInsurancePolicyEnabled } = useStatsigGate(
    DevFeatureFlag.ESD_2792_InsurancePolicy
  );

  useEffect(() => {
    if (auth.currentUser && dealId && isRolodexEnabled) {
      getBranchRepByDealIdHandler(dealId, true);
    }
  }, [auth.currentUser, dealId, isRolodexEnabled]);

  useEffect(() => {
    if (auth.currentUser && dealId && isInsurancePolicyEnabled) {
      getInsurancePoliciesByDealId(dealId, true);
    }
  }, [auth.currentUser, dealId]);

  useEffect(() => {
    if (auth.currentUser && dealId) {
      getDealProperties([dealId], true);
      getDeal(dealId, true);
      getDealParties([dealId], true);
    }
  }, [auth.currentUser, dealId]);

  return {
    dealState,
    dealPropertyRelationState,
    dealPartyRelationState,
    partyState,
    dealPropertiesResponse,
    dealResponse,
    dealPartiesResponse
  };
};
