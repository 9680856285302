import { Box, Flex } from "@chakra-ui/react";
import { useDocumentConfigurationHooks } from "../../../hooks/documentConfiguration.hooks";
import CreateDocumentConfigurationModal from "./CreateDocumentConfigurationModal";
import DocumentConfigurationSearch from "./DocumentConfigurationSearch";

const DocumentConfigurationHeader = () => {
  const { selectedDocumentConfiguration, setSelectedDocumentConfiguration } =
    useDocumentConfigurationHooks();
  return (
    <Box w="100%">
      <Flex w="100%">
        <Box w="50%">
          <Box w="600px">
            <DocumentConfigurationSearch
              onSelect={setSelectedDocumentConfiguration}
              currentValue={
                selectedDocumentConfiguration
                  ? selectedDocumentConfiguration.id
                  : ""
              }
            />
          </Box>
        </Box>
        <Box p="10px" w="50%">
          <CreateDocumentConfigurationModal />
        </Box>
      </Flex>
    </Box>
  );
};

export default DocumentConfigurationHeader;
