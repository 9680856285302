import { Box, Flex } from "@chakra-ui/react";
import {
  FieldType,
  FirebaseFilter,
  StatusCode,
  TaskStatusType,
  TaskTemplate
} from "@elphi/types";
import { removeEmptyValues } from "@elphi/utils/src/common.utils";

import { EntityId } from "@reduxjs/toolkit";
import { useEffect, useMemo, useState } from "react";
import { AppConfig } from "../../../../config/appConfig";
import { auth } from "../../../../firebase/firebaseConfig";
import { useAuthStateChangeHook } from "../../../../hooks/authStateChange.hooks";
import { useTaskTemplateHooks } from "../../../../hooks/taskTemplate.hooks";
import useWindowDimensions from "../../../../hooks/windowDimensions";
import { useRTKPagination } from "../../../../redux/v2/hooks";
import { taskTemplateApi } from "../../../../redux/v2/task-template";
import RoleSearch from "../../../admin/role-permission/RoleSearch";
import { ElphiPaginationList } from "../../../elphi-list/ElphiList";
import StyledInputBuilder, {
  ValueContainer,
  useFormBuilderStateHandler
} from "../../../form-builder/InputBuilder";
import { FilterCellComponent } from "../../../table/FilterCellComponent";
import {
  SIZE_FIELD,
  TableRowSizeComponent
} from "../../../table/TableRowSizeComponent";
import {
  CellsFilterStateFlat,
  useFilterHook
} from "../../../table/hooks/filter.hook";
import { ElphiCellType, ElphiTableProps } from "../../../table/table.types";
import { useElphiToast } from "../../../toast/toast.hook";
import { filterChildrenStyle, filterStyle } from "../../../utils/filter.utils";
import { createOptionsFromEnum } from "../../../utils/formUtils";
import {
  TaskTemplateTableRow,
  multiDropDownComponent
} from "./TaskTemplateTableRow";
import { filterState } from "./taskTemplate.util";

export const useTaskTemplateTableHooks = () => {
  const taskTemplateHooks = useTaskTemplateHooks();
  const paginationHandler = useRTKPagination({
    entityState: taskTemplateHooks.taskTemplateState,
    useLazyPaginateQuery: taskTemplateApi.useLazyPaginateQuery
  });
  const { updateBatch, searchTaskTemplateApi } = taskTemplateHooks;
  const { errorToast, successToast } = useElphiToast();

  const updateTaskTemplateBatch = async (
    r: Partial<{
      [id: string]: TaskTemplate;
    }>
  ) => {
    const tasks = removeEmptyValues(r);
    if (!tasks?.length) return null;

    return await updateBatch({ tasks }).then((r) => {
      if (r.status === StatusCode.OK) {
        successToast({
          title: "Template Updated",
          description: `total templates updated: ${r.data?.batch?.length}`
        });
      } else if (r.status === StatusCode.BadRequest) {
        errorToast({
          title: "Failed to update batch",
          description: r.data?.description
        });
      }
      return r;
    });
  };
  return {
    taskTemplateHooks,
    paginationHandler,
    updateTaskTemplateBatch,
    searchTaskTemplateApi
  };
};

export const TaskTemplateTable = () => {
  const pageSize = 10;
  const limit = 50;
  const { heightOffsetInPx } = useWindowDimensions();
  const { taskTemplateHooks, paginationHandler, updateTaskTemplateBatch } =
    useTaskTemplateTableHooks();
  const { taskTemplateState, searchTaskTemplateApi } = taskTemplateHooks;
  const { syncState, onChange, state } = useFormBuilderStateHandler<{
    [id: string]: Partial<TaskTemplate>;
  }>({
    initialState: {} as { [id: string]: TaskTemplate },
    callback: updateTaskTemplateBatch,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  useAuthStateChangeHook({
    onAuthStateChange: (_) => {
      if (
        paginationHandler.pageResponse.isUninitialized &&
        !paginationHandler.pageResponse.isSuccess &&
        !paginationHandler.pageResponse.isLoading &&
        !paginationHandler.pageResponse.isFetching &&
        auth.currentUser
      ) {
        paginationHandler.next();
      }
    },
    deps: []
  });

  const isLoading =
    paginationHandler.pageResponse.isFetching ||
    paginationHandler.pageResponse.isLoading;

  const [filteredTask, setFilteredTask] = useState<{
    filter?: FirebaseFilter | FirebaseFilter[];
  }>();
  const [filteredTaskIds, setFilteredTaskIds] = useState<EntityId[]>([]);
  const [checklistOf, setChecklistOf] = useState<string[]>([]);
  const [assignableBy, setAssignableBy] = useState<string[]>([]);
  const [editableBy, setEditableBy] = useState<string[]>([]);
  const [availableStatus, setAvailableStatus] = useState<string[]>([]);

  const {
    setStateDebouncer: tableFilterSetter,
    setStateInputDebouncer: tableInputSetter,
    state: tableFilterState,
    selectedOrderState: selectedOrderState,
    setSelectedOrderState: setSelectedOrderState,
    getFilteredAndSortDataIds
  } = useFilterHook<TaskTemplate, CellsFilterStateFlat<TaskTemplate>>({
    state: filterState,
    options: {
      debounceRate: 100
    },
    searchApi: searchTaskTemplateApi,
    onNextFilter: (filter) => {
      setFilteredTask(filter);
      if (filter) {
        paginationHandler.next(filter);
      } else {
        paginationHandler.reset();
      }
    },
    nextFilterConfig: {
      baseField: "template",
      customFilter: {
        "template.assignableBy": {
          multi: "array-contains-any"
        },
        "template.checklistOf": {
          multi: "array-contains-any"
        },
        "template.availableStatus": {
          multi: "array-contains-any"
        }
      }
    }
  });

  useEffect(() => {
    const ids = getFilteredAndSortDataIds(taskTemplateState);
    setFilteredTaskIds(ids);
  }, [taskTemplateState.ids, tableFilterState]);

  const tableHeaders: ElphiTableProps["header"] = [
    {
      index: 0,
      data: "",
      type: ElphiCellType.String,
      size: SIZE_FIELD.S
    },
    {
      index: 1,
      data: (
        <FilterCellComponent<TaskTemplate>
          data={[]}
          disabled
          tooltipLabel="Not Supported Yet"
          fieldKey={"createdAt"}
          baseField="template"
          inputSetter={tableInputSetter}
          setter={tableFilterSetter}
          state={tableFilterState}
          selectedOrderSetter={setSelectedOrderState}
          selectedOrderState={selectedOrderState}
          showOrderState={false}
          showSelectAll={false}
          customStyles={filterStyle}
        />
      ),
      type: ElphiCellType.Element,
      size: SIZE_FIELD.M
    },
    {
      index: 2,
      data: (
        <FilterCellComponent<TaskTemplate>
          data={Object.values(taskTemplateState.entities) as TaskTemplate[]}
          fieldKey={"templateName"}
          baseField="template"
          inputSetter={tableInputSetter}
          setter={tableFilterSetter}
          state={tableFilterState}
          selectedOrderSetter={setSelectedOrderState}
          selectedOrderState={selectedOrderState}
          showSelectAll={false}
          customStyles={filterStyle}
        />
      ),
      type: ElphiCellType.Element,
      size: SIZE_FIELD.L
    },
    {
      index: 3,
      data: (
        <FilterCellComponent<TaskTemplate>
          data={Object.values(taskTemplateState.entities) as TaskTemplate[]}
          fieldKey={"type"}
          baseField="template"
          inputSetter={tableInputSetter}
          setter={tableFilterSetter}
          state={tableFilterState}
          selectedOrderSetter={setSelectedOrderState}
          selectedOrderState={selectedOrderState}
          showOrderState={false}
          showSelectAll={false}
          customStyles={filterStyle}
        />
      ),
      type: ElphiCellType.Element,
      size: SIZE_FIELD.L
    },
    {
      index: 4,
      data: (
        <FilterCellComponent<TaskTemplate>
          data={[]}
          selectedValue={checklistOf}
          fieldKey={"checklistOf"}
          baseField="template"
          inputSetter={tableInputSetter}
          setter={tableFilterSetter}
          state={tableFilterState}
          selectedOrderSetter={setSelectedOrderState}
          selectedOrderState={selectedOrderState}
          showOrderState={false}
          showSelectAll={false}
          customStyles={filterChildrenStyle}
        >
          <RoleSearch
            customComponent={multiDropDownComponent}
            currentValue={checklistOf}
            fieldType={FieldType.MultiSelect}
            onSelect={(items: string[]) => {
              setChecklistOf(items);
            }}
            hideSelectedOptions={false}
          />
        </FilterCellComponent>
      ),
      type: ElphiCellType.Element,
      size: SIZE_FIELD.L
    },
    {
      index: 5,
      data: (
        <FilterCellComponent<TaskTemplate>
          data={[]}
          selectedValue={assignableBy}
          fieldKey={"assignableBy"}
          baseField="template"
          inputSetter={tableInputSetter}
          setter={tableFilterSetter}
          state={tableFilterState}
          selectedOrderSetter={setSelectedOrderState}
          selectedOrderState={selectedOrderState}
          showOrderState={false}
          showSelectAll={false}
          customStyles={filterChildrenStyle}
        >
          <RoleSearch
            customComponent={multiDropDownComponent}
            currentValue={assignableBy}
            fieldType={FieldType.MultiSelect}
            onSelect={(items: string[]) => {
              setAssignableBy(items);
            }}
            hideSelectedOptions={false}
          />
        </FilterCellComponent>
      ),
      type: ElphiCellType.Element,
      size: SIZE_FIELD.L
    },
    {
      index: 6,
      data: (
        <FilterCellComponent<TaskTemplate>
          data={[]}
          selectedValue={editableBy}
          fieldKey={"editableBy"}
          baseField="template"
          inputSetter={tableInputSetter}
          setter={tableFilterSetter}
          state={tableFilterState}
          selectedOrderSetter={setSelectedOrderState}
          selectedOrderState={selectedOrderState}
          showOrderState={false}
          showSelectAll={false}
          customStyles={filterChildrenStyle}
        >
          <RoleSearch
            customComponent={multiDropDownComponent}
            currentValue={editableBy}
            fieldType={FieldType.MultiSelect}
            onSelect={(items: string[]) => {
              setEditableBy(items);
            }}
            hideSelectedOptions={false}
          />
        </FilterCellComponent>
      ),
      type: ElphiCellType.Element,
      size: SIZE_FIELD.L
    },
    {
      index: 7,
      data: (
        <FilterCellComponent<TaskTemplate>
          data={[]}
          selectedValue={availableStatus}
          fieldKey={"availableStatus"}
          baseField="template"
          inputSetter={tableInputSetter}
          setter={tableFilterSetter}
          state={tableFilterState}
          selectedOrderSetter={setSelectedOrderState}
          selectedOrderState={selectedOrderState}
          showOrderState={false}
          showSelectAll={false}
          customStyles={filterStyle}
        >
          <Box>
            <StyledInputBuilder
              chakraStyles={{
                valueContainer: (provided) => ({
                  ...provided,
                  fontSize: "12px"
                }),
                dropdownIndicator: (prev) => ({
                  ...prev,
                  w: "20px",
                  p: "0px"
                })
              }}
              hideSelectedOptions={false}
              customComponent={{
                ValueContainer: ValueContainer
              }}
              currentValue={availableStatus}
              fieldType={FieldType.MultiSelect}
              options={createOptionsFromEnum(TaskStatusType)}
              onChange={(e) => {
                setAvailableStatus(e.target.value);
              }}
              label="filter..."
            />
          </Box>
        </FilterCellComponent>
      ),
      type: ElphiCellType.Element,
      size: SIZE_FIELD.L
    },
    {
      index: 8,
      data: "",
      type: ElphiCellType.String,
      size: SIZE_FIELD.S
    }
  ];

  const items = useMemo(() => {
    return !!taskTemplateState.selectedId
      ? [taskTemplateState.selectedId.toString()]
      : filteredTaskIds || taskTemplateState.ids;
  }, [paginationHandler, filteredTaskIds]);

  return (
    <Flex width="100%">
      <ElphiPaginationList
        tableName={"TaskTemplate"}
        isLoading={isLoading}
        header={
          <TableRowSizeComponent
            row={tableHeaders}
            withBorderRightWidth={false}
            justify="space-evenly"
          />
        }
        height={heightOffsetInPx(320)}
        hasMore={paginationHandler.pageData?.ids?.length < limit}
        items={items}
        next={() => paginationHandler.next(filteredTask)}
        pageSize={pageSize}
        rowBuilder={(taskTemplateId, index) => {
          return (
            <TaskTemplateTableRow
              taskTemplateId={taskTemplateId?.toString() || ""}
              index={(Number(index) + 1).toString()}
              syncState={syncState}
              taskTemplateHooks={taskTemplateHooks}
              onChange={onChange}
              state={state}
              key={index}
            />
          );
        }}
      />
    </Flex>
  );
};
