import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import {
  DEFAULT_PARTY_GROUP,
  EntityParty,
  IndividualParty,
  Party,
  PartyType,
  getPartyTemplate
} from "@elphi/types";
import { DevFeatureFlag } from "@elphi/utils";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { AppResponse } from "../../apis/appResponse.types";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { GateKeeper } from "../../features/gate/GateKeeper";
import { usePartyHooks } from "../../hooks/party.hooks";
import { RootState } from "../../redux/store";
import {
  createEntitySection,
  createIndividualSection,
  partyInfoSection
} from "../application/sections/party.sections";
import FormBuilder from "../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";
import { basePartyFieldSpecs } from "../form-builder/field-specs/party/party.fields";
import { useElphiToast } from "../toast/toast.hook";
import PartySearch from "./PartySearch";
import { partyToString } from "./utils/printUtils";
import { PartyGroupSearch } from "../organization-management/party-group/search/PartyGroupSearch";

export const CreatePartyForm = (props?: {
  createParty?: (newParty: Party) => void;
  onSuccess?: () => void;
  isLoading?: boolean;
}) => {
  const { createParty, createPartyApiResponse } = usePartyHooks();
  const { errorToast, successToast } = useElphiToast();

  const { onChange: partyPropsOnChange, state: partyPropsState } =
    useFormBuilderStateHandler<Partial<Party>>({
      initialState: {
        GroupId: DEFAULT_PARTY_GROUP
      }
    });

  const { onChange: individualOnChange, state: individualState } =
    useFormBuilderStateHandler<IndividualParty>({
      initialState: getPartyTemplate(PartyType.Individual) as IndividualParty
    });
  const { onChange: entityOnChange, state: entityState } =
    useFormBuilderStateHandler<EntityParty>({
      initialState: getPartyTemplate(PartyType.Entity) as EntityParty
    });

  const currentState =
    partyPropsState.PartyType === PartyType.Individual
      ? individualState
      : entityState;

  const isStateValidForCreation =
    (currentState.PartyType === PartyType.Entity &&
      !!currentState.FullName &&
      !!currentState.TaxpayerIdentifierValue) ||
    (currentState.PartyType === PartyType.Individual &&
      !!currentState.FirstName &&
      !!currentState.LastName &&
      !!currentState.TaxpayerIdentifierValue);

  const createPartyHandler = () => {
    const newParty = currentState;
    partyPropsState.PartyType && props?.createParty
      ? props?.createParty(newParty)
      : createParty(newParty).then((r) => {
          if (r.status === 200) {
            successToast({
              title: "Party Created",
              description: `new party: ${r.data.id}`
            });
            props?.onSuccess && props?.onSuccess();
          }
          r.status === 400 &&
            errorToast({
              title: "Failed to create party",
              description: r.data.description
            });
        });
  };

  const partyGroupSpec = {
    fieldKey: basePartyFieldSpecs.GroupId.fieldKey,
    fieldType: basePartyFieldSpecs.GroupId.fieldType
  };

  return (
    <>
      <Box>
        <FormBuilder
          customKey="partyInfoSectionCreateParty"
          elphiView="form"
          onChange={partyPropsOnChange}
          sections={[
            partyInfoSection({
              state: partyPropsState,
              onChange: partyPropsOnChange,
              options: { hideAttachedComponent: true }
            })
          ]}
        />
        <GateKeeper gate={DevFeatureFlag.ESD_2826_Organization_Management}>
          <PartyGroupSearch
            label={basePartyFieldSpecs.GroupId.label}
            currentValue={partyPropsState.GroupId!}
            shouldFilterDisabled={true}
            onSelect={(id) => {
              const onChangeDetails = { ...partyGroupSpec, value: id };
              partyPropsOnChange(onChangeDetails);
              partyPropsState.PartyType === PartyType.Individual &&
                individualOnChange(onChangeDetails);
              partyPropsState.PartyType === PartyType.Entity &&
                entityOnChange(onChangeDetails);
            }}
          />
        </GateKeeper>
        {partyPropsState.PartyType === PartyType.Individual ? (
          <FormBuilder
            customKey="createIndividualSection"
            elphiView="form"
            onChange={individualOnChange}
            sections={[
              createIndividualSection({
                state: individualState,
                options: { hideAttachedComponent: true }
              })
            ]}
          />
        ) : null}
        {partyPropsState.PartyType === PartyType.Entity ? (
          <FormBuilder
            customKey="createEntitySection"
            elphiView="form"
            onChange={entityOnChange}
            sections={[
              createEntitySection({
                state: entityState,
                options: { hideAttachedComponent: true }
              })
            ]}
          />
        ) : null}
      </Box>
      <Button
        {...elphiTheme.components.light.button.primary}
        onClick={() => createPartyHandler()}
        isDisabled={!isStateValidForCreation}
        isLoading={createPartyApiResponse.isLoading || props?.isLoading}
      >
        Create
      </Button>
    </>
  );
};

export const CreatePartyModal = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const buttonComponent = props.buttonFn?.(onOpen) ?? null;

  return (
    <Box>
      <>
        {props.buttonFn ? (
          buttonComponent
        ) : (
          <Button
            {...elphiTheme.components.light.button.primary}
            onClick={onOpen}
          >
            Create New Party
          </Button>
        )}

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent minWidth={"600px"}>
            <ModalHeader>New Party</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <CreatePartyForm onSuccess={onClose} />
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    </Box>
  );
};

export const AddPartyChildModal = (props: {
  callback?: (r: {
    childId: string;
    relationId: string;
    parentId: string;
  }) => void;
  buttonFn?: (props: {
    handleOpen: () => void;
    isDisabled?: boolean;
  }) => JSX.Element;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedChild, setSelectedChild] = useState<
    Party | null | "" | undefined
  >("");

  const { successToast, errorToast } = useElphiToast();

  const { addChild, highlightedParty, addChildApiResponse } = usePartyHooks();
  const parentId = highlightedParty ? highlightedParty.id : null;

  const propsButton = props.buttonFn && props.buttonFn({ handleOpen: onOpen });

  const partyRelationState = useSelector(
    (state: RootState) => state.partyRelation
  );
  const partyState = useSelector((state: RootState) => state.party);

  const addChildResponseHandler = (
    r: AppResponse<{
      childId: string;
      relationId: string;
      parentId: string;
    }>
  ) => {
    if (r.status === 200) {
      successToast({
        title: "new child added successfully",
        description: `child id: ${r.data?.childId}`
      });
      props.callback && r.data && props.callback(r.data);
      onClose();
    }
    if (r.status === 400) {
      errorToast({
        title: "failed to add child",
        description: r.data.description
      });
    }
  };
  const addChildHandler = (newParty: Party) => {
    if (parentId) {
      addChild({
        parentId,
        child: newParty
      }).then(addChildResponseHandler);
    }
  };
  const finalRef = useRef<HTMLDivElement | null>(null);
  return (
    <Box tabIndex={-1} ref={finalRef}>
      {propsButton ? (
        propsButton
      ) : (
        <Button
          {...elphiTheme.components.light.button.primary}
          onClick={onOpen}
        >
          Add Child
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose} finalFocusRef={finalRef}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"}>
          <ModalHeader>Add Child</ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <Text pb="10px" fontWeight={"bold"}>
              Parent: {highlightedParty && partyToString(highlightedParty)}
            </Text>
            <Text pb="10px" fontWeight={"bold"}>
              Child:
            </Text>
            <PartySearch
              currentValue={(selectedChild && selectedChild.id) ?? ""}
              onSelect={(id) => {
                if (id === "") {
                  setSelectedChild("");
                }
                partyState.entities[id] &&
                  setSelectedChild(partyState.entities[id]);
              }}
            />

            {!selectedChild && (
              <CreatePartyForm
                createParty={addChildHandler}
                isLoading={addChildApiResponse.isLoading}
                onSuccess={onClose}
              />
            )}
            {selectedChild && (
              <Box pt="10px">
                <Button
                  {...elphiTheme.components.light.button.primary}
                  isLoading={addChildApiResponse.isLoading}
                  onClick={() => {
                    if (parentId) {
                      const relationId = `${parentId}_${selectedChild.id}`;
                      if (partyRelationState.ids.includes(relationId)) {
                        errorToast({
                          title: "Cannot add two of the same child to a parent",
                          description: `relation id: ${relationId}`
                        });
                      } else {
                        addChild({
                          parentId,
                          child: selectedChild
                        }).then(addChildResponseHandler);
                      }
                    }
                  }}
                >
                  Add Child
                </Button>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
