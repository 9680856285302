import { Property, PropertyReportVendorType } from "@elphi/types";
import { OnChangeInput, Section } from "../../../form-builder/FormBuilder";
import { siteXSections } from "./site-x";

export const propertyReportSections = (
  state: Partial<Property>,
  onChange: (v: OnChangeInput) => void
): { [key in PropertyReportVendorType]: Section[] } => ({
  [PropertyReportVendorType.SiteX]: siteXSections(state, onChange)
});
