import { Box, Text } from "@chakra-ui/react";
import { removeNulls } from "@elphi/aggregations/src/utils/filter.utils";
import { AggregationType, Deal, FieldType } from "@elphi/types";
import lodash from "lodash";
import useDealHooks from "../../../../hooks/deal.hooks";
import { AggregationColumnTypes } from "../../../application/AggregationFooter";
import { actionStyles, milestoneStyles } from "../../../deal/styles/table";
import { ElphiListFooter } from "../../../elphi-list/ElphiListFooter";
import StyledInputBuilder from "../../../form-builder/InputBuilder";
import { dealFieldSpecs } from "../../../form-builder/field-specs/deal/deal.fields";
import { getFocusedAggregationSpecs } from "../../../form-builder/field-specs/utils/aggregation.utils";
import { moneyFormatter } from "../../../form-builder/formatters/inputs.formatter";
import { OrderableTableProps } from "../../../table/table.types";
import { resolveFieldValue } from "../../../utils/tableUtils";

export const PartyFooter = (
  props: {
    dealsId: string[];
    localDealState: { deals: { [id: string]: Deal } };
  } & OrderableTableProps
) => {
  const footerCells = [
    {
      cellType: AggregationColumnTypes.Empty,
      index: 0,
      minWidth: "80px",
      maxWidth: "80px"
    },
    {
      cellType: AggregationColumnTypes.Empty,
      index: 1,
      minWidth: actionStyles.maxWidth,
      maxWidth: actionStyles.maxWidth
    },
    {
      cellType: AggregationColumnTypes.Empty,
      index: 2,
      minWidth: "150px",
      maxWidth: "150px"
    },
    {
      cellType: AggregationColumnTypes.Empty,
      index: 3,
      minWidth: "300px",
      maxWidth: "300px"
    },
    {
      cellType: AggregationColumnTypes.Empty,
      index: 4,
      minWidth: "250px",
      maxWidth: "250px"
    },
    {
      cellType: AggregationColumnTypes.Empty,
      index: 5,
      minWidth: "150px",
      maxWidth: "150px"
    },
    {
      cellType: AggregationColumnTypes.Empty,
      index: 6,
      minWidth: "150px",
      maxWidth: "150px"
    },
    {
      cellType: AggregationColumnTypes.Empty,
      index: 7,
      minWidth: "150px",
      maxWidth: "150px"
    },
    {
      cellType: AggregationColumnTypes.Empty,
      index: 8,
      minWidth: "350px",
      maxWidth: "350px"
    },
    {
      cellType: AggregationColumnTypes.Aggregation,
      index: 9,
      minWidth: "400px",
      maxWidth: "400px",
      data: (
        <Box>
          <Text>Sum Total Loan Amount:</Text>
          <SumTotalLoanAmountCell
            dealsId={props.dealsId}
            localDealState={props.localDealState}
          />
        </Box>
      )
    },
    {
      cellType: AggregationColumnTypes.Empty,
      index: 10,
      minWidth: "350px",
      maxWidth: "350px"
    },
    {
      cellType: AggregationColumnTypes.Empty,
      index: 11,
      minWidth: "200px",
      maxWidth: "200px"
    },
    {
      cellType: AggregationColumnTypes.Empty,
      index: 12,
      minWidth: milestoneStyles.maxWidth,
      maxWidth: milestoneStyles.maxWidth
    }
  ];
  return (
    <ElphiListFooter
      cells={footerCells}
      tableName={props.tableName}
      isOrderable={props.isOrderable}
    />
  );
};

export const SumTotalLoanAmountCell = (props: {
  dealsId: string[];
  localDealState: { deals: { [id: string]: Deal } };
}) => {
  {
    const { dealsId, localDealState } = props;
    const { dealState } = useDealHooks();

    const deals = lodash
      .uniq(dealsId)
      .map((did) => {
        return dealState.entities[did];
      })
      .filter(removeNulls);
    const sumTotalLoanAmount = deals?.reduce((acc, deal) => {
      if (!deal?.id) return acc;

      const { currentValue } = resolveFieldValue({
        prefix: ["deals", deal.id],
        specFields: getFocusedAggregationSpecs({
          focused:
            localDealState.deals[deal.id]?.aggregations?.RequestedLoanAmount
              ?.focused,
          spec: dealFieldSpecs!.aggregations!.RequestedLoanAmount
        }),
        props: {
          isAggregation: AggregationType.Aggregation
        },
        state: localDealState
      });
      return acc + +(currentValue ?? 0);
    }, 0);
    return (
      <StyledInputBuilder
        isReadOnly={true}
        formatter={moneyFormatter}
        currentValue={sumTotalLoanAmount.toString()}
        onChange={() => {}}
        fieldType={FieldType.Money}
      />
    );
  }
};
