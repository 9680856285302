import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from "@chakra-ui/react";
import { LabelValue } from "@elphi/types";
import { isString } from "lodash";
import { ReactNode, useEffect, useState } from "react";

export type Option = { icon?: ReactNode } & LabelValue<
  ReactNode | string,
  string
>;

export type SelectOptionsProps = {
  value?: string;
  options: Array<Option>;
  onSelect: (option: Option) => void;
};

export const SelectOptions = ({
  options,
  onSelect,
  value
}: SelectOptionsProps) => {
  const [selected, setSelected] = useState(options[0]);

  useEffect(() => {
    const selectedOption =
      options.find((option) => option.value === value) || options[0];
    !!selectedOption && setSelected(selectedOption);
  }, [value]);

  const onOptionSelect = (option: Option) => {
    setSelected(option);
    onSelect(option);
  };

  const ConditionalLabel = ({ label }) => (
    <>
      {isString(label) ? (
        <Text fontSize="14px" fontWeight="400">
          {label}
        </Text>
      ) : (
        label
      )}
    </>
  );

  return (
    <Menu>
      <MenuButton
        as={Button}
        background={"transparent"}
        rightIcon={<ChevronDownIcon w={4} h={4} />}
        pl="16px"
        pr="16px"
        w="100%"
      >
        {selected.icon || <ConditionalLabel label={selected.label} />}
      </MenuButton>
      <MenuList zIndex={999}>
        {options?.map((option, index) => (
          <MenuItem
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              onOptionSelect(option);
            }}
          >
            <ConditionalLabel label={option.label} />
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
