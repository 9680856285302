import { Box, Button, Flex } from "@chakra-ui/react";
import elphiTheme from "../../../../assets/themes/elphi.theme.default";
import ModalContainer from "../../../modal-container/ModalContainer";

export const NotificationModal = (props: {
  isShow: boolean;
  onClose: () => void;
  acknowledgeAction?: () => Promise<void>;
  children?: React.ReactNode;
  isLoading?: boolean;
  headerText: string;
  subHeaderText?: string;
  acknowledgeButtonText?: string;
}) => {
  const handleSubmit = async () => {
    props.onClose();
    props.acknowledgeAction && (await props.acknowledgeAction());
  };

  return (
    <ModalContainer
      isShow={props.isShow}
      onCloseModal={props.onClose}
      header={props.headerText}
      customStyles={{ footer: { width: "100%" } }}
      body={
        <>
          {props.children}
          {props.subHeaderText && <Box as="p">{props.subHeaderText}</Box>}
        </>
      }
      footer={
        <Flex width="100%" justifyContent={"space-between"}>
          <Box p="5px">
            <Button
              isLoading={props.isLoading}
              onClick={handleSubmit}
              float="right"
              {...elphiTheme.components.light.button.primary}
            >
              {props.acknowledgeButtonText || "Confirm"}
            </Button>
          </Box>
          <Box p="5px">
            <Button
              onClick={props.onClose}
              float="right"
              {...elphiTheme.components.light.button.secondary}
            >
              Cancel
            </Button>
          </Box>
        </Flex>
      }
    />
  );
};
