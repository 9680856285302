import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBoolean
} from "@chakra-ui/react";
import { Role } from "@elphi/types";
import { useState } from "react";
import { responseHandler } from "../../../apis/rtk/response.handler";
import elphiTheme from "../../../assets/themes/elphi.theme.default";
import { useRoleHooks } from "../../../hooks/role.hooks";
import { roleApi } from "../../../redux/v2/role";
import { useElphiToast } from "../../toast/toast.hook";
import { RoleForm, RoleFormState } from "./RoleForm";
import { RoleList } from "./RoleList";

export const useRoleUpdateHooks = () => {
  const { successToast, errorToast } = useElphiToast();
  const [update, response] = roleApi.useUpdateMutation();

  const roleUpdateHandler = async (role: Partial<Role> & { id: string }) => {
    return await update(role)
      .then(responseHandler)
      .then((r) => {
        if (r.status === 200) {
          successToast({
            title: "Update Success",
            description: `${role?.label} updated successfully`
          });
        } else {
          errorToast({
            title: "Failed",
            description: `${role?.label} updated failed | ${JSON.stringify(
              r.data
            )}`
          });
        }
      });
  };
  return {
    roleUpdateHandler,
    response
  };
};
export const useRoleCreateHooks = () => {
  const { successToast, errorToast } = useElphiToast();
  const [create, response] = roleApi.useCreateMutation();

  const roleCreateHandler = async (role: Partial<Role>) => {
    return await create(role as Role)
      .then(responseHandler)
      .then((r) => {
        if (r.status === 200) {
          successToast({
            title: "Create Success",
            description: `${role?.label} create successfully`
          });
        } else {
          errorToast({
            title: "Failed",
            description: `${role?.label} create failed | ${JSON.stringify(
              r.data
            )}`
          });
        }
      });
  };
  return {
    roleCreateHandler,
    response
  };
};
export const RolePage = () => {
  const [
    showCreateRoleModal,
    { on: onShowCreateRole, off: offShowCreateRole }
  ] = useBoolean();
  const [
    showUpdateRoleModal,
    { on: onShowUpdateRole, off: offShowUpdateRole }
  ] = useBoolean();

  const { roleState } = useRoleHooks();

  const [selectedRoleToEdit, setSelectedRoleToEdit] = useState("");

  const createRoleHook = useRoleCreateHooks();
  const updateRoleHook = useRoleUpdateHooks();
  return (
    <Box>
      <Box p="10px">
        <Button
          {...elphiTheme.components.light.button.primary}
          leftIcon={<AddIcon />}
          onClick={onShowCreateRole}
        >
          Create Role
        </Button>
      </Box>
      <CreateRoleFormModal
        isOpen={showCreateRoleModal}
        onClose={offShowCreateRole}
        isLoading={createRoleHook.response.isLoading}
        onSubmit={(v) => {
          createRoleHook.roleCreateHandler(v);
        }}
      />
      {selectedRoleToEdit && roleState.entities?.[selectedRoleToEdit] && (
        <UpdateRoleFormModal
          isOpen={showUpdateRoleModal}
          onClose={offShowUpdateRole}
          isLoading={updateRoleHook.response.isLoading}
          onSubmit={(v) => {
            selectedRoleToEdit &&
              updateRoleHook.roleUpdateHandler({
                id: selectedRoleToEdit.toString(),
                ...v
              });
          }}
          role={{
            id: selectedRoleToEdit.toString(),
            ...roleState.entities?.[selectedRoleToEdit]
          }}
        />
      )}

      <RoleList
        onRoleEdit={(v) => {
          onShowUpdateRole();
          setSelectedRoleToEdit(v);
        }}
      />
    </Box>
  );
};

export const CreateRoleFormModal = (props: {
  onSubmit: (role: RoleFormState) => void;
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
}) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay>
        <ModalContent w="xl">
          <ModalCloseButton />
          <ModalHeader>Create Role</ModalHeader>
          <ModalBody>
            <RoleForm onSubmit={props.onSubmit} isLoading={props.isLoading} />
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export const UpdateRoleFormModal = (props: {
  onSubmit: (role: RoleFormState) => void;
  isOpen: boolean;
  onClose: () => void;
  role: RoleFormState;
  isLoading?: boolean;
}) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay>
        <ModalContent w="xl">
          <ModalCloseButton />
          <ModalHeader>Update Role - {props.role?.label}</ModalHeader>
          <ModalBody>
            <RoleForm
              onSubmit={props.onSubmit}
              initialState={props.role}
              isLoading={props.isLoading}
              specs={{
                label: {
                  isReadOnly: true,
                  toolTip: {
                    label: "labels cant be changed after creation"
                  }
                }
              }}
            />
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
