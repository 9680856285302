import { actionStyles, milestoneStyles } from "../../../deal/styles/table";
import { ElphiCellType, ElphiTableProps } from "../../../table/table.types";
import {
  borrowingEntityStyles,
  closingDateStyles,
  dealPartyRoleStyles,
  loanNumberStyles,
  loanProgramStyles,
  partyMilestoneStyles,
  partyNameStyles,
  rowIndexStyles,
  sponsorStyles,
  totalLoanAmountStyles,
  userAssignmentStyles
} from "../../styles/table";

export const headerCells: ElphiTableProps["header"] = [
  {
    index: 0,
    data: "",
    type: ElphiCellType.String,
    maxWidth: rowIndexStyles.maxWidth,
    minWidth: rowIndexStyles.minWidth
  },
  {
    index: 1,
    data: "Actions",
    type: ElphiCellType.String,
    maxWidth: actionStyles.maxWidth,
    minWidth: actionStyles.minWidth
  },
  {
    index: 2,
    data: "Party Name",
    type: ElphiCellType.String,
    maxWidth: partyNameStyles.maxWidth,
    minWidth: partyNameStyles.minWidth
  },
  {
    index: 3,
    data: "Party Milestone",
    type: ElphiCellType.String,
    maxWidth: partyMilestoneStyles.maxWidth,
    minWidth: partyMilestoneStyles.minWidth
  },
  {
    index: 4,
    data: "User Assignment",
    type: ElphiCellType.String,
    maxWidth: userAssignmentStyles.maxWidth,
    minWidth: userAssignmentStyles.minWidth
  },
  {
    index: 5,
    data: "Loan Number",
    type: ElphiCellType.String,
    maxWidth: loanNumberStyles.maxWidth,
    minWidth: loanNumberStyles.minWidth
  },
  {
    index: 6,
    data: "Borrowing Entity",
    type: ElphiCellType.String,
    maxWidth: borrowingEntityStyles.maxWidth,
    minWidth: borrowingEntityStyles.minWidth
  },
  {
    index: 7,
    data: "Sponsor",
    type: ElphiCellType.String,
    maxWidth: sponsorStyles.maxWidth,
    minWidth: sponsorStyles.minWidth
  },
  {
    index: 8,
    data: "Loan Program",
    type: ElphiCellType.String,
    maxWidth: loanProgramStyles.maxWidth,
    minWidth: loanProgramStyles.minWidth
  },
  {
    index: 9,
    data: "Total Loan Amount",
    type: ElphiCellType.String,
    maxWidth: totalLoanAmountStyles.maxWidth,
    minWidth: totalLoanAmountStyles.minWidth
  },
  {
    index: 10,
    data: "Deal-Party Role",
    type: ElphiCellType.String,
    maxWidth: dealPartyRoleStyles.maxWidth,
    minWidth: dealPartyRoleStyles.minWidth
  },
  {
    index: 11,
    data: "Closing Date",
    type: ElphiCellType.String,
    maxWidth: closingDateStyles.maxWidth,
    minWidth: closingDateStyles.minWidth
  },
  {
    index: 12,
    data: "Deal Milestone",
    type: ElphiCellType.String,
    maxWidth: milestoneStyles.maxWidth,
    minWidth: milestoneStyles.minWidth
  }
];
