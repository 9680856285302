import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { useRef } from "react";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { usePartyHooks } from "../../hooks/party.hooks";
import { usePartyRelationHooks } from "../../hooks/partyrelation.hooks";
import { useElphiToast } from "../toast/toast.hook";
import { partyToString } from "./utils/printUtils";

export const DeletePartyRelationModal = (props: {
  // treeState: ElphiNode<Party>;
  // elphiTreeOperations: TreeOperations<Party>;
  callback?: () => void;
  buttonFn?: (props?: {
    onClick?: () => void;
    isDisabled?: boolean;
  }) => JSX.Element;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { deletePartyRelation, deletePartyRelationResponse } =
    usePartyRelationHooks();

  const { selectedParent, highlightedParty } = usePartyHooks();
  const { successToast, errorToast } = useElphiToast();

  const buttonComponent = props.buttonFn?.({ onClick: onOpen }) ?? null;

  const deletePartyRelationHandler = async () => {
    if (highlightedParty) {
      if (selectedParent) {
        const relationId = `${selectedParent.id}_${highlightedParty.id}`;
        deletePartyRelation(relationId).then((r) => {
          if (r.status === 200) {
            successToast({
              title: "Party Relation Deleted",
              description: `party relation: ${r?.data?.id}`
            });
            props.callback && props.callback();
            onClose();
          }
          r.status === 400 &&
            errorToast({
              title: "Failed to delete party relation",
              description: r.data.description
            });
        });
      }
    }
  };

  const finalRef = useRef<HTMLDivElement | null>(null);
  return (
    <Box ref={finalRef} tabIndex={-1}>
      {props.buttonFn ? (
        buttonComponent
      ) : (
        <Button
          {...elphiTheme.components.light.button.primary}
          onClick={onOpen}
        >
          Delete Party Relation
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose} finalFocusRef={finalRef}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"}>
          <ModalHeader>Delete Party Relation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {highlightedParty && selectedParent && selectedParent && (
              <Box>
                <Text
                  p="10px"
                  fontWeight={"light"}
                  color={elphiTheme.colors.light.notifications.info}
                >
                  <b>parent:</b> {partyToString(selectedParent)}
                </Text>
                <Text
                  p="10px"
                  fontWeight={"light"}
                  color={elphiTheme.colors.light.notifications.info}
                >
                  <b>child:</b> {partyToString(highlightedParty)}
                </Text>
              </Box>
            )}

            <Text
              p="10px"
              fontWeight={"bold"}
              color={elphiTheme.colors.light.notifications.error}
            >
              {!!(
                highlightedParty &&
                selectedParent &&
                highlightedParty.id === selectedParent.id
              ) ||
              (highlightedParty && !selectedParent)
                ? "You cant delete a party relation from is self"
                : "Are you sure you want to remove this relation?"}
            </Text>
            <Flex justifyContent={"space-between"}>
              <Button
                {...elphiTheme.components.light.button.primary}
                isLoading={deletePartyRelationResponse.isLoading}
                isDisabled={
                  !!(
                    highlightedParty &&
                    selectedParent &&
                    highlightedParty.id === selectedParent.id
                  ) ||
                  (!!highlightedParty && !selectedParent)
                }
                onClick={() => {
                  deletePartyRelationHandler();
                }}
              >
                Yes, delete party relation
              </Button>
              <Button
                {...elphiTheme.components.light.button.secondary}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
