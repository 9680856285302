import { Flex, Text } from "@chakra-ui/react";
import { debounce } from "lodash";
import { useEffect } from "react";
import { DEFAULT_HOVER_DEBOUNCE } from "../../constants/common";
import { CalendarProps } from "./Calendar";

export type DayProps = {
  currentMonth: boolean;
  isToday?: boolean;
  showDayName?: boolean;
  monthName?: string;
  currentDay: Date;
} & CalendarProps;

export const Day = ({
  currentDay,
  currentMonth,
  isToday,
  showDayName,
  monthName,
  onHoverDate,
  BodyComponent,
  FooterComponent,
  DayToolTipLabelComponent
}: DayProps) => {
  const debouncedHandleMouseEnter = debounce(() => {
    onHoverDate?.(currentDay);
  }, DEFAULT_HOVER_DEBOUNCE);

  useEffect(() => {
    return () => {
      onCancelDebouncedMouseEnter();
    };
  }, []);

  const onCancelDebouncedMouseEnter = () => {
    debouncedHandleMouseEnter.cancel();
  };

  return (
    <Flex w={"100%"} margin={"2px"}>
      <Flex
        onMouseEnter={debouncedHandleMouseEnter}
        onMouseLeave={onCancelDebouncedMouseEnter}
        h="100%"
        w="100%"
        borderWidth={"1px"}
        borderColor={"gray.200"}
        paddingY={"3px"}
        paddingX={"6px"}
        borderRadius={"4px"}
        flexDir={"column"}
        justify={"flex-start"}
      >
        <Flex flexDir={"row"} justifyContent={"space-between"}>
          <Flex>
            <Text fontWeight={"700"} fontSize={"11px"}>
              {showDayName
                ? currentDay.toLocaleString("en-US", { weekday: "long" })
                : undefined}
            </Text>
          </Flex>
          <Flex>
            {!!monthName && (
              <Text
                color={
                  !currentMonth ? "gray.400" : isToday ? "white" : undefined
                }
                fontWeight={"700"}
                fontSize={"11px"}
                mr={"5px"}
              >
                {monthName}
              </Text>
            )}
            <Text
              fontWeight={"700"}
              fontSize={"11px"}
              textAlign={"center"}
              alignContent={"center"}
              color={!currentMonth ? "gray.400" : isToday ? "white" : undefined}
              width={isToday ? "22px" : undefined}
              height={isToday ? "22px" : undefined}
              bgColor={isToday ? "blue.600" : undefined}
              borderRadius={isToday ? "100px" : undefined}
            >
              {currentDay.getDate().toString()}
            </Text>
          </Flex>
        </Flex>
        {!!BodyComponent && (
          <Flex h={"100%"}>{<BodyComponent currentDay={currentDay} />}</Flex>
        )}
        {!!FooterComponent && (
          <Flex>{<FooterComponent currentDay={currentDay} />}</Flex>
        )}
      </Flex>
      {!!DayToolTipLabelComponent ? (
        <DayToolTipLabelComponent currentDay={currentDay} />
      ) : undefined}
    </Flex>
  );
};
