import { DealCalendarType, KeyClosingTaskType, Task } from "@elphi/types";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { TaskCards } from "../../../../task-cards/TaskCards";

export type KCTsSectionProps = {
  dealCalendar: DealCalendarType;
};

export const KCTsSection = ({ dealCalendar }: KCTsSectionProps) => {
  const { tasks, loanIdentifier } = dealCalendar;

  const tasksValues: Task[] = useMemo(() => {
    return Object.values(tasks || []).filter(
      (task) => task.keyClosingTaskType !== KeyClosingTaskType.None
    );
  }, [tasks]);

  if (isEmpty(tasksValues)) return <></>;

  return <TaskCards tasks={tasksValues} dealIdentifier={loanIdentifier!} />;
};
