import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Spacer, useDisclosure } from "@chakra-ui/react";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { useRoleGroupHooks } from "../../hooks/roleGroup.hooks";
import { GroupModal } from "../custom/modal/GroupModal";
import { RoleGroupSearch } from "./RoleGroupSearch";
import { RoleGroupFilters } from "./table/RoleGroupFilters";
import { RoleGroupTable } from "./table/RoleGroupTable";

export const RoleGroup = () => {
  const { isOpen: isOpenCreateModal, onToggle: onToggleCreateModal } =
    useDisclosure();
  const { createGroup, createResponse } = useRoleGroupHooks();
  return (
    <Box>
      <Flex>
        <Box width={"100%"}>
          <RoleGroupSearch />
        </Box>
        <Spacer />
        <Box p={"10px"}>
          <Button
            aria-label="add-role-group-btn"
            onClick={onToggleCreateModal}
            leftIcon={<AddIcon />}
            {...elphiTheme.components.light.button.primary}
          >
            Create Role Group
          </Button>
        </Box>
      </Flex>
      <Flex p={"10px"} direction={"column"}>
        <RoleGroupFilters />
        <RoleGroupTable />
      </Flex>
      <GroupModal
        header={"Create role group"}
        action={"create"}
        isShow={isOpenCreateModal}
        onClose={onToggleCreateModal}
        isLoading={createResponse.isLoading}
        onSubmit={createGroup}
      />
    </Box>
  );
};
