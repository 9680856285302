import {
  AggregationType,
  IntegrationType,
  Property,
  PropertyReportVendorType
} from "@elphi/types";
import { propertySpecFields } from "../../../../form-builder/field-specs/property/property.fields";
import { OnChangeInput, Section } from "../../../../form-builder/FormBuilder";
import { buildInputs } from "../../../../form-builder/formBuilder.utils";
import { stringValidation } from "../../../../utils/validationUtils";
import {
  propertySiteXOrderCompletedOnFieldPath,
  propertySiteXOrderStatusFieldPath
} from "../../../field-paths/property.fieldPaths";

export const siteXOrderStatusSection = (
  state: Partial<Property>,
  onChange: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Property Report: Order Status",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: propertySpecFields,
      specs: [
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.PropertyReport
            ]?.[PropertyReportVendorType.SiteX].orderStatus?.focused !==
            "override",
          path: propertySiteXOrderStatusFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.PropertyReport
            ]?.[PropertyReportVendorType.SiteX].orderCompletedOn?.focused !==
            "override",
          path: propertySiteXOrderCompletedOnFieldPath,
          validation: stringValidation
        }
      ]
    })
  };
};
