import { Box, Checkbox, Flex, Tooltip } from "@chakra-ui/react";
import {
  DEFAULT_PARTY_GROUP,
  FieldType,
  PartyGroup,
  ServiceProviderEntityType
} from "@elphi/types";
import { useEffect } from "react";
import StyledInputBuilder, {
  useFormBuilderStateHandler
} from "../../../form-builder/InputBuilder";
import { MenuRowAction } from "../../../table/MenuRowAction";
import {
  SIZE_FIELD,
  TableRowSizeComponent,
  TableTextCell
} from "../../../table/TableRowSizeComponent";
import { createOptionsFromEnum } from "../../../utils/formUtils";

export type PartyGroupRowProps = {
  index: number;
  data: PartyGroup;
  onChange: ReturnType<typeof useFormBuilderStateHandler>["onChange"];
  syncState: ReturnType<typeof useFormBuilderStateHandler>["syncState"];
  isSelected: boolean;
  onSelect: (r: { isChecked: boolean; id: string }) => void;
  onClone: (r: { partyGroup: Partial<PartyGroup> }) => void;
  state: {
    [id: string]: Partial<PartyGroup>;
  };
};

export const PartyGroupRow = (props: PartyGroupRowProps) => {
  const { index, data, onChange, state, syncState } = props;
  const { id } = data;

  useEffect(() => {
    syncState({
      state: data,
      shouldSync: true,
      statePath: () => {
        return [id];
      }
    });
  }, [data]);

  const isDefaultPartyGroup = state[id]?.id === DEFAULT_PARTY_GROUP;
  return (
    <>
      <Flex w="100%" justify="space-between">
        <TableRowSizeComponent size={SIZE_FIELD.T} withBorderRightWidth={false}>
          <Flex alignItems={"center"} justifyContent={"center"} gap={"18px"}>
            <Checkbox
              isChecked={props.isSelected}
              value={state[id]?.id}
              onChange={(e) =>
                props.onSelect({
                  isChecked: e.target.checked,
                  id: e.target.value
                })
              }
            />
            <TableTextCell text={index + 1} />
          </Flex>
        </TableRowSizeComponent>

        <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
          <TableTextCell text={state[id]?.id} />
        </TableRowSizeComponent>

        <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
          <StyledInputBuilder
            currentValue={state[id]?.name}
            fieldType={FieldType.String}
            onChange={(e) => {
              onChange({
                fieldKey: [id, "name"],
                fieldType: FieldType.String,
                value: e.target.value
              });
            }}
            isValid={true}
          />
        </TableRowSizeComponent>

        <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
          <StyledInputBuilder
            chakraStyles={{
              valueContainer: (provided) => ({
                ...provided,
                fontSize: "12px"
              })
            }}
            currentValue={state[id]?.description}
            fieldType={FieldType.String}
            onChange={(e) => {
              onChange({
                fieldKey: [id, "description"],
                fieldType: FieldType.String,
                value: e.target.value
              });
            }}
            hideSelectedOptions={false}
            options={createOptionsFromEnum(ServiceProviderEntityType)}
            isValid={true}
          />
        </TableRowSizeComponent>

        <TableRowSizeComponent size={SIZE_FIELD.S} withBorderRightWidth={false}>
          <Flex alignItems={"center"} justifyContent={"center"} gap={"18px"}>
            <Tooltip
              isDisabled={!isDefaultPartyGroup}
              placement="top-start"
              label={`Party group id: '${DEFAULT_PARTY_GROUP}' cannot be disabled`}
              hasArrow
            >
              <Box w="fit-content">
                <StyledInputBuilder
                  currentValue={state[id]?.status}
                  fieldType={FieldType.Switch}
                  isDisabled={isDefaultPartyGroup}
                  onChange={(e) => {
                    onChange({
                      fieldKey: [id, "status"],
                      fieldType: FieldType.Switch,
                      value: e.target.value
                    });
                  }}
                />
              </Box>
            </Tooltip>
            <MenuRowAction
              onClone={() => {
                props.onClone({ partyGroup: state[id] });
              }}
            />
          </Flex>
        </TableRowSizeComponent>
      </Flex>
    </>
  );
};
