import { Box, BoxProps } from "@chakra-ui/react";
import { Deal, DealPartyRelation, Party } from "@elphi/types";
import { useEffect, useMemo } from "react";
import { auth } from "../../../../firebase/firebaseConfig";
import { useAuthStateChangeHook } from "../../../../hooks/authStateChange.hooks";
import useWindowDimensions from "../../../../hooks/windowDimensions";
import { usePaginationHooksV2 } from "../../../../redux/v2/hooks/pagination.hooks.v2";
import { PartyTableCursorType } from "../../../../redux/v2/party";
import { partyApi } from "../../../../redux/v2/party/party.service";
import { uniqCursorId } from "../../../../redux/v2/utils/filter-table.utils";
import { removeNulls } from "../../../../utils/filter.utils";
import { ElphiPaginationList } from "../../../elphi-list/ElphiList";
import { PartyFooter } from "./PartyFooter";
import { headerCells } from "./PartyTableHeader";
import { PartyRow } from "./PartyTableRow";
import { usePartyTableReduxState, usePartyTableState } from "./hooks";
export type PartyTableProps = {
  pageSize: number;
  userId?: string;
  partyId?: string;
  w?: BoxProps["w"];
  h?: BoxProps["h"];
  offsetH?: number;
};
export type PartyTableState = {
  deals: { [id: string]: { id: string } & Partial<Deal> };
  parties: { [id: string]: { id: string } & Partial<Party> };
  dealPartyRelations: {
    [id: string]: { id: string } & Partial<DealPartyRelation>;
  };
};
const TABLE_MIN_HEIGHT = "90px";
export const getCurrentCursorType = (r: {
  userId?: string;
  partyId?: string;
}) => {
  if (!r.partyId && !r.userId) return PartyTableCursorType.ALL;
  else if (r.partyId && !r.userId) return PartyTableCursorType.PARTY_FILTER;
  else if (!r.partyId && r.userId) return PartyTableCursorType.USER_FILTER;
  else if (r.partyId && r.userId) return PartyTableCursorType.PARTY_USER_FILTER;
  throw "missing case for current cursorType";
};
export const PartyTable = (props: PartyTableProps) => {
  const {
    dealPartyRelationState,
    partyUserRelationState,
    partyState,
    setPartyTableCursor,
    getPartyTableCursor
  } = usePartyTableReduxState();

  const { nextPage, hasMore, paginateApiResponse } = usePaginationHooksV2({
    paginationProvider: partyApi.useLazyPaginateV2Query,
    limit: props.pageSize,
    input: {
      userId: props.userId,
      partyId: props.partyId
    },
    setHasMore: async (hasMore) => {
      partyTableState.setCurrentCursorHasMore(hasMore);
    },
    setCursor: (cursor) => {
      const filtersUniqCursorId = uniqCursorId({
        id: props.partyId,
        userId: props.userId
      });
      partyTableState.setCurrentCursorId(filtersUniqCursorId);
      const currentCursorType = getCurrentCursorType({
        userId: props.userId,
        partyId: props.partyId
      });
      setPartyTableCursor({
        cursor,
        cursorType: currentCursorType,
        partyId: props.partyId,
        userId: props.userId
      });
    },
    currentCursor: getPartyTableCursor({
      cursorType: getCurrentCursorType({
        userId: props.userId,
        partyId: props.partyId
      }),
      partyId: props.partyId,
      userId: props.userId
    })
  });

  const partyTableState = usePartyTableState();

  const OFFSET_HEIGHT = props.offsetH || 400;

  const isLoading =
    paginateApiResponse.isFetching || paginateApiResponse.isLoading;

  useAuthStateChangeHook({
    onAuthStateChange: (_) => !items?.length && nextPage(),
    deps: [auth.currentUser]
  });

  useEffect(() => {
    const uniqId = uniqCursorId({
      id: props.partyId,
      userId: props.userId
    });
    partyTableState.setCurrentCursorId(uniqId);
    partyTableState.setCurrentCursorHasMore(true);
    if (props.partyId || props.userId) {
      !isLoading && nextPage();
    }
  }, [props.partyId, props.userId]);

  const { heightOffsetInPx } = useWindowDimensions();

  const items = useMemo(() => {
    if (!props.partyId && !props.userId) {
      //all
      const res = partyState.ids
        .map((partyId) => {
          const pairs = dealPartyRelationState.ids
            .filter(
              (dpid) =>
                dealPartyRelationState.entities?.[dpid]?.partyId === partyId
            )
            .map((dpid) => {
              return {
                dealPartyId: dpid.toString(),
                partyId: partyId.toString()
              };
            });
          if (pairs.length) return pairs;
          else return [{ partyId: partyId.toString(), dealPartyId: undefined }];
        })
        .flat();
      return res;
    } else if (!props.partyId && props.userId) {
      //user
      const userPartiesIds = partyUserRelationState.ids
        .filter(
          (puid) =>
            partyUserRelationState.entities?.[puid]?.userId === props.userId
        )
        .filter(removeNulls);
      const partiesIds = userPartiesIds
        .map((puid) => partyUserRelationState.entities?.[puid]?.partyId)
        .filter(removeNulls);

      const res = partiesIds
        .map((partyId) => {
          const pairs = dealPartyRelationState.ids
            ?.filter(
              (id) => dealPartyRelationState.entities?.[id]?.partyId === partyId
            )
            .filter(removeNulls)
            .map((dpid) => {
              return {
                dealPartyId: dpid.toString(),
                partyId: partyId.toString()
              };
            });
          if (pairs.length) return pairs;
          else return { partyId: partyId.toString(), dealPartyId: undefined };
        })
        .flat();
      return res;
    } else if (props.partyId && !props.userId) {
      //party
      const partyId = props.partyId;
      const pairs = dealPartyRelationState.ids
        .filter(
          (id) =>
            dealPartyRelationState.entities?.[id]?.partyId === props.partyId
        )
        .map((dpid) => {
          return {
            partyId: partyId,
            dealPartyId: dpid.toString()
          };
        });
      if (pairs.length) return pairs;
      else return [{ partyId: props.partyId!, dealPartyId: undefined }];
    } else if (props.partyId && props.userId) {
      //both
      const partyUserId = partyUserRelationState.ids
        .filter(
          (id) =>
            partyUserRelationState.entities?.[id]?.partyId === props.partyId &&
            partyUserRelationState.entities?.[id]?.userId === props.userId
        )
        .filter(removeNulls);
      if (!partyUserId.length) return [];

      const pairs = dealPartyRelationState.ids
        .filter(
          (dpid) =>
            dealPartyRelationState.entities?.[dpid]?.partyId === props.partyId
        )
        .filter(removeNulls)
        .map((dpid) => {
          return {
            partyId: props.partyId!,
            dealPartyId: dpid.toString()
          };
        });
      if (pairs.length) return pairs;
      else return [{ partyId: props.partyId, dealPartyId: undefined }];
    }
    return [];
  }, [
    props.partyId,
    props.userId,
    dealPartyRelationState,
    partyState,
    partyUserRelationState
  ]);
  const tableMaxHeight = heightOffsetInPx(OFFSET_HEIGHT);
  return (
    <Box w={props.w || "2880px"} height={props.h || "100%"}>
      <ElphiPaginationList
        tableName={"Party"}
        isLoading={isLoading}
        maxHeight={tableMaxHeight}
        minHeight={TABLE_MIN_HEIGHT}
        headerCells={headerCells}
        items={items}
        orderableRowBuilder={({ item, index, tableName, isOrderable }) => {
          return (
            <Box>
              <PartyRow
                partyId={item?.partyId?.toString()}
                dealPartyId={item?.dealPartyId?.toString()}
                partyTableState={partyTableState}
                index={(Number(index) + 1).toString()}
                tableName={tableName}
                isOrderable={isOrderable}
              />
            </Box>
          );
        }}
        footerBuilder={({ tableName, isOrderable }) => {
          return props.partyId || props.userId ? (
            <PartyFooter
              tableName={tableName}
              isOrderable={isOrderable}
              localDealState={partyTableState.localDealState}
              dealsId={items
                .map((i) => i.dealPartyId?.toString()?.split("_")[0])
                .filter(removeNulls)}
            />
          ) : (
            <></>
          );
        }}
        pageSize={10}
        hasMore={hasMore || partyTableState.currentCursorIdHasMore}
        next={nextPage}
        isOrderable={true}
      />
    </Box>
  );
};

// export const PartyTableContainer = (props: PartyTableProps) => {};
// export const SelectedPartyTableContainer = (props: PartyTableProps) => {};
// export const UserPartyTableContainer = (props: PartyTableProps) => {};
// export const AllPartyTableContainer = (props: PartyTableProps) => {};
