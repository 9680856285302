import { TObject, TProperties } from "@sinclair/typebox";
import { CommonResponse, StatusCode } from "../../response";
import { validatorFactory } from "../validations/validation.factory";

export const isBatchValidationValid = <T>(
  request: T[],
  schema: TObject<TProperties>
): CommonResponse | null => {
  let validationErrors: string = "";
  const validResponse = request.every((r) => {
    const validationResponse = validatorFactory(schema).verify(r);
    if (validationResponse.status === "failed") {
      validationErrors = validationResponse.errors;
    }
    return validationResponse.status === "valid";
  });
  if (!validResponse) {
    const errorResponse: CommonResponse = {
      statusCode: StatusCode.BadRequest,
      payload: {
        error: "invalid fields",
        description: validationErrors
      }
    };
    return errorResponse;
  }
  return null;
};
