import { Box, useDisclosure } from "@chakra-ui/react";
import { RoleGroup } from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { uniq } from "lodash";
import { useEffect, useState } from "react";
import { AppConfig } from "../../../config/appConfig";
import { EMPTY } from "../../../constants/common";
import { auth } from "../../../firebase/firebaseConfig";
import { useAuthStateChangeHook } from "../../../hooks/authStateChange.hooks";
import { useRoleHooks } from "../../../hooks/role.hooks";
import {
  LIMIT,
  useRoleGroupHooks,
  useRoleGroupTableHooks
} from "../../../hooks/roleGroup.hooks";
import useWindowDimensions from "../../../hooks/windowDimensions";
import { baseCellStyle } from "../../custom/chakra/Cell";
import { GroupModal } from "../../custom/modal/GroupModal";
import { ElphiPaginationList } from "../../elphi-list/ElphiList";
import { useFormBuilderStateHandler } from "../../form-builder/InputBuilder";
import { ElphiCellType, ElphiTableProps } from "../../table/table.types";
import {
  SIZE_FIELD,
  TableRowSizeComponent
} from "../../table/TableRowSizeComponent";
import { RoleGroupRow } from "./RoleGroupRow";

export const RoleGroupTable = () => {
  const { heightOffsetInPx } = useWindowDimensions();
  const { isOpen: isOpenCloneModal, onToggle: onToggleCloneModal } =
    useDisclosure();
  const { roleGroupState, updateGroupBatch, cloneGroup, createResponse } =
    useRoleGroupHooks();
  const { nextPage, paginateApiResponse, filters, currentCombination } =
    useRoleGroupTableHooks();
  const { getRoleBatchApi, getRoleBatchResponse } = useRoleHooks();
  const [selectedRow, setSelectedRow] = useState<RoleGroup>();

  useAuthStateChangeHook({
    onAuthStateChange: (_) => {
      if (auth.currentUser) {
        nextPage();
      }
    },
    deps: [filters]
  });

  useEffect(() => {
    const selected = roleGroupState.entities[selectedRow?.id || EMPTY];
    if (selected) {
      setSelectedRow(selected);
    }
  }, [roleGroupState.entities[selectedRow?.id || EMPTY]]);

  useEffect(() => {
    if (auth.currentUser) {
      const roleIds = uniq(
        paginateApiResponse.currentData?.page
          .map((x) => x?.ids)
          .filter(removeEmpty)
          .flat()
      );
      roleIds.length && getRoleBatchApi(roleIds);
    }
  }, [auth.currentUser, paginateApiResponse.currentData?.page]);

  const { syncState, state, onChange } = useFormBuilderStateHandler<{
    [id: string]: Partial<RoleGroup>;
  }>({
    initialState: {},
    callback: updateGroupBatch,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  const handleOnCloneClick = (id: string) => {
    setSelectedRow(roleGroupState.entities[id]);
    onToggleCloneModal();
  };
  return (
    <>
      <ElphiPaginationList
        height={heightOffsetInPx(320)}
        tableName={"role-groups"}
        isLoading={paginateApiResponse.isLoading}
        rowStyle={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none"
        }}
        header={
          <TableRowSizeComponent
            bgColor="gray.100"
            row={headerCells}
            withBorderRightWidth={false}
            justify="space-between"
          />
        }
        pageSize={LIMIT}
        items={
          !roleGroupState.selectedId
            ? currentCombination.ids
            : [roleGroupState.selectedId]
        }
        next={nextPage}
        hasMore={paginateApiResponse.currentData?.page?.length === LIMIT}
        options={{
          showEndMessage: false,
          showWatching: false,
          showLoader: true
        }}
        rowBuilder={(id, index) => {
          const data = roleGroupState.entities[id];
          if (!data) {
            return <></>;
          }
          return (
            <RoleGroupRow
              index={Number(index) + 1}
              data={data}
              onChange={onChange}
              syncState={syncState}
              state={state}
              onClone={handleOnCloneClick}
              isLoadingRoles={getRoleBatchResponse.isLoading}
            />
          );
        }}
      />
      {selectedRow && (
        <GroupModal
          key={`clone-${selectedRow.id}-${isOpenCloneModal}`}
          header={"Clone role group"}
          action={"clone"}
          isShow={isOpenCloneModal}
          onClose={onToggleCloneModal}
          isLoading={createResponse.isLoading}
          onSubmit={cloneGroup}
          cloneGroup={selectedRow}
        >
          <Box as="p">{`Clone ${selectedRow.id} - ${selectedRow.name}`}</Box>
        </GroupModal>
      )}
    </>
  );
};

const headerCells: ElphiTableProps["header"] = [
  {
    index: 0,
    data: "index",
    type: ElphiCellType.String,
    size: SIZE_FIELD.T,
    ...baseCellStyle
  },
  {
    index: 1,
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    data: "Role Group Name",
    ...baseCellStyle
  },
  {
    index: 2,
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    data: "Roles",
    ...baseCellStyle
  },
  {
    index: 3,
    type: ElphiCellType.String,
    size: SIZE_FIELD.S,
    data: "Status",
    ...baseCellStyle
  }
];
