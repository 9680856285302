import { Box, Flex, Skeleton, SkeletonText } from "@chakra-ui/react";
import { FieldType, NotificationConfiguration } from "@elphi/types";
import { useEffect } from "react";
import { EMPTY } from "../../../../constants/common";
import { AuditEventGroupSelect } from "../../../audit-event-group/AuditEventGroupSearch";
import { FieldGroupSelect } from "../../../field-group/FieldGroupSearch";
import StyledInputBuilder, {
  useFormBuilderStateHandler
} from "../../../form-builder/InputBuilder";
import { RoleGroupSelect } from "../../../role-group/RoleGroupSearch";
import { MenuRowAction } from "../../../table/MenuRowAction";
import {
  SIZE_FIELD,
  TableRowSizeComponent,
  TableTextCell
} from "../../../table/TableRowSizeComponent";

type NotificationConfigurationRowProps = {
  index: number;
  data: NotificationConfiguration;
  onClone: (id: string) => void;
  onChange: ReturnType<typeof useFormBuilderStateHandler>["onChange"];
  syncState: ReturnType<typeof useFormBuilderStateHandler>["syncState"];
  state: {
    [id: string]: Partial<NotificationConfiguration>;
  };
  loading: {
    auditGroups: boolean;
    roleGroups: boolean;
    fieldGroups: boolean;
  };
};

export const NotificationConfigurationRow = ({
  index,
  data,
  onChange,
  state,
  syncState,
  onClone,
  loading
}: NotificationConfigurationRowProps) => {
  const { id } = data;
  const { auditGroups, fieldGroups, roleGroups } = loading;
  useEffect(() => {
    syncState({
      state: data,
      shouldSync: true,
      statePath: () => {
        return [id];
      }
    });
  }, [data]);

  return (
    <Flex w="100%" justify="space-between">
      <TableRowSizeComponent size={SIZE_FIELD.T} withBorderRightWidth={false}>
        <TableTextCell text={index} />
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        <StyledInputBuilder
          currentValue={state[id]?.name}
          fieldType={FieldType.String}
          onChange={(e) => {
            onChange({
              fieldKey: [id, "name"],
              fieldType: FieldType.String,
              value: e.target.value
            });
          }}
          isValid={true}
        />
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        {!roleGroups ? (
          <RoleGroupSelect
            fieldType={FieldType.SingleSelect}
            onSelect={(value) => {
              onChange({
                fieldKey: [data.id, "roleGroupId"],
                fieldType: FieldType.SingleSelect,
                value
              });
            }}
            currentValue={state?.[data.id]?.roleGroupId || EMPTY}
          />
        ) : (
          <Skeleton height="10px">
            <SkeletonText />
          </Skeleton>
        )}
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        {!auditGroups ? (
          <AuditEventGroupSelect
            fieldType={FieldType.SingleSelect}
            onSelect={(value) => {
              onChange({
                fieldKey: [data.id, "auditGroupId"],
                fieldType: FieldType.SingleSelect,
                value
              });
            }}
            currentValue={state?.[data.id]?.auditGroupId || EMPTY}
          />
        ) : (
          <Skeleton height="10px">
            <SkeletonText />
          </Skeleton>
        )}
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        {!fieldGroups ? (
          <FieldGroupSelect
            fieldType={FieldType.SingleSelect}
            onSelect={(value) => {
              onChange({
                fieldKey: [data.id, "fieldGroupId"],
                fieldType: FieldType.SingleSelect,
                value
              });
            }}
            currentValue={state?.[data.id]?.fieldGroupId || EMPTY}
          />
        ) : (
          <Skeleton height="10px">
            <SkeletonText />
          </Skeleton>
        )}
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.S} withBorderRightWidth={false}>
        <Flex alignItems={"center"} justifyContent={"center"} gap={"18px"}>
          <Box w="fit-content">
            <StyledInputBuilder
              currentValue={state[id]?.status}
              fieldType={FieldType.Switch}
              onChange={(e) => {
                onChange({
                  fieldKey: [id, "status"],
                  fieldType: FieldType.Switch,
                  value: e.target.value
                });
              }}
            />
          </Box>
          <MenuRowAction
            onClone={() => {
              onClone(id);
            }}
          />
        </Flex>
      </TableRowSizeComponent>
    </Flex>
  );
};
