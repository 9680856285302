import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { usePartyHooks } from "../../hooks/party.hooks";
import { useElphiToast } from "../toast/toast.hook";
import { partyToString } from "./utils/printUtils";

export const DeletePartyModal = (props: {
  callback?: (id: string) => void;
  buttonFn?: (handleOpen: () => void) => JSX.Element;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { deleteParty, setSelectedParty, selectedParty } = usePartyHooks();
  const { successToast, errorToast } = useElphiToast();

  const buttonComponent = props.buttonFn?.(onOpen) ?? null;

  const deletePartyHandler = async () => {
    if (selectedParty) {
      deleteParty(selectedParty.id).then((r) => {
        if (r.status === 200) {
          successToast({
            title: "Party Deleted",
            description: `party: ${r.data?.id}`
          });
          if (r.data.id) {
            props?.callback?.(r.data.id) || setSelectedParty("");
          }
        }
        r.status === 400 &&
          errorToast({
            title: "Failed to delete party",
            description: r.data.description
          });
      });
    }
  };
  return (
    <Box>
      {props.buttonFn ? (
        buttonComponent
      ) : (
        <Button
          {...elphiTheme.components.light.button.secondary}
          onClick={onOpen}
        >
          Delete Party
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"}>
          <ModalHeader>Delete Party</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedParty && (
              <Text p="10px" fontWeight={"light"}>
                selected party: {partyToString(selectedParty)}
              </Text>
            )}
            <Text p="10px" fontWeight={"bold"}>
              Are you sure you want to delete this party?
            </Text>
            <Text
              p="10px"
              fontWeight={"extrabold"}
              color={elphiTheme.colors.light.notifications.error}
            >
              This operation is irreversible
            </Text>
            <Flex justifyContent={"space-between"}>
              <Button
                {...elphiTheme.components.light.button.primary}
                onClick={() => {
                  deletePartyHandler();
                  onClose();
                }}
              >
                Yes, delete party
              </Button>
              <Button
                {...elphiTheme.components.light.button.secondary}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
