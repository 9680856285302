import { Box, Flex, Text } from "@chakra-ui/react";
import { useTableManagerHook } from "../../hooks/tableManager.hooks";
import { AggregationColumnTypes } from "../application/AggregationFooter";
import { OrderableTableProps } from "../table/table.types";
import { ElphiFooterCell } from "./elphiList.types";

type ElphiFooterProps = {
  cells: ElphiFooterCell[];
} & OrderableTableProps;

export const ElphiListFooter = ({
  cells,
  tableName,
  isOrderable
}: ElphiFooterProps) => {
  const { getSortCallback } = useTableManagerHook();
  const sortCallback = getSortCallback({ isOrderable });
  const sortedCells = sortCallback({ tableName, cells });
  return (
    <Flex>
      {sortedCells.map(({ cellType, minWidth, maxWidth, data }, i) => {
        return (
          <Box minW={minWidth} maxW={maxWidth} key={i}>
            {cellType === AggregationColumnTypes.String ? (
              <Text fontWeight="bold">{data}</Text>
            ) : cellType === AggregationColumnTypes.Empty ? (
              <></>
            ) : cellType === AggregationColumnTypes.Aggregation ? (
              <>{data}</>
            ) : (
              <></>
            )}
          </Box>
        );
      })}
    </Flex>
  );
};
