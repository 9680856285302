import { FieldGroup, FirebaseFilter, Status, StatusCode } from "@elphi/types";
import { PartialWithId } from "@elphi/types/services/service.types";
import { removeEmpty, removeEmptyValues } from "@elphi/utils/src/common.utils";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { useElphiToast } from "../components/toast/toast.hook";
import { RootState } from "../redux/store";
import {
  fieldGroupApi,
  fieldGroupSlice,
  FieldGroupSliceState
} from "../redux/v2/field-group";
import { usePaginationHooksV2 } from "../redux/v2/hooks/pagination.hooks.v2";
import { taskConfigurationApi } from "../redux/v2/task-configuration";
import { FilterCombinationState } from "../redux/v2/types/stateWithFilter.types";
import { useFilter } from "./filter.hooks";

export const LIMIT = 10;
export const useFieldGroupHooks = () => {
  const dispatch = useDispatch();
  const { errorToast, successToast } = useElphiToast();
  const [searchApi, searchResponse] = fieldGroupApi.useLazySearchQuery();
  const [createApi, createResponse] = fieldGroupApi.useCreateMutation();
  const [updateApi, updateResponse] = fieldGroupApi.useUpdateMutation();
  const [updateBatchApi, updateBatchResponse] =
    fieldGroupApi.useUpdateBatchMutation();
  const [getTaskConfigurationBatchApi, getTaskConfigurationBatchResponse] =
    taskConfigurationApi.useLazyGetBatchQuery();

  const setSelected = (id: string) =>
    dispatch(fieldGroupSlice.actions.selectedId({ id }));

  const fieldGroupState = useSelector((state: RootState) => state.fieldGroup);
  const selectedGroup = useSelector(
    (state: RootState) =>
      (state.fieldGroup.selectedId &&
        state.fieldGroup.entities[state.fieldGroup.selectedId]) ||
      undefined
  );

  const createGroup = async (r: Pick<FieldGroup, "name">) => {
    return await createApi({
      ...r,
      status: Status.Disabled
    })
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.OK) {
          successToast({
            title: "Group created",
            description: `Field group: ${res.data?.id}`
          });
        }
        if (res.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to create field group",
            description: res.data?.description
          });
        }
        return res;
      });
  };

  const updateGroup = async (r: PartialWithId<FieldGroup>) => {
    return await updateApi(r)
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.OK) {
          successToast({
            title: "Group updated",
            description: `Field group: ${res.data?.id}`
          });
        }
        if (res.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to update field group",
            description: res.data?.description
          });
        }
        return res;
      });
  };

  const cloneGroup = async (r: Pick<FieldGroup, "name">) => {
    return await createApi({
      ...r,
      status: Status.Disabled
    })
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.OK) {
          successToast({
            title: "Group cloned",
            description: `Field group: ${res.data?.id}`
          });
        }
        if (res.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to clone field group",
            description: res.data?.description
          });
        }
        return res;
      });
  };

  const updateGroupBatch = async (r: { [id: string]: Partial<FieldGroup> }) => {
    const groups = removeEmptyValues(r);
    if (!groups?.length) {
      return null;
    }

    return await updateBatchApi({ groups })
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.OK) {
          successToast({
            title: "Field group Updated",
            description: `total groups updated: ${res.data?.batch?.length}`
          });
        }
        if (res.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to update batch",
            description: res.data?.description
          });
        }
        return res;
      });
  };

  return {
    setSelected,
    fieldGroupState,
    searchApi,
    searchResponse,
    selectedGroup,
    createGroup,
    createResponse,
    cloneGroup,
    updateGroupBatch,
    updateBatchResponse,
    updateGroup,
    updateResponse,
    getTaskConfigurationBatchApi,
    getTaskConfigurationBatchResponse
  };
};

export const useFieldGroupFilterHooks = () => {
  const { fieldGroupState } = useFieldGroupHooks();
  const { buildFireStoreFilters, setCombination, setFilterCombinationDetails } =
    useFilter<FieldGroup, FieldGroupSliceState>(fieldGroupState);
  const currentFilter = fieldGroupState.filters.current;
  const [filters, setFilters] = useState<FirebaseFilter[]>([]);

  useEffect(() => {
    const filters = buildFireStoreFilters();
    setFilters(filters);
  }, [fieldGroupState.filters.currentCombination]);

  const setCurrentFilter = (filter: {
    values: FieldGroupSliceState["filters"]["current"];
  }) => {
    setCombination(
      filter.values,
      fieldGroupSlice.actions.setFilterAndCombination
    );
  };

  const currentCombination = useSelector((state: RootState) => {
    const filters = state.fieldGroup.filters;
    return filters.combinations[filters.currentCombination];
  });

  const filteredItems = useMemo(() => {
    const names = new Set(currentFilter.name);
    const statuses = new Set(currentFilter.status);

    const filtered = fieldGroupState.ids
      .map((id) => {
        const entity = fieldGroupState.entities[id];
        if (!entity) {
          return undefined;
        }

        const { name, status } = entity;
        if (
          (names.size === 0 || (names && names.has(name))) &&
          (statuses.size === 0 || (status && statuses.has(status)))
        ) {
          return {
            ...entity
          };
        }
        return undefined;
      })
      .filter(removeEmpty);

    return filtered;
  }, [currentFilter, fieldGroupState.entities]);

  useEffect(() => {
    setFilterCombinationDetails(
      { ids: filteredItems.map((x) => x.id) },
      fieldGroupSlice.actions.setFilterCombinationDetails
    );
  }, [filteredItems]);

  const setPagingInfo = (info: Partial<FilterCombinationState[string]>) => {
    setFilterCombinationDetails(
      info,
      fieldGroupSlice.actions.setFilterCombinationDetails
    );
  };

  return {
    setCurrentFilter,
    filters,
    currentCombination,
    setPagingInfo
  };
};

export const useFieldGroupTableHooks = () => {
  const { filters, currentCombination, setPagingInfo } =
    useFieldGroupFilterHooks();
  const { nextPage, paginateApiResponse } = usePaginationHooksV2({
    paginationProvider: fieldGroupApi.useLazyPaginateV2Query,
    limit: LIMIT,
    input: {
      cursor: currentCombination.cursor,
      options: {
        filter: filters
      }
    },
    currentCursor: currentCombination.cursor,
    setHasMore: async (hasMore) => {
      setPagingInfo({
        hasMore
      });
    },
    setCursor: (cursor) => {
      setPagingInfo({
        cursor
      });
    }
  });

  return {
    nextPage,
    paginateApiResponse,
    filters,
    currentCombination
  };
};
