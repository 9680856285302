export const extractNonEnumValues = (
  values: string[],
  enumType: { [s: string]: string }
): string[] => {
  const enumValues = Object.keys(enumType).map((key) => enumType[key]);
  return values.filter((value) => !enumValues.includes(value));
};

export const getEnumValue = <T extends { [s: string]: any }>(
  enumObject: T,
  key: string
): T[keyof T] | undefined => {
  const enumValue = Object.keys(enumObject).find(
    (enumKey) => enumObject[enumKey] === key
  );
  return enumValue ? enumObject[enumValue] : undefined;
};
