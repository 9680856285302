import { Box, Flex } from "@chakra-ui/react";
import { FieldType } from "@elphi/types";
import { useTaskConfigurationHooks } from "../../../hooks/taskConfiguration.hooks";
import CreateTaskConfigurationModal from "./CreateTaskConfigurationModal";
import TaskConfigurationSearch from "./TaskConfigurationSearch";
const TaskConfigurationHeader = () => {
  const { selectedTaskConfiguration, setSelectedTaskConfiguration } =
    useTaskConfigurationHooks();
  return (
    <Box w="100%">
      <Flex w="100%">
        <Box w="50%">
          <TaskConfigurationSearch
            fieldType={FieldType.SingleSelect}
            onSelect={setSelectedTaskConfiguration}
            currentValue={
              selectedTaskConfiguration ? selectedTaskConfiguration.id : ""
            }
          />
        </Box>

        <Box p="10px" w="50%">
          <CreateTaskConfigurationModal />
        </Box>
      </Flex>
    </Box>
  );
};

export default TaskConfigurationHeader;
