import {
  aggregationFieldTemplate,
  thirdPartyFieldTemplate
} from "../../aggregations";
import { OmitCreate } from "../../services/service.types";
import { CreditScore } from "../creditReport.types";
import { BooleanSelectType } from "../fieldFormat.types";
import { CreditReportPullType } from "../party.types";

export const getCreditReportTemplate = () => {
  const creditReport: OmitCreate<CreditScore> = {
    experian: {
      date: thirdPartyFieldTemplate(),
      score: thirdPartyFieldTemplate()
    },
    equifax: {
      date: thirdPartyFieldTemplate(),
      score: thirdPartyFieldTemplate()
    },
    transunion: {
      date: thirdPartyFieldTemplate(),
      score: thirdPartyFieldTemplate()
    },
    pullType: "" as CreditReportPullType,
    creditReportIdentifier: "",
    creditReportRequestActionType: "",
    creditReportType: "",
    creditReportTypeOtherDescription: "",
    loanIdentifier: "",
    loanIdentifierType: "",
    loanOriginationSystemName: "",
    partyRoleType: "",
    reportingInformationIdentifier: "",
    reportingInformationName: "",
    includeEquifaxIndicator: "" as BooleanSelectType,
    includeExperianIndicator: "" as BooleanSelectType,
    includeTransunionIndicator: "" as BooleanSelectType,
    aggregations: { expirationDate: aggregationFieldTemplate() }
  };
  return creditReport;
};
