import { Box, Flex, Skeleton, SkeletonText } from "@chakra-ui/react";
import { FieldType, RoleGroup } from "@elphi/types";
import { useEffect } from "react";
import RoleSearch from "../../admin/role-permission/RoleSearch";
import StyledInputBuilder, {
  useFormBuilderStateHandler
} from "../../form-builder/InputBuilder";
import { MenuRowAction } from "../../table/MenuRowAction";
import {
  SIZE_FIELD,
  TableRowSizeComponent,
  TableTextCell
} from "../../table/TableRowSizeComponent";

type RoleGroupRowProps = {
  index: number;
  data: RoleGroup;
  onClone: (id: string) => void;
  onChange: ReturnType<typeof useFormBuilderStateHandler>["onChange"];
  syncState: ReturnType<typeof useFormBuilderStateHandler>["syncState"];
  state: {
    [id: string]: Partial<RoleGroup>;
  };
  isLoadingRoles: boolean;
};

export const RoleGroupRow = ({
  index,
  data,
  onChange,
  state,
  syncState,
  onClone,
  isLoadingRoles
}: RoleGroupRowProps) => {
  const { id } = data;

  useEffect(() => {
    syncState({
      state: data,
      shouldSync: true,
      statePath: () => {
        return [id];
      }
    });
  }, [data]);

  return (
    <Flex w="100%" justify="space-between">
      <TableRowSizeComponent size={SIZE_FIELD.T} withBorderRightWidth={false}>
        <TableTextCell text={index} />
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        <StyledInputBuilder
          currentValue={state[id]?.name}
          fieldType={FieldType.String}
          onChange={(e) => {
            onChange({
              fieldKey: [id, "name"],
              fieldType: FieldType.String,
              value: e.target.value
            });
          }}
          isValid={true}
        />
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        {!isLoadingRoles ? (
          <RoleSearch
            fieldType={FieldType.MultiSelect}
            onSelect={(value) => {
              onChange({
                fieldKey: [data.id, "ids"],
                fieldType: FieldType.MultiSelect,
                value
              });
            }}
            currentValue={state?.[data.id]?.ids || []}
          />
        ) : (
          <Skeleton height="10px">
            <SkeletonText />
          </Skeleton>
        )}
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.S} withBorderRightWidth={false}>
        <Flex alignItems={"center"} justifyContent={"center"} gap={"18px"}>
          <Box w="fit-content">
            <StyledInputBuilder
              currentValue={state[id]?.status}
              fieldType={FieldType.Switch}
              onChange={(e) => {
                onChange({
                  fieldKey: [id, "status"],
                  fieldType: FieldType.Switch,
                  value: e.target.value
                });
              }}
            />
          </Box>
          <MenuRowAction
            onClone={() => {
              onClone(id);
            }}
          />
        </Flex>
      </TableRowSizeComponent>
    </Flex>
  );
};
