import { Party } from "@elphi/types";
import { ButtonIconAdd } from "../../button-icon/ButtonIcon";
import { NodeAction } from "../../tree/types/Tree.types";
import { AddPartyChildModal } from "../CreatePartyComponent";

const AddNodeButton = () => {
  const PartyModal = <AddPartyChildModal buttonFn={ButtonIconAdd} />;

  return {
    component: PartyModal,
    tooltip: "Add a new Party"
  } as NodeAction<Party>;
};

export default AddNodeButton;
