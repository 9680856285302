import { Flex } from "@chakra-ui/react";
import { PartyGroup } from "@elphi/types";
import { EntityId } from "@reduxjs/toolkit";
import { useState } from "react";
import { AppConfig } from "../../../../config/appConfig";
import { auth } from "../../../../firebase/firebaseConfig";
import { useAuthStateChangeHook } from "../../../../hooks/authStateChange.hooks";
import {
  usePartyGroup,
  usePartyGroupFilter
} from "../../../../hooks/partyGroup.hooks";
import useWindowDimensions from "../../../../hooks/windowDimensions";
import { usePaginationHooksV2 } from "../../../../redux/v2/hooks/pagination.hooks.v2";
import { partyGroupApi } from "../../../../redux/v2/party-group";
import { ElphiPaginationList } from "../../../elphi-list/ElphiList";
import { useFormBuilderStateHandler } from "../../../form-builder/InputBuilder";
import { TableRowSizeComponent } from "../../../table/TableRowSizeComponent";
import { PartyGroupModal } from "../modal/PartyGroupModal";
import { PartyGroupRow } from "./PartyGroupRow";
import { headerCells } from "./tableHeaders";

const ALL_LIMIT = 600; // Fetch all the data. Assumption: there are no more than 600 party groups.
const LIMIT = 10;

export const PartyGroupTable = () => {
  const [modalDetails, setModalDetails] = useState<{
    isShow: boolean;
    partyGroup: Partial<PartyGroup> | undefined;
  }>({ isShow: false, partyGroup: undefined });
  const {
    partyGroupState,
    updatePartyGroupBatch,
    selectPartyGroup,
    unSelectPartyGroup,
    clonePartyGroup,
    SHOULD_FETCH_ALL
  } = usePartyGroup();

  const { filters, currentCombination, setPagingInfo } = usePartyGroupFilter();

  const { nextPage, paginateApiResponse } = usePaginationHooksV2({
    paginationProvider: partyGroupApi.useLazyPaginateV2Query,
    limit: SHOULD_FETCH_ALL ? ALL_LIMIT : LIMIT,
    input: {
      cursor: currentCombination.cursor,
      options: {
        filter: filters
      }
    },

    currentCursor: currentCombination.cursor,
    setHasMore: async (hasMore) => {
      setPagingInfo({
        hasMore
      });
    },
    setCursor: (cursor) => {
      setPagingInfo({
        cursor
      });
    }
  });

  useAuthStateChangeHook({
    onAuthStateChange: (_) => {
      if (partyGroupState?.ids.length <= LIMIT) {
        nextPage();
      }
    },
    deps: [auth.currentUser]
  });

  const { syncState, state, onChange } = useFormBuilderStateHandler<{
    [id: string]: Partial<PartyGroup>;
  }>({
    initialState: {},
    callback: updatePartyGroupBatch,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  const { heightOffsetInPx } = useWindowDimensions();

  const handleRowToggle = (r: { isChecked: boolean; id: string }) => {
    !r.isChecked ? unSelectPartyGroup(r.id) : selectPartyGroup(r.id);
  };

  const buildRowData = (id: EntityId, index: string | number) => {
    const data = partyGroupState.entities[id];
    if (!data) {
      return <></>;
    }

    return (
      <PartyGroupRow
        index={Number(index)}
        data={data}
        state={state}
        syncState={syncState}
        onChange={onChange}
        isSelected={partyGroupState.selectedIds.includes(id)}
        onSelect={(r) => handleRowToggle(r)}
        onClone={(r) => {
          setModalDetails({
            isShow: true,
            partyGroup: r.partyGroup
          });
        }}
      />
    );
  };
  const isLoading =
    paginateApiResponse.isFetching || paginateApiResponse.isLoading;
  return (
    <>
      <Flex width="100%">
        <ElphiPaginationList
          height={heightOffsetInPx(320)}
          tableName={"Party groups"}
          isLoading={isLoading}
          rowStyle={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none"
          }}
          header={
            <TableRowSizeComponent
              bgColor="gray.100"
              row={headerCells}
              withBorderRightWidth={false}
              justify="space-between"
            />
          }
          pageSize={LIMIT}
          items={
            !partyGroupState.selectedSearchResultId
              ? currentCombination.ids
              : [partyGroupState.selectedSearchResultId]
          }
          next={nextPage}
          hasMore={false}
          options={{
            showEndMessage: false,
            showWatching: false
          }}
          rowBuilder={buildRowData}
        />
      </Flex>
      <PartyGroupModal
        isShow={modalDetails.isShow}
        onClose={() =>
          setModalDetails({ isShow: false, partyGroup: undefined })
        }
        partyGroup={modalDetails.partyGroup}
        headerText="Clone Party Group"
        subHeaderText={`Cloning: id: ${modalDetails.partyGroup?.id}, ${modalDetails.partyGroup?.name}`}
        acknowledgeButtonText="Clone"
        acknowledgeAction={clonePartyGroup}
      />
    </>
  );
};
