import { Box, Flex } from "@chakra-ui/react";
import { LoanProgramType } from "@elphi/types";
import { isNumber, map, max } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { FilterMenuOptions } from "../../common-components/menu-options/FilterMenuOptions";
import { FilterMenuHook } from "../../common-components/menu-options/filter-menu-hook";
import { MenuRangeSlider } from "../../common-components/menu-range-slider/MenuRangeSlider";
import useDealHooks from "../../hooks/deal.hooks";
import { useDealCalendarHooks } from "../../hooks/dealCalendar.hooks";
import { createOptionsFromEnum } from "../utils/formUtils";

type DealCalendarFiltersProps = {
  filterMenuHook: FilterMenuHook;
};

export const DealCalendarFilters = ({
  filterMenuHook
}: DealCalendarFiltersProps) => {
  const { setDealTableFilter, dealTableFilter } = useDealHooks();

  const { dates, selectedDateType, selectedCalendarViewDate } =
    useDealCalendarHooks();
  const data = dates[selectedDateType];
  const [minValue, setMinValue] = useState<number>();
  const [maxValue, setMaxValue] = useState<number>();
  const [toReset, setToReset] = useState<boolean>(false);
  const selectedFilterValues = {
    selectedDealParties: dealTableFilter?.dealParties || [],
    selectedLoanProgramTypes: dealTableFilter?.loanProgramTypes || [],
    selectedTotalLoanAmount: dealTableFilter?.totalLoanAmount || []
  };

  const { selectedLoanProgramTypes } = selectedFilterValues;

  useEffect(() => {
    if (!dealTableFilter?.totalLoanAmount?.length) {
      setMinValue(undefined);
      setMaxValue(undefined);
    }
  }, [dealTableFilter?.totalLoanAmount?.length]);

  useEffect(() => {
    const totalRequestedLoanAmounts = map(
      dates[selectedDateType],
      "totalRequestedLoanAmount"
    );

    const currentMin = 0;
    const currentMax = max(totalRequestedLoanAmounts);

    if (
      !isNumber(minValue) &&
      !isNumber(maxValue) &&
      isNumber(currentMin) &&
      isNumber(currentMax)
    ) {
      setMaxValue(currentMax);
      setMinValue(currentMin);
    }

    if (isNumber(currentMax) && isNumber(maxValue) && currentMax > maxValue) {
      setMaxValue(currentMax);
    }

    if (toReset && isNumber(currentMax) && isNumber(currentMin)) {
      setMaxValue(currentMax);
      setMinValue(currentMin);
      setToReset(false);
    }
  }, [data, dealTableFilter?.totalLoanAmount?.length]);

  useEffect(() => {
    setToReset(true);
  }, [selectedCalendarViewDate, selectedDateType]);

  const memoizedSlider = useMemo(() => {
    if (isNumber(minValue) && isNumber(maxValue)) {
      return (
        <Box pl="5px">
          <MenuRangeSlider
            title="Total Loan Amount"
            min={minValue}
            max={maxValue}
            defaultValue={[minValue, maxValue]}
            onChange={(values) => {
              const [min, max] = values;
              setDealTableFilter({
                ...dealTableFilter,
                totalLoanAmount: [min.toString(), max.toString()]
              });
            }}
          />
        </Box>
      );
    }
    return null;
  }, [minValue, maxValue, dealTableFilter]);

  return (
    <Flex ml={"2px"}>
      <Box>
        <Box pl="5px">
          <FilterMenuOptions
            customKey="dealPageLoanProgramTypesFilter"
            filterMenuHook={filterMenuHook}
            title="Loan Program"
            buttonTooltipLabel={`${dealTableFilter?.loanProgramTypes?.join(
              ","
            )}`}
            onChange={(items: string[]) => {
              setDealTableFilter({
                ...dealTableFilter,
                loanProgramTypes: items
              });
            }}
            options={createOptionsFromEnum(LoanProgramType)}
            selected={selectedLoanProgramTypes}
            showSearchBar
            showSelectClearAll
          />
        </Box>
      </Box>
      <Box>{memoizedSlider}</Box>
    </Flex>
  );
};
