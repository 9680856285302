import { Deal } from "@elphi/types";
import { ObjectKeyValidation } from "../../form-builder/field-specs/objectKeyValidation.types";

export const underwriterApprovalStatusFieldPath: ObjectKeyValidation<
  Deal,
  ["UnderwriterApprovalStatus"]
> = ["UnderwriterApprovalStatus"];

export const underwriterApprovalCommentsFieldPath: ObjectKeyValidation<
  Deal,
  ["UnderwriterApprovalComments"]
> = ["UnderwriterApprovalComments"];

export const assetSummaryReportIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["AssetSummaryReportIndicator"]
> = ["AssetSummaryReportIndicator"];

export const creditMemorandumIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["CreditMemorandumIndicator"]
> = ["CreditMemorandumIndicator"];

export const creditReviewCommitteeApprovalIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["CreditReviewCommitteeApprovalIndicator"]
> = ["CreditReviewCommitteeApprovalIndicator"];

export const programDirectorApprovalIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["ProgramDirectorApprovalIndicator"]
> = ["ProgramDirectorApprovalIndicator"];

export const programDirectorCommentsFieldPath: ObjectKeyValidation<
  Deal,
  ["ProgramDirectorComments"]
> = ["ProgramDirectorComments"];

export const submissionNotesFieldPath: ObjectKeyValidation<
  Deal,
  ["SubmissionNotes"]
> = ["SubmissionNotes"];

export const pricingEngineExceptionStatusFieldPath: ObjectKeyValidation<
  Deal,
  ["PricingEngineExceptionStatus"]
> = ["PricingEngineExceptionStatus"];

export const pricingEngineOverridesFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "PricingEngineOverrides"]
> = ["quote", "aggregations", "PricingEngineOverrides"];

export const feeLLPAsFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "FeeLLPAs"]
> = ["quote", "aggregations", "FeeLLPAs"];

export const rateLLPAsFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "RateLLPAs"]
> = ["quote", "aggregations", "RateLLPAs"];
