import { RolodexServiceProvider, ServiceProviderType } from "../../../types";

export const buildProviderName = (
  serviceProvider?: Partial<RolodexServiceProvider>
) => {
  if (serviceProvider?.type === ServiceProviderType.Representative) {
    return serviceProvider.middleName
      ? `${serviceProvider.firstName} ${serviceProvider.middleName} ${serviceProvider.lastName}`
      : `${serviceProvider.firstName} ${serviceProvider.lastName}`;
  }

  if (
    serviceProvider?.type === ServiceProviderType.Branch ||
    serviceProvider?.type === ServiceProviderType.Company
  ) {
    return serviceProvider.name;
  }

  return undefined;
};
