import { useSnapshotHooks } from "../../../hooks/snapshot.hooks";
import { MilestoneChangeReasonContainer } from "./MilestoneChangeReasonContainer";
import { isChangeReasonMilestoneGuard } from "./changeReasonRequiredForMilestones.utils";
import { ChangeReasonSnapshotContainer } from "./deadDeal/DeadDealChangeReasonSnapshotContainer";
export const MilestoneChangeReasonSnapshotContainer = (
  props: MilestoneChangeReasonContainer & {
    snapshotId: string;
  }
) => {
  const { snapshotDataState } = useSnapshotHooks();
  const snapshot = snapshotDataState({ snapshotId: props.snapshotId });
  const deal = snapshot.dealState?.entities[props.dealId];
  const milestone = deal?.DealMetadata.milestone;
  return (
    <>
      {milestone && isChangeReasonMilestoneGuard(milestone) && (
        <ChangeReasonSnapshotContainer
          dealId={props.dealId}
          deal={deal}
          newDealMilestone={milestone}
          lastDealMilestone={props.lastDealMilestone}
          revertToLastMilestone={props.revertToLastMilestone}
        />
      )}
    </>
  );
};
