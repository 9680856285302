import { Box, Flex } from "@chakra-ui/react";
import { TABLE_MIN_WIDTH } from "../../constants/common";
import DocumentConfigurationHeader from "./document-configuration/DocumentConfigurationHeader";
import { DocumentConfigurationTable } from "./document-configuration/v2/DocumentConfigurationTable";

const DocumentConfigurationTab = () => {
  return (
    <Box>
      <DocumentConfigurationHeader />
      <Box overflowX="auto">
        <Flex overflowX={"scroll"} minW={TABLE_MIN_WIDTH}>
          <DocumentConfigurationTable />
        </Flex>
      </Box>
    </Box>
  );
};

export default DocumentConfigurationTab;
