import { Box } from "@chakra-ui/react";
import { DealCalendarType } from "@elphi/types";
import { PartySection } from "./PartySection";

export type PartiesSectionProps = { dealCalendar: DealCalendarType };

export const PartiesSection = ({ dealCalendar }: PartiesSectionProps) => {
  const { id, parties } = dealCalendar;
  if (!parties) return <></>;
  const sortedPartiesArray = Object.entries(parties)
    .sort(([, a], [, b]) =>
      (a.dealPartyRelationId ?? "") > (b.dealPartyRelationId ?? "") ? 1 : -1
    )
    .map(([, value]) => value);

  return (
    <Box>
      {sortedPartiesArray?.map((party, index) => (
        <PartySection
          key={`${index}_${party.dealPartyRelationId}`}
          dealCalendarParty={party}
          dealId={id!}
        />
      ))}
    </Box>
  );
};
