import { BaseInsurancePolicy, InsurancePolicyEntityType } from "../../../types";
import { removeEmpty } from "../common.utils";

export const filterEntityIdFromEntitiesAndGetDealIds = (r: {
  insurancePolicyEntities: BaseInsurancePolicy<InsurancePolicyEntityType>["entities"];
  entityId: string;
}) => {
  if (!r.insurancePolicyEntities)
    return {
      updatedEntities: {}
    };

  const updatedEntities = Object.entries(r.insurancePolicyEntities).reduce(
    (acc, [entityId, insuranceData]) => {
      if (entityId === r.entityId) return acc;
      return {
        ...acc,
        [entityId]: insuranceData
      };
    },
    {} as BaseInsurancePolicy<InsurancePolicyEntityType>["entities"]
  );
  return {
    updatedEntities
  };
};

export const getInsurancePolicyDealIds = (r: {
  insurancePolicyEntities: BaseInsurancePolicy<InsurancePolicyEntityType>["entities"];
}) => {
  if (!r.insurancePolicyEntities) {
    return [];
  }

  const dealIds = Object.values(r.insurancePolicyEntities)
    .map((insuranceData) => insuranceData.dealId)
    .filter(removeEmpty);
  return [...new Set(dealIds)].filter((x) => x);
};
