import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { FieldGroup, FieldType } from "@elphi/types";
import { useEffect, useState } from "react";
import { useFieldGroupHooks } from "../../../hooks/fieldGroup.hooks";
import { DNDPicker } from "../../drag-and-drop/DNDPicker";
import { DNDPickerItems } from "../../drag-and-drop/dnd.types";
import StyledInputBuilder from "../../form-builder/InputBuilder";
import ModalContainer from "../../modal-container/ModalContainer";
import { FuzzySearch } from "../../search/fuzzy/FuzzySearch";
import {
  buildTargetFields,
  dataSet,
  excludeFieldsFromDataSet,
  FieldGroupDNDRow
} from "../utils/fieldGroup.utils";

export const FieldGroupSelectionModal = (props: {
  selectedRow: FieldGroup;
  isShow: boolean;
  onClose: () => void;
}) => {
  const { updateGroup, updateResponse } = useFieldGroupHooks();
  const [items, setItems] = useState<DNDPickerItems<FieldGroupDNDRow>>({
    source: dataSet,
    target: []
  });

  useEffect(() => {
    if (props.isShow && props.selectedRow.fields) {
      const selectedFields = Object.values(props.selectedRow.fields || {});
      const source = excludeFieldsFromDataSet(selectedFields);
      const target = buildTargetFields(selectedFields);
      setItems({
        source,
        target
      });
    }
  }, [props.isShow, props.selectedRow.fields]);

  const handleOnPick = (r: {
    source: FieldGroupDNDRow[];
    target: FieldGroupDNDRow[];
  }) => {
    setItems(r);
  };

  const handleOnSearchResult = (result: FieldGroupDNDRow[]) => {
    const shouldExcludeFromResult = items.target.length;
    const source = shouldExcludeFromResult
      ? result.filter((x) => !items.target.some((item) => item.id === x.id))
      : result;
    setItems((prev) => ({
      ...prev,
      source
    }));
  };

  const handleOnSubmit = () => {
    const targetFields = items.target.reduce((acc, x, index) => {
      return {
        ...acc,
        [index]: {
          fieldPath: x.path,
          entityType: x.entityType,
          from: x.from,
          to: x.to
        }
      };
    }, {});
    updateGroup({
      id: props.selectedRow.id,
      fields: targetFields
    });
  };

  const handleFieldChange = (
    index: number,
    field: "from" | "to",
    value: string
  ) => {
    setItems((prevItems) => {
      const newTarget = prevItems.target.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      );
      return { ...prevItems, target: newTarget };
    });
  };

  return (
    <ModalContainer
      maxWidth={1600}
      isShow={props.isShow}
      onCloseModal={props.onClose}
      body={
        <>
          <FuzzySearch
            label={"Search fields"}
            dataSet={dataSet}
            configuration={{
              keys: ["path", "label", "entityType"],
              threshold: 0.4,
              includeScore: true
            }}
            onResult={handleOnSearchResult}
            sanitizer={(q) => decodeURIComponent(encodeURIComponent(q))}
          />
          <DNDPicker
            items={items}
            handleOnPick={handleOnPick}
            HeaderTargetComponent={HeaderTarget}
            RowTargetComponent={(props) => {
              return (
                <RowTarget
                  {...props}
                  onFieldChange={(field, value) => {
                    handleFieldChange((props.index || 1) - 1, field, value);
                  }}
                />
              );
            }}
            HeaderSourceComponent={HeaderSource}
            RowSourceComponent={RowSource}
          />
        </>
      }
      submit={{
        showClose: true,
        onConfirm: handleOnSubmit,
        isLoading: updateResponse.isLoading
      }}
    />
  );
};

const HeaderSource = () => (
  <Flex flexFlow={"column nowrap"} w={"100%"}>
    <Flex>
      <Flex flex={1} justifyContent={"center"} alignItems={"center"}></Flex>
      <Flex flex={2} justifyContent={"center"} alignItems={"center"}>
        <Text fontWeight={600}>Index</Text>
      </Flex>
      <Flex flex={3} justifyContent={"center"} alignItems={"center"}>
        <Text fontWeight={600}>Entity</Text>
      </Flex>
      <Flex
        flex={16}
        justifyContent={"center"}
        alignItems={"center"}
        width={"400px"}
      >
        <Text fontWeight={600}>Field</Text>
      </Flex>
    </Flex>
  </Flex>
);

const RowSource = (props: { item: FieldGroupDNDRow; index?: number }) => (
  <Flex flexFlow={"column nowrap"} w={"100%"} p={2}>
    <Flex>
      <Flex flex={2} justifyContent={"center"} alignItems={"center"}>
        {props.index}
      </Flex>
      <Flex flex={3} justifyContent={"center"} alignItems={"center"}>
        {props.item.entityType}
      </Flex>
      <Flex flex={16} justifyContent={"left"}>
        {props.item.label}
      </Flex>
    </Flex>
  </Flex>
);

const HeaderTarget = () => (
  <Flex flexFlow={"column nowrap"} w={"100%"}>
    <Flex>
      <Flex flex={1} justifyContent={"center"} alignItems={"center"}>
        <Text fontWeight={600}>Index</Text>
      </Flex>
      <Flex flex={2} justifyContent={"center"} alignItems={"center"}>
        <Text fontWeight={600}>Entity</Text>
      </Flex>
      <Flex flex={3} justifyContent={"center"} alignItems={"center"}>
        <Text fontWeight={600}>Field</Text>
      </Flex>
      <Flex flex={4} justifyContent={"center"} alignItems={"center"}>
        <Text fontWeight={600}>From</Text>
      </Flex>
      <Flex flex={5} justifyContent={"center"} alignItems={"center"}>
        <Text fontWeight={600}>To</Text>
      </Flex>
    </Flex>
  </Flex>
);

const supportedFieldTypes = new Set([
  FieldType.SingleSelect,
  FieldType.Boolean,
  FieldType.MultiSelect
]);

const toMultiSelect = new Set([FieldType.SingleSelect, FieldType.Boolean]);

const RowTarget = (props: {
  item: FieldGroupDNDRow;
  index?: number;
  onFieldChange: (field: "from" | "to", value: string) => void;
}) => {
  const { index, item, onFieldChange } = props;
  const [from, setFrom] = useState(props.item.from);
  const [to, setTo] = useState(props.item.to);

  const fieldType = item.type || FieldType.String;
  const finalFieldType = toMultiSelect.has(fieldType)
    ? FieldType.MultiSelect
    : fieldType;
  const isDisabled = !supportedFieldTypes.has(finalFieldType);

  const handleFromChange = (e) => {
    const value = e.target.value;
    setFrom(value);
    onFieldChange("from", value);
  };

  const handleToChange = (e) => {
    const value = e.target.value;
    setTo(value);
    onFieldChange("to", value);
  };

  return (
    <Flex flexFlow={"column nowrap"} w={"100%"} p={2}>
      <Flex>
        <Flex flex={1} justifyContent={"center"} alignItems={"center"}>
          {index}
        </Flex>
        <Flex flex={2} justifyContent={"center"} alignItems={"center"}>
          {item.entityType}
        </Flex>
        <Flex flex={3} justifyContent={"center"} alignItems={"center"}>
          <Tooltip label={item.label} hasArrow>
            <Text noOfLines={2} width={"200px"}>
              {item.label}
            </Text>
          </Tooltip>
        </Flex>
        <Flex flex={4} justifyContent={"center"} alignItems={"center"}>
          <Tooltip
            label={isDisabled ? "Not Supported Yet" : from && from.join(", ")}
            placement={"auto"}
            hasArrow
          >
            <Box width={"200px"}>
              <StyledInputBuilder
                isDisabled={isDisabled}
                currentValue={from}
                fieldType={finalFieldType}
                label={"Any value..."}
                onChange={handleFromChange}
                options={item.options}
              />
            </Box>
          </Tooltip>
        </Flex>
        <Flex flex={5} justifyContent={"center"} alignItems={"center"}>
          <Tooltip
            label={isDisabled ? "Not Supported Yet" : to && to.join(", ")}
            placement={"auto"}
            hasArrow
          >
            <Box width={"200px"}>
              <StyledInputBuilder
                isDisabled={isDisabled}
                currentValue={to}
                fieldType={finalFieldType}
                label={"Any value..."}
                onChange={handleToChange}
                options={item.options}
              />
            </Box>
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
  );
};
