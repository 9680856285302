import { Box, Text } from "@chakra-ui/react";
import { AggregationType, FieldType, IndividualParty } from "@elphi/types";
import { elphiDecimal } from "@elphi/utils/src/elphiDecimal";
import { useMemo } from "react";
import useWindowDimensions from "../../hooks/windowDimensions";
import AggregationFooter, {
  AggregationColumnTypes
} from "../application/AggregationFooter";
import { OnChangeInput } from "../form-builder/FormBuilder";
import StyledInputBuilder from "../form-builder/InputBuilder";
import { partyFieldSpecs } from "../form-builder/field-specs/party/party.fields";
import { getFocusedAggregationSpecs } from "../form-builder/field-specs/utils/aggregation.utils";
import {
  moneyFormatter,
  numberFormatter,
  percentFormatter
} from "../form-builder/formatters/inputs.formatter";
import { ElphiTable } from "../table/ElphiTableComponent";
import { ElphiCellType, ElphiTableProps } from "../table/table.types";
import { TableInput, resolveFieldValue } from "../utils/tableUtils";
import { PartyStructureState } from "./PartyStructure";
import { locExpirationDateStyles } from "./styles/partyIndividualTable";

export type PartyIndividualTableState = IndividualParty & {
  ultimateEquitableOwnership: number;
};
const TABLE_MIN_HEIGHT = "90px";
const ZERO = "0";
export const PartyIndividualTable = (props: {
  formBuilderState: {
    onChange: (v: OnChangeInput) => void;
    state: PartyStructureState<PartyIndividualTableState>;
  };
  isLoading: boolean;
}) => {
  const tableFullDataRows: ElphiTableProps["rows"] = useMemo(() => {
    return (
      Object.entries(props?.formBuilderState.state.parties).map(
        ([_, rowData], i) => {
          const specFieldLOCExpirationDate = getFocusedAggregationSpecs({
            focused: rowData.aggregations?.LOCExpirationDate?.focused,
            spec: partyFieldSpecs.aggregations?.LOCExpirationDate
          });
          return {
            cells: [
              {
                index: 1,
                data: (
                  <Text align={"center"}>
                    {`${rowData.FirstName} ${rowData.LastName}`}
                  </Text>
                ),
                type: ElphiCellType.Element,
                maxWidth: "150px",
                minWidth: "150px"
              },
              {
                index: 2,
                data: (
                  <Text align={"center"}>
                    {`${percentFormatter.format.encode(
                      rowData.ultimateEquitableOwnership.toString()
                    )}%`}
                  </Text>
                ),
                type: ElphiCellType.Element,
                maxWidth: "150px",
                minWidth: "150px"
              },
              {
                index: 3,
                data: <Text align={"center"}>{rowData.LOCStatusType}</Text>,
                type: ElphiCellType.Element,
                maxWidth: "200px",
                minWidth: "200px"
              },
              {
                index: 4,
                data: (
                  <Text align={"center"}>
                    {moneyFormatter.format.encode(
                      rowData.FixNFlipNBridgePlusTier?.toString()
                    )}
                  </Text>
                ),
                type: ElphiCellType.Element,
                maxWidth: "200px",
                minWidth: "200px"
              },
              {
                index: 5,
                data: (
                  <Box w="100%">
                    {rowData.aggregations?.TotalStatementQualifyingBalance && (
                      <TableInput
                        isAggregation={AggregationType.Aggregation}
                        specFields={getFocusedAggregationSpecs({
                          focused:
                            rowData.aggregations
                              ?.TotalStatementQualifyingBalance?.focused,
                          spec: partyFieldSpecs!.aggregations
                            ?.TotalStatementQualifyingBalance
                        })}
                        prefix={["parties", rowData.id]}
                        state={props.formBuilderState.state}
                        currentEntity={
                          props.formBuilderState.state.parties[rowData.id]
                        }
                        onChange={props.formBuilderState.onChange}
                      />
                    )}
                  </Box>
                ),
                type: ElphiCellType.Element,
                maxWidth: "400px",
                minWidth: "400px"
              },
              {
                index: 6,
                data: (
                  <Text align={"center"}>
                    {moneyFormatter.format.encode(
                      rowData.TotalApprovedLOC?.toString()
                    )}
                  </Text>
                ),
                type: ElphiCellType.Element,
                maxWidth: "150px",
                minWidth: "150px"
              },
              {
                index: 7,
                data: (
                  <Box w="100%">
                    <TableInput
                      isAggregation={AggregationType.Aggregation}
                      specFields={specFieldLOCExpirationDate}
                      prefix={["parties", rowData.id]}
                      state={props.formBuilderState.state}
                      currentEntity={
                        props.formBuilderState.state.parties[rowData.id]
                      }
                      onChange={props.formBuilderState.onChange}
                    />
                  </Box>
                ),
                type: ElphiCellType.Element,
                maxWidth: locExpirationDateStyles.maxWidth,
                minWidth: locExpirationDateStyles.minWidth
              },
              {
                index: 8,
                data: (
                  <Text align={"center"}>
                    {numberFormatter.format.encode(
                      rowData.NumberOfTransactions?.toString()
                    )}
                  </Text>
                ),
                type: ElphiCellType.Element,
                maxWidth: "150px",
                minWidth: "150px"
              },
              {
                index: 9,
                data: (
                  <Text align={"center"}>
                    {moneyFormatter.format.encode(
                      rowData.NumberOfFlips?.toString()
                    )}
                  </Text>
                ),
                type: ElphiCellType.Element,
                maxWidth: "150px",
                minWidth: "150px"
              }
            ],
            index: `${i}`,
            minHeight: "53px",
            maxHeight: "53px"
          };
        }
      ) || []
    );
  }, [props?.formBuilderState.state]);

  const partyTableHeader: ElphiTableProps["header"] = useMemo(() => {
    return [
      {
        index: 1,
        data: "Individual",
        type: ElphiCellType.String,
        maxWidth: "150px",
        minWidth: "150px"
      },
      {
        index: 2,
        data: "Ultimate Equitable Ownership",
        type: ElphiCellType.String,
        maxWidth: "150px",
        minWidth: "150px"
      },
      {
        index: 3,
        data: "Line of Credit (LOC) Status",
        type: ElphiCellType.String,
        maxWidth: "200px",
        minWidth: "200px"
      },
      {
        index: 4,
        data: "Short Term Tier",
        type: ElphiCellType.String,
        maxWidth: "200px",
        minWidth: "200px"
      },
      {
        index: 5,
        data: "Total Qualifying Balance",
        type: ElphiCellType.String,
        maxWidth: "400px",
        minWidth: "400px"
      },
      {
        index: 6,
        data: "Approved LOC",
        type: ElphiCellType.String,
        maxWidth: "150px",
        minWidth: "150px"
      },
      {
        index: 7,
        data: "LOC Expiration Date",
        type: ElphiCellType.String,
        maxWidth: locExpirationDateStyles.maxWidth,
        minWidth: locExpirationDateStyles.minWidth
      },
      {
        index: 8,
        data: "36Mo Transaction Count",
        type: ElphiCellType.String,
        maxWidth: "150px",
        minWidth: "150px"
      },
      {
        index: 9,
        data: "Number Of Flips",
        type: ElphiCellType.String,
        maxWidth: "150px",
        minWidth: "150px"
      }
    ];
  }, []);

  const tableProps: ElphiTableProps = useMemo(() => {
    const sumUEO = Object.entries(props?.formBuilderState.state.parties)
      .map(([_, party]) => party)
      .reduce((acc: string, party) => {
        return elphiDecimal(acc)
          .add(party.ultimateEquitableOwnership || 0)
          .toString();
      }, ZERO)
      .toString();
    return {
      header: partyTableHeader,
      rows: tableFullDataRows,
      aggregationFooter: (
        <AggregationFooter
          columns={[
            {
              columnType: AggregationColumnTypes.Empty
            },
            {
              columnType: AggregationColumnTypes.Aggregation,
              input: (
                <Box>
                  <Text>Sum Ultimate Equitable Ownership:</Text>
                  <Box>
                    <StyledInputBuilder
                      key={0}
                      isReadOnly={true}
                      formatter={percentFormatter}
                      currentValue={sumUEO}
                      onChange={() => {}}
                      fieldType={FieldType.Percentage}
                    />
                  </Box>
                </Box>
              )
            },
            {
              columnType: AggregationColumnTypes.Empty
            },
            {
              columnType: AggregationColumnTypes.Empty
            },
            {
              columnType: AggregationColumnTypes.Aggregation,
              input: (
                <Box>
                  <Text>Sum Total Qualifying Balance:</Text>
                  {(() => {
                    const parties = Object.entries(
                      props?.formBuilderState.state.parties
                    ).map(([_, party]) => party);
                    const sum = parties?.reduce((acc, party) => {
                      const { currentValue } = resolveFieldValue({
                        prefix: ["parties", party.id],
                        specFields: getFocusedAggregationSpecs({
                          focused:
                            props?.formBuilderState.state.parties[party.id]
                              .aggregations?.TotalStatementQualifyingBalance
                              ?.focused,
                          spec: partyFieldSpecs!.aggregations
                            ?.TotalStatementQualifyingBalance
                        }),
                        props: {
                          isAggregation: AggregationType.Aggregation
                        },
                        state: props.formBuilderState.state
                      });
                      return acc + +(currentValue || 0);
                    }, 0);
                    return (
                      <Box>
                        <StyledInputBuilder
                          key={0}
                          isReadOnly={true}
                          formatter={moneyFormatter}
                          currentValue={sum.toString()}
                          onChange={() => {}}
                          fieldType={FieldType.Money}
                        />
                      </Box>
                    );
                  })()}
                </Box>
              )
            },
            {
              columnType: AggregationColumnTypes.Aggregation,
              input: (
                <Box>
                  <Text>Sum Approved LOC:</Text>
                  {(() => {
                    const parties = Object.entries(
                      props?.formBuilderState.state.parties
                    ).map(([_, party]) => party);
                    const sum = parties?.reduce((acc, party) => {
                      return acc + +(party.TotalApprovedLOC || 0);
                    }, 0);
                    return (
                      <Box>
                        <StyledInputBuilder
                          key={0}
                          isReadOnly={true}
                          formatter={moneyFormatter}
                          currentValue={sum.toString()}
                          onChange={() => {}}
                          fieldType={FieldType.Money}
                        />
                      </Box>
                    );
                  })()}
                </Box>
              )
            },
            {
              columnType: AggregationColumnTypes.Empty
            }
          ]}
          width={"200px"}
        />
      ),
      isLoading: props.isLoading,
      rowsCount: 1
    };
  }, [tableFullDataRows]);

  const { heightOffsetInPx } = useWindowDimensions();
  const tableMaxHeight = heightOffsetInPx(350);
  return (
    <>
      <ElphiTable
        minHeight={TABLE_MIN_HEIGHT}
        maxHeight={tableMaxHeight}
        header={tableProps.header}
        rows={tableProps.rows}
        aggregationFooter={tableProps.aggregationFooter}
        isLoading={tableProps.isLoading}
        rowsCount={1}
      />
    </>
  );
};

export default PartyIndividualTable;
