import { FieldType, TaskInstruction } from "@elphi/types";
import { EMPTY, NOT_AVAILABLE } from "../../../constants/common";
import { useTaskInstructionHooks } from "../../../hooks/taskInstruction.hooks";
import { FuzzySearchSelect } from "../../search/fuzzy/FuzzySearchSelect";

const buildOption = (t: TaskInstruction) => ({
  label: t.name || NOT_AVAILABLE,
  value: t?.id
});

export const TaskInstructionSearch = (props: {
  onSelect?: (id: string) => void;
  selectedValue?: string;
  label?: string;
}) => {
  const { onSelect, selectedValue, label } = props;
  const {
    searchApi,
    searchResponse,
    taskInstructionState,
    setSelected,
    selectedInstruction
  } = useTaskInstructionHooks();
  const handleOnSelect = (id: string) => {
    onSelect ? onSelect(id) : setSelected(id);
  };

  const currentValue =
    selectedValue !== undefined
      ? selectedValue
      : selectedInstruction
      ? selectedInstruction.id
      : EMPTY;

  return (
    <FuzzySearchSelect
      label={label || "Search task instructions"}
      fieldType={FieldType.SingleSelect}
      searchApi={searchApi}
      searchResponse={searchResponse}
      state={taskInstructionState}
      currentValue={currentValue}
      configuration={{
        includeScore: true,
        threshold: 0.4,
        keys: ["name"],
        shouldSort: true
      }}
      buildOption={buildOption}
      onSelect={handleOnSelect}
    />
  );
};
