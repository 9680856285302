import { useCallbackRef } from "@chakra-ui/react";
import { Deal, DealPartyRelation, Party } from "@elphi/types";
import { RootState } from "../../../../redux/store";
// import { Buffer } from "buffer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppConfig } from "../../../../config/appConfig";
import useDealHooks from "../../../../hooks/deal.hooks";
import { useDealPartyRelationHooks } from "../../../../hooks/dealpartyrelation.hooks";
import { usePartyHooks } from "../../../../hooks/party.hooks";
import { usePartyUserRelationHooks } from "../../../../hooks/partyuser.hooks";
import { partyApi, partySlice } from "../../../../redux/v2/party";
import { getListFromDictionary } from "../../../../utils/batchUtils";
import { useFormBuilderStateHandler } from "../../../form-builder/InputBuilder";
import { useElphiToast } from "../../../toast/toast.hook";
import { usePartyTableHook } from "../../hooks/partyTable.hook";
import { PartyTableState } from "./PartyTable";

/**
 
 pagination cases
 user filter: paginate user 
    
    deal-party pagination find index by id

 */

export type PartyPaginationCursor = {
  partyCursor?: string;
  partyUserCursor?: string;
  partyDealCursor?: string;
};

export const usePartyPaginationHooks = (props: {
  userId?: string;
  partyId?: string;
  pageSize: number;
}) => {
  useEffect(() => {
    setLastCursor(undefined);
    setParsedCursor(undefined);
    setPrevCursor(undefined);
  }, [props.userId, props.partyId]);
  const { partyState } = usePartyHooks();
  const { dealState } = useDealHooks();
  const { dealPartyRelationState } = useDealPartyRelationHooks();
  const { partyUserRelationState } = usePartyUserRelationHooks();
  const [paginateApi, paginateApiResponse] = partyApi.useLazyPaginateV2Query();

  const [lastCursor, setLastCursor] = useState<string | undefined>(undefined);
  const [parsedCursor, setParsedCursor] = useState<
    PartyPaginationCursor | undefined
  >(undefined);
  const [_, setPrevCursor] = useState<string | undefined>(undefined);

  useEffect(() => {
    const responseNextCursor = paginateApiResponse.currentData?.nextCursor;
    const parsedCursorSrc = responseNextCursor
      ? JSON.parse(Buffer.from(responseNextCursor, "base64").toString())
      : undefined;
    setParsedCursor(parsedCursorSrc);

    if (
      responseNextCursor !== lastCursor &&
      paginateApiResponse.currentData?.hasMore
    ) {
      setLastCursor(responseNextCursor);
      setPrevCursor(lastCursor);
    }
  }, [paginateApiResponse.currentData?.nextCursor]);
  const nextPage = useCallbackRef(() => {
    return paginateApi(
      {
        limit: props.pageSize.toString(),
        cursor: lastCursor,
        userId: props.userId,
        partyId: props.partyId
      },
      true
    );
  }, [
    paginateApiResponse.currentData,
    // paginateApiResponse.currentData,
    props.userId,
    props.partyId,
    lastCursor,
    props.pageSize
  ]);

  return {
    partyState,
    dealState,
    dealPartyRelationState,
    partyUserRelationState,
    paginateApi,
    lastCursor,
    paginateApiResponse,
    nextPage,
    parsedCursor,
    // hasMore: !!paginateApiResponse.currentData?.hasMore
    hasMore: !!paginateApiResponse.currentData?.hasMore
  };
};

export const usePartyTableState = () => {
  const { errorToast, successToast } = useElphiToast();
  const { handleUpdatePartyBatch } = usePartyTableHook();
  const { updateBatch: updateDealBatch } = useDealHooks();
  const { updateBatch: updateDealPartyBatch } = useDealPartyRelationHooks();
  const dispatch = useDispatch();

  const handleUpdateDealPartyBatch = async (
    relations: Partial<PartyTableState>
  ) => {
    if (!relations.dealPartyRelations) return null;
    const listRelations = getListFromDictionary(relations?.dealPartyRelations);
    if (!listRelations.length) return null;
    const filteredRelations = listRelations.filter(
      (d): d is DealPartyRelation => !!d
    );
    return await updateDealPartyBatch({ relations: filteredRelations }).then(
      (r) => {
        if (r.status === 200) {
          successToast({
            title: "Deal Party Relations Updated",
            description: `total relations updated: ${listRelations.length}`
          });
        }
        r.status === 400 &&
          errorToast({
            title: "Failed to update batch",
            description: r.data.description
          });
        return r;
      }
    );
  };
  const handleUpdateDealBatch = async (
    tableState: Partial<PartyTableState>
  ) => {
    if (!tableState.deals) return null;
    const listDeals = getListFromDictionary(tableState?.deals);
    if (!listDeals.length) return null;
    const filteredDeals = listDeals.filter((d): d is Deal => !!d);
    return await updateDealBatch({ deals: filteredDeals }).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "Deals Updated",
          description: `total deals updated: ${listDeals.length}`
        });
      }
      r.status === 400 &&
        errorToast({
          title: "Failed to update batch",
          description: r.data.description
        });
      return r;
    });
  };

  const {
    onChange: dealPartyRelationOnChange,
    state: localDealPartyRelationState,
    syncState: syncDealPartyRelationState
  } = useFormBuilderStateHandler({
    initialState: {
      dealPartyRelations: {}
    } as { dealPartyRelations: { [id: string]: DealPartyRelation } },
    callback: handleUpdateDealPartyBatch,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  const {
    state: localPartyState,
    onChange: partyOnChange,
    syncState: syncPartyState
  } = useFormBuilderStateHandler({
    initialState: { parties: {} } as { parties: { [id: string]: Party } },
    callback: handleUpdatePartyBatch,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  const {
    state: localDealState,
    syncState: syncDealState,
    onChange: dealOnChange
  } = useFormBuilderStateHandler({
    initialState: { deals: {} } as { deals: { [id: string]: Deal } },
    callback: handleUpdateDealBatch,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  const setCurrentCursorHasMore = (hasMore: boolean) => {
    dispatch(partySlice.actions.setPartyTableCurrentCursorIdHasMore(hasMore));
  };
  const setCurrentCursorId = (cursorId: string) => {
    dispatch(partySlice.actions.setPartyTableCurrentCursorId({ id: cursorId }));
  };
  const currentCursorIdHasMore = useSelector((state: RootState) =>
    state.party?.partyTable
      ? state.party.partyTable.cursor.hasMore[
          state.party.partyTable.cursor.currentId
        ]
      : false
  );

  return {
    setCurrentCursorHasMore,
    setCurrentCursorId,
    currentCursorIdHasMore,
    localDealState,
    localPartyState,
    localDealPartyRelationState,
    partyOnChange,
    dealPartyRelationOnChange,
    dealOnChange,
    syncDealState,
    syncDealPartyRelationState,
    syncPartyState
  };
};

export const usePartyTableReduxState = () => {
  const { partyState, setPartyTableCursor, getPartyTableCursor } =
    usePartyHooks();
  const { dealState } = useDealHooks();
  const { dealPartyRelationState } = useDealPartyRelationHooks();
  const { partyUserRelationState } = usePartyUserRelationHooks();

  return {
    dealPartyRelationState,
    partyUserRelationState,
    dealState,
    partyState,
    setPartyTableCursor,
    getPartyTableCursor
  };
};
