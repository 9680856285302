import { Box } from "@chakra-ui/react";
import { Property, PropertyFields } from "@elphi/types";
import { useEffect } from "react";
import { AppConfig } from "../../config/appConfig";
// import { usePropertyHooks } from "../../hooks/property.hooks";
import { usePropertyFormHandler } from "../../hooks/propertyform.hooks";
import { NavigationPath } from "../../shared/types/navigation.types";
import { applicationConstructionManagementSections } from "../application/sections/constructionManagement.sections";
import FormBuilder, { OnChangeInput } from "../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";

const ConstructionManagementSections = (props: {
  selectedProperty?: Property;
  navigationPath?: NavigationPath;
}) => {
  const { selectedProperty, navigationPath } = props;
  const { updatePropertyHandler } = usePropertyFormHandler();

  const { onChange, state, syncState } = useFormBuilderStateHandler({
    initialState: { properties: {} },
    callback: updatePropertyHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  useEffect(() => {
    if (selectedProperty) {
      syncState({
        shouldSync: !!selectedProperty,
        state: selectedProperty,
        statePath: () => {
          if (selectedProperty) {
            return ["properties", selectedProperty.id];
          }
        }
      });
    }
  }, [selectedProperty]);

  const propertyState =
    ((selectedProperty &&
      state.properties[selectedProperty.id]) as PropertyFields) ||
    ({} as PropertyFields);

  const propertyOnChangeBatch = (v: OnChangeInput) => {
    selectedProperty &&
      onChange({
        fieldType: v.fieldType,
        fieldKey: ["properties", selectedProperty.id, ...v.fieldKey],
        value: v.value
      });
  };

  return (
    <Box h={`calc(100%)`} overflow="scroll">
      {selectedProperty && (
        <Box h="100%">
          <Box bgColor="white">
            <FormBuilder
              customKey="applicationConstructionManagementSections"
              onChange={propertyOnChangeBatch}
              sections={applicationConstructionManagementSections({
                state: propertyState,
                onChange: propertyOnChangeBatch
              })}
              navigationPath={navigationPath}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default ConstructionManagementSections;
