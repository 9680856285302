import { AddIcon, CopyIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, HStack, useBoolean } from "@chakra-ui/react";
import { FieldType, Organization } from "@elphi/types";
import { useEffect, useState } from "react";
import elphiTheme from "../../../../assets/themes/elphi.theme.default";
import { EMPTY } from "../../../../constants/common";
import { auth } from "../../../../firebase/firebaseConfig";
import { useAuthStateChangeHook } from "../../../../hooks/authStateChange.hooks";
import { useOrgHooks } from "../../../../hooks/org.hooks";
import useWindowDimensions from "../../../../hooks/windowDimensions";
import { useRTKPagination } from "../../../../redux/v2/hooks";
import { orgApi } from "../../../../redux/v2/organization/service";
import { ElphiPaginationList } from "../../../elphi-list/ElphiList";
import { useFormBuilderStateHandler } from "../../../form-builder/InputBuilder";
import { OrganizationSearch } from "./OrganizationSearch";
import { CloneConfigurationModal } from "./modal/CloneConfigurationModal";
import {
  CreateOrganizationModal,
  UpdateOrganizationModal
} from "./modal/OrganizationModal";
import { OrganizationTableHeader } from "./table/Header";
import { OrgRow } from "./table/Row";
import { buildDefaultOption } from "./utils/Organization.utils";

const PAGINATION_LIMIT = 20;

export const OrganizationPage = () => {
  const { orgState } = useOrgHooks();
  const { heightOffsetInPx } = useWindowDimensions();
  const [showCreateModal, { on: onOpenCreateModal, off: onCloseCreateModal }] =
    useBoolean();
  const [showEditModal, { on: onEdit, off: offEdit }] = useBoolean();
  const [showCloneModal, { on: onOpenCloneModal, off: onCloseCloneModal }] =
    useBoolean();
  const [selectedOrgId, setSelectedOrgId] = useState<string>();
  const [selectedSearchedOrgId, setSelectedSearchedOrgId] = useState<string>();

  const { syncState } = useFormBuilderStateHandler<{
    [id: string]: Partial<Organization>;
  }>({
    initialState: {}
  });

  const paginationHandler = useRTKPagination({
    entityState: orgState,
    useLazyPaginateQuery: orgApi.useLazyPaginateQuery,
    options: {
      limit: PAGINATION_LIMIT
    }
  });

  useAuthStateChangeHook({
    onAuthStateChange: (_) => {
      if (auth.currentUser) {
        paginationHandler.next();
      }
    }
  });

  useEffect(() => {
    if (auth.currentUser && orgState?.ids.length <= PAGINATION_LIMIT) {
      paginationHandler.next();
    }
  }, []);

  return (
    <Box overflow={"scroll"}>
      <Flex justifyContent="space-between" wrap={"wrap"}>
        <Box width={"300px"}>
          <OrganizationSearch
            fieldType={FieldType.SingleSelect}
            currentValue={selectedSearchedOrgId || EMPTY}
            onSelect={(o) => setSelectedSearchedOrgId(o)}
            buildOption={buildDefaultOption}
          />
        </Box>
        <HStack spacing={2} wrap="wrap">
          <Box p="10px">
            <Button
              aria-label="add-org-btn"
              onClick={onOpenCreateModal}
              leftIcon={<AddIcon />}
              {...elphiTheme.components.light.button.primary}
            >
              Add Organization
            </Button>
          </Box>
          <Box p="10px">
            <Button
              aria-label="clone-conf-btn"
              onClick={onOpenCloneModal}
              leftIcon={<CopyIcon />}
              {...elphiTheme.components.light.button.primary}
            >
              Clone Configuration
            </Button>
          </Box>
        </HStack>
      </Flex>
      <ElphiPaginationList
        tableName={"Organization"}
        header={<OrganizationTableHeader />}
        height={heightOffsetInPx(320)}
        next={paginationHandler.next}
        hasMore={paginationHandler.pageData?.ids?.length <= PAGINATION_LIMIT}
        items={!selectedSearchedOrgId ? orgState.ids : [selectedSearchedOrgId]}
        pageSize={PAGINATION_LIMIT}
        rowBuilder={(orgId, index) => {
          return (
            <OrgRow
              orgId={orgId?.toString() || ""}
              index={index}
              syncState={syncState}
              onEdit={(o) => {
                setSelectedOrgId(o);
                onEdit();
              }}
              key={index}
              orgState={orgState}
            />
          );
        }}
      />
      <CloneConfigurationModal
        show={showCloneModal}
        onClose={onCloseCloneModal}
      />
      <CreateOrganizationModal
        show={showCreateModal}
        onClose={onCloseCreateModal}
      />
      {selectedOrgId && (
        <UpdateOrganizationModal
          show={showEditModal}
          onClose={offEdit}
          orgId={selectedOrgId}
          key={selectedOrgId}
        />
      )}
    </Box>
  );
};

export default OrganizationPage;
