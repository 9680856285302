import { CreditScore } from "../../entities/creditReport.types";
import { DotNestedKeys, Flatten } from "../../utils/flatten";
import { CreditReportPOSTV2 } from "./creditReportRequest.types";
export type CreditScoreGenericResponse = {
  date: string;
  Score: string; // LimaOne has this capitalized
};

export type CreditScoreResponse = {
  creditScores: {
    Experian: CreditScoreGenericResponse;
    Equifax: CreditScoreGenericResponse;
    Transun: CreditScoreGenericResponse;
  };
};

export type DatabaseFormatCreditScoreMap = Partial<
  Flatten<
    CreditScoreResponse,
    DotNestedKeys<Pick<CreditScore, "equifax" | "experian" | "transunion">>
  >
>;
export const databaseFormatCreditScoreMap: DatabaseFormatCreditScoreMap = {
  "creditScores.Equifax.Score": "equifax.score.thirdParty",
  "creditScores.Equifax.date": "equifax.date.thirdParty",
  "creditScores.Experian.Score": "experian.score.thirdParty",
  "creditScores.Experian.date": "experian.date.thirdParty",
  "creditScores.Transun.Score": "transunion.score.thirdParty",
  "creditScores.Transun.date": "transunion.date.thirdParty"
};

export const plusReplaceList: DotNestedKeys<
  Pick<CreditScore, "equifax" | "experian" | "transunion">
>[] = [
  "experian.score.thirdParty",
  "transunion.score.thirdParty",
  "equifax.score.thirdParty"
];

export type CreditScoreMappingType =
  | "string"
  | "boolean"
  | "pullType"
  | "identifier";
export type CreditScoreMappingObject = {
  path: Partial<DotNestedKeys<CreditScore>>;
  type: CreditScoreMappingType;
};
export const creditScorePathMapping: {
  [key in DotNestedKeys<CreditReportPOSTV2>]?: CreditScoreMappingObject;
} = {
  loanIdentifier: { path: "loanIdentifier", type: "string" },
  loanIdentifierType: { path: "loanIdentifierType", type: "string" },
  loanOriginationSystemName: {
    path: "loanOriginationSystemName",
    type: "string"
  },
  partyRoleType: { path: "partyRoleType", type: "string" },
  creditReportRequestActionType: {
    path: "creditReportRequestActionType",
    type: "string"
  },
  creditReportType: { path: "creditReportType", type: "string" },
  creditReportTypeOtherDescription: {
    path: "creditReportTypeOtherDescription",
    type: "string"
  },
  reportingInformationIdentifier: {
    path: "reportingInformationIdentifier",
    type: "string"
  },
  reportingInformationName: {
    path: "reportingInformationName",
    type: "string"
  },
  "CreditReports.includeEquifax": {
    path: "includeEquifaxIndicator",
    type: "boolean"
  },
  "CreditReports.includeExperian": {
    path: "includeExperianIndicator",
    type: "boolean"
  },
  "CreditReports.includeTransUnion": {
    path: "includeTransunionIndicator",
    type: "boolean"
  },
  SoftPull: { path: "pullType", type: "pullType" },
  creditReportIdentifier: { path: "creditReportIdentifier", type: "identifier" }
};
export const creditScorePathBooleanMapping: {
  [key in DotNestedKeys<CreditReportPOSTV2>]?: DotNestedKeys<CreditScore>;
} = {
  "CreditReports.includeEquifax": "includeEquifaxIndicator",
  "CreditReports.includeExperian": "includeExperianIndicator",
  "CreditReports.includeTransUnion": "includeTransunionIndicator"
};
