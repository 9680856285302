import { PartyUser } from "@elphi/types";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RTKResponse } from "../apis/rtk/response.types";
import { RootState } from "../redux/store";
import { partyUserRelationApi } from "../redux/v2/party-user-relation/partyUserRelation.service";
import { partyUserRelationSlice } from "../redux/v2/party-user-relation/partyUserRelation.slice";

export const usePartyUserRelationHooks = () => {
  const dispatcher = useDispatch();
  const [createBatchApi] = partyUserRelationApi.useBatchCreateMutation();
  const [setBatchApi] = partyUserRelationApi.useBatchSetMutation();
  const [updateBatchApi] = partyUserRelationApi.useBatchUpdateMutation();
  const [deleteBatchApi] = partyUserRelationApi.useBatchDeleteMutation();
  const [getPartyUserRelation] = partyUserRelationApi.useLazyGetQuery();
  const [updatePartyUserRelationApi] = partyUserRelationApi.useUpdateMutation();
  const [deletePartyUserRelationApi, deletePartyUserRelationResponse] =
    partyUserRelationApi.useDeleteMutation();

  const [createPartyUserRelationApi, createPartyUserRelationApiResponse] =
    partyUserRelationApi.useCreateMutation();

  const [useAddUserAssignmentApi, useAddUserAssignmentResponse] =
    partyUserRelationApi.useAddUserAssignmentMutation();

  const [useRemoveUserAssignmentApi, useRemoveUserAssignmentResponse] =
    partyUserRelationApi.useRemoveUserAssignmentMutation();

  const partyUserRelationState = useSelector(
    (state: RootState) => state.partyUserRelation
  );

  const createPartyUserRelation = async (newPartyUserRelation: PartyUser) => {
    return await createPartyUserRelationApi(newPartyUserRelation).then(
      (response) =>
        responseHandler(
          response as RTKResponse<{
            entities: Array<{ collection: string; id: string }>;
            relation: { collection: string; id: string };
          }>
        )
    );
  };

  const updatePartyUserRelation = async (
    updatePartyUserRelation: { id: string } & Partial<PartyUser>
  ) => {
    return await updatePartyUserRelationApi(updatePartyUserRelation).then(
      responseHandler
    );
  };

  const deletePartyUserRelation = async (r: string) => {
    const response = await deletePartyUserRelationApi(r).then(responseHandler);
    if (response.status === 200) {
      dispatcher(partyUserRelationSlice.actions.remove(r));
    }
    return response;
  };

  const createBatch = async (r: {
    relations: ({ id: string } & Partial<PartyUser>)[];
  }) => {
    const response = await createBatchApi(r);
    return responseHandler(
      response as RTKResponse<{
        batch: string[];
      }>
    );
  };

  const setBatch = async (r: {
    relations: ({ id: string } & Partial<PartyUser>)[];
  }) => {
    const response = await setBatchApi(r);
    return responseHandler(
      response as RTKResponse<{
        batch: string[];
      }>
    );
  };

  const updateBatch = async (r: {
    relations: ({ id: string } & Partial<PartyUser>)[];
  }) => {
    const response = await updateBatchApi(r);
    return responseHandler(
      response as RTKResponse<{
        batch: string[];
      }>
    );
  };

  const deleteBatch = async (r: { relations: string[] }) => {
    const response = await deleteBatchApi(r);
    return responseHandler(
      response as RTKResponse<{
        batch: string[];
      }>
    );
  };

  const addUserAssignment = async (r: {
    partyId: string;
    partyUserIds: string[];
  }) => {
    const response = await useAddUserAssignmentApi(r);
    return responseHandler(
      response as RTKResponse<{
        ids: string[];
      }>
    );
  };

  const removeUserAssignment = async (r: {
    partyId: string;
    partyUserIds: string[];
  }) => {
    const response = await useRemoveUserAssignmentApi(r);
    return responseHandler(
      response as RTKResponse<{
        ids: string[];
      }>
    );
  };

  return {
    getPartyUserRelation,
    createPartyUserRelation,
    createPartyUserRelationApiResponse,
    updatePartyUserRelation,
    deletePartyUserRelation,
    deletePartyUserRelationResponse,
    partyUserRelationState,
    createBatch,
    updateBatch,
    deleteBatch,
    setBatch,
    addUserAssignment,
    useAddUserAssignmentResponse,
    removeUserAssignment,
    useRemoveUserAssignmentResponse
  };
};
