import { DocumentRuleTemplate } from "@elphi/types";
import { useDocumentRuleHooks } from "../../../hooks/documentRule.hooks";
import { SearchHandler } from "../../search/SearchHandler";
import { SearchComponentProps } from "../../SearchComponent";

const buildOption = (documentRule: DocumentRuleTemplate) => {
  const label = `${documentRule.ruleName}`;
  const value = documentRule.id;
  return {
    label,
    value
  };
};

const DocumentRuleSearch = (
  props: {
    filter?: (a: DocumentRuleTemplate) => boolean;
  } & Pick<
    SearchComponentProps,
    | "onSelect"
    | "currentValue"
    | "label"
    | "labelPosition"
    | "isReadOnly"
    | "isDisabled"
  > &
    Pick<Partial<SearchComponentProps>, "fieldType">
) => {
  const {
    searchDocumentRuleApi,
    rankedSort,
    documentRuleState,
    searchResponse
  } = useDocumentRuleHooks();

  return (
    <SearchHandler
      {...props}
      searchApi={searchDocumentRuleApi}
      searchResponse={searchResponse}
      rankedSort={rankedSort}
      state={documentRuleState}
      buildOption={buildOption}
    />
  );
};

export default DocumentRuleSearch;
