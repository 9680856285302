import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { TaskConfiguration } from "@elphi/types";
import { responseHandler } from "../../../apis/rtk/response.handler";
import elphiTheme from "../../../assets/themes/elphi.theme.default";
import { useTaskConfigurationHooks } from "../../../hooks/taskConfiguration.hooks";

import { taskConfigurationApi } from "../../../redux/v2/task-configuration/taskConfiguration.service";
import { FieldType } from "../../form-builder/fieldFormat.types";
import FormBuilder from "../../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../../form-builder/InputBuilder";
import { useElphiToast } from "../../toast/toast.hook";
const CreateTaskConfigurationModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [createConfigurationApi, createConfigurationResponse] =
    taskConfigurationApi.useCreateMutation();
  const { selectedTaskConfiguration, setSelectedTaskConfiguration } =
    useTaskConfigurationHooks();
  const { onChange, state } = useFormBuilderStateHandler({
    initialState: {
      name: "",
      status: "disabled"
    } as TaskConfiguration
  });

  const toastHandler = (
    d:
      | {
          status: 400;
          data: {
            error: any;
            description: string;
          };
        }
      | {
          status: 200;
          data: {
            id: string;
          };
        }
  ) => {
    if (d.status === 200) {
      successToast({
        title: "New Configuration Created",
        description: `task configuration: ${d.data.id}`
      });

      onClose();
    } else if (d.status === 400) {
      errorToast({
        title: "Failed to create configuration",
        description: d?.data?.description || "something went wrong"
      });
    }
  };
  const { successToast, errorToast } = useElphiToast();
  const createConfigurationHandler = () => {
    createConfigurationApi({
      name: state.name,
      status: state.status
    } as TaskConfiguration)
      .then(responseHandler)
      .then((r) => {
        if (r.status === 200) {
          setSelectedTaskConfiguration(r.data.id);
        }
        return r;
      })
      .then(toastHandler);
  };
  const cloneConfigurationHandler = () => {
    if (!selectedTaskConfiguration) return;
    createConfigurationApi({
      ...selectedTaskConfiguration,
      status: state.status,
      name: state.name
    } as TaskConfiguration)
      .then(responseHandler)
      .then((r) => {
        if (r.status === 200) {
          setSelectedTaskConfiguration(r.data.id);
        }
        return r;
      })
      .then(toastHandler);
  };
  return (
    <Box>
      <Box>
        <Button
          float={"right"}
          {...elphiTheme.components.light.button.primary}
          onClick={onOpen}
        >
          Create Configuration
        </Button>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"} width="100%">
          <ModalHeader>Task Configuration</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedTaskConfiguration && (
              <Text>Cloning: {selectedTaskConfiguration.name}</Text>
            )}
            <FormBuilder
              customKey="createConfigurationForm"
              elphiView="form"
              onChange={onChange}
              sections={[
                {
                  inputs: [
                    {
                      label: "name",
                      labelPosition: "up",
                      fieldType: FieldType.String,
                      fieldKey: ["name"],
                      currentValue: state.name,
                      isValid: state.name > ""
                    }
                  ]
                }
              ]}
            />
            <Flex float="right">
              <Box p="5px">
                <Button
                  isLoading={createConfigurationResponse.isLoading}
                  onClick={cloneConfigurationHandler}
                  isDisabled={!state.name || !selectedTaskConfiguration}
                  float="right"
                  {...elphiTheme.components.light.button.secondary}
                >
                  Clone
                </Button>
              </Box>
              <Box p="5px">
                <Button
                  isLoading={createConfigurationResponse.isLoading}
                  onClick={createConfigurationHandler}
                  isDisabled={!!selectedTaskConfiguration || !state.name}
                  float="right"
                  {...elphiTheme.components.light.button.primary}
                >
                  Submit New
                </Button>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CreateTaskConfigurationModal;
