import { Box } from "@chakra-ui/react";
import { elphiDate } from "@elphi/utils";
import { first, isNumber, last } from "lodash";
import { useEffect, useState } from "react";
import { Month } from "./Month";
import { CalendarDayData, useCalendarHooks } from "./calendar.hooks";

export type DateRange = {
  startDate?: Date;
  endDate?: Date;
};

export type CalendarProps = {
  height?: number;
  startWeekInMonday?: boolean;
  onHoverDate?: (date: Date) => void;
  onInitMonth?: ({ startDate, endDate }: DateRange) => void;
  showDayToolTipOnHover?: boolean;
  DayToolTipLabelComponent?: React.ComponentType<{ currentDay: Date }>;
  BodyComponent?: React.ComponentType<{ currentDay: Date }>;
  FooterComponent?: React.ComponentType<{ currentDay: Date }>;
  selectedMonth?: number;
  selectedYear?: number;
};

export const Calendar = ({
  onHoverDate,
  onInitMonth,
  selectedMonth,
  selectedYear = new Date().getFullYear(),
  startWeekInMonday,
  ...props
}: CalendarProps) => {
  const [today, setToday] = useState(elphiDate().toDate());
  const [days, setDays] = useState<CalendarDayData[]>([]);
  const { generateDates } = useCalendarHooks();
  const elphiToday = elphiDate(today);

  useEffect(() => {
    isNumber(selectedMonth) &&
      setToday(elphiToday.month(selectedMonth).year(selectedYear).toDate());

    const currentDays = generateDates(
      selectedMonth,
      selectedYear,
      startWeekInMonday
    );
    onInitMonth?.({
      startDate: first(currentDays)?.date,
      endDate: last(currentDays)?.date
    });
    setDays(currentDays);
  }, [selectedMonth, selectedYear]);

  return (
    <Box pos={"relative"} padding={"8px"}>
      <Month
        {...props}
        days={days}
        startWeekInMonday={startWeekInMonday}
        onHoverDate={(date) => onHoverDate?.(date)}
      />
    </Box>
  );
};
