import { useEffect } from "react";
import { useAuditEventGroupFilterHooks } from "../../../hooks/auditEventGroup.hooks";
import { useFieldGroupFilterHooks } from "../../../hooks/fieldGroup.hooks";
import { useNotificationConfigurationFilterHooks } from "../../../hooks/notificationConfiguration.hooks";
import { useRoleGroupFilterHooks } from "../../../hooks/roleGroup.hooks";
import { AuditEventGroup } from "../../audit-event-group/AuditEventGroup";
import { FieldGroups } from "../../field-group/FieldGroup";
import { RoleGroup } from "../../role-group/RoleGroup";
import { TabsContainer } from "../../tabs-container/TabsContainer";
import { NotificationConfiguration } from "./NotificationConfiguration";

export const NotificationManagementPage = () => {
  const { setCurrentFilter: setNotificationFilters } =
    useNotificationConfigurationFilterHooks();
  const { setCurrentFilter: setAuditEventGroupFilters } =
    useAuditEventGroupFilterHooks();
  const { setCurrentFilter: setFieldGroupsFilters } =
    useFieldGroupFilterHooks();
  const { setCurrentFilter: setRoleGroupFilters } = useRoleGroupFilterHooks();

  useEffect(() => {
    return () => {
      const resetFilters = {
        values: {
          name: [],
          status: []
        }
      };
      setNotificationFilters(resetFilters);
      setAuditEventGroupFilters(resetFilters);
      setRoleGroupFilters(resetFilters);
      setFieldGroupsFilters(resetFilters);
    };
  }, []);

  return (
    <TabsContainer
      tabs={[
        {
          label: "Notification Configurations",
          page: <NotificationConfiguration />
        },
        { label: "Role Groups", page: <RoleGroup /> },
        { label: "Audit Event Groups", page: <AuditEventGroup /> },
        { label: "Field Groups", page: <FieldGroups /> }
      ]}
    />
  );
};

export default NotificationManagementPage;
