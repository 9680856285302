import { basePropertyInsurancePolicyFieldSpecs } from "../../../../../form-builder/field-specs/insurance-policy/property";
import {
  dateValidation,
  moneyValidation,
  stringValidation
} from "../../../../../utils/validationUtils";
import { buildInput } from "../utils/formUtils";

export const getPolicySectionInputs = (): ReturnType<typeof buildInput>[] => {
  const policyNumber = buildInput({
    fieldSpec: basePropertyInsurancePolicyFieldSpecs.policyNumber,
    validation: stringValidation,
    isRequired: true
  });
  const coverageTypes = buildInput({
    fieldSpec: basePropertyInsurancePolicyFieldSpecs.coverageTypes,
    validation: stringValidation,
    isRequired: true
  });
  const insuranceCarrier = buildInput({
    fieldSpec: basePropertyInsurancePolicyFieldSpecs.insuranceCarrier,
    validation: stringValidation
  });
  const effectiveDate = buildInput({
    fieldSpec: basePropertyInsurancePolicyFieldSpecs.effectiveDate,
    validation: dateValidation
  });
  const expirationDate = buildInput({
    fieldSpec: basePropertyInsurancePolicyFieldSpecs.expirationDate,
    validation: dateValidation
  });
  const nextPaymentDueDate = buildInput({
    fieldSpec: basePropertyInsurancePolicyFieldSpecs.nextPaymentDueDate,
    validation: dateValidation
  });
  const policyDeductibleAmount = buildInput({
    fieldSpec: basePropertyInsurancePolicyFieldSpecs.policyDeductibleAmount,
    validation: moneyValidation
  });
  const outstandingPremiumStatus = buildInput({
    fieldSpec: basePropertyInsurancePolicyFieldSpecs.outstandingPremiumStatus,
    validation: stringValidation
  });
  const outstandingPremiumAmount = buildInput({
    fieldSpec: basePropertyInsurancePolicyFieldSpecs.outstandingPremiumAmount,
    validation: stringValidation
  });
  const insurancePremiumPaymentAmount = buildInput({
    fieldSpec:
      basePropertyInsurancePolicyFieldSpecs.insurancePremiumPaymentAmount,
    validation: moneyValidation
  });

  return [
    policyNumber,
    coverageTypes,
    insuranceCarrier,
    effectiveDate,
    expirationDate,
    nextPaymentDueDate,
    policyDeductibleAmount,
    outstandingPremiumStatus,
    outstandingPremiumAmount,
    insurancePremiumPaymentAmount
  ];
};
