import {
  AggregationType,
  IntegrationType,
  Property,
  RentalDataVendorType
} from "@elphi/types";
import { OnChangeInput, Section } from "../../../../form-builder/FormBuilder";
import { propertySpecFields } from "../../../../form-builder/field-specs/property/property.fields";
import { buildInputs } from "../../../../form-builder/formBuilder.utils";
import { stringValidation } from "../../../../utils/validationUtils";
import {
  propertyRentalDataRentRangeErrorMessageFieldPath,
  propertyRentalDataRentRangeOrderStatusFieldPath
} from "../../../field-paths/property.fieldPaths";

export const rentRangeStatusSection = (
  state: Partial<Property>,
  onChange: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Integration Rental Data: Order Status",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: propertySpecFields,
      specs: [
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[IntegrationType.RentalData]?.[
              RentalDataVendorType.RentRange
            ]?.orderStatus?.focused !== "override",
          path: propertyRentalDataRentRangeOrderStatusFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[IntegrationType.RentalData]?.[
              RentalDataVendorType.RentRange
            ]?.errorMessage?.focused !== "override",
          path: propertyRentalDataRentRangeErrorMessageFieldPath,
          validation: stringValidation
        }
      ]
    })
  };
};
