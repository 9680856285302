import { orgConfigDependenciesMap } from "@elphi/scopes/src/organization/validators/configDependencies.utils";
import { CloneConfigurationType, FieldType } from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { concat, uniq } from "lodash";
import { useEffect, useState } from "react";
import { ChecklistInputBuilder } from "../../../../task/export-checklist/ChecklistInputBuilder";
import { createOptionsFromEnum } from "../../../../utils/formUtils";

export type CloneConfigurationCheckboxProps = {
  isDisabled: boolean;
  onChangeSelectConfiguration: (v: CloneConfigurationType[]) => void;
  selectConfiguration: CloneConfigurationType[];
};

export const CloneConfigurationCheckbox = (
  props: CloneConfigurationCheckboxProps
) => {
  const [disabledOptions, setDisabledOptions] = useState<
    CloneConfigurationType[]
  >([]);

  const onSelectConfiguration = (v?: CloneConfigurationType[]) => {
    const dependentConfigurations =
      v
        ?.map((config) => orgConfigDependenciesMap[config])
        .flat()
        .filter(removeEmpty) || [];

    const uniqueValues = uniq(concat(v || [], dependentConfigurations));
    props.onChangeSelectConfiguration(uniqueValues);
    setDisabledOptions(dependentConfigurations);
  };

  useEffect(() => {
    onSelectConfiguration(props.selectConfiguration);
  }, []);

  const selectConfigurationOptions = createOptionsFromEnum(
    CloneConfigurationType
  )
    .map((x) => {
      const isDisabled = disabledOptions.includes(
        x.value as CloneConfigurationType
      );
      return {
        ...x,
        isDisabled,
        tooltipText: isDisabled
          ? `${x.label} is required for your selected Configurations`
          : undefined
      };
    })
    .filter((x) => x.label && x.value);

  return (
    <ChecklistInputBuilder
      isDisabled={props.isDisabled}
      title={"Select Configuration"}
      fieldType={FieldType.MultiCheckbox}
      onSetState={onSelectConfiguration}
      options={selectConfigurationOptions}
      selectData={props.selectConfiguration}
      headerStyle={{
        paddingLeft: "5px"
      }}
    />
  );
};
