import { Box, useDisclosure } from "@chakra-ui/react";
import { FieldGroup } from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { uniq } from "lodash";
import { useEffect, useState } from "react";
import { AppConfig } from "../../../config/appConfig";
import { EMPTY } from "../../../constants/common";
import { auth } from "../../../firebase/firebaseConfig";
import { useAuthStateChangeHook } from "../../../hooks/authStateChange.hooks";
import {
  LIMIT,
  useFieldGroupHooks,
  useFieldGroupTableHooks
} from "../../../hooks/fieldGroup.hooks";
import useWindowDimensions from "../../../hooks/windowDimensions";
import { baseCellStyle } from "../../custom/chakra/Cell";
import { GroupModal } from "../../custom/modal/GroupModal";
import { ElphiPaginationList } from "../../elphi-list/ElphiList";
import { useFormBuilderStateHandler } from "../../form-builder/InputBuilder";
import { ElphiCellType, ElphiTableProps } from "../../table/table.types";
import {
  SIZE_FIELD,
  TableRowSizeComponent
} from "../../table/TableRowSizeComponent";
import { FieldGroupSelectionModal } from "../modal/FieldGroupSelectionModal";
import { FieldGroupRow } from "./FieldGroupRow";

export const FieldGroupTable = () => {
  const { heightOffsetInPx } = useWindowDimensions();
  const { isOpen: isOpenCloneModal, onToggle: onToggleCloneModal } =
    useDisclosure();
  const { isOpen: isOpenSelectionModal, onToggle: onToggleSelectionModal } =
    useDisclosure();
  const {
    fieldGroupState,
    updateGroupBatch,
    cloneGroup,
    createResponse,
    getTaskConfigurationBatchApi,
    getTaskConfigurationBatchResponse
  } = useFieldGroupHooks();
  const { nextPage, paginateApiResponse, filters, currentCombination } =
    useFieldGroupTableHooks();
  const [selectedRow, setSelectedRow] = useState<FieldGroup>();

  useAuthStateChangeHook({
    onAuthStateChange: (_) => {
      if (auth.currentUser) {
        nextPage();
      }
    },
    deps: [filters]
  });

  useEffect(() => {
    const selected = fieldGroupState.entities[selectedRow?.id || EMPTY];
    if (selected) {
      setSelectedRow(selected);
    }
  }, [fieldGroupState.entities[selectedRow?.id || EMPTY]]);

  useEffect(() => {
    if (auth.currentUser) {
      const taskConfigurationIds = uniq(
        paginateApiResponse.currentData?.page
          .map((x) => x?.taskConfigurationIds)
          .filter(removeEmpty)
          .flat()
      );
      taskConfigurationIds.length &&
        getTaskConfigurationBatchApi(taskConfigurationIds);
    }
  }, [auth.currentUser, paginateApiResponse.currentData?.page]);

  const { syncState, state, onChange } = useFormBuilderStateHandler<{
    [id: string]: Partial<FieldGroup>;
  }>({
    initialState: {},
    callback: updateGroupBatch,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  const handleOnCloneClick = (id: string) => {
    setSelectedRow(fieldGroupState.entities[id]);
    onToggleCloneModal();
  };
  const handleOnConfigureFieldsClick = (id: string) => {
    setSelectedRow(fieldGroupState.entities[id]);
    onToggleSelectionModal();
  };

  return (
    <>
      <ElphiPaginationList
        height={heightOffsetInPx(320)}
        tableName={"field-groups"}
        isLoading={paginateApiResponse.isLoading}
        rowStyle={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none"
        }}
        header={
          <TableRowSizeComponent
            bgColor="gray.100"
            row={headerCells}
            withBorderRightWidth={false}
            justify="space-between"
          />
        }
        pageSize={LIMIT}
        items={
          !fieldGroupState.selectedId
            ? currentCombination.ids
            : [fieldGroupState.selectedId]
        }
        next={nextPage}
        hasMore={paginateApiResponse.currentData?.page?.length === LIMIT}
        options={{
          showEndMessage: false,
          showWatching: false,
          showLoader: true
        }}
        rowBuilder={(id, index) => {
          const data = fieldGroupState.entities[id];
          if (!data) {
            return <></>;
          }
          return (
            <FieldGroupRow
              index={Number(index) + 1}
              data={data}
              onChange={onChange}
              syncState={syncState}
              state={state}
              onClone={handleOnCloneClick}
              onConfigureFields={handleOnConfigureFieldsClick}
              isLoadingTaskConfiguration={
                getTaskConfigurationBatchResponse.isLoading
              }
            />
          );
        }}
      />
      {selectedRow && (
        <GroupModal
          key={`clone-${selectedRow.id}-${isOpenCloneModal}`}
          header={"Clone field group"}
          action={"clone"}
          isShow={isOpenCloneModal}
          onClose={onToggleCloneModal}
          isLoading={createResponse.isLoading}
          onSubmit={cloneGroup}
          cloneGroup={selectedRow}
        >
          <Box as="p">{`Clone ${selectedRow.id} - ${selectedRow.name}`}</Box>
        </GroupModal>
      )}
      {selectedRow && (
        <FieldGroupSelectionModal
          key={`selection-${selectedRow.id}-${isOpenSelectionModal}`}
          selectedRow={selectedRow}
          isShow={isOpenSelectionModal}
          onClose={onToggleSelectionModal}
        />
      )}
    </>
  );
};

const headerCells: ElphiTableProps["header"] = [
  {
    index: 0,
    data: "index",
    type: ElphiCellType.String,
    size: SIZE_FIELD.T,
    ...baseCellStyle
  },
  {
    index: 1,
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    data: "Field Group Name",
    ...baseCellStyle
  },
  {
    index: 2,
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    data: "Configure Fields",
    ...baseCellStyle
  },
  {
    index: 3,
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    data: "Restrict To Task Statuses",
    ...baseCellStyle
  },
  {
    index: 4,
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    data: "Restrict To Task Configurations",
    ...baseCellStyle
  },
  {
    index: 5,
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    data: "Restrict To Integration Statuses",
    ...baseCellStyle
  },
  {
    index: 6,
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    data: "Restrict To Deal Milestones",
    ...baseCellStyle
  },
  {
    index: 7,
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    data: "Restrict To User Assignments",
    ...baseCellStyle
  },
  {
    index: 8,
    type: ElphiCellType.String,
    size: SIZE_FIELD.S,
    data: "Status",
    ...baseCellStyle
  }
];
