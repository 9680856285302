import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import { getDealTemplate } from "@elphi/types";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { useDealHooks } from "../../hooks/deal.hooks";
import { useOrgHooks } from "../../hooks/org.hooks";
import { useElphiToast } from "../toast/toast.hook";

export const CreateDealForm = (props?: { onSuccess?: () => void }) => {
  const { createDeal, createDealApiResponse, navigateToWorksheet } =
    useDealHooks();
  const { errorToast, successToast } = useElphiToast();
  const isStateValidForCreation = true;
  const { selectedOrgId } = useOrgHooks();
  const createDealHandler = () => {
    const newDeal = getDealTemplate();
    createDeal(newDeal).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "Deal Created",
          description: `new deal: ${r.data.id}`
        });
        selectedOrgId &&
          navigateToWorksheet(selectedOrgId.toString(), r.data.id);
        props?.onSuccess && props?.onSuccess();
      }
      r.status === 400 &&
        errorToast({
          title: "Failed to create deal",
          description: r.data.description
        });
    });
  };
  return (
    <>
      <Button
        {...elphiTheme.components.light.button.primary}
        onClick={() => createDealHandler()}
        isDisabled={!isStateValidForCreation}
        isLoading={createDealApiResponse.isLoading || false}
      >
        Submit
      </Button>
    </>
  );
};

export const CreateDealModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <Button {...elphiTheme.components.light.button.primary} onClick={onOpen}>
        Create a New Application
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"}>
          <ModalHeader>Submit to Create a New Application</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CreateDealForm onSuccess={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
