import {
  FirebaseFilter,
  NotificationConfiguration,
  Status,
  StatusCode
} from "@elphi/types";
import { PartialWithId } from "@elphi/types/services/service.types";
import { removeEmpty, removeEmptyValues } from "@elphi/utils/src/common.utils";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { useElphiToast } from "../components/toast/toast.hook";
import { RootState } from "../redux/store";
import { auditEventGroupApi } from "../redux/v2/audit-event-group";
import { fieldGroupApi } from "../redux/v2/field-group";
import { usePaginationHooksV2 } from "../redux/v2/hooks/pagination.hooks.v2";
import {
  notificationConfigurationApi,
  notificationConfigurationSlice,
  NotificationConfigurationSliceState
} from "../redux/v2/notification-configuration";
import { roleGroupApi } from "../redux/v2/role-group";
import { FilterCombinationState } from "../redux/v2/types/stateWithFilter.types";
import { useFilter } from "./filter.hooks";

export const LIMIT = 10;
export const useNotificationConfigurationHooks = () => {
  const dispatch = useDispatch();
  const { errorToast, successToast } = useElphiToast();
  const [searchApi, searchResponse] =
    notificationConfigurationApi.useLazySearchQuery();
  const [createApi, createResponse] =
    notificationConfigurationApi.useCreateMutation();
  const [updateApi, updateResponse] =
    notificationConfigurationApi.useUpdateMutation();
  const [updateBatchApi, updateBatchResponse] =
    notificationConfigurationApi.useUpdateBatchMutation();

  const [getRoleGroupBatchApi, getRoleGroupBatchResponse] =
    roleGroupApi.useLazyGetBatchQuery();
  const [getAuditEventGroupBatchApi, getAuditEventGroupBatchResponse] =
    auditEventGroupApi.useLazyGetBatchQuery();
  const [getFieldGroupBatchApi, getFieldGroupBatchResponse] =
    fieldGroupApi.useLazyGetBatchQuery();

  const setSelected = (id: string) =>
    dispatch(notificationConfigurationSlice.actions.selectedId({ id }));

  const notificationConfigurationState = useSelector(
    (state: RootState) => state.notificationConfiguration
  );
  const selectedConfiguration = useSelector(
    (state: RootState) =>
      (state.notificationConfiguration.selectedId &&
        state.notificationConfiguration.entities[
          state.notificationConfiguration.selectedId
        ]) ||
      undefined
  );

  const createConfiguration = async (
    r: Pick<NotificationConfiguration, "name">
  ) => {
    return await createApi({
      ...r,
      status: Status.Disabled
    })
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.OK) {
          successToast({
            title: "Configuration created",
            description: `Configuration: ${res.data?.id}`
          });
        }
        if (res.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to create configuration",
            description: res.data?.description
          });
        }
        return res;
      });
  };

  const updateConfiguration = async (
    r: PartialWithId<NotificationConfiguration>
  ) => {
    return await updateApi(r)
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.OK) {
          successToast({
            title: "Configuration updated",
            description: `Configuration: ${res.data?.id}`
          });
        }
        if (res.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to update configuration",
            description: res.data?.description
          });
        }
        return res;
      });
  };

  const cloneConfiguration = async (
    r: Pick<NotificationConfiguration, "name">
  ) => {
    return await createApi({
      ...r,
      status: Status.Disabled
    })
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.OK) {
          successToast({
            title: "Configuration cloned",
            description: `Configuration: ${res.data?.id}`
          });
        }
        if (res.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to clone configuration",
            description: res.data?.description
          });
        }
        return res;
      });
  };

  const updateConfigurationBatch = async (r: {
    [id: string]: Partial<NotificationConfiguration>;
  }) => {
    const configurations = removeEmptyValues(r);
    if (!configurations?.length) {
      return null;
    }

    return await updateBatchApi({ configurations })
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.OK) {
          successToast({
            title: "Configuration updated",
            description: `Configurations updated: ${res.data?.batch?.length}`
          });
        }
        if (res.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to update batch",
            description: res.data?.description
          });
        }
        return res;
      });
  };

  return {
    setSelected,
    notificationConfigurationState,
    searchApi,
    searchResponse,
    selectedConfiguration,
    createConfiguration,
    createResponse,
    cloneConfiguration,
    updateConfigurationBatch,
    updateBatchResponse,
    updateConfiguration,
    updateResponse,
    getRoleGroupBatchApi,
    getRoleGroupBatchResponse,
    getAuditEventGroupBatchApi,
    getAuditEventGroupBatchResponse,
    getFieldGroupBatchApi,
    getFieldGroupBatchResponse
  };
};

export const useNotificationConfigurationFilterHooks = () => {
  const { notificationConfigurationState } =
    useNotificationConfigurationHooks();
  const { buildFireStoreFilters, setCombination, setFilterCombinationDetails } =
    useFilter<NotificationConfiguration, NotificationConfigurationSliceState>(
      notificationConfigurationState
    );
  const currentFilter = notificationConfigurationState.filters.current;
  const [filters, setFilters] = useState<FirebaseFilter[]>([]);

  useEffect(() => {
    const filters = buildFireStoreFilters();
    setFilters(filters);
  }, [notificationConfigurationState.filters.currentCombination]);

  const setCurrentFilter = (filter: {
    values: NotificationConfigurationSliceState["filters"]["current"];
  }) => {
    setCombination(
      filter.values,
      notificationConfigurationSlice.actions.setFilterAndCombination
    );
  };

  const currentCombination = useSelector((state: RootState) => {
    const filters = state.notificationConfiguration.filters;
    return filters.combinations[filters.currentCombination];
  });

  const filteredItems = useMemo(() => {
    const names = new Set(currentFilter.name);
    const statuses = new Set(currentFilter.status);

    const filtered = notificationConfigurationState.ids
      .map((id) => {
        const entity = notificationConfigurationState.entities[id];
        if (!entity) {
          return undefined;
        }

        const { name, status } = entity;
        if (
          (names.size === 0 || (names && names.has(name))) &&
          (statuses.size === 0 || (status && statuses.has(status)))
        ) {
          return {
            ...entity
          };
        }
        return undefined;
      })
      .filter(removeEmpty);

    return filtered;
  }, [currentFilter, notificationConfigurationState.entities]);

  useEffect(() => {
    setFilterCombinationDetails(
      { ids: filteredItems.map((x) => x.id) },
      notificationConfigurationSlice.actions.setFilterCombinationDetails
    );
  }, [filteredItems]);

  const setPagingInfo = (info: Partial<FilterCombinationState[string]>) => {
    setFilterCombinationDetails(
      info,
      notificationConfigurationSlice.actions.setFilterCombinationDetails
    );
  };

  return {
    setCurrentFilter,
    filters,
    currentCombination,
    setPagingInfo
  };
};

export const useNotificationConfigurationTableHooks = () => {
  const { filters, currentCombination, setPagingInfo } =
    useNotificationConfigurationFilterHooks();
  const { nextPage, paginateApiResponse } = usePaginationHooksV2({
    paginationProvider: notificationConfigurationApi.useLazyPaginateV2Query,
    limit: LIMIT,
    input: {
      cursor: currentCombination.cursor,
      options: {
        filter: filters
      }
    },
    currentCursor: currentCombination.cursor,
    setHasMore: async (hasMore) => {
      setPagingInfo({
        hasMore
      });
    },
    setCursor: (cursor) => {
      setPagingInfo({
        cursor
      });
    }
  });

  return {
    nextPage,
    paginateApiResponse,
    filters,
    currentCombination
  };
};
