import { CloneConfigurationType, StatusCode } from "@elphi/types";
import { useState } from "react";
import { responseHandler } from "../../../../../apis/rtk/response.handler";
import { EMPTY } from "../../../../../constants/common";
import { orgApi } from "../../../../../redux/v2/organization/service";
import { useElphiToast } from "../../../../toast/toast.hook";

export const useCloneConfigurationHooks = () => {
  const { successToast, errorToast } = useElphiToast();
  const [cloneConfigurationApi, cloneConfigurationResponse] =
    orgApi.useCloneConfigurationMutation();

  const [selectConfiguration, setSelectConfiguration] = useState<
    CloneConfigurationType[]
  >([]);

  const [selectSourceOrg, setSelectSourceOrg] = useState<string>(EMPTY);
  const [selectDestinationOrg, setSelectDestinationOrg] =
    useState<string>(EMPTY);

  const onResetModal = () => {
    setSelectConfiguration([]);
    setSelectSourceOrg(EMPTY);
    setSelectDestinationOrg(EMPTY);
  };

  const cloneConfigurationApiHandler = async () => {
    return await cloneConfigurationApi({
      sourceOrgId: selectSourceOrg,
      destinationOrgId: selectDestinationOrg,
      cloneConfiguration: selectConfiguration
    })
      .then(responseHandler)
      .then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "Clone Configuration",
            description: "Completed successfully"
          });
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Clone Configuration",
            description: `Failed ${JSON.stringify(r?.data?.error)}`
          });
        }
      });
  };

  return {
    selectConfiguration,
    setSelectConfiguration,
    selectSourceOrg,
    setSelectSourceOrg,
    selectDestinationOrg,
    setSelectDestinationOrg,
    onResetModal,
    cloneConfigurationResponse,
    cloneConfigurationApiHandler
  };
};
