import { Box, HStack } from "@chakra-ui/react";
import { AggregationType, Deal, FieldType, UserRoleType } from "@elphi/types";
import { EMPTY } from "../../../constants/common";
import { OnChangeInput, Section } from "../../form-builder/FormBuilder";
import { dealFieldSpecs } from "../../form-builder/field-specs/deal/deal.fields";
import { buildInputs } from "../../form-builder/formBuilder.utils";
import UserSearch from "../../los-user/UserSearch";
import { filterChildrenStyle } from "../../utils/filter.utils";
import {
  booleanValidation,
  dateValidation,
  emailValidation,
  moneyValidation,
  numberValidation,
  singleSelectValidation,
  stringValidation
} from "../../utils/validationUtils";
import {
  estimatedClosingDateFieldPath,
  loanProgramTypeFieldPath,
  wireReleaseDateFieldPath
} from "../field-paths/closing.fieldPaths";
import {
  achElectedIndicatorFieldPath,
  achExemptionStatusFieldPath,
  achRequiredIndicatorFieldPath,
  allongeAssignmentSentToWarehouseIndicatorFieldPath,
  allongeAssignmentTrackingNumberFieldPath,
  closingAgentFirstNameFieldPath,
  closingAgentLastNameFieldPath,
  closingAnalystFieldPath,
  fundingAgentEmailAddressFieldPath,
  fundingAndDisbursementApprovalIndicatorFieldPath,
  fundingShieldStatusTypeFieldPath,
  haircutAmountFieldPath,
  loanPackageCheckAmountFieldPath,
  loanPackageCheckNumberFieldPath,
  loanPackageChecksReceivedIndicatorFieldPath,
  loanPackageInternationalExecutionIndicatorFieldPath,
  loanPackageNoteReceivedByLenderIndicatorFieldPath,
  loanPackageNoteTrackingNumberFieldPath,
  loanPackageTrackingNumberFieldPath,
  mailAwayIndicatorFieldPath,
  netWireAmountFieldPath,
  notaryAnalystFieldPath,
  notaryExpirationDateFieldPath,
  personalGuarantorsCountFieldPath,
  postClosingNotesFieldPath,
  powerOfAttorneyFullNameFieldPath,
  promissoryNoteCommentsFieldPath,
  promissoryNoteRequestedIndicatorFieldPath,
  promissoryNoteShippedIndicatorFieldPath,
  promissoryNoteTrackingNumberFieldPath,
  purchaserWarrantyDeedAddedIndicator,
  recordedMortgageReceivedDateFieldPath,
  recordedWarrantyDeedReceivedDateFieldPath,
  sellerHUDAddedIndicatorFieldPath,
  settlementStatementFileNumberFieldPath,
  shippingCompanyTypeFieldPath,
  titlePolicyReceivedDateFieldPath,
  warehouseBankStatusTypeFieldPath,
  warehouseBankTypeFieldPath
} from "../field-paths/postClosing.fieldPaths";
export const preFundingSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Pre-Funding",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly: state.aggregations?.NetWireAmount?.focused !== "override",
          path: netWireAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.EstimatedClosingDate?.focused !== "override",
          path: estimatedClosingDateFieldPath,
          validation: dateValidation
        },
        { path: wireReleaseDateFieldPath, validation: dateValidation },
        {
          path: warehouseBankTypeFieldPath,
          validation: singleSelectValidation
        },
        {
          path: warehouseBankStatusTypeFieldPath,
          validation: singleSelectValidation
        },
        {
          path: fundingShieldStatusTypeFieldPath,
          validation: singleSelectValidation
        },
        { path: haircutAmountFieldPath, validation: moneyValidation },
        { path: postClosingNotesFieldPath, validation: stringValidation },
        { path: mailAwayIndicatorFieldPath, validation: booleanValidation }
      ]
    })
  };
};

export const promissoryNoteWarehouseTrackingSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Promissory Note - Warehouse Tracking",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          path: warehouseBankTypeFieldPath,
          validation: singleSelectValidation
        },
        { path: closingAgentFirstNameFieldPath, validation: stringValidation },
        { path: closingAgentLastNameFieldPath, validation: stringValidation },
        { path: promissoryNoteCommentsFieldPath, validation: stringValidation },
        {
          path: shippingCompanyTypeFieldPath,
          validation: singleSelectValidation
        },
        {
          path: settlementStatementFileNumberFieldPath,
          validation: stringValidation
        },
        {
          path: personalGuarantorsCountFieldPath,
          validation: numberValidation
        },
        {
          path: powerOfAttorneyFullNameFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.LoanProgramType?.focused !== "override",
          path: loanProgramTypeFieldPath,
          validation: singleSelectValidation
        },
        {
          path: loanPackageNoteTrackingNumberFieldPath,
          validation: stringValidation
        }
      ]
    })
  };
};
export const promissoryNoteMFA = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Promissory Note - MFA",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          path: promissoryNoteRequestedIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: promissoryNoteShippedIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: promissoryNoteTrackingNumberFieldPath,
          validation: stringValidation
        }
      ]
    })
  };
};

export const custodianCollateralTrackingSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Allonge and Assignment",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          path: allongeAssignmentSentToWarehouseIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: allongeAssignmentTrackingNumberFieldPath,
          validation: stringValidation
        }
      ]
    })
  };
};

export const electronicExecutedLoanPackage = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Electronic Executed Loan Package",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          path: loanPackageTrackingNumberFieldPath,
          validation: stringValidation
        },
        {
          path: loanPackageNoteTrackingNumberFieldPath,
          validation: stringValidation
        },
        {
          path: fundingAndDisbursementApprovalIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: loanPackageInternationalExecutionIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: warehouseBankStatusTypeFieldPath,
          validation: singleSelectValidation
        },
        {
          path: achElectedIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.AchRequiredIndicator?.focused !==
            "override",
          path: achRequiredIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.AchExemptionStatus?.focused !==
            "override",
          path: achExemptionStatusFieldPath,
          validation: booleanValidation
        },
        {
          path: sellerHUDAddedIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: purchaserWarrantyDeedAddedIndicator,
          validation: booleanValidation
        },
        {
          path: fundingAgentEmailAddressFieldPath,
          validation: emailValidation
        }
      ]
    })
  };
};

export const physicalExecutedLoanPackage = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Physical Executed Loan Package",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          path: loanPackageTrackingNumberFieldPath,
          validation: stringValidation
        },
        {
          path: loanPackageNoteReceivedByLenderIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: personalGuarantorsCountFieldPath,
          validation: numberValidation
        },
        {
          path: loanPackageChecksReceivedIndicatorFieldPath,
          validation: booleanValidation
        },
        { path: loanPackageCheckNumberFieldPath, validation: stringValidation },
        { path: loanPackageCheckAmountFieldPath, validation: moneyValidation },
        {
          path: recordedMortgageReceivedDateFieldPath,
          validation: dateValidation
        },
        { path: titlePolicyReceivedDateFieldPath, validation: dateValidation },
        {
          path: recordedWarrantyDeedReceivedDateFieldPath,
          validation: dateValidation
        }
      ]
    })
  };
};

export const applicationPostClosingBeforeSections = (r: {
  state: Partial<Deal>;
  onChange?: (v: OnChangeInput) => void;
}): Section[] => {
  const { state, onChange } = r;
  return [
    preFundingSection(state, onChange),
    promissoryNoteWarehouseTrackingSection(state, onChange),
    promissoryNoteMFA(state, onChange)
  ];
};

export const applicationPostClosingAfterSections = (r: {
  state: Partial<Deal>;
  onChange?: (v: OnChangeInput) => void;
}): Section[] => {
  const { state, onChange } = r;
  return [
    custodianCollateralTrackingSection(state, onChange),
    electronicExecutedLoanPackage(state, onChange),
    physicalExecutedLoanPackage(state, onChange)
  ];
};

const FilteredUserSearch = ({
  currentValue,
  onSelect,
  label,
  fieldPath,
  roles
}) => {
  return (
    <Box
      width={"500px"}
      {...filterChildrenStyle.container}
      {...filterChildrenStyle.inputContainer}
    >
      <UserSearch
        labelPosition={"up"}
        currentValue={currentValue}
        onSelect={(id) =>
          onSelect({
            fieldKey: fieldPath,
            fieldType: FieldType.String,
            value: id
          })
        }
        showRoles={roles}
        label={label}
        filter={(u) => !!u.roles?.find((r) => roles.includes(r.value))}
      />
    </Box>
  );
};

export const applicationPostClosingUserAssignmentSection = (r: {
  state: Partial<Deal>;
  onChange: (v: OnChangeInput) => void;
}): Section[] => {
  const { state, onChange } = r;
  const closingAnalystUserId = state.ClosingAnalystUserId || EMPTY;
  const notaryUserId = state.NotaryUserId || EMPTY;
  return [
    {
      header: "User Assignment",
      inputs: buildInputs({
        state,
        onChange,
        fieldSpecs: dealFieldSpecs,
        specs: [
          { path: notaryExpirationDateFieldPath, validation: dateValidation }
        ]
      }),
      extraBody: (
        <HStack spacing={"10px"} mt="15px">
          <FilteredUserSearch
            currentValue={closingAnalystUserId}
            onSelect={onChange}
            label={"Closing Analyst"}
            fieldPath={closingAnalystFieldPath}
            roles={[UserRoleType.ClosingAnalyst]}
          />
          <FilteredUserSearch
            currentValue={notaryUserId}
            onSelect={onChange}
            label={"Notary"}
            fieldPath={notaryAnalystFieldPath}
            roles={[UserRoleType.Notary]}
          />
        </HStack>
      )
    }
  ];
};
