import { Box, Text } from "@chakra-ui/react";
import { isNumber } from "lodash";
import { ReactNode, useEffect, useState } from "react";

type LazyLoadedBodyContentProps = {
  loadingTime?: number;
  children?: ReactNode;
  fallback?: ReactNode | string;
};

const LazyLoadedContent = ({
  loadingTime,
  fallback,
  children
}: LazyLoadedBodyContentProps) => {
  const [showContent, setShowContent] = useState<boolean>(false);

  const fetchData = () => {
    if (isNumber(loadingTime)) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, loadingTime);
      });
    } else {
      return new Promise((resolve) => resolve(true));
    }
  };

  useEffect(() => {
    fetchData().then((data: boolean) => {
      setShowContent(data);
    });
  }, []);

  return (
    <Box>
      {showContent ? (
        <Box>{children}</Box>
      ) : !!fallback ? (
        fallback
      ) : (
        <Text>Loading...</Text>
      )}
    </Box>
  );
};

export default LazyLoadedContent;

/* How to use:
import { lazy } from "react";
const LazyLoadedContent = lazy(() => import("./LazyLoadedContent"));
<LazyLoadedContent><COMPONENT/></LazyLoadedContent>
*/
