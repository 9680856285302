import { OrderStatusTaskFields } from "@elphi/types";
import { ObjectKeyValidation } from "../../form-builder/field-specs/objectKeyValidation.types";

export const vendorNameFieldPath: ObjectKeyValidation<
  OrderStatusTaskFields,
  ["vendorName"]
> = ["vendorName"];

export const vendorInformationFieldPath: ObjectKeyValidation<
  OrderStatusTaskFields,
  ["vendorInformation"]
> = ["vendorInformation"];

export const receivedDateFieldPath: ObjectKeyValidation<
  OrderStatusTaskFields,
  ["receivedDate"]
> = ["receivedDate"];

export const dueDateFieldPath: ObjectKeyValidation<
  OrderStatusTaskFields,
  ["dueDate"]
> = ["dueDate"];

export const orderedDateFieldPath: ObjectKeyValidation<
  OrderStatusTaskFields,
  ["orderedDate"]
> = ["orderedDate"];

export const orderedFromFieldPath: ObjectKeyValidation<
  OrderStatusTaskFields,
  ["orderedFrom"]
> = ["orderedFrom"];

export const orderStatusFieldPath: ObjectKeyValidation<
  OrderStatusTaskFields,
  ["orderStatus"]
> = ["orderStatus"];
