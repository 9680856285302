import {
  BaseCreditReportOrderTask,
  CreditPullType
} from "../creditReport.types";
import { BaseCreditReportVendor, CreditReportVendorType } from "./vendor.types";

export type LimaCreditReport = BaseCreditReportOrderTask<{
  vendor: {
    selectedVendor: CreditReportVendorType.Lima;
    [CreditReportVendorType.Lima]?: {
      pullType: CreditPullType;
    } & BaseCreditReportVendor<CreditReportVendorType.Lima>;
  };
}>;
