import { Flex, Text } from "@chakra-ui/react";
import { formatNumberWithCommas } from "@elphi/types";
import { DATES_FORMATS, elphiDate } from "@elphi/utils";
import { useEffect, useState } from "react";
import { useDealCalendarHooks } from "../../../hooks/dealCalendar.hooks";

export type DealCalendarDayProps = { currentDay: Date };

export const DealCalendarFooterDay = ({ currentDay }: DealCalendarDayProps) => {
  const { dealCalendarState, selectedDateType } = useDealCalendarHooks();
  const currentDate = elphiDate(currentDay).format(DATES_FORMATS.YYYY_MM_DD);
  const [currentDayDealsLength, setCurrentDayDealsLength] = useState(
    dealCalendarState.dates?.[selectedDateType]?.[currentDate]
      ?.totalDealsCalendar
  );
  const [totalRequestedLoanAmount, setTotalRequestedLoanAmount] = useState(
    dealCalendarState.dates?.[selectedDateType]?.[currentDate]
      ?.totalRequestedLoanAmount || 0
  );

  useEffect(() => {
    const { totalDealsCalendar, totalRequestedLoanAmount } =
      dealCalendarState.dates?.[selectedDateType]?.[currentDate] || {};
    setCurrentDayDealsLength(totalDealsCalendar);
    setTotalRequestedLoanAmount(totalRequestedLoanAmount || 0);
  }, [dealCalendarState.dates?.[selectedDateType]?.[currentDate]]);

  return (
    <Flex
      flexDir={"row"}
      justifyContent={"space-between"}
      w={"100%"}
      h={"100%"}
      alignItems={"center"}
      minH={"20px"}
    >
      <Flex>
        {!!currentDayDealsLength && (
          <Text fontWeight={700} fontSize={"12px"}>
            {currentDayDealsLength} deals
          </Text>
        )}
      </Flex>
      <Flex>
        {!!totalRequestedLoanAmount && (
          <Text fontWeight={700} fontSize={"12px"}>
            ${formatNumberWithCommas(totalRequestedLoanAmount.toString())}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};
