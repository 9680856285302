export const rowIndexStyles = {
  maxWidth: "80px",
  minWidth: "80px"
};

export const partyNameStyles = {
  maxWidth: "150px",
  minWidth: "150px"
};

export const partyMilestoneStyles = {
  maxWidth: "300px",
  minWidth: "300px"
};

export const userAssignmentStyles = {
  maxWidth: "250px",
  minWidth: "250px"
};

export const loanNumberStyles = {
  maxWidth: "150px",
  minWidth: "150px"
};

export const borrowingEntityStyles = {
  maxWidth: "150px",
  minWidth: "150px"
};

export const sponsorStyles = {
  maxWidth: "150px",
  minWidth: "150px"
};

export const loanProgramStyles = {
  maxWidth: "350px",
  minWidth: "350px"
};

export const totalLoanAmountStyles = {
  maxWidth: "400px",
  minWidth: "400px"
};

export const dealPartyRoleStyles = {
  maxWidth: "350px",
  minWidth: "350px"
};

export const closingDateStyles = {
  maxWidth: "150px",
  minWidth: "150px"
};
