import { Box, Flex, Grid, GridItem, Skeleton, Text } from "@chakra-ui/react";
import { getFocusedData } from "@elphi/aggregations/src/utils/aggregation.utils";
import {
  Deal,
  DealPartyRelation,
  DealProperty,
  DiscussionEntityType,
  ElphiEntityType,
  Party
} from "@elphi/types";
import { DevFeatureFlag } from "@elphi/utils";
import { EntityState } from "@reduxjs/toolkit";
import { useMemo } from "react";
import {
  DOLLAR,
  EMPTY,
  NOT_AVAILABLE,
  PERCENTAGE
} from "../../constants/common";
import { GateKeeper } from "../../features/gate/GateKeeper";
import { printDateTime } from "../../firebase/firebase.utils";
import { useSnapshotHooks } from "../../hooks/snapshot.hooks";
import { worksheetHeaderLiveStateContainerHooks } from "../../hooks/worksheetHeaderLiveStateContainer.hooks";
import { AllEventsMode } from "../../redux/v2/audit-event";
import { getStringValueOrDefault } from "../../utils/common";
import { removeNulls } from "../../utils/filter.utils";
import { AuditLogBox } from "../audit-log/AuditLogBox";
import { BorrowingEntityMilestone } from "../deal/utils/table.utils";
import { DiscussionBox } from "../discussion/DiscussionBox";
import {
  moneyFormatter,
  threeDecimalPlacesFormatter
} from "../form-builder/formatters/inputs.formatter";

type WorksheetHeaderProps = {
  dealId: string;
};

export const WorksheetHeaderContainer = (props: WorksheetHeaderProps) => {
  const { selectedSnapshot } = useSnapshotHooks();
  return selectedSnapshot &&
    selectedSnapshot.id &&
    selectedSnapshot.entityType === ElphiEntityType.deal &&
    selectedSnapshot.dealId === props.dealId ? (
    <WorksheetHeaderSnapshotContainer
      {...props}
      snapshotId={selectedSnapshot.id}
    />
  ) : (
    <WorksheetHeaderLiveStateContainer {...props} />
  );
};
export const WorksheetHeaderSnapshotContainer = (
  props: WorksheetHeaderProps & { snapshotId: string }
) => {
  const { snapshotDataState } = useSnapshotHooks();
  const snapshot = snapshotDataState({ snapshotId: props.snapshotId });

  return (
    <WorksheetHeader
      {...props}
      dealId={props.dealId}
      dealSlice={snapshot?.dealState}
      dealPropertySlice={snapshot?.dealPropertyState}
      dealPartySlice={snapshot?.dealPartyRelationState}
      partySlice={snapshot?.partyState}
      isSuccess={!!snapshot?.dealState && !!snapshot?.dealPropertyState}
    />
  );
};

export const WorksheetHeaderLiveStateContainer = (
  props: WorksheetHeaderProps
) => {
  const {
    dealState,
    dealPartyRelationState,
    dealPropertyRelationState,
    partyState,
    dealPartiesResponse,
    dealPropertiesResponse,
    dealResponse
  } = worksheetHeaderLiveStateContainerHooks();

  return (
    <WorksheetHeader
      {...props}
      dealId={props.dealId}
      dealSlice={dealState}
      dealPropertySlice={dealPropertyRelationState}
      dealPartySlice={dealPartyRelationState}
      partySlice={partyState}
      isSuccess={
        dealPropertiesResponse.isSuccess &&
        dealResponse.isSuccess &&
        dealPartiesResponse.isSuccess
      }
    />
  );
};
const WorksheetHeader = (
  props: WorksheetHeaderProps & {
    dealSlice?: EntityState<Deal>;
    dealPropertySlice?: EntityState<DealProperty>;
    dealPartySlice?: EntityState<DealPartyRelation>;
    partySlice?: EntityState<Party>;
    isSuccess: boolean;
    isSnapshot?: boolean;
  }
) => {
  const { selectedSnapshot } = useSnapshotHooks();
  const { dealSlice } = props;

  const deal = dealSlice?.entities[props.dealId];

  const loanAmount = getStringValueOrDefault(
    getFocusedData(deal?.aggregations?.RequestedLoanAmount),
    EMPTY
  );

  const netWireAmount = getStringValueOrDefault(
    getFocusedData(deal?.aggregations?.NetWireAmount),
    EMPTY
  );

  const noteRatePercent = getStringValueOrDefault(
    getFocusedData(deal?.quote?.aggregations?.NoteRatePercent),
    EMPTY
  );

  const dealPartyMemo = useMemo(() => {
    const dealPartyEntities = props.dealPartySlice?.entities;
    const partyEntities = props.partySlice?.entities;
    const currentDealPartyIds = props.dealPartySlice?.ids
      .filter((dpid) => {
        return dealPartyEntities?.[dpid]?.dealId === props.dealId;
      })
      .filter(removeNulls);

    const dealPartyData = currentDealPartyIds
      ?.map((dpid) => dealPartyEntities?.[dpid])
      .filter(removeNulls);
    const partyData = dealPartyData
      ?.map((d) => partyEntities?.[d.partyId])
      .filter(removeNulls);

    return {
      currentParties: partyData || [],
      dealPartyRelations: dealPartyData || []
    };
  }, [props.partySlice, props.dealPartySlice, props.dealId]);

  const loanIdentifier = getStringValueOrDefault(deal?.LoanIdentifier);
  const fields: { name?: string; data: string | JSX.Element }[] = [
    {
      name: "Deal Number",
      data: loanIdentifier
    },
    {
      name: "Total Loan Amount",
      data: `${loanAmount ? DOLLAR : EMPTY}${
        moneyFormatter.format.encode(loanAmount) || NOT_AVAILABLE
      }`
    },
    {
      name: "Closing Date",
      data: getStringValueOrDefault(
        getFocusedData(deal?.aggregations?.EstimatedClosingDate)
      )
    },
    {
      name: "Loan Program",
      data: getStringValueOrDefault(
        getFocusedData(deal?.aggregations?.LoanProgramType)
      )
    },
    {
      name: "Loan Purpose",
      data: getStringValueOrDefault(String(deal?.LoanPurposeType))
    },
    {
      name: "Note Rate",
      data: `${
        threeDecimalPlacesFormatter.format.decode(noteRatePercent) ||
        NOT_AVAILABLE
      }${noteRatePercent ? PERCENTAGE : EMPTY}`
    },
    {
      name: "Total Net Wire Amount",
      data: `${netWireAmount ? DOLLAR : EMPTY}${
        moneyFormatter.format.encode(netWireAmount) || NOT_AVAILABLE
      }`
    },
    {
      name: "Rate Lock End Date",
      data: getStringValueOrDefault(deal?.RateLockEndDate)
    },

    { name: "Loan Name", data: getStringValueOrDefault(deal?.LoanName) },
    {
      name: "Deal Milestone",
      data: getStringValueOrDefault(deal?.DealMetadata?.milestone)
    },
    {
      data: (
        <BorrowingEntityMilestone
          currentParties={dealPartyMemo?.currentParties || []}
          dealPartyRelations={dealPartyMemo?.dealPartyRelations || []}
        />
      )
    },
    {
      name: "Total Number of Properties",
      data: getStringValueOrDefault(
        getFocusedData(deal?.aggregations.TotalNumberOfProperties)
      )
    }
  ];

  const numberOfColumns = 3;
  const isSnapshot =
    selectedSnapshot &&
    selectedSnapshot.id &&
    selectedSnapshot.entityType === ElphiEntityType.deal &&
    selectedSnapshot.dealId == props.dealId;
  return (
    <>
      <Grid
        p="10px"
        gap={2}
        background="#F6F7F8"
        h="160px"
        borderRadius={"8px"}
        templateColumns={"repeat(5, 1fr)"}
        templateRows={"repeat(4, 1fr)"}
        overflow="scroll"
      >
        <GridItem rowStart={1} colStart={1} colSpan={2}>
          <Box
            bgColor={isSnapshot ? "black" : "green.300"}
            h="20px"
            w={isSnapshot ? "" : "50px"}
          >
            <Text align="center" fontSize="16" color={"white"}>
              {isSnapshot
                ? `viewing snapshot from: ${printDateTime(
                    selectedSnapshot.createdAt
                  )}`
                : "LIVE"}
            </Text>
          </Box>
        </GridItem>
        <GridItem rowStart={1} colStart={6}>
          <Flex direction={"row-reverse"} gap={1}>
            <AuditLogBox
              type={AllEventsMode.Deal}
              dealId={props.dealId}
              title={loanIdentifier}
            />
            <GateKeeper gate={DevFeatureFlag.Esd_3067_Discussion_Per_Entity}>
              <DiscussionBox
                entityId={props.dealId}
                entityType={DiscussionEntityType.Deal}
                title={`Deal ${loanIdentifier}`}
              />
            </GateKeeper>
          </Flex>
        </GridItem>
        {fields.map((f, i) => (
          <GridItem
            key={i}
            rowStart={(i % numberOfColumns) + 2}
            colStart={i / numberOfColumns + 1}
          >
            {props.isSuccess ? (
              typeof f.data === "string" ? (
                <Text
                  fontSize="md"
                  w="100%"
                  whiteSpace={"nowrap"}
                  color="gray.800"
                >
                  <b>{f.name}</b>: {f.data}
                </Text>
              ) : (
                <>
                  <Box>{f.data}</Box>
                </>
              )
            ) : (
              <Skeleton w="280px" h="20px" />
            )}
          </GridItem>
        ))}
      </Grid>
    </>
  );
};
export default WorksheetHeader;
