import { Button, Flex } from "@chakra-ui/react";
import { NotificationConfiguration, Status } from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { isEmpty, uniqBy } from "lodash";
import { useMemo } from "react";
import { useFilterMenuHook } from "../../../../common-components/menu-options/filter-menu-hook";
import { FilterMenuOptions } from "../../../../common-components/menu-options/FilterMenuOptions";
import {
  useNotificationConfigurationFilterHooks,
  useNotificationConfigurationHooks
} from "../../../../hooks/notificationConfiguration.hooks";
import { NotificationConfigurationSliceState } from "../../../../redux/v2/notification-configuration";

const namePath: DotNestedKeys<NotificationConfiguration> = "name";
export const NotificationConfigurationFilters = () => {
  const filterMenuHook = useFilterMenuHook();
  const { notificationConfigurationState, searchApi } =
    useNotificationConfigurationHooks();
  const { setCurrentFilter } = useNotificationConfigurationFilterHooks();
  const { clearFilters } = filterMenuHook;

  const handleClearClick = () => {
    clearFilters();
    setCurrentFilter({
      values: {
        name: [],
        status: []
      }
    });
  };

  const isDisable = !notificationConfigurationState.filters?.current
    ? true
    : Object.keys(notificationConfigurationState.filters?.current).every(
        (key) => isEmpty(notificationConfigurationState.filters?.current[key])
      );

  const nameOptions = useMemo(() => {
    const ncNames = uniqBy(
      Object.values(notificationConfigurationState.entities),
      namePath
    );
    return ncNames.map((nc: NotificationConfiguration) => ({
      value: nc.name,
      label: nc.name
    }));
  }, [notificationConfigurationState.entities]);

  const onFilterChange = (
    filter: NotificationConfigurationSliceState["filters"]["current"]
  ) => {
    setCurrentFilter({
      values: {
        ...notificationConfigurationState.filters.current,
        ...filter
      }
    });
  };
  return (
    <Flex>
      <FilterMenuOptions
        customKey={"namesFilter"}
        maxHeight={"300px"}
        scrollHeight={"300px"}
        filterMenuHook={filterMenuHook}
        title={"Name"}
        options={nameOptions}
        searchApi={searchApi}
        showSearchBar={true}
        onChange={(items: string[]) => onFilterChange({ name: items })}
        selected={notificationConfigurationState.filters.current.name}
        showClearAll
      />
      <FilterMenuOptions
        customKey={"statusFilter"}
        maxHeight={"300px"}
        scrollHeight={"300px"}
        filterMenuHook={filterMenuHook}
        title={"Status"}
        options={[
          { label: "Active", value: Status.Active },
          { label: "Disabled", value: Status.Disabled }
        ]}
        onChange={(items: string[]) => onFilterChange({ status: items })}
        selected={notificationConfigurationState.filters.current.status}
      />
      <Button
        mr={1}
        background={"transparent"}
        fontSize={14}
        fontWeight={"normal"}
        onClick={handleClearClick}
        isDisabled={isDisable}
      >
        Clear filters
      </Button>
    </Flex>
  );
};
