import { Box, Text, Tooltip } from "@chakra-ui/react";
import { Deal, DealMilestoneType } from "@elphi/types";
import { changeReasonMilestones } from "@elphi/utils";
import { ActionMeta } from "chakra-react-select";
import { useState } from "react";
import StyledInputBuilder from "../../form-builder/InputBuilder";
import { FieldType } from "../../form-builder/fieldFormat.types";
import ModalContainer from "../../modal-container/ModalContainer";
import { createOptionsFromEnum } from "../../utils/formUtils";

type MilestoneSelectionProps = {
  dealId: string;
  deal: Deal;
  onChange?: (e: {
    target: {
      value: DealMilestoneType;
    };
    actionMeta?: ActionMeta<any>;
  }) => void;
  isDisabled?: boolean;
  tooltipLabel?: React.ReactNode;
  withConfirmModal?: boolean;
  exceptMilestoneTypes?: DealMilestoneType[];
};

export const MilestoneSelectionComponent = (props: MilestoneSelectionProps) => {
  const {
    withConfirmModal = true,
    exceptMilestoneTypes = [...changeReasonMilestones]
  } = props;

  const [selectedMilestone, setSelectedMilestone] = useState<{
    target: { value: DealMilestoneType };
  }>();
  const [showMilestoneModal, setShowMilestoneModal] = useState(false);
  const [openToolTip, setOpenToolTip] = useState(false);

  const onMilestoneChanged = () => {
    setShowMilestoneModal(false);
    props?.onChange && !!selectedMilestone && props.onChange(selectedMilestone);
  };

  const onChange = (e) => {
    if (withConfirmModal && !exceptMilestoneTypes?.includes(e?.target?.value)) {
      setSelectedMilestone(e);
      setShowMilestoneModal(true);
    } else {
      props?.onChange && props?.onChange(e);
    }
    setOpenToolTip(false);
  };

  const currentMilestone = props.deal?.DealMetadata?.milestone;

  const getModalBody = () => (
    <Box>
      Are you sure you want to update the Deal Milestone?
      <Text>
        From: <Text as="b">{currentMilestone}</Text>
      </Text>
      <Text>
        To: <Text as="b">{selectedMilestone?.target?.value}</Text>
      </Text>
    </Box>
  );

  return (
    <>
      <Tooltip
        isOpen={openToolTip}
        aria-label="milestone-drop-down"
        placement="right"
        label={props?.tooltipLabel ?? ""}
        minW="50px"
        maxW="400px"
      >
        <Box
          w="100%"
          onMouseEnter={() => setOpenToolTip(true)}
          onMouseLeave={() => setOpenToolTip(false)}
        >
          <StyledInputBuilder
            options={createOptionsFromEnum(DealMilestoneType)}
            label="Milestone"
            onChange={onChange}
            isDisabled={props.isDisabled}
            isClearable={false}
            chakraStyles={{
              dropdownIndicator: (provided, state) => ({
                ...provided,
                background: state.isFocused ? "#DBE7FF" : "white",
                p: 0,
                w: "20px"
              }),
              control: (provided, _) => ({
                ...provided,
                height: "10px",
                p: 0
              }),
              container: (provided, _) => ({
                ...provided,
                bgColor: "#F2F7FF",
                p: 0
              }),
              placeholder: (provided, _) => ({
                ...provided,
                color: "white",
                p: 0
              }),
              valueContainer: (provided, _) => ({
                ...provided,
                fontSize: "12px",
                background: "white",
                fontWeight: "bold",
                p: "2px"
              })
            }}
            isValid={true}
            fieldType={FieldType.SingleSelect}
            currentValue={!!props.dealId ? currentMilestone ?? "" : ""}
          />
        </Box>
      </Tooltip>
      {withConfirmModal && (
        <ModalContainer
          isShow={showMilestoneModal}
          onCloseModal={() => setShowMilestoneModal(false)}
          header={`Milestone Change`}
          body={getModalBody()}
          submit={{
            onConfirm: onMilestoneChanged,
            confirmTitle: "Save"
          }}
        />
      )}
    </>
  );
};
