import { baseCellStyle } from "../../../custom/chakra/Cell";
import { SIZE_FIELD } from "../../../table/TableRowSizeComponent";
import { ElphiCellType, ElphiTableProps } from "../../../table/table.types";

export const headerCells: ElphiTableProps["header"] = [
  {
    index: 0,
    data: "index",
    type: ElphiCellType.String,
    size: SIZE_FIELD.T,
    ...baseCellStyle
  },
  {
    index: 1,
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    data: "Id",
    ...baseCellStyle
  },
  {
    index: 2,
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    data: "Name",
    ...baseCellStyle
  },
  {
    index: 3,
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    data: "Description",
    ...baseCellStyle
  },
  {
    index: 4,
    type: ElphiCellType.String,
    size: SIZE_FIELD.S,
    data: "Status",
    ...baseCellStyle
  }
];
