import { Deal } from "@elphi/types";
import { dealFieldSpecs } from "../../form-builder/field-specs/deal/deal.fields";
import { OnChangeInput, Section } from "../../form-builder/FormBuilder";
import { buildInputs } from "../../form-builder/formBuilder.utils";
import {
  booleanValidation,
  dateValidation,
  emailValidation,
  singleSelectValidation,
  stringValidation
} from "../../utils/validationUtils";
import {
  titleCompanyAddressLineTextFieldPath,
  titleCompanyAddressUnitIdentifierFieldPath,
  titleCompanyCityNameFieldPath,
  titleCompanyClosingAgentFirstNameFieldPath,
  titleCompanyClosingAgentLastNameFieldPath,
  titleCompanyPostalCodeFieldPath,
  titleCompanyStateCodeFieldPath,
  titleInsuranceUnderwriterFieldPath
} from "../field-paths/closing.fieldPaths";
import {
  lenderFinanceBorrowerFinalTermSheetApprovalStatusDateFieldPath,
  lenderFinancePartnerFinalTermSheetApprovalStatusDateFieldPath,
  lenderFinancePartnerFirstNameFieldPath,
  lenderFinancePartnerInitialTermSheetApprovalStatusDateFieldPath,
  lenderFinancePartnerInitialTermSheetApprovalStatusIndicatorFieldPath,
  lenderFinancePartnerLastNameFieldPath
} from "../field-paths/deal.fieldPaths";
import {
  brokerContactPointEmailValueFieldPath,
  brokerContactPointTelephoneFieldPath,
  brokerFullNameFieldPath,
  brokerRepresentativeFirstNameFieldPath,
  brokerRepresentativeLastNameFieldPath,
  brokerWiringInstructionsIndicatorFieldPath,
  generalContractorContactPointEmailFieldPath,
  generalContractorContactPointTelephoneFieldPath,
  generalContractorFullNameFieldPath,
  generalContractorRepresentativeFirstNameFieldPath,
  generalContractorRepresentativeLastNameFieldPath,
  nmlsIdentifierFieldPath,
  titleCompanyClosingAgentContactPointEmailFieldPath,
  titleCompanyClosingAgentContactPointTelephoneFieldPath,
  titleCompanyNameFieldPath
} from "../field-paths/thirdParty.fieldPaths";

export const titleCompanyClosingAttorneySection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Title Company",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          path: titleInsuranceUnderwriterFieldPath,
          validation: stringValidation
        },
        { path: titleCompanyNameFieldPath, validation: stringValidation },
        {
          path: titleCompanyClosingAgentFirstNameFieldPath,
          validation: stringValidation
        },
        {
          path: titleCompanyClosingAgentLastNameFieldPath,
          validation: stringValidation
        },
        {
          path: titleCompanyClosingAgentContactPointTelephoneFieldPath,
          validation: stringValidation
        },
        {
          path: titleCompanyClosingAgentContactPointEmailFieldPath,
          validation: emailValidation
        },
        {
          path: titleCompanyAddressLineTextFieldPath,
          validation: stringValidation
        },
        {
          path: titleCompanyAddressUnitIdentifierFieldPath,
          validation: stringValidation
        },
        { path: titleCompanyCityNameFieldPath, validation: stringValidation },
        {
          path: titleCompanyStateCodeFieldPath,
          validation: singleSelectValidation
        },
        { path: titleCompanyPostalCodeFieldPath, validation: stringValidation }
      ]
    })
  };
};

export const generalContractorSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "General Contractor",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          path: generalContractorFullNameFieldPath,
          validation: stringValidation
        },
        {
          path: generalContractorRepresentativeFirstNameFieldPath,
          validation: stringValidation
        },
        {
          path: generalContractorRepresentativeLastNameFieldPath,
          validation: stringValidation
        },
        {
          path: generalContractorContactPointTelephoneFieldPath,
          validation: stringValidation
        },
        {
          path: generalContractorContactPointEmailFieldPath,
          validation: emailValidation
        }
      ]
    })
  };
};

export const brokerSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Broker",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          path: brokerRepresentativeFirstNameFieldPath,
          validation: stringValidation
        },
        {
          path: brokerRepresentativeLastNameFieldPath,
          validation: stringValidation
        },
        {
          path: brokerFullNameFieldPath,
          validation: stringValidation
        },
        {
          path: brokerContactPointEmailValueFieldPath,
          validation: emailValidation
        },
        {
          path: brokerContactPointTelephoneFieldPath,
          validation: stringValidation
        },
        {
          path: nmlsIdentifierFieldPath,
          validation: stringValidation
        },
        {
          path: brokerWiringInstructionsIndicatorFieldPath,
          validation: booleanValidation
        }
      ]
    })
  };
};

export const lenderFinanceSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Lender Finance",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          path: lenderFinancePartnerFirstNameFieldPath,
          validation: stringValidation
        },
        {
          path: lenderFinancePartnerLastNameFieldPath,
          validation: stringValidation
        },
        {
          path: lenderFinancePartnerInitialTermSheetApprovalStatusIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: lenderFinancePartnerInitialTermSheetApprovalStatusDateFieldPath,
          validation: dateValidation
        },
        {
          path: lenderFinancePartnerFinalTermSheetApprovalStatusDateFieldPath,
          validation: dateValidation
        },
        {
          path: lenderFinanceBorrowerFinalTermSheetApprovalStatusDateFieldPath,
          validation: dateValidation
        }
      ]
    })
  };
};

export const applicationThirdPartySections = (r: {
  state: Partial<Deal>;
  onChange: (v: OnChangeInput) => void;
}): Section[] => {
  const { state, onChange } = r;
  return [
    titleCompanyClosingAttorneySection(state, onChange),
    generalContractorSection(state, onChange),
    brokerSection(state, onChange),
    lenderFinanceSection(state, onChange)
  ];
};
