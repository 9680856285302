import { AggregationType, Deal, Task } from "@elphi/types";
import { dealFieldSpecs } from "../../../form-builder/field-specs/deal/deal.fields";
import { OnChangeInput, Section } from "../../../form-builder/FormBuilder";
import { buildInputs } from "../../../form-builder/formBuilder.utils";
import {
  emailValidation,
  moneyValidation,
  stringValidation
} from "../../../utils/validationUtils";
import {
  lenderIdentifierFieldPath,
  requestedLoanAmountFieldPath,
  titleCompanyAddressLineTextFieldPath,
  titleCompanyAddressUnitIdentifierFieldPath,
  titleCompanyCityNameFieldPath,
  titleCompanyClosingAgentContactPointEmailValueFieldPath,
  titleCompanyClosingAgentFirstNameFieldPath,
  titleCompanyClosingAgentLastNameFieldPath,
  titleCompanyPostalCodeFieldPath,
  titleCompanyStateCodeFieldPath,
  wireABARoutingNumberFieldPath,
  wireAccountNameFieldPath,
  wireAccountNumberFieldPath
} from "../../field-paths/closing.fieldPaths";
import {
  wireInsuranceCertificateFundingShieldRushOrderIndicator,
  wireInsuranceCertificateFundingShieldUserEmail,
  wireInsuranceCertificateFundingShieldUserFirstName,
  wireInsuranceCertificateFundingShieldUserLastName
} from "../../field-paths/deal.fieldPaths";
import { titleCompanyNameFieldPath } from "../../field-paths/thirdParty.fieldPaths";
import { WireInsuranceFiles } from "./wireInsuranceFiles";

export const wireInsuranceOrderSection = (
  state: Partial<Deal>,
  onChange: (v: OnChangeInput) => void,
  selectedTask: Task
): Section => {
  return {
    id: "integrationWireInsuranceCertOrder",
    header: "Integration Wire Insurance Cert: Order",
    extraBody: (
      <WireInsuranceFiles
        selectedTask={selectedTask}
        onChange={onChange}
        state={state}
      />
    ),
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          path: wireABARoutingNumberFieldPath,
          isRequired: true,
          validation: (v) => stringValidation(v, true)
        },
        {
          path: wireAccountNumberFieldPath,
          isRequired: true,
          validation: (v) => stringValidation(v, true)
        },
        {
          path: wireAccountNameFieldPath,
          isRequired: true,
          validation: (v) => stringValidation(v, true)
        },
        {
          path: titleCompanyNameFieldPath,
          isRequired: true,
          validation: (v) => stringValidation(v, true)
        },
        {
          path: titleCompanyAddressLineTextFieldPath,
          isRequired: true,
          validation: (v) => stringValidation(v, true)
        },
        {
          path: titleCompanyAddressUnitIdentifierFieldPath,
          validation: stringValidation
        },
        {
          path: titleCompanyCityNameFieldPath,
          isRequired: true,
          validation: (v) => stringValidation(v, true)
        },
        {
          path: titleCompanyStateCodeFieldPath,
          isRequired: true,
          validation: (v) => stringValidation(v, true)
        },
        {
          path: titleCompanyPostalCodeFieldPath,
          isRequired: true,
          validation: (v) => stringValidation(v, true)
        },
        {
          path: titleCompanyClosingAgentFirstNameFieldPath,
          isRequired: true,
          validation: (v) => stringValidation(v, true)
        },
        {
          path: titleCompanyClosingAgentLastNameFieldPath,
          isRequired: true,
          validation: (v) => stringValidation(v, true)
        },
        {
          path: titleCompanyClosingAgentContactPointEmailValueFieldPath,
          isRequired: true,
          validation: (v) => emailValidation(v, true)
        },
        {
          path: lenderIdentifierFieldPath,
          isRequired: true,
          validation: (v) => stringValidation(v, true)
        },
        {
          path: wireInsuranceCertificateFundingShieldUserFirstName,
          isRequired: true,
          validation: (v) => stringValidation(v, true)
        },
        {
          path: wireInsuranceCertificateFundingShieldUserLastName,
          isRequired: true,
          validation: (v) => stringValidation(v, true)
        },
        {
          path: wireInsuranceCertificateFundingShieldUserEmail,
          isRequired: true,
          validation: (v) => emailValidation(v, true)
        },
        {
          path: wireInsuranceCertificateFundingShieldRushOrderIndicator,
          isRequired: true,
          validation: (v) => stringValidation(v, true)
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.RequestedLoanAmount?.focused !== "override",
          path: requestedLoanAmountFieldPath,
          isRequired: true,
          validation: (v) => moneyValidation(v, true)
        }
      ]
    })
  };
};
