import { LabelValue, Organization } from "@elphi/types";

export type OptionType = LabelValue & { isDisabled: boolean; reason?: string };

export const buildDefaultOption = (o: Organization): OptionType => {
  return {
    value: o.id,
    label: o.name,
    isDisabled: false
  };
};

export const buildDisabledCloneOption = (
  o: Organization,
  selfOrgId?: string
): OptionType => {
  return {
    ...buildDefaultOption(o),
    isDisabled: o.id === selfOrgId,
    reason: "Cannot clone to self"
  };
};

export const buildDisabledActionOption = (
  o: Organization,
  selfOrgId?: string
): OptionType => {
  return {
    ...buildDefaultOption(o),
    isDisabled: o.isSubscribed === "active" || o.id === selfOrgId,
    reason: getDisabledReason(o, selfOrgId)
  };
};

export const getDisabledReason = (o: Organization, selfOrgId?: string) => {
  if (o.isSubscribed === "active") {
    return "Cannot subscribe to a subscriber";
  }
  if (o.id === selfOrgId) {
    return "Cannot subscribe to self";
  }
  return;
};
