import { Box } from "@chakra-ui/react";
import { ConditionNode, OperationNode, RuleNodeType } from "@elphi/types";
import { useEffect } from "react";
import { usePrevious } from "../../../utils/common";
import FormBuilder from "../../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../../form-builder/InputBuilder";
import { ElphiNode, TreeOperations } from "../../tree/types/Tree.types";
import { RuleNodeDataType } from "../tree/RuleTreeBuilder";
import {
  conditionNodeSection,
  operationNodeSection,
  ruleNodeSections
} from "./sections/ruleNodeSections";

const ELPHI_COMPONENT_VIEW = "form";
const CreateRuleNodeForm = (props: {
  node: ElphiNode<RuleNodeDataType>;
  elphiTreeOperations: TreeOperations<unknown>;
}) => {
  const { onChange, state, setState } = useFormBuilderStateHandler({
    initialState: props.node.data || ({} as OperationNode | ConditionNode),
    callback: async (_) => {
      props.elphiTreeOperations.updateNode(props.node.id, state);
      return null;
    }
  });

  const previousStateType = usePrevious(state?.type ?? "");
  useEffect(() => {
    if (
      !!previousStateType &&
      !!state?.type &&
      state.type !== previousStateType
    ) {
      setState({
        type: state.type
      } as OperationNode | ConditionNode);
    }
  }, [state.type]);

  return (
    <Box minW="100%">
      <FormBuilder
        customKey="ruleNodeSections"
        elphiView={ELPHI_COMPONENT_VIEW}
        size={{ minW: "200px" }}
        onChange={onChange}
        sections={ruleNodeSections(
          state,
          (props.node.id !== "$" && state.type > "") ||
            props.node.children.length > 0
        )}
      />
      {state.type === RuleNodeType.Condition && (
        <FormBuilder
          customKey="conditionNodeSection"
          elphiView={ELPHI_COMPONENT_VIEW}
          size={{ minW: "200px" }}
          onChange={onChange}
          sections={[conditionNodeSection(state)]}
        />
      )}
      {state.type === RuleNodeType.Operation && (
        <FormBuilder
          customKey="operationNodeSection"
          elphiView={ELPHI_COMPONENT_VIEW}
          size={{ minW: "100%" }}
          onChange={onChange}
          sections={[operationNodeSection(state)]}
          inputsWrapperProps={{ style: { flexDirection: "column" } }}
        />
      )}
    </Box>
  );
};

export default CreateRuleNodeForm;
