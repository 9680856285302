import { FieldType, NotificationConfiguration } from "@elphi/types";
import { EMPTY, NOT_AVAILABLE } from "../../../constants/common";
import { useNotificationConfigurationHooks } from "../../../hooks/notificationConfiguration.hooks";
import { FuzzySearchSelect } from "../../search/fuzzy/FuzzySearchSelect";

const buildOption = (n: NotificationConfiguration) => {
  const label = n.name || NOT_AVAILABLE;
  const value = n?.id;
  return {
    label,
    value
  };
};
export const NotificationConfigurationSearch = () => {
  const {
    setSelected,
    searchApi,
    searchResponse,
    notificationConfigurationState,
    selectedConfiguration
  } = useNotificationConfigurationHooks();
  const handleOnSelect = (id: string) => {
    setSelected(id);
  };
  return (
    <FuzzySearchSelect
      searchApi={searchApi}
      searchResponse={searchResponse}
      state={notificationConfigurationState}
      fieldType={FieldType.SingleSelect}
      currentValue={selectedConfiguration ? selectedConfiguration.id : EMPTY}
      onSelect={handleOnSelect}
      label={"Search notification configuration"}
      configuration={{
        includeScore: true,
        threshold: 0.4,
        keys: ["name"],
        shouldSort: true
      }}
      buildOption={buildOption}
    />
  );
};
