import {
  ConditionNode,
  ExpressionRuleNode,
  OperationNode,
  RuleNodeType
} from "@elphi/types";
import reactStringReplace from "react-string-replace";

export const valueFormulaPrinter = (
  node: Partial<ExpressionRuleNode> | undefined
) => {
  if (node?.type === RuleNodeType.Value) {
    return node.value;
  } else if (node?.type === RuleNodeType.Field) {
    return `${node.entityType}.${node?.path}`;
  } else if (node?.type === RuleNodeType.Function) {
    if (node.functionType === "dateDelta") {
      return `${node.functionType}(${node.field?.entityType}.${node.field?.path},${node.delta},${node.period})`;
    }
    return `${node.functionType}()`;
  }
  return "";
};

export const ruleFormulaPrinter = (
  node: ConditionNode | OperationNode | undefined,
  que: string[]
): string[] => {
  if (node?.type === RuleNodeType.Condition) {
    node.children?.forEach((c) => {
      que.push("(");
      ruleFormulaPrinter(c, que);
      que.push(node.condition);
    });
  } else if (node?.type === RuleNodeType.Operation) {
    que.push(String(valueFormulaPrinter(node.left)));
    que.push(` ${node.operation} `);
    que.push(String(valueFormulaPrinter(node.right)));
  }
  if (que[que.length - 1] === "and" || que[que.length - 1] === "or") {
    que.pop();
  }
  que.push(")");
  return que;
};

export const ruleFormula = (node: OperationNode | ConditionNode): string => {
  const que: string[] = ["("];
  const qRes = ruleFormulaPrinter(node, que);
  return qRes.join("");
};

export type FormulaKeyworkComponent = {
  keyword: string;
  component: (keyword: string) => JSX.Element;
};

const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const formulaComponentPrinterAdapter = (word: string, index: number) => {
  if (word === "or") return " OR ";
  if (word === "and") return " AND ";
  if (word === "includes") return " INCLUDES ";
  if (word === "includes-any") return " INCLUDES-ANY ";
  if (word === "not-includes") return " NOT-INCLUDES ";

  if (word[0] === "(") {
    return <a style={{ color: colors[index % 4] }}>{word}</a>;
  }
  if (word[0] === ")") {
    return <a style={{ color: colors[index % 4] }}>{word}</a>;
  }
  return word;
};

export const formulaElementBuilder = (
  formula: string,
  _: FormulaKeyworkComponent[]
) => {
  const formulaComponent = reactStringReplace(
    formula,
    /(?:\b|')(or|and|includes-any|includes|not-includes| < | > | == | != | <= | >= )(?:\b|')/g,
    (match, i) => {
      return <b key={match + i}>{formulaComponentPrinterAdapter(match, i)}</b>;
    }
  );
  return <>{formulaComponent}</>;
};
