import { Property } from "@elphi/types";
import { OnChangeInput, Section } from "../../../form-builder/FormBuilder";
import { serviceLinkFloodOrderSection } from "./serviceLinkFloodOrderSection";
import { serviceLinkFloodStatusSection } from "./serviceLinkFloodStatusSection";
import { serviceLinkFloodVerifySection } from "./serviceLinkFloodVerifySection";

export const serviceLinkFloodSections = (
  state: Partial<Property>,
  onChange: (v: OnChangeInput) => void
): Section[] => {
  return [
    serviceLinkFloodOrderSection(state, onChange),
    serviceLinkFloodVerifySection(state, onChange),
    serviceLinkFloodStatusSection(state, onChange)
  ];
};
