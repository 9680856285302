import { EditIcon } from "@chakra-ui/icons";
import { Box, Center, Flex, IconButton, Tooltip } from "@chakra-ui/react";
import { FieldType, LOSUser } from "@elphi/types";
import { useEffect } from "react";
import { printDateTime } from "../../../../firebase/firebase.utils";
import { fromFirebaseIdToLabel } from "../../../../utils/common";
import {
  LargeBoxCell,
  MediumBoxCell,
  SmallBoxCell,
  TextCell
} from "../../../custom/chakra/Cell";
import StyledInputBuilder, {
  useFormBuilderStateHandler
} from "../../../form-builder/InputBuilder";
import { ItemsMenuCopyWidget } from "../../../party/table/v2/ItemsMenuCopyWidget";
import { useAdminUserHooks } from "../AdminUserPage";

export type UserRowProps = {
  index: string | number;
  userId: string;
  onEdit: (userId: string) => void;
  setUsersStatus: ReturnType<typeof useAdminUserHooks>["setUsersStatusHandler"];
  adminUserHooks: ReturnType<typeof useAdminUserHooks>["adminUserHooks"];
  syncState: ReturnType<typeof useFormBuilderStateHandler>["syncState"];
  onChange: ReturnType<typeof useFormBuilderStateHandler>["onChange"];
  state: {
    [id: string]: Partial<LOSUser>;
  };
};
export const UserRow = (props: UserRowProps) => {
  const {
    userId,
    index,
    adminUserHooks,
    // updateLOSUser,
    setUsersStatus,
    syncState,
    onChange,
    state
  } = props;
  const { losUserState } = adminUserHooks;
  useEffect(() => {
    syncState({
      state: losUserState.entities?.[userId],
      shouldSync: !!losUserState.entities?.[userId],
      statePath: () => {
        return [userId.toString()];
      }
    });
  }, [losUserState.entities?.[userId]]);

  return (
    <Flex w="100%" key={index}>
      <SmallBoxCell>
        <TextCell>{index}</TextCell>
      </SmallBoxCell>
      <MediumBoxCell>
        <Flex>
          <Tooltip label={userId}>
            <TextCell>{userId.toString().slice(0, 7)}...</TextCell>
          </Tooltip>
          <Box>
            <ItemsMenuCopyWidget
              items={[
                {
                  label: "elphi-user-id",
                  value: userId
                }
              ]}
            />
          </Box>
        </Flex>
      </MediumBoxCell>
      <MediumBoxCell>
        <TextCell>{losUserState?.entities?.[userId]?.firstName}</TextCell>
      </MediumBoxCell>
      <MediumBoxCell>
        <TextCell>{losUserState?.entities?.[userId]?.middleName}</TextCell>
      </MediumBoxCell>
      <MediumBoxCell>
        <TextCell>{losUserState?.entities?.[userId]?.lastName}</TextCell>
      </MediumBoxCell>
      <LargeBoxCell>
        <TextCell>{losUserState?.entities?.[userId]?.email}</TextCell>
      </LargeBoxCell>
      <LargeBoxCell>
        <TextCell>{losUserState?.entities?.[userId]?.title}</TextCell>
      </LargeBoxCell>
      <LargeBoxCell>
        <Flex w="100%" justifyContent={"space-between"}>
          <Box>
            {losUserState?.entities?.[userId]?.roles?.map((r, i) => {
              return (
                <Box key={i}>
                  <TextCell key={i}>{fromFirebaseIdToLabel(r.label)}</TextCell>
                </Box>
              );
            })}
          </Box>
        </Flex>
      </LargeBoxCell>
      <LargeBoxCell>
        <TextCell>
          {printDateTime(losUserState?.entities?.[userId]?.createdAt)}
        </TextCell>
      </LargeBoxCell>
      <LargeBoxCell>
        <TextCell>
          {printDateTime(losUserState?.entities?.[userId]?.modifiedAt)}
        </TextCell>
      </LargeBoxCell>
      <LargeBoxCell>
        <TextCell>
          {printDateTime(losUserState?.entities?.[userId]?.lastSignIn)}
        </TextCell>
      </LargeBoxCell>
      <SmallBoxCell>
        <Center>
          <Box
            w="30px"
            h="30px"
            borderRadius={"50%"}
            bgColor={
              losUserState?.entities?.[userId]?.losStatus === "active"
                ? "green.400"
                : "gray.400"
            }
          ></Box>
        </Center>
      </SmallBoxCell>
      <SmallBoxCell>
        <Box>
          <StyledInputBuilder
            currentValue={state?.[userId]?.status}
            fieldType={FieldType.Switch}
            onChange={(e) => {
              setUsersStatus({
                ids: [userId.toString()],
                status: e.target.value
              }).then((r) => {
                if (r.status === 200) {
                  alert(
                    `user: ${state?.[userId]?.email} is now ${e.target.value}`
                  );
                } else {
                  alert(
                    `Error user: ${state?.[userId]?.email} failed to ${e.target.value}`
                  );
                }
              });
              onChange({
                fieldKey: [userId.toString(), "status"],
                fieldType: FieldType.Switch,
                value: e.target.value
              });
            }}
            label=""
          />
        </Box>
      </SmallBoxCell>
      <MediumBoxCell>
        <Tooltip
          aria-label="edit-user"
          label={`edit ${state?.[userId]?.email}`}
        >
          <Box>
            <IconButton
              disabled={!userId}
              bgColor="transparent"
              aria-label="edit-user"
              icon={<EditIcon />}
              size="m"
              onClick={() => props.onEdit(userId)}
            />
          </Box>
        </Tooltip>
      </MediumBoxCell>
    </Flex>
  );
};
