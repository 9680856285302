const notaryTemplateMap = {
  "Notary - Connecticut":
    "static/document/notary/Elphi_ConnecticutNotaryTemplate.docx",
  "Notary - Florida":
    "static/document/notary/Elphi_FloridaNotaryTemplate.docx",
  "Notary - Georgia":
    "static/document/notary/Elphi_GeorgiaNotaryTemplate.docx",
  "Notary - Louisiana":
    "static/document/notary/Elphi_LouisianaNotaryTemplate.docx",
  "Notary - Maine":
    "static/document/notary/Elphi_MaineNotaryTemplate.docx",
  "Notary - Maryland":
    "static/document/notary/Elphi_MarylandNotaryTemplate.docx",
  "Notary - Michigan":
    "static/document/notary/Elphi_MichiganNotaryTemplate.docx",
  "Notary - Minnesota":
    "static/document/notary/Elphi_MinnesotaNotaryTemplate.docx",
  "Notary - New Jersey":
    "static/document/notary/Elphi_NewJerseyNotaryTemplate.docx",
  "Notary - Ohio":
    "static/document/notary/Elphi_OhioNotaryTemplate.docx",
  "Notary - All Other States":
    "static/document/notary/Elphi_OtherStatesNotaryTemplate.docx",
  "Notary - South Carolina":
    "static/document/notary/Elphi_SouthCarolinaNotaryTemplate.docx",
  "Notary - Virginia":
    "static/document/notary/Elphi_VirginiaNotaryTemplate.docx"
};

const closingTemplateMap = {
  "Rental30 LRF":
    "static/document/closing/Elphi_R30LRF.docx",
  "Rental30 Closing Instructions Purchase":
    "static/document/closing/Elphi_R30ClosingInstructions_Purchase.docx",
  "Short Term Closing Instructions Purchase":
    "static/document/closing/Elphi_STClosingInstructions_Purchase.docx",
  "Short Term LRF Purchase":
    "static/document/closing/Elphi_STLRF_Purchase.docx",
  "Rental30 Closing Instructions Refinance":
    "static/document/closing/Elphi_R30ClosingInstructions_Refinance.docx",
  "Short Term Closing Instructions Refinance":
    "static/document/closing/Elphi_STClosingInstructions_Refinance.docx",
  "Short Term LRF Refinance":
    "static/document/closing/Elphi_STLRF_Refinance.docx",
  "Lender Finance Rental30 Closing Instructions Purchase":
    "static/document/closing/Elphi_LF_R30ClosingInstructions_Purchase.docx",
  "Lender Finance Rental30 Closing Instructions Refinance":
    "static/document/closing/Elphi_LF_R30ClosingInstructions_Refinance.docx",
  "Lender Finance Short Term Closing Instructions Purchase":
    "static/document/closing/Elphi_LF_STClosingInstructions_Purchase.docx",
  "Lender Finance Short Term Closing Instructions Refinance":
    "static/document/closing/Elphi_LF_STClosingInstructions_Refinance.docx"
};

export const documentTemplateMap = {
  Demo:
    "static/document/Demo.docx",
  "ACH Payment Form":
    "static/document/Elphi_ach_payment_form.docx",
    "The REI Lender ACH":
    "static/document/Elphi_LF_ach_payment_form.docx",
  "FixNFlip Portfolio Affidavit Of Occupancy":
    "static/document/Elphi_affidavit_of_occupancy_-_fixnflip_portfolio.docx",
  "Short Term Cross Collateralization Agreement":
    "static/document/Elphi_fixnflip_cross-collateralization_agreement.docx",
  "Affidavit of Title":
    "static/document/Elphi_affidavit_of_title_portfolio.docx",
  "Commercial Guaranty":
    "static/document/Elphi_commercial_guaranty_v1.4.docx",
  "Short Term Commercial Promissory Note - Cashout":
    "static/document/Elphi_commercial_promissory_note_-_cashout_portfolio_v1.2.docx",
  "Short Term Deed to Secure Debt - GA":
    "static/document/Elphi_GA_Deed_to_Secure_Debt.docx",
  "Short Term Deed of Trust":
    "static/document/Elphi_deed_of_trust_short_term.docx",
  "Document Re-Execution":
    "static/document/Elphi_document_re-execution_v.1.0_old.docx",
  "Entity Certification":
    "static/document/Elphi_entity_certification.docx",
  "Short Term Lender Affidavit - Maryland":
    "static/document/Elphi_lender_affidavit_-_md_v1.2.docx",
  "Non-Commitment Portfolio Loan Agreement":
    "static/document/Elphi_loan_agreement_-_non_commitment_portfolio_v1.3.docx",
  "Short Term Mortgage":
    "static/document/Elphi_short_term_mortgage.docx",
  "New York Tax Affidavit":
    "static/document/Elphi_ny_tax_affidavit.docx",
  "Post Closing Letter":
    "static/document/Elphi_post-closing_letter_v.1.0_old.docx",
  "Rental30 Single Asset Affidavit Of Occupancy":
    "static/document/Elphi_rental30_affidavit_of_occupancy_-_single_asset.docx",
  "Rental30 Portfolio Affidavit Of Title":
    "static/document/Elphi_rental30_affidavit_of_title.docx",
  "Rental30 Affidavit of Occupancy":
    "static/document/Elphi_rental30_affidavit_of_occupancy_-_portfolio.docx",
  "Rental30 Business Purpose Affidavit":
    "static/document/Elphi_rental30_business_purpose_affidavit.docx",
  "Rental30 Assignment Of Leases And Rents":
    "static/document/Elphi_rental30_assignment_of_leases_and_rents.docx",
  "Short Term Certificate of Borrower and Guarantor":
    "static/document/Elphi_certificate_of_borrower_and_guarantor.docx",
  "Rental30 Certificate Of Commercial Loan":
    "static/document/Elphi_rental30_certificate_of_commercial_loan.docx",
  "Rental30 Re-Execution Agreement":
    "static/document/Elphi_rental30_document_re-execution_agreement.docx",
  "Rental30 Environmental Indemnity":
    "static/document/Elphi_rental30_environmental_indemnity.docx",
  "Rental30 Guaranty":
    "static/document/Elphi_rental30_guaranty_v2.1.docx",
  "Rental30 Loan Agreement":
    "static/document/Elphi_rental30_loan_agreementv1.3.docx",
  "Rental30 ARM Promissory Note":
    "static/document/Elphi_rental30_promissory_note_5.1_arm_v1.3.docx",
  "Rental30 5 IO 25 ARM Promissory Note":
    "static/document/Elphi_rental30_promissory_note_5_io_25_arm.docx",
  "Rental30 Fixed Promissory Note":
    "static/document/Elphi_rental30_promissory_note_fixed_v1.3.docx",
  "Rental30 Deed to Secure Debt - GA":
    "static/document/Elphi_rental30_security_instrument_-_ga_portfolio.docx",
  "Rental30 Mortgage":
    "static/document/Elphi_rental30_mortgage.docx",
  "Rental30 Deed of Trust":
    "static/document/Elphi_rental30_DeedOfTrust.docx",
  "Short Term Business Purpose Affidavit":
    "static/document/Elphi_short_term_business_purpose_affidavit.docx",
  "Short Term Assignment of Leases and Rents":
    "static/document/Elphi_term_loan_assignment_of_leases_and_rents_portfolio.docx",
  "Short Term Assignment of Licenses":
    "static/document/Elphi_term_loan_assignment_of_licenses_portfolio.docx",
  "Term Loan Certification Of Commercial Loan":
    "static/document/Elphi_term_loan_certification_of_commercial_loan.docx",
  "Short Term Environmental Indemnity":
    "static/document/Elphi_term_loan_environmental_indemnity_v.1.0_old.docx",
  "Unanimous Written Consent":
    "static/document/Elphi_unanimous_written_consent.docx",
  "Assignment of Mortgage - Deephaven":
    "static/document/Elphi_Assignment_of_Mortgage_Deephaven.docx",
  "Assignment of Mortgage - Neuberger Berman":
    "static/document/Elphi_Assignment_of_Mortgage_Neuberger_Berman.docx",
  "Assignment of Mortgage - Palisades":
    "static/document/Elphi_Assignment_of_Mortgage_Palisades.docx",
  "Assignment of Mortgage":
    "static/document/Elphi_Assignment_of_Mortgage.docx",
  "Deed of Trust - Palisades":
    "static/document/Elphi_Deed_Of_Trust_Palisades.docx",
  "Deed of Trust - Neuberger Berman":
    "static/document/Elphi_Deed_Of_Trust_Neuberger_Berman.docx",
  "Deed of Trust - Deephaven":
    "static/document/Elphi_Deed_Of_Trust_Deephaven.docx",
  "Deed of Trust":
    "static/document/Elphi_Deed_Of_Trust.docx",
  "Short Term Loan Agreement - Commitment Funding":
    "static/document/Elphi_Loan_Agreement_Commitment_Funding.docx",
  "Short Term Loan Agreement - Full Interest":
    "static/document/Elphi_Loan_Agreement_Full_Interest.docx",
  "Short Term Commercial Guaranty - Limited Recourse":
    "static/document/Elphi_Commercial_Guaranty_Limited_Recourse.docx",
  "Short Term Commercial Promissory Note - Commitment Funding":
    "static/document/Elphi_Commercial_Promissory_Note_Commitment_Funding.docx",
  "Short Term Commercial Promissory Note - Full Interest":
    "static/document/Elphi_Commercial_Promissory_Note_Full_Interest.docx",
  "Rental30 Deed of Trust - TN":
    "static/document/Elphi_Rental30_DeedOfTrust_TN.docx",
  "Rental30 Mortgage - MI":
    "static/document/Elphi_Rental30_Mortgage_MI.docx",
  "Rental30 Insurance Requirements":
    "static/document/Elphi_Rental30_Insurance_Requirements.docx",
  "Bridge Plus Insurance Requirements":
    "static/document/Elphi_BridgePlus_Insurance Requirements.docx",
  "FixNFlip Insurance Requirements":
    "static/document/Elphi_FNF_Insurance Requirements.docx",
  "New Construction Insurance Requirements":
    "static/document/Elphi_NC_Insurance Requirements.docx",
  "ST Borrower Approval":
    "static/document/Elphi_ST_Borrower_Approval.docx",
  "New Construction Affidavit of Occupancy":
    "static/document/elphi_affidavit_of_occupancy_-_fixnflip_portfolio_New_Construction.docx",
  "New Construction Affidavit of Title":
    "static/document/elphi_affidavit_of_title_portfolio_new_construction.docx",
  "New Construction Certificate of Borrower and Guarantor":
    "static/document/elphi_certificate_of_borrower_and_guarantor_new_construction.docx",
  "New Construction Promissory Note Cashout":
    "static/document/elphi_commercial_promissory_note_-_cashout_portfolio_v1.2_new_construction.docx",
  "New Construction Promissory Note Commitment Funding":
    "static/document/elphi_commercial_promissory_note_commitment_Funding_new_construction.docx",
  "New Construction Promissory Note Full Interest":
    "static/document/elphi_commercial_promissory_note_full_interest_new_construction.docx",
  "New Construction Deed of Trust":
    "static/document/elphi_deed_of_trust_short_term_new_construction.docx",
  "New Construction Cross Collateralization":
    "static/document/elphi_fixnflip_cross-collateralization_agreement_new_construction.docx",
  "New Construction GA Deed to Secure Debt":
    "static/document/elphi_ga_deed_to_secure_debt_new_construction.docx",
  "New Construction Loan Agreement Non Commitment":
    "static/document/elphi_loan_agreement_-_non_commitment_portfolio_v1.3_new_construction.docx",
  "New Construction Loan Agreement Commitment Funding":
    "static/document/elphi_loan_agreement_commitment_funding_new_construction.docx",
  "New Construction Loan Agreement Full Interest":
    "static/document/elphi_loan_agreement_full_Interest_new_construction.docx",
  "New Construction Insurance Requirements Legal Description":
    "static/document/elphi_nc_insurance requirements_legal_description.docx",
  "New Construction Business Purpose Affidavit":
    "static/document/elphi_short_term_business_purpose_affidavit_new_construction.docx",
  "New Construction Mortgage":
    "static/document/elphi_short_term_mortgage_new_construction.docx",
  "New Construction Loan Assignment of Leases":
    "static/document/elphi_term_loan_assignment_of_leases_and_rents_portfolio_new_construction.docx",
  "New Construction Loan Assignment of Licenses":
    "static/document/elphi_term_loan_assignment_of_licenses_portfolio_new_construction.docx",
  "New Construction Loan Environmental Indemnity":
    "static/document/elphi_term_loan_environmental_indemnity_v.1.0_old_new_construction.docx",
  "Lender Finance Bridge Plus Insurance Requirements":
    "static/document/Elphi_LF_BridgePlus_Insurance_Requirements.docx",
  "Lender Finance New Construction Insurance Requirements":
    "static/document/Elphi_LF_NC_Insurance_Requirements.docx",
  "Lender Finance Rental30 Insurance Requirements":
    "static/document/Elphi_LF_Rental30_Insurance_Requirements.docx",
  "Lender Finance Assignment of Mortgage":
    "static/document/Elphi_LF_Assignment_of_Mortgage.docx",
  "Lender Finance Deed of Trust":
    "static/document/Elphi_LF_Deed_Of_Trust.docx",
  "Loan Status Update":
    "static/document/Elphi_loan_status_update.docx",
  "CLA FNF NC Cashout Refinance":
    "static/document/Elphi_cla_fnf_nc_cashout_refi.docx",
  "CLA FNF NC Purchase":
    "static/document/Elphi_cla_fnf_nc_purchase.docx",
  "CLA FNF NC Rate Term Refinance":
    "static/document/Elphi_cla_fnf_nc_rateterm_refi.docx",
  "CLA Rental30 BridgePlus Cashout Refinance":
    "static/document/Elphi_cla_r30_bridgeplus_cashout_refi.docx",
  "CLA Rental30 BridgePlus Purchase":
    "static/document/Elphi_cla_r30_bridgeplus_purchase.docx",
  "CLA Rental30 BridgePlus Rate Term Refinance":
    "static/document/Elphi_cla_r30_bridgeplus_rateterm_refi.docx",
  ...notaryTemplateMap,
  ...closingTemplateMap
};
