import { Flex } from "@chakra-ui/react";
import { BulkOperations } from "./BulkOperationButton";
import { PartyGroupFilters } from "./PartyGroupFilters";

export const Toolbar = () => {
  return (
    <Flex gap={"8px"}>
      <BulkOperations />
      <PartyGroupFilters />
    </Flex>
  );
};
