import { Box } from "@chakra-ui/react";
import TaskRuleBuilder from "../task/task-rule/TaskRuleBuilder";

const TaskRuleTemplateTab = () => {
  return (
    <Box h="100%" w="100%">
      <Box h="100%" w="100%">
        <TaskRuleBuilder />
      </Box>
    </Box>
  );
};

export default TaskRuleTemplateTab;
