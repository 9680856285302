import {
  BaseMilestoneData,
  ChangeReasonMilestones,
  DeadDealGenericReasonType,
  DeadDealSpecificReasonForDeniedInUnderwritingType,
  DeadDealSpecificReasonForIncompleteSubmissionType,
  DeadDealSpecificReasonForSubmittedByMistakeType,
  DeadDealSpecificReasonForWithdrawnByApplicantType,
  DealMilestoneType,
  GenericReasonMap,
  MilestoneData,
  SpecificReasonMap,
  SuspendedGenericReasonType,
  SuspendedSpecificReasonForBorrowerSuspendedType,
  SuspendedSpecificReasonForDelayInProcessingType
} from "@elphi/types";
import { changeReasonMilestones } from "@elphi/utils";

export const genericReasonMap: GenericReasonMap = {
  [DealMilestoneType.DeadDeal]: DeadDealGenericReasonType,
  [DealMilestoneType.Suspended]: SuspendedGenericReasonType
};

export const specificReasonMap: SpecificReasonMap = {
  [DealMilestoneType.DeadDeal]: {
    [DeadDealGenericReasonType.IncompleteSubmission]:
      DeadDealSpecificReasonForIncompleteSubmissionType,
    [DeadDealGenericReasonType.DeniedInUnderwriting]:
      DeadDealSpecificReasonForDeniedInUnderwritingType,
    [DeadDealGenericReasonType.WithdrawnByApplicant]:
      DeadDealSpecificReasonForWithdrawnByApplicantType,
    [DeadDealGenericReasonType.SubmittedByMistake]:
      DeadDealSpecificReasonForSubmittedByMistakeType
  },
  [DealMilestoneType.Suspended]: {
    [SuspendedGenericReasonType.DelayInProcessing]:
      SuspendedSpecificReasonForDelayInProcessingType,
    [SuspendedGenericReasonType.BorrowerSuspended]:
      SuspendedSpecificReasonForBorrowerSuspendedType
  }
};

const isInChangeReasonMilestones = (milestone: string): boolean => {
  return changeReasonMilestones.map((k) => k.toString()).includes(milestone);
};

export const isChangeReasonMilestoneGuard = (
  value: string
): value is ChangeReasonMilestones => {
  return isInChangeReasonMilestones(value.toString());
};

export const isChangeReasonMilestoneDataGuard = (
  data: BaseMilestoneData | MilestoneData<ChangeReasonMilestones>
): data is MilestoneData<ChangeReasonMilestones> => {
  return isInChangeReasonMilestones(data.type.toString());
};
