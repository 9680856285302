import {
  Box,
  Flex,
  FlexProps,
  Heading,
  PlacementWithLogical,
  Stack,
  Text,
  Tooltip
} from "@chakra-ui/react";
import { FieldStatus, SectionStatus } from "@elphi/types";
import { JSX } from "@emotion/react/jsx-runtime";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { chakraComponents } from "chakra-react-select";
import React from "react";
import { NavigationPath } from "../../shared/types/navigation.types";
import ScrollableSections from "../ScrollableSections";
import { SearchComponentProps } from "../SearchComponent";
import { isMobileDevice } from "../utils/mobileUtils";
import StyledInputBuilder, {
  StrictUnionInputBuilderProps
} from "./InputBuilder";
import {
  SectionStatistics,
  StatisticCountView,
  sectionStatistics
} from "./SectionStatistics";
import { SectionStatusUpdater } from "./SectionStatusUpdater";
import { FieldType } from "./fieldFormat.types";
import { BaseInputFormatter } from "./formatters/formatter.types";

type CustomComponent<C extends React.ComponentType> = {
  component: C;
  props?: Partial<React.ComponentProps<C>>;
};

type BaseInputChangeEvents = {
  onChange?: StrictUnionInputBuilderProps["onChange"];
  onSelect?: SearchComponentProps["onSelect"];
};

type ComponentsBaseProps = (
  | { fieldType: FieldType }
  | Partial<Omit<SearchComponentProps, "fieldType" | "onSelect">>
) &
  BaseInputChangeEvents;

export const createCustomComponentConfig = <C extends React.ComponentType>(
  config: CustomComponent<C>
) => config;

export type InputBuilderSpecs<
  C extends React.ComponentType = React.ComponentType<ComponentsBaseProps>
> = {
  label: string;
  labelPosition?: "left" | "up" | "right" | "down";
  fieldKey: string[];
  fieldType: FieldType;
  isValid?: boolean;
  shouldMarkEmpty?: boolean;
  isRequired?: boolean;
  isReadOnly?: boolean;
  formatter?: BaseInputFormatter<FieldType, any, any>;
  isHidden?: boolean;
  currentValue: any;
  fieldStatus?: FieldStatus;
  customColor?: string;
  options?: { label: string; value: string }[];
  componentOverride?: CustomComponent<C>;
  attachedComponent?: JSX.Element | null;
  toolTipLabel?: string;
  toolTipPlace?: PlacementWithLogical;
  toolTipArrow?: boolean;
  customComponent?: Partial<{
    [P in keyof typeof chakraComponents]: (props: any) => EmotionJSX.Element;
  }>;
};
export type Section = {
  isHidden?: boolean;
  header?: string | JSX.Element;
  inputs: InputBuilderSpecs[];
  extraBody?: React.ReactElement;
  id?: string;
};

export type OnChangeInput = Pick<
  InputBuilderSpecs,
  "fieldKey" | "fieldType"
> & {
  value: any;
};
export type FormBuilderProps = {
  top?: React.ComponentProps<typeof Box>["top"];
  elphiView?: "accordion" | "form";
  onChange: (e: OnChangeInput) => void;
  size?: {
    minW: string;
  };
  customKey: string;
  sections: Section[];
  lazyLoad?: boolean;
  isDisabled?: boolean;
  defaultIndex?: number[];
  navigationPath?: NavigationPath;
  enableExpandAll?: boolean;
  showOptional?: boolean;
  inputsWrapperProps?: FlexProps;
  setSectionValidationState?: React.Dispatch<
    React.SetStateAction<{
      [index: string]: boolean;
    }>
  >;
};
export const SectionHeader = (props: { header: string | JSX.Element }) => {
  return typeof props.header === "string" ? (
    <Heading>{props.header}</Heading>
  ) : (
    props.header
  );
};

type InputsFormBuilderProps = {
  inputs: InputBuilderSpecs[];
  onChange: Function;
  showOptional?: boolean;
  isDisabled?: boolean;
  inputsWrapperProps?: FlexProps;
  size?: {
    minW: string;
  };
};

const InputHandlerWrapper = (props: {
  input: InputBuilderSpecs<React.ComponentType<ComponentsBaseProps>>;
  onChange: Function;
  showOptional?: boolean;
  isDisabled?: boolean;
  size?: {
    minW: string;
  };
}) => {
  const { input } = props;
  const customOnSelect = input.componentOverride?.props?.onSelect;
  const customOnChange = input.componentOverride?.props?.onChange;
  const OverrideComponent = input.componentOverride?.component;

  const onChange = (value) => {
    input.fieldKey?.length !== 0 &&
      props.onChange({
        value,
        fieldType: input.fieldType,
        fieldKey: input.fieldKey
      });

    customOnSelect?.(value);
    customOnChange?.(value);
  };
  if (OverrideComponent) {
    return (
      <OverrideComponent
        {...input.componentOverride?.props}
        currentValue={input.currentValue}
        fieldType={input.fieldType}
        onSelect={onChange}
        onChange={onChange}
      />
    );
  }

  return (
    <StyledInputBuilder
      customComponent={input.customComponent}
      isRequired={input.isRequired}
      isDisabled={props.isDisabled || input.fieldKey?.length === 0}
      isReadOnly={input.isReadOnly}
      isValid={input.isValid}
      shouldMarkEmpty={input.shouldMarkEmpty}
      fieldType={input.fieldType}
      options={input.options}
      label={input.label}
      currentValue={input.currentValue}
      customColor={input.customColor}
      formatter={input.formatter}
      shouldDisplayErrorMessage={true}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export const InputsFormBuilder = ({
  inputs,
  ...props
}: InputsFormBuilderProps) => {
  const minW = props.size
    ? props.size.minW
    : isMobileDevice()
    ? "310px"
    : "500px";

  return (
    <Stack>
      <Flex flexWrap={"wrap"} gap={"8"} {...props.inputsWrapperProps}>
        {inputs
          .filter((input) => input.isHidden !== true)
          .map((input, index) => {
            return (
              <Tooltip
                key={index}
                label={input.toolTipLabel}
                isDisabled={!input.toolTipLabel}
                placement={input.toolTipPlace}
                hasArrow={input.toolTipArrow}
              >
                <Box key={index} minW={minW}>
                  {input.labelPosition === "up" && (
                    <Text pl="3px" fontWeight={"bold"} width={minW}>
                      {input.label}
                      {input.isRequired && !props.showOptional && " *"}
                      {!input.isRequired && props.showOptional && (
                        <Box as="span" ml={1} color={"gray.400"}>
                          (optional)
                        </Box>
                      )}
                    </Text>
                  )}
                  <Flex w="100%" width={minW}>
                    <Box w="100%">
                      <InputHandlerWrapper input={input} {...props} />
                    </Box>
                    <Box>{input.attachedComponent}</Box>
                  </Flex>
                </Box>
              </Tooltip>
            );
          })}
      </Flex>
    </Stack>
  );
};

const FormBuilder = (props: FormBuilderProps) => {
  const sections = props.sections
    .filter((s) => s.isHidden !== true)
    .map((s, i) => {
      const fieldsStats = sectionStatistics(s);
      const invalid = fieldsStats["Populated Not Valid"];
      const invalidRequired = fieldsStats[SectionStatus.InvalidRequired];
      const overrideFields = fieldsStats[SectionStatus.Override];

      return {
        index: i,
        extraBody: s.extraBody,
        header: s.header ? (
          <Flex w="100%">
            <Box w="100%">
              <SectionHeader key={i} header={s.header} />
            </Box>
            {props.elphiView !== "form" && (
              <Flex pt="10px" float="right" w="100%" justifyContent={"center"}>
                <Flex
                  display={"flex"}
                  flexDirection={"row"}
                  w={250}
                  pl={"20px"}
                >
                  {!!invalidRequired && (
                    <StatisticCountView
                      label={"Required"}
                      value={invalidRequired.toString()}
                      labelBgColor="orange.500"
                    />
                  )}
                  {!!overrideFields && (
                    <StatisticCountView
                      label={"override"}
                      value={overrideFields.toString()}
                      labelBgColor="black"
                      textLabelColor="white"
                    />
                  )}
                  {!!invalid && (
                    <StatisticCountView
                      label={"Invalid"}
                      value={invalid.toString()}
                      labelBgColor="red.300"
                    />
                  )}
                </Flex>
                <SectionStatusUpdater
                  section={s}
                  onChange={props.onChange}
                  isDisabled={props.isDisabled}
                />
                <SectionStatistics
                  index={i}
                  section={s}
                  onSectionValidationStateChange={(isSectionValid) => {
                    props?.setSectionValidationState?.((prev) => ({
                      ...prev,
                      [i]: isSectionValid
                    }));
                  }}
                  id={s.id}
                />
              </Flex>
            )}
          </Flex>
        ) : undefined,
        body: <InputsFormBuilder inputs={s.inputs} {...props} />
      };
    });

  return (
    <Stack>
      <ScrollableSections
        customKey={props.customKey}
        defaultIndex={props.defaultIndex}
        lazyLoad={props.lazyLoad}
        sections={sections}
        top={props.top}
        elphiView={props.elphiView}
        navigationPath={props.navigationPath}
      />
    </Stack>
  );
};
export const InputBuilderAttachedComponentWrapper = (props: {
  inputComponent: JSX.Element;
  attachedComponent: JSX.Element;
}) => {
  return (
    <Box w="100%">
      <Flex w="100%">
        <Box w="100%">{props.inputComponent}</Box>
        <Box>{props.attachedComponent}</Box>
      </Flex>
    </Box>
  );
};

export default FormBuilder;
