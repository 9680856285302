import { Box, Flex } from "@chakra-ui/react";
import { TABLE_MIN_WIDTH } from "../../constants/common";
import TaskTemplateHeader from "../task/task-template/TaskTemplateHeader";
import { TaskTemplateTable } from "../task/task-template/v2/TaskTemplateTable";
const TaskTemplateTab = () => {
  return (
    <Box>
      <TaskTemplateHeader />
      <Box overflowX="auto">
        <Flex overflowX={"scroll"} minW={TABLE_MIN_WIDTH}>
          <TaskTemplateTable />
        </Flex>
      </Box>
    </Box>
  );
};

export default TaskTemplateTab;
