import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { RuleTemplateType, TaskRuleTemplate } from "@elphi/types";
import { useEffect } from "react";
import { responseHandler } from "../../../apis/rtk/response.handler";
import elphiTheme from "../../../assets/themes/elphi.theme.default";
import { OmitCreate, OmitUpdate } from "../../../firebase/firebase.types";
import { useTaskRuleHooks } from "../../../hooks/taskRule.hooks";
import { taskRuleApi } from "../../../redux/v2/task-rule/taskRule.service";
import { FieldType } from "../../form-builder/fieldFormat.types";
import FormBuilder from "../../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../../form-builder/InputBuilder";
import { formulaElementBuilder } from "../../rule-builder/formulaPrinter.utils";
import { TreePrint } from "../../rule-builder/tree/RuleTreeBuilder";
import { ruleSchema } from "../../rule-builder/tree/serialization.utils";
import { useElphiToast } from "../../toast/toast.hook";

export const TaskRuleBuilderModal = (props: { treePrint: TreePrint }) => {
  const { selectedTaskRule, setSelectedTaskRule } = useTaskRuleHooks();
  const { onChange, state, setState } = useFormBuilderStateHandler({
    initialState: {
      ruleName: selectedTaskRule ? selectedTaskRule.ruleName : ""
    }
  });
  useEffect(() => {
    if (selectedTaskRule) {
      setState({ ruleName: selectedTaskRule.ruleName });
    } else {
      setState({ ruleName: "" });
    }
  }, [selectedTaskRule]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { errorToast, successToast } = useElphiToast();
  const [createRuleApi, createRuleResponse] = taskRuleApi.useCreateMutation();
  const [updateRuleApi, updateRuleResponse] = taskRuleApi.useUpdateMutation();

  const createTaskRuleHandler = (rule: TaskRuleTemplate) => {
    createRuleApi(rule)
      .then(responseHandler)
      .then((d) => {
        if (d.status === 200) {
          successToast({
            title: "New Rule Created",
            description: `task rule: ${d.data.id}`
          });
          setSelectedTaskRule(d.data.id);
          onClose();
        } else if (d.status === 400) {
          errorToast({
            title: "Failed to create rule",
            description: d?.data?.description || "something went wrong"
          });
        }
      });
  };
  const updateTaskRuleHandler = (rule: TaskRuleTemplate) => {
    updateRuleApi(rule)
      .then(responseHandler)
      .then((d) => {
        if (d.status === 200) {
          successToast({
            title: "Rule Update",
            description: `task rule: ${d.data.id}`
          });
          onClose();
        } else if (d.status === 400) {
          errorToast({
            title: "Failed to create rule",
            description: d?.data?.description || "something went wrong"
          });
        }
      });
  };
  return (
    <Box>
      <Box>
        <Button
          float={"right"}
          {...elphiTheme.components.light.button.primary}
          onClick={onOpen}
        >
          Save Rule
        </Button>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"} width="100%">
          <ModalHeader>Task Rule</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize={"2xl"}>Rule Formula:</Text>
            <Box p="10px">
              {formulaElementBuilder(props.treePrint.formula, [])}
            </Box>
            <FormBuilder
              customKey="TaskRuleBuilderForm"
              elphiView="form"
              onChange={onChange}
              sections={[
                {
                  inputs: [
                    {
                      label: "name",
                      labelPosition: "up",
                      fieldType: FieldType.String,
                      fieldKey: ["ruleName"],
                      currentValue: state.ruleName,
                      isValid: state.ruleName > ""
                    }
                  ]
                }
              ]}
            />
            <Flex float="right">
              {selectedTaskRule && (
                <Box p="5px">
                  <Button
                    isLoading={updateRuleResponse.isLoading}
                    onClick={() => {
                      try {
                        const { includeEntities, rule } = ruleSchema(
                          props.treePrint.rule as any
                        );
                        if (selectedTaskRule) {
                          const newRule: OmitUpdate<TaskRuleTemplate> = {
                            type: RuleTemplateType.Task,
                            id: selectedTaskRule.id,
                            ruleName: state.ruleName,
                            includeEntities,
                            rule
                          };
                          updateTaskRuleHandler(newRule as any);
                        }
                      } catch (err) {
                        errorToast({
                          title: "failed to save rule",
                          description: "unable to build data schema" + err
                        });
                      }
                    }}
                    isDisabled={!state.ruleName || createRuleResponse.isLoading}
                    float="right"
                    {...elphiTheme.components.light.button.secondary}
                  >
                    Update
                  </Button>
                </Box>
              )}
              <Box p="5px">
                <Button
                  isLoading={createRuleResponse.isLoading}
                  onClick={() => {
                    try {
                      const { includeEntities, rule } = ruleSchema(
                        props.treePrint.rule as any
                      );

                      const newRule: OmitCreate<TaskRuleTemplate> = {
                        type: RuleTemplateType.Task,
                        ruleName: state.ruleName,
                        includeEntities,
                        rule
                      };
                      createTaskRuleHandler(newRule as any);
                    } catch (err) {
                      errorToast({
                        title: "failed to save rule",
                        description: "unable to build data schema" + err
                      });
                    }
                  }}
                  isDisabled={!state.ruleName || updateRuleResponse.isLoading}
                  float="right"
                  {...elphiTheme.components.light.button.primary}
                >
                  Create New
                </Button>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
