import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { RolePage } from "../role-permission/RolePage";
import { AdminUserPage } from "../user/AdminUserPage";

const HEADER_HEIGHT = "80px";
export const AdminHomeTabs = () => {
  const tabsLabel = ["Users", "Roles & Permissions", "Scopes"];
  return (
    <Box h="100%" w="100%" overflow="hidden">
      <Box p="15px" boxShadow={"lg"} bgColor="grey.400">
        STATS
      </Box>
      <Tabs
        // defaultIndex={1}
        isLazy
        variant="enclosed"
        lazyBehavior="unmount"
        h="100%"
        w="100%"
        position="sticky"
        top="0"
      >
        <TabList>
          {tabsLabel.map((l, i) => {
            return <Tab key={i}>{l}</Tab>;
          })}
        </TabList>
        <TabPanels h={`calc(100% - ${HEADER_HEIGHT})`} w="100%" p="0">
          <TabPanel h="100%" w="100%" p="0">
            <AdminUserPage />
          </TabPanel>
          <TabPanel h="100%" w="100%" p="0">
            <RolePage />
          </TabPanel>
          <TabPanel h="100%" w="100%" p="0"></TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
