import { BaseEntity, FirebaseCollections } from "../db";
import {
  Asset,
  BaseDocumentMetadata,
  BaseInsurancePolicy,
  CreditScore,
  Deal,
  DealPartyRelation,
  DealProperty,
  DealUser,
  ElphiEntityType,
  InsurancePolicyEntityType,
  LOSUser,
  Party,
  PartyAsset,
  PartyRelation,
  Property,
  RolodexBranchRepresentativeRelation,
  RolodexServiceProvider,
  Statement,
  Task
} from "../entities";
import { GcpFileInfo, StorageProvider } from "../storage";

export type BaseSnapshot<
  TType extends FirebaseCollections,
  TData extends BaseEntity<object>
> = {
  collection: TType;
  documents: { [id: string]: TData };
};

export type ElphiSnapshot = {
  snapshot?: {
    [FirebaseCollections.Deal]?: BaseSnapshot<
      FirebaseCollections.Deal,
      BaseEntity<Deal>
    >;
    [FirebaseCollections.DealPartyRelation]?: BaseSnapshot<
      FirebaseCollections.DealPartyRelation,
      BaseEntity<DealPartyRelation>
    >;
    [FirebaseCollections.Party]?: BaseSnapshot<
      FirebaseCollections.Party,
      BaseEntity<Party>
    >;
    [FirebaseCollections.PartyRelation]?: BaseSnapshot<
      FirebaseCollections.PartyRelation,
      BaseEntity<PartyRelation>
    >;
    [FirebaseCollections.PartyAsset]?: BaseSnapshot<
      FirebaseCollections.PartyAsset,
      BaseEntity<PartyAsset>
    >;
    [FirebaseCollections.Asset]?: BaseSnapshot<
      FirebaseCollections.Asset,
      BaseEntity<Asset>
    >;
    [FirebaseCollections.Statement]?: BaseSnapshot<
      FirebaseCollections.Statement,
      BaseEntity<Statement>
    >;

    [FirebaseCollections.Property]?: BaseSnapshot<
      FirebaseCollections.Property,
      BaseEntity<Property>
    >;
    [FirebaseCollections.DealProperty]?: BaseSnapshot<
      FirebaseCollections.DealProperty,
      BaseEntity<DealProperty>
    >;
    [FirebaseCollections.DealUser]?: BaseSnapshot<
      FirebaseCollections.DealUser,
      BaseEntity<DealUser>
    >;
    [FirebaseCollections.LOSUser]?: BaseSnapshot<
      FirebaseCollections.LOSUser,
      BaseEntity<LOSUser>
    >;
    [FirebaseCollections.Task]?: BaseSnapshot<
      FirebaseCollections.Task,
      BaseEntity<Task>
    >;
    [FirebaseCollections.CreditScore]?: BaseSnapshot<
      FirebaseCollections.CreditScore,
      BaseEntity<CreditScore>
    >;
    [FirebaseCollections.RolodexServiceProvider]?: BaseSnapshot<
      FirebaseCollections.RolodexServiceProvider,
      BaseEntity<RolodexServiceProvider>
    >;
    [FirebaseCollections.RolodexBranchRepresentativeRelation]?: BaseSnapshot<
      FirebaseCollections.RolodexBranchRepresentativeRelation,
      BaseEntity<RolodexBranchRepresentativeRelation>
    >;
    [FirebaseCollections.InsurancePolicy]?: BaseSnapshot<
      FirebaseCollections.InsurancePolicy,
      BaseEntity<BaseInsurancePolicy<InsurancePolicyEntityType>>
    >;
  };
};

export type ElphiStorageSnapshot<TType extends StorageProvider> = {
  provider: TType;
  root: {
    [folderId: string]: {
      folderName: string;
      description?: string;
      folders: {};
      files: {};
    };
  };
};

export type BaseElphiSnapshotEntity<T extends ElphiEntityType> = BaseEntity<
  {
    entityType: T;
    entityId: string;
    fileInfo: BaseDocumentMetadata<GcpFileInfo>;
  } & ElphiSnapshot
>;

export type ElphiSnapshotFile = GcpFileInfo;
