import { Property } from "@elphi/types";
import { OnChangeInput } from "../../../../../form-builder/FormBuilder";
import { StepForm } from "../components/StepForm";
import { PropertyInsurancePolicyState } from "../types/insurancePolicySteps.types";
import { entitiesPath } from "../utils/formUtils";
import { buildPropertySectionFields } from "./sections";

type PropertyCoveragesDataProps = {
  isDisabled: boolean;
  property: Property;
  propertyId: string;
  state: PropertyInsurancePolicyState;
  onChange: (value: OnChangeInput) => void;
};

export const PropertyCoveragesData = (props: PropertyCoveragesDataProps) => {
  const { isDisabled, property, propertyId, onChange, state } = props;
  const onPolicyFieldChange = (r: { e: OnChangeInput; propertyId: string }) => {
    const { e, propertyId } = r;
    onChange({
      ...e,
      fieldKey: [entitiesPath, propertyId, ...e.fieldKey]
    });
  };

  const propertySection = buildPropertySectionFields({
    propertyId,
    state,
    onChange,
    isReadOnly: isDisabled
  });
  return (
    <StepForm
      isDisabled={isDisabled}
      customKey={`insurancePolicyForm-${property?.id}`}
      onChange={(e) => onPolicyFieldChange({ e, propertyId })}
      sections={[propertySection]}
    />
  );
};
