import { DealProperty } from "../dealProperty.types";

export const getDealPropertyTemplate = () => {
  const dealPropertyRelation: DealProperty = {
    id: "",
    index: "",
    createdAt: "",
    modifiedAt: "",
    dealId: "",
    propertyId: ""
  };
  return dealPropertyRelation;
};
