import { DealMilestoneType } from "@elphi/types";
import { getEnumValue } from "@elphi/utils/src/enum.utils";
import useDealHooks from "../../../hooks/deal.hooks";
import { MilestoneChangeReasonContainer } from "./MilestoneChangeReasonContainer";
import { isChangeReasonMilestoneGuard } from "./changeReasonRequiredForMilestones.utils";
import { ChangeReasonLiveStateContainer } from "./deadDeal/DeadDealChangeReasonLiveStateContainer";
export const MilestoneChangeReasonLiveStateContainer = (
  props: MilestoneChangeReasonContainer
) => {
  const { dealState } = useDealHooks();
  const deal = dealState?.entities[props.dealId];
  const newDealMilestone = getEnumValue(
    DealMilestoneType,
    props.newDealMilestone
  );
  return (
    <>
      {props?.milestoneChanged?.onMilestoneChanged &&
        newDealMilestone &&
        isChangeReasonMilestoneGuard(newDealMilestone) && (
          <ChangeReasonLiveStateContainer
            dealId={props.dealId}
            deal={deal}
            newDealMilestone={newDealMilestone}
            lastDealMilestone={props.lastDealMilestone}
            milestoneChanged={{
              ...props.milestoneChanged,
              onMilestoneChanged: props?.milestoneChanged?.onMilestoneChanged
            }}
            revertToLastMilestone={props.revertToLastMilestone}
          />
        )}
    </>
  );
};
