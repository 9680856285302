import { BaseEntity } from "../../db/baseEntity.types";
import {
    InsurancePolicyEntityType,
    OutstandingPremiumStatus
} from "./insurancePolicy.enums";
import { PropertyCoveragesFields } from "./property/propertyInsurance.types";
import { PropertyInsurancePolicyCoverageType } from "./property/propertyInsurancePolicy.enums";

export type EntityCoverages = {
  [InsurancePolicyEntityType.Property]: {
    coverageTypes: PropertyInsurancePolicyCoverageType;
    coveragesFields: PropertyCoveragesFields;
  };
};

export type PolicyCommonFields = {
  entityId?: string;
};

export type PolicyEntityBaseData<TCoverageFields extends object> = {
  coverages?: Partial<TCoverageFields>;
} & PolicyCommonFields;

export type CommonFields = {
  policyPremiumAmount?: string;
};

export type IdToCoveragesMap<
  TCoverageFields extends object,
  TAdditionalDate
> = {
  [entityId: string]: {
    dealId?: string;
  } & CommonFields &
    PolicyEntityBaseData<TCoverageFields> &
    TAdditionalDate;
};

export type BaseInsurancePolicy<
  TInsuredEntity extends InsurancePolicyEntityType,
  TEntitiesCoverageAdditionalData = {}
> = BaseEntity<{
  insuranceCarrier?: string;
  policyNumber: string;
  expirationDate?: string;
  nextPaymentDueDate?: string;
  policyDeductibleAmount?: string;
  outstandingPremiumStatus?: OutstandingPremiumStatus;
  outstandingPremiumAmount?: string;
  insurancePremiumPaymentAmount?: string;
  entityIds?: string[];
  dealIds?: string[];
  deals?: { [dealId: string]: { loanIdentifier: string } };
  branchRepId?: string;
  effectiveDate?: string;
  domainConfigurationId?: string;
  policyProviderInfo?: { id?: string; name?: string };
  entityType: TInsuredEntity;
  coverageTypes: EntityCoverages[TInsuredEntity]["coverageTypes"][];
  entities?: IdToCoveragesMap<
    EntityCoverages[TInsuredEntity]["coveragesFields"],
    TEntitiesCoverageAdditionalData
  >;
}> &
  CommonFields;
