import {
  Property,
  PropertyReportOrderTask,
  PropertyReportVendorType
} from "@elphi/types";
import { useEffect } from "react";
import { AppConfig } from "../../../../config/appConfig";
import { usePropertyHooks } from "../../../../hooks/property.hooks";
import { usePropertyReportTaskHooks } from "../../../../hooks/propertyReportTask.hooks";
import { propertyReportSections } from "../../../application/sections/property-report";
import { OnChangeInput, Section } from "../../../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../../../form-builder/InputBuilder";
import { IntegrationFormContainerProps } from "../IntegrationFormContainer";
import { SiteXTaskForm } from "./site-x/SiteXTaskForm";

const vendorTypeForm = {
  [PropertyReportVendorType.SiteX]: SiteXTaskForm
};

export type VendorTypeTaskFormProps = {
  sections: Section[];
  propertyOnChangeBatch: (v: OnChangeInput) => void;
  selectedPropertyState: Partial<Property>;
} & IntegrationFormContainerProps;

export type PropertyReportTaskFormProps =
  {} & IntegrationFormContainerProps<PropertyReportOrderTask>;

export const PropertyReportTaskForm = ({
  snapshotId,
  selectedTask,
  state
}: PropertyReportTaskFormProps) => {
  const { propertyState: propertyReduxState } = usePropertyHooks();
  const { updatePropertiesHandler } = usePropertyReportTaskHooks();

  const selectedProperty =
    selectedTask?.entityId &&
    propertyReduxState?.entities[selectedTask?.entityId];

  const {
    onChange: propertyOnChange,
    state: propertyState,
    syncState: syncPropertyState
  } = useFormBuilderStateHandler({
    initialState: {
      properties: {} as { [id: string]: { id: string } & Partial<Property> }
    },
    callback: updatePropertiesHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  useEffect(() => {
    selectedProperty &&
      syncPropertyState({
        shouldSync: !!selectedProperty,
        state: selectedProperty,
        statePath: () => {
          if (selectedProperty) {
            return ["properties", selectedProperty.id];
          }
        }
      });
  }, [selectedProperty]);

  const propertyOnChangeBatch = (v: OnChangeInput) => {
    selectedProperty &&
      !snapshotId &&
      propertyOnChange({
        fieldType: v.fieldType,
        fieldKey: ["properties", selectedProperty.id, ...v.fieldKey],
        value: v.value
      });
  };

  const selectedPropertyState =
    (selectedProperty && propertyState.properties[selectedProperty.id]) || {};

  const VendorTypeFormComponent = vendorTypeForm?.[selectedTask.vendorType];
  const sections = propertyReportSections(
    selectedPropertyState,
    propertyOnChangeBatch
  )?.[selectedTask.vendorType];
  return VendorTypeFormComponent ? (
    <VendorTypeFormComponent
      snapshotId={snapshotId}
      selectedTask={selectedTask}
      state={state}
      sections={sections}
      selectedPropertyState={selectedPropertyState}
      propertyOnChangeBatch={propertyOnChangeBatch}
    />
  ) : null;
};
