import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Spacer, useDisclosure } from "@chakra-ui/react";
import elphiTheme from "../../../assets/themes/elphi.theme.default";
import { useNotificationConfigurationHooks } from "../../../hooks/notificationConfiguration.hooks";
import { GroupModal } from "../../custom/modal/GroupModal";
import { NotificationConfigurationSearch } from "./NotificationConfigurationSearch";
import { NotificationConfigurationFilters } from "./table/NotificationConfigurationFilters";
import { NotificationConfigurationTable } from "./table/NotificationConfigurationTable";

export const NotificationConfiguration = () => {
  const { isOpen: isOpenCreateModal, onToggle: onToggleCreateModal } =
    useDisclosure();
  const { createConfiguration, createResponse } =
    useNotificationConfigurationHooks();
  return (
    <Box>
      <Flex>
        <Box width={"100%"}>
          <NotificationConfigurationSearch />
        </Box>
        <Spacer />
        <Box p={"10px"}>
          <Button
            aria-label="add-audit-event-group-btn"
            onClick={onToggleCreateModal}
            leftIcon={<AddIcon />}
            {...elphiTheme.components.light.button.primary}
          >
            Create Configuration
          </Button>
        </Box>
      </Flex>
      <Flex p={"10px"} direction={"column"}>
        <NotificationConfigurationFilters />
        <NotificationConfigurationTable />
      </Flex>
      <GroupModal
        header={"Create notification configuration"}
        action={"create"}
        isShow={isOpenCreateModal}
        onClose={onToggleCreateModal}
        isLoading={createResponse.isLoading}
        onSubmit={createConfiguration}
      />
    </Box>
  );
};
