import { FieldType, RoleGroup } from "@elphi/types";
import { EMPTY, NOT_AVAILABLE } from "../../constants/common";
import { useRoleGroupHooks } from "../../hooks/roleGroup.hooks";
import { FuzzySearchSelect } from "../search/fuzzy/FuzzySearchSelect";
import { SelectSearchProps } from "../search/search.types";

const buildOption = (f: RoleGroup) => {
  const label = f.name || NOT_AVAILABLE;
  const value = f?.id;
  return {
    label,
    value
  };
};

const RoleGroupSearchBase = (
  props: SelectSearchProps & {
    label: string;
  }
) => {
  const { searchApi, searchResponse, roleGroupState } = useRoleGroupHooks();
  return (
    <FuzzySearchSelect
      {...props}
      searchApi={searchApi}
      searchResponse={searchResponse}
      state={roleGroupState}
      configuration={{
        includeScore: true,
        threshold: 0.4,
        keys: ["name"],
        shouldSort: true
      }}
      buildOption={buildOption}
    />
  );
};

export const RoleGroupSearch = () => {
  const { setSelected, selectedGroup } = useRoleGroupHooks();
  const handleOnSelect = (id: string) => {
    setSelected(id);
  };
  return (
    <RoleGroupSearchBase
      fieldType={FieldType.SingleSelect}
      currentValue={selectedGroup ? selectedGroup.id : EMPTY}
      onSelect={handleOnSelect}
      label="Search role group"
    />
  );
};

export const RoleGroupSelect = (props: SelectSearchProps) =>
  props.fieldType === FieldType.MultiSelect ? (
    <RoleGroupSearchBase {...props} label="Select role groups" />
  ) : (
    <RoleGroupSearchBase {...props} label="Select role group" />
  );
