import { ChangeReasonMilestones, MilestoneData } from "@elphi/types";
import { MilestoneChangeReasonLiveStateContainer } from "./MilestoneChangeReasonLiveStateContainer";
import { MilestoneChangeReasonSnapshotContainer } from "./MilestoneChangeReasonSnapshotContainer";
import { DealState } from "./deadDeal/DeadDealChangeReasonLiveStateContainer";

export type MilestoneChangeReasonContainer = {
  newDealMilestone: ChangeReasonMilestones;
  lastDealMilestone: string;
  dealId: string;
  milestoneChanged?: {
    isLoading: boolean;
    formState: DealState;
    syncState: <D extends object>(r: {
      shouldSync: boolean;
      state: D | undefined;
      statePath?: (() => string[] | undefined) | undefined;
    }) => void;
    onMilestoneChanged?: (params: MilestoneData<ChangeReasonMilestones>) => void;
  };
  revertToLastMilestone: () => void;
};

export const MilestoneChangeReasonContainer = (
  props: MilestoneChangeReasonContainer & {
    snapshotId?: string;
  }
) => {
  return props.snapshotId ? (
    <MilestoneChangeReasonSnapshotContainer
      {...props}
      snapshotId={props.snapshotId!}
    />
  ) : (
    <MilestoneChangeReasonLiveStateContainer {...props} />
  );
};
