import { AuditEventGroup, FieldType } from "@elphi/types";
import { EMPTY, NOT_AVAILABLE } from "../../constants/common";
import { useAuditEventGroupHooks } from "../../hooks/auditEventGroup.hooks";
import { FuzzySearchSelect } from "../search/fuzzy/FuzzySearchSelect";
import { SelectSearchProps } from "../search/search.types";

const buildOption = (a: AuditEventGroup) => {
  const label = a.name || NOT_AVAILABLE;
  const value = a?.id;
  return {
    label,
    value
  };
};

const AuditEventGroupSearchBase = (
  props: SelectSearchProps & {
    label: string;
  }
) => {
  const { searchApi, searchResponse, auditEventGroupState } =
    useAuditEventGroupHooks();
  return (
    <FuzzySearchSelect
      {...props}
      searchApi={searchApi}
      searchResponse={searchResponse}
      state={auditEventGroupState}
      configuration={{
        includeScore: true,
        threshold: 0.4,
        keys: ["name"],
        shouldSort: true
      }}
      buildOption={buildOption}
    />
  );
};

export const AuditEventGroupSearch = () => {
  const { setSelected, selectedGroup } = useAuditEventGroupHooks();
  const handleOnSelect = (id: string) => {
    setSelected(id);
  };
  return (
    <AuditEventGroupSearchBase
      fieldType={FieldType.SingleSelect}
      currentValue={selectedGroup ? selectedGroup.id : EMPTY}
      onSelect={handleOnSelect}
      label={"Search audit event group"}
    />
  );
};

export const AuditEventGroupSelect = (props: SelectSearchProps) =>
  props.fieldType === FieldType.MultiSelect ? (
    <AuditEventGroupSearchBase {...props} label="Select audit event groups" />
  ) : (
    <AuditEventGroupSearchBase {...props} label="Select audit event group" />
  );
