const traverse = (
  obj: object,
  into: Record<string, unknown>,
  prefix: string[] = []
) => {
  Object.entries(obj).forEach(([key, val]) => {
    if (typeof val === "object" && !Array.isArray(val)) {
      traverse(val, into, [...prefix, key]);
    } else {
      if (isNaN(Number(key))) {
        into[[...prefix, key].join(".")] = val;
      } else {
        into[[...prefix].join(".")] = val;
      }
    }
  });
};

export const toDotObj = (obj: object) => {
  const out = {};
  traverse(obj, out);
  return out;
};
