import { Deal, DealFields, IntegrationType } from "@elphi/types";
import { WireInsuranceVendorType } from "@elphi/types/entities/task-types/integrations/wire-insurance-certificate/wireInsuranceVendor.types";
import { ObjectKeyValidation } from "../../form-builder/field-specs/objectKeyValidation.types";

export const loanProgramTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "LoanProgramType"]
> = ["aggregations", "LoanProgramType"];

export const loanPurposeFieldPath: ObjectKeyValidation<
  Deal,
  ["LoanPurposeType"]
> = ["LoanPurposeType"];

export const portfolioLoanIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["PortfolioLoanIndicator"]
> = ["PortfolioLoanIndicator"];

export const totalPropertyCountFieldPath: ObjectKeyValidation<
  Deal,
  ["TotalPropertyCount"]
> = ["TotalPropertyCount"];

export const estimatedClosingDateFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "EstimatedClosingDate"]
> = ["aggregations", "EstimatedClosingDate"];

export const channelFieldPath: ObjectKeyValidation<Deal, ["Channel"]> = [
  "Channel"
];

export const employeeLoanIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["EmployeeLoanIndicator"]
> = ["EmployeeLoanIndicator"];

export const chargentTransactionIdentifierFieldPath: ObjectKeyValidation<
  Deal,
  ["ChargentTransactionIdentifier"]
> = ["ChargentTransactionIdentifier"];

export const chargentGatewayIdentifierFieldPath: ObjectKeyValidation<
  Deal,
  ["ChargentGatewayIdentifier"]
> = ["ChargentGatewayIdentifier"];

export const crmIdFieldPath: ObjectKeyValidation<Deal, ["CRMId"]> = ["CRMId"];
export const operationsDepartmentFieldPath: ObjectKeyValidation<
  Deal,
  ["OperationsDepartment"]
> = ["OperationsDepartment"];

export const chargentCollectionAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["ChargentCollectionAmount"]
> = ["ChargentCollectionAmount"];

export const valuationFeesCollectedBySalesIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["ValuationFeesCollectedBySalesIndicator"]
> = ["ValuationFeesCollectedBySalesIndicator"];

export const appraisalNotesFieldPath: ObjectKeyValidation<
  Deal,
  ["AppraisalNotes"]
> = ["AppraisalNotes"];

export const totalPurchasePriceAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalPurchasePriceAmount"]
> = ["aggregations", "TotalPurchasePriceAmount"];

export const totalBudgetAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalBudgetAmount"]
> = ["aggregations", "TotalBudgetAmount"];

export const totalAsIsAppraisedValueAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalAsIsAppraisedValueAmount"]
> = ["aggregations", "TotalAsIsAppraisedValueAmount"];

export const totalSubjectToAppraisedValueAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalSubjectToAppraisedValueAmount"]
> = ["aggregations", "TotalSubjectToAppraisedValueAmount"];

export const totalLTVRatePercentFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalLTVRatePercent"]
> = ["aggregations", "TotalLTVRatePercent"];

export const totalLTARVRatePercentFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalLTARVRatePercent"]
> = ["aggregations", "TotalLTARVRatePercent"];

export const requestedLoanAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "RequestedLoanAmount"]
> = ["aggregations", "RequestedLoanAmount"];

export const initialLTVRatePercentFieldPath: ObjectKeyValidation<
  Deal,
  ["InitialLTVRatePercent"]
> = ["InitialLTVRatePercent"];

export const totalOutstandingLoanPayoffAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalOutstandingLoanPayoffAmount"]
> = ["aggregations", "TotalOutstandingLoanPayoffAmount"];

export const initialAdvancedFundAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["InitialAdvancedFundAmount"]
> = ["InitialAdvancedFundAmount"];

export const totalLoanFeesAndClosingCostAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["TotalLoanFeesAndClosingCostAmount"]
> = ["TotalLoanFeesAndClosingCostAmount"];

export const debtServiceCoverageRatioPercentFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "DebtServiceCoverageRatioPercent"]
> = ["quote", "aggregations", "DebtServiceCoverageRatioPercent"];

export const loanProductTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "LoanProductType"]
> = ["aggregations", "LoanProductType"];

export const amortizationTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["AmortizationType"]
> = ["AmortizationType"];

export const noteRatePercentFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "NoteRatePercent"]
> = ["quote", "aggregations", "NoteRatePercent"];

export const wireReleaseDateFieldPath: ObjectKeyValidation<
  Deal,
  ["WireReleaseDate"]
> = ["WireReleaseDate"];

export const scheduledFirstPaymentDateFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "ScheduledFirstPaymentDate"]
> = ["aggregations", "ScheduledFirstPaymentDate"];

export const paymentDueDayFieldPath: ObjectKeyValidation<
  Deal,
  ["PaymentDueDay"]
> = ["PaymentDueDay"];

export const lastPaymentDueDateFieldPath: ObjectKeyValidation<
  Deal,
  ["LastPaymentDueDate"]
> = ["LastPaymentDueDate"];

export const loanMaturityDateFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "LoanMaturityDate"]
> = ["aggregations", "LoanMaturityDate"];

export const loanAmortizationPeriodMonthCountFieldPath: ObjectKeyValidation<
  Deal,
  ["LoanAmortizationPeriodMonthCount"]
> = ["LoanAmortizationPeriodMonthCount"];

export const loanInterestOnlyPeriodMonthCountFieldPath: ObjectKeyValidation<
  Deal,
  ["LoanInterestOnlyPeriodMonthCount"]
> = ["LoanInterestOnlyPeriodMonthCount"];

export const loanTermPeriodMonthCountFieldPath: ObjectKeyValidation<
  Deal,
  ["LoanTermPeriodMonthCount"]
> = ["LoanTermPeriodMonthCount"];

export const loanAfterInterestOnlyPeriodMonthCountFieldPath: ObjectKeyValidation<
  Deal,
  ["LoanAfterInterestOnlyPeriodMonthCount"]
> = ["LoanAfterInterestOnlyPeriodMonthCount"];

export const interestAccrualMethodTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["InterestAccrualMethodType"]
> = ["InterestAccrualMethodType"];

export const loanPaymentTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["LoanPaymentType"]
> = ["LoanPaymentType"];

export const totalMonthlyPITIPaymentAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "TotalMonthlyPITIPaymentAmount"]
> = ["quote", "aggregations", "TotalMonthlyPITIPaymentAmount"];

export const monthlyPrincipalInterestPaymentAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "MonthlyPrincipalInterestPaymentAmount"]
> = ["quote", "aggregations", "MonthlyPrincipalInterestPaymentAmount"];

export const monthlyTaxInsurancePaymentAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "MonthlyTaxInsurancePaymentAmount"]
> = ["quote", "aggregations", "MonthlyTaxInsurancePaymentAmount"];

export const monthlyHomeownersAssociationAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "MonthlyHomeownersAssociationAmount"]
> = ["quote", "aggregations", "MonthlyHomeownersAssociationAmount"];

export const executionTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["ExecutionType"]
> = ["ExecutionType"];

export const prepaymentPenaltyTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "PrepaymentPenaltyType"]
> = ["quote", "aggregations", "PrepaymentPenaltyType"];

export const prepaymentPenaltyExpirationDateFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "PrepaymentPenaltyExpirationDate"]
> = ["aggregations", "PrepaymentPenaltyExpirationDate"];

export const recourseTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["RecourseType"]
> = ["RecourseType"];

export const lenderIdentifierFieldPath: ObjectKeyValidation<
  Deal,
  ["LenderIdentifier"]
> = ["LenderIdentifier"];

export const lenderEntityTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["Lender", "EntityType"]
> = ["Lender", "EntityType"];

export const lenderAddressLineTextFieldPath: ObjectKeyValidation<
  Deal,
  ["Lender", "AddressLineText"]
> = ["Lender", "AddressLineText"];

export const lenderAddressUnitIdentifierFieldPath: ObjectKeyValidation<
  Deal,
  ["Lender", "AddressUnitIdentifier"]
> = ["Lender", "AddressUnitIdentifier"];

export const lenderCityNameFieldPath: ObjectKeyValidation<
  Deal,
  ["Lender", "CityName"]
> = ["Lender", "CityName"];

export const lenderCountyNameFieldPath: ObjectKeyValidation<
  Deal,
  ["Lender", "CountyName"]
> = ["Lender", "CountyName"];

export const lenderFormationStateFieldPath: ObjectKeyValidation<
  Deal,
  ["Lender", "FormationState"]
> = ["Lender", "FormationState"];

export const lenderStateCodeFieldPath: ObjectKeyValidation<
  Deal,
  ["Lender", "StateCode"]
> = ["Lender", "StateCode"];

export const lenderPostalCodeFieldPath: ObjectKeyValidation<
  Deal,
  ["Lender", "PostalCode"]
> = ["Lender", "PostalCode"];

export const propertyManagementFullNameFieldPath: ObjectKeyValidation<
  Deal,
  ["PropertyManagement", "FullName"]
> = ["PropertyManagement", "FullName"];

export const propertyManagementRepresentativeFullNameFieldPath: ObjectKeyValidation<
  Deal,
  ["PropertyManagement", "RepresentativeFullName"]
> = ["PropertyManagement", "RepresentativeFullName"];

export const propertyManagementFullAddressNameFieldPath: ObjectKeyValidation<
  Deal,
  ["PropertyManagement", "FullAddressName"]
> = ["PropertyManagement", "FullAddressName"];

export const propertyManagementContactPointTelephoneValueFieldPath: ObjectKeyValidation<
  Deal,
  ["PropertyManagement", "ContactPointTelephoneValue"]
> = ["PropertyManagement", "ContactPointTelephoneValue"];

export const propertyManagementContactPointEmailValueFieldPath: ObjectKeyValidation<
  Deal,
  ["PropertyManagement", "ContactPointEmailValue"]
> = ["PropertyManagement", "ContactPointEmailValue"];

export const propertyManagementCommentsFieldPath: ObjectKeyValidation<
  Deal,
  ["PropertyManagement", "PropertyManagementComments"]
> = ["PropertyManagement", "PropertyManagementComments"];

export const totalCostAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalCostAmount"]
> = ["aggregations", "TotalCostAmount"];

export const loanNameFieldPath: ObjectKeyValidation<Deal, ["LoanName"]> = [
  "LoanName"
];

export const totalPropertyInsurancePremiumAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalPropertyInsurancePremiumAmount"]
> = ["aggregations", "TotalPropertyInsurancePremiumAmount"];
export const totalFloodInsurancePremiumAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalFloodInsurancePremiumAmount"]
> = ["aggregations", "TotalFloodInsurancePremiumAmount"];

export const totalPropertyInsurancePremiumOutstandingAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalPropertyInsurancePremiumOutstandingAmount"]
> = ["aggregations", "TotalPropertyInsurancePremiumOutstandingAmount"];
export const totalFloodInsurancePremiumOutstandingAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalFloodInsurancePremiumOutstandingAmount"]
> = ["aggregations", "TotalFloodInsurancePremiumOutstandingAmount"];

export const totalHOAFeesAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalHOAFeesAmount"]
> = ["aggregations", "TotalHOAFeesAmount"];
export const totalTaxAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalTaxAmount"]
> = ["aggregations", "TotalTaxAmount"];
export const totalValuationOrPurchasePriceAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "TotalValuationOrPurchasePriceAmount"]
> = ["quote", "aggregations", "TotalValuationOrPurchasePriceAmount"];

export const originationFeeAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "OriginationFeeAmount"]
> = ["quote", "aggregations", "OriginationFeeAmount"];

export const quoteIdFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "QuoteId"]
> = ["quote", "aggregations", "QuoteId"];
export const validationErrorsFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "ValidationErrors"]
> = ["quote", "aggregations", "ValidationErrors"];
export const ltvEligibilityFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "LtvEligibility"]
> = ["quote", "aggregations", "LtvEligibility"];
export const servicingSetupFeeFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "ServicingSetupFee"]
> = ["quote", "aggregations", "ServicingSetupFee"];
export const lenderFinanceProcessingFeeFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "LenderFinanceProcessingFee"]
> = ["quote", "aggregations", "LenderFinanceProcessingFee"];
export const lenderFinanceApplicationFeeFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "LenderFinanceApplicationFee"]
> = ["quote", "aggregations", "LenderFinanceApplicationFee"];
export const lenderFinanceDocumentFeeFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "LenderFinanceDocumentFee"]
> = ["quote", "aggregations", "LenderFinanceDocumentFee"];
export const lenderFinanceAppraisalFeeFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "LenderFinanceAppraisalFee"]
> = ["quote", "aggregations", "LenderFinanceAppraisalFee"];
export const limaRateToBorrowerPercentFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "LimaRateToBorrowerPercent"]
> = ["quote", "aggregations", "LimaRateToBorrowerPercent"];
export const limaOriginationFeeToBorrowerPercentFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "LimaOriginationFeeToBorrowerPercent"]
> = ["quote", "aggregations", "LimaOriginationFeeToBorrowerPercent"];
export const interestReserveEscrowFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "InterestReserveEscrow"]
> = ["quote", "aggregations", "InterestReserveEscrow"];
export const pricingEngineWarningsFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "PricingEngineWarnings"]
> = ["quote", "aggregations", "PricingEngineWarnings"];
export const brokerProcessingFeeFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "BrokerProcessingFeeAmount"]
> = ["quote", "aggregations", "BrokerProcessingFeeAmount"];
// New fields
export const lesserOfLotOrPurchaseFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "LesserOfLotOrPurchase"]
> = ["quote", "aggregations", "LesserOfLotOrPurchase"];
export const pricingEngineLTARVRatePercentFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "PricingEngineLTARVRatePercent"]
> = ["quote", "aggregations", "PricingEngineLTARVRatePercent"];
export const fullyDrawnInterestOnlyPaymentFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "FullyDrawnInterestOnlyPayment"]
> = ["quote", "aggregations", "FullyDrawnInterestOnlyPayment"];
export const initialMonthlyInterestOnlyPaymentAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "InitialMonthlyInterestOnlyPaymentAmount"]
> = ["quote", "aggregations", "InitialMonthlyInterestOnlyPaymentAmount"];
export const internalL1CRefinanceIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "InternalL1CRefinanceIndicator"]
> = ["quote", "aggregations", "InternalL1CRefinanceIndicator"];

export const wireInsuranceCertificateFundingShieldUserFirstName: ObjectKeyValidation<
  Deal,
  [
    "Integrations",
    IntegrationType.WireInsuranceCertificate,
    WireInsuranceVendorType.FundingShield,
    "userFirstName"
  ]
> = [
  "Integrations",
  IntegrationType.WireInsuranceCertificate,
  WireInsuranceVendorType.FundingShield,
  "userFirstName"
];

export const wireInsuranceCertificateFundingShieldUserLastName: ObjectKeyValidation<
  Deal,
  [
    "Integrations",
    IntegrationType.WireInsuranceCertificate,
    WireInsuranceVendorType.FundingShield,
    "userLastName"
  ]
> = [
  "Integrations",
  IntegrationType.WireInsuranceCertificate,
  WireInsuranceVendorType.FundingShield,
  "userLastName"
];

export const wireInsuranceCertificateFundingShieldUserEmail: ObjectKeyValidation<
  Deal,
  [
    "Integrations",
    IntegrationType.WireInsuranceCertificate,
    WireInsuranceVendorType.FundingShield,
    "userEmail"
  ]
> = [
  "Integrations",
  IntegrationType.WireInsuranceCertificate,
  WireInsuranceVendorType.FundingShield,
  "userEmail"
];

export const wireInsuranceCertificateFundingShieldRushOrderIndicator: ObjectKeyValidation<
  Deal,
  [
    "Integrations",
    IntegrationType.WireInsuranceCertificate,
    WireInsuranceVendorType.FundingShield,
    "rushOrderIndicator"
  ]
> = [
  "Integrations",
  IntegrationType.WireInsuranceCertificate,
  WireInsuranceVendorType.FundingShield,
  "rushOrderIndicator"
];

export const wireInsuranceCertificateFundingShieldTransactionId: ObjectKeyValidation<
  Deal,
  [
    "aggregations",
    "Integrations",
    IntegrationType.WireInsuranceCertificate,
    WireInsuranceVendorType.FundingShield,
    "TransactionId"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.WireInsuranceCertificate,
  WireInsuranceVendorType.FundingShield,
  "TransactionId"
];

export const wireInsuranceCertificateFundingShieldTransactionStatus: ObjectKeyValidation<
  Deal,
  [
    "aggregations",
    "Integrations",
    IntegrationType.WireInsuranceCertificate,
    WireInsuranceVendorType.FundingShield,
    "TransactionStatus"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.WireInsuranceCertificate,
  WireInsuranceVendorType.FundingShield,
  "TransactionStatus"
];

export const wireInsuranceCertificateFundingShieldStateNYIndicator: ObjectKeyValidation<
  Deal,
  [
    "aggregations",
    "Integrations",
    IntegrationType.WireInsuranceCertificate,
    WireInsuranceVendorType.FundingShield,
    "StateNYIndicator"
  ]
> = [
  "aggregations",
  "Integrations",
  IntegrationType.WireInsuranceCertificate,
  WireInsuranceVendorType.FundingShield,
  "StateNYIndicator"
];

export const pricingEngineTotalLoanToAfterRepairValueOverridePercentFieldPath: ObjectKeyValidation<
  Deal,
  [
    "quote",
    "aggregations",
    "PricingEngineTotalLoanToAfterRepairValueOverridePercent"
  ]
> = [
  "quote",
  "aggregations",
  "PricingEngineTotalLoanToAfterRepairValueOverridePercent"
];
export const pricingEngineTotalBlendedLoanToCostOverridePercentFieldPath: ObjectKeyValidation<
  Deal,
  [
    "quote",
    "aggregations",
    "PricingEngineTotalBlendedLoanToCostOverridePercent"
  ]
> = [
  "quote",
  "aggregations",
  "PricingEngineTotalBlendedLoanToCostOverridePercent"
];
export const pricingEngineTotalPurchaseLoanToCostOverridePercentFieldPath: ObjectKeyValidation<
  Deal,
  [
    "quote",
    "aggregations",
    "PricingEngineTotalPurchaseLoanToCostOverridePercent"
  ]
> = [
  "quote",
  "aggregations",
  "PricingEngineTotalPurchaseLoanToCostOverridePercent"
];
export const pricingEngineNoteRateOverridePercentFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "PricingEngineNoteRateOverridePercent"]
> = ["quote", "aggregations", "PricingEngineNoteRateOverridePercent"];
export const pricingEngineMaxLoanToAfterRepairValuePercentFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "PricingEngineMaxLoanToAfterRepairValuePercent"]
> = ["quote", "aggregations", "PricingEngineMaxLoanToAfterRepairValuePercent"];
export const pricingEngineMaxBlendedLoanToCostPercentFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "PricingEngineMaxBlendedLoanToCostPercent"]
> = ["quote", "aggregations", "PricingEngineMaxBlendedLoanToCostPercent"];
export const pricingEngineMaxPurchaseLoanToCostPercentFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "PricingEngineMaxPurchaseLoanToCostPercent"]
> = ["quote", "aggregations", "PricingEngineMaxPurchaseLoanToCostPercent"];
export const marginFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "Margin"]
> = ["quote", "aggregations", "Margin"];
export const floorPercentFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "FloorPercent"]
> = ["quote", "aggregations", "FloorPercent"];
export const lifetimeCapPercentFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "LifetimeCapPercent"]
> = ["quote", "aggregations", "LifetimeCapPercent"];

export const blendedLTCAdjusterFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "BlendedLTCAdjuster"]
> = ["quote", "aggregations", "BlendedLTCAdjuster"];
export const purchaseLTCAdjusterFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "PurchaseLTCAdjuster"]
> = ["quote", "aggregations", "PurchaseLTCAdjuster"];
export const arvAdjusterFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "ARVAdjuster"]
> = ["quote", "aggregations", "ARVAdjuster"];

export const totalNumberOfPropertiesFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalNumberOfProperties"]
> = ["aggregations", "TotalNumberOfProperties"];
export const totalAdjustedMonthlyRentAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalAdjustedMonthlyRentAmount"]
> = ["aggregations", "TotalAdjustedMonthlyRentAmount"];
export const totalAnnualCapitalExpenditureFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalAnnualCapitalExpenditure"]
> = ["aggregations", "TotalAnnualCapitalExpenditure"];
export const monthlyPaymentOfCapitalExpenditureFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "MonthlyPaymentOfCapitalExpenditure"]
> = ["aggregations", "MonthlyPaymentOfCapitalExpenditure"];
export const lastDayOfClosingMonthFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "LastDayOfClosingMonth"]
> = ["aggregations", "LastDayOfClosingMonth"];
export const rateLockStartDateFieldPath: ObjectKeyValidation<
  Deal,
  ["RateLockStartDate"]
> = ["RateLockStartDate"];
export const rateLockEndDateFieldPath: ObjectKeyValidation<
  Deal,
  ["RateLockEndDate"]
> = ["RateLockEndDate"];

export const totalCashToFromBorrowerFieldPath: ObjectKeyValidation<
  Deal,
  ["TotalCashToFromBorrower"]
> = ["TotalCashToFromBorrower"];
export const cashFromBorrowerAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["CashFromBorrowerAmount"]
> = ["CashFromBorrowerAmount"];
export const cashToBorrowerAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["CashToBorrowerAmount"]
> = ["CashToBorrowerAmount"];

export const marketingPromotionCreditAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["MarketingPromotionCreditAmount"]
> = ["MarketingPromotionCreditAmount"];

export const marketingPromotionCodeFieldPath: ObjectKeyValidation<
  Deal,
  ["MarketingPromotionCode"]
> = ["MarketingPromotionCode"];

export const lenderFinancePartnerFirstNameFieldPath: ObjectKeyValidation<
  Deal,
  ["LenderFinance", "PartnerFirstName"]
> = ["LenderFinance", "PartnerFirstName"];

export const lenderFinancePartnerLastNameFieldPath: ObjectKeyValidation<
  Deal,
  ["LenderFinance", "PartnerLastName"]
> = ["LenderFinance", "PartnerLastName"];

export const lenderFinancePartnerInitialTermSheetApprovalStatusIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["LenderFinance", "PartnerInitialTermSheetApprovalStatusIndicator"]
> = ["LenderFinance", "PartnerInitialTermSheetApprovalStatusIndicator"];

export const lenderFinancePartnerInitialTermSheetApprovalStatusDateFieldPath: ObjectKeyValidation<
  Deal,
  ["LenderFinance", "PartnerInitialTermSheetApprovalStatusDate"]
> = ["LenderFinance", "PartnerInitialTermSheetApprovalStatusDate"];

export const lenderFinancePartnerFinalTermSheetApprovalStatusDateFieldPath: ObjectKeyValidation<
  Deal,
  ["LenderFinance", "PartnerFinalTermSheetApprovalStatusDate"]
> = ["LenderFinance", "PartnerFinalTermSheetApprovalStatusDate"];

export const lenderFinanceBorrowerFinalTermSheetApprovalStatusDateFieldPath: ObjectKeyValidation<
  Deal,
  ["LenderFinance", "BorrowerFinalTermSheetApprovalStatusDate"]
> = ["LenderFinance", "BorrowerFinalTermSheetApprovalStatusDate"];

export const exitStrategyFieldPath: ObjectKeyValidation<
  Deal,
  ["ExitStrategy"]
> = ["ExitStrategy"];

export const targetCloseDateFieldPath: ObjectKeyValidation<
  Deal,
  ["TargetCloseDate"]
> = ["TargetCloseDate"];

export const applicationRequestedLoanAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["ApplicationRequestedLoanAmount"]
> = ["ApplicationRequestedLoanAmount"];

export const projectAnticipatedCompletionTimelineFieldPath: ObjectKeyValidation<
  Deal,
  ["ProjectAnticipatedCompletionTimeline"]
> = ["ProjectAnticipatedCompletionTimeline"];

export const constructionBudgetRemainingAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["ConstructionBudgetRemainingAmount"]
> = ["ConstructionBudgetRemainingAmount"];

export const verifiableConstructionCompletedAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["VerifiableConstructionCompletedAmount"]
> = ["VerifiableConstructionCompletedAmount"];

export const lienDollarAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["LienDollarAmount"]
> = ["LienDollarAmount"];

export const propertyTypeChangingIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["PropertyTypeChangingIndicator"]
> = ["PropertyTypeChangingIndicator"];

export const propertyZoningChangesIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["PropertyZoningChangesIndicator"]
> = ["PropertyZoningChangesIndicator"];

export const subdividePropertyPartialReleasesIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["SubdividePropertyPartialReleasesIndicator"]
> = ["SubdividePropertyPartialReleasesIndicator"];

export const lenderTitleProviderIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["LenderTitleProviderIndicator"]
> = ["LenderTitleProviderIndicator"];
export const lenderPropertyInsuranceProviderIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["LenderPropertyInsuranceProviderIndicator"]
> = ["LenderPropertyInsuranceProviderIndicator"];
export const hoaContactNameFieldPath: ObjectKeyValidation<
  Deal,
  ["HOAContactName"]
> = ["HOAContactName"];
export const hoaContactPhoneFieldPath: ObjectKeyValidation<
  Deal,
  ["HOAContactPhone"]
> = ["HOAContactPhone"];
export const hoaContactEmailFieldPath: ObjectKeyValidation<
  Deal,
  ["HOAContactEmail"]
> = ["HOAContactEmail"];
export const occupancyPath: ObjectKeyValidation<Deal, ["Occupancy"]> = [
  "Occupancy"
];
export const propertyRentReadyConditionIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["PropertyRentReadyConditionIndicator"]
> = ["PropertyRentReadyConditionIndicator"];
export const propertyLeasePurchaseOptionIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["PropertyLeasePurchaseOptionIndicator"]
> = ["PropertyLeasePurchaseOptionIndicator"];
export const occupationIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["OccupationIndicator"]
> = ["OccupationIndicator"];
export const sellerRelationshipIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["SellerRelationshipIndicator"]
> = ["SellerRelationshipIndicator"];
export const associatedPropertiesDescriptionFieldPath: ObjectKeyValidation<
  Deal,
  ["AssociatedPropertiesDescription"]
> = ["AssociatedPropertiesDescription"];
export const developmentStrategyFieldPath: ObjectKeyValidation<
  Deal,
  ["DevelopmentStrategy"]
> = ["DevelopmentStrategy"];
export const lotStatusFieldPath: ObjectKeyValidation<Deal, ["LotStatus"]> = [
  "LotStatus"
];
export const currentLotMarketValueAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["CurrentLotMarketValueAmount"]
> = ["CurrentLotMarketValueAmount"];
export const lotZonedParcelIdIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["LotZonedParcelIdIndicator"]
> = ["LotZonedParcelIdIndicator"];
export const buildingPermitsIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["BuildingPermitsIndicator"]
> = ["BuildingPermitsIndicator"];
export const buildingPermitsExpectedDateFieldPath: ObjectKeyValidation<
  Deal,
  ["BuildingPermitsExpectedDate"]
> = ["BuildingPermitsExpectedDate"];
export const lotUtilitiesIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["LotUtilitiesIndicator"]
> = ["LotUtilitiesIndicator"];

export const buildingEnvelopeExpandedIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["BuildingEnvelopeExpandedIndicator"]
> = ["BuildingEnvelopeExpandedIndicator"];

export const loadBearingWallsRemoveIntendedIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["LoadBearingWallsRemoveIntendedIndicator"]
> = ["LoadBearingWallsRemoveIntendedIndicator"];

export const squareFootageAddedIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["SquareFootageAddedIndicator"]
> = ["SquareFootageAddedIndicator"];

export const interestReserveEscrowOverrideMonthCountFieldPath: ObjectKeyValidation<
  Deal,
  ["InterestReserveEscrowOverrideMonthCount"]
> = ["InterestReserveEscrowOverrideMonthCount"];
export const interestReserveEscrowOverrideAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["InterestReserveEscrowOverrideAmount"]
> = ["InterestReserveEscrowOverrideAmount"];
export const processingFeeOverrideFieldPath: ObjectKeyValidation<
  Deal,
  ["ProcessingFeeOverride"]
> = ["ProcessingFeeOverride"];

export const exceptionApprovedBy: ObjectKeyValidation<
  DealFields,
  ["Exceptions", "ExceptionApprovedBy"]
> = ["Exceptions", "ExceptionApprovedBy"];

export const exceptionCommentary: ObjectKeyValidation<
  DealFields,
  ["Exceptions", "ExceptionCommentary"]
> = ["Exceptions", "ExceptionCommentary"];

export const exceptionType: ObjectKeyValidation<
  DealFields,
  ["Exceptions", "ExceptionType"]
> = ["Exceptions", "ExceptionType"];

export const policySection: ObjectKeyValidation<
  DealFields,
  ["Exceptions", "PolicySection"]
> = ["Exceptions", "PolicySection"];

export const creditMemoDraftedDate: ObjectKeyValidation<
  DealFields,
  ["CreditReviewCommittee", "creditMemoDraftedDate"]
> = ["CreditReviewCommittee", "creditMemoDraftedDate"];
export const creditMemoApprovedDate: ObjectKeyValidation<
  DealFields,
  ["CreditReviewCommittee", "creditMemoApprovedDate"]
> = ["CreditReviewCommittee", "creditMemoApprovedDate"];
export const internalSubmissionDate: ObjectKeyValidation<
  DealFields,
  ["CreditReviewCommittee", "internalSubmissionDate"]
> = ["CreditReviewCommittee", "internalSubmissionDate"];
export const internalApprovalDate: ObjectKeyValidation<
  DealFields,
  ["CreditReviewCommittee", "internalApprovalDate"]
> = ["CreditReviewCommittee", "internalApprovalDate"];
export const externalSubmissionDate: ObjectKeyValidation<
  DealFields,
  ["CreditReviewCommittee", "externalSubmissionDate"]
> = ["CreditReviewCommittee", "externalSubmissionDate"];
export const externalApprovalDate: ObjectKeyValidation<
  DealFields,
  ["CreditReviewCommittee", "externalApprovalDate"]
> = ["CreditReviewCommittee", "externalApprovalDate"];
export const lOIIssuedDate: ObjectKeyValidation<
  DealFields,
  ["CreditReviewCommittee", "lOIIssuedDate"]
> = ["CreditReviewCommittee", "lOIIssuedDate"];
export const lOIAcceptedDate: ObjectKeyValidation<
  DealFields,
  ["CreditReviewCommittee", "lOIAcceptedDate"]
> = ["CreditReviewCommittee", "lOIAcceptedDate"];
