import { Box, Flex } from "@chakra-ui/react";
import { TABLE_MIN_WIDTH } from "../../constants/common";
import TaskConfigurationHeader from "../task/task-configuration/TaskConfigurationHeader";
import { TaskConfigurationTable } from "../task/task-configuration/v2/TaskConfigurationTable";

const TaskConfigurationTab = () => {
  return (
    <Box>
      <TaskConfigurationHeader />
      <Box overflowX="auto">
        <Flex overflowX={"scroll"} minW={TABLE_MIN_WIDTH}>
          <TaskConfigurationTable />
        </Flex>
      </Box>
    </Box>
  );
};

export default TaskConfigurationTab;
