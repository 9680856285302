import { Box } from "@chakra-ui/react";
import { CloneConfigurationType, FieldType, Organization } from "@elphi/types";
import FormBuilder, {
  OnChangeInput
} from "../../../../form-builder/FormBuilder";
import { sanitizeStringFormatter } from "../../../../form-builder/formatters/inputs.formatter";
import { OptionType } from "../utils/Organization.utils";
import { CloneConfigurationCheckbox } from "./CloneConfigurationCheckbox";
import { OrganizationDropDown } from "./OrganizationDropDown";

export type OrganizationFormProps = {
  onChange: (e: OnChangeInput) => void;
  buildOption: (o: Organization) => OptionType;
  state: Partial<Organization>;
  selectConfiguration: CloneConfigurationType[];
  onChangeSelectConfiguration: (v: CloneConfigurationType[]) => void;
  disableSubscription?: boolean;
};

export const OrganizationForm = (props: OrganizationFormProps) => {
  const { state, onChange } = props;
  return (
    <Box w={"100%"}>
      <FormBuilder
        customKey="orgForm"
        elphiView="form"
        onChange={onChange}
        sections={[
          {
            inputs: [
              {
                label: "Name",
                formatter: {
                  fieldType: FieldType.String,
                  format: sanitizeStringFormatter.format
                },
                currentValue: state.name,
                fieldKey: ["name"],
                fieldType: FieldType.String,
                labelPosition: "up",
                isValid: !!state.name
              },
              {
                label: "Description",
                currentValue: state.description,
                fieldKey: ["description"],
                fieldType: FieldType.String,
                labelPosition: "up",
                isValid: !!state.description
              },
              {
                label: "Subscribe to Configurations from another Organization",
                currentValue: state.isSubscribed,
                fieldKey: ["isSubscribed"],
                fieldType: FieldType.Switch,
                labelPosition: "up",
                isValid: !!state.isSubscribed,
                isReadOnly: props.disableSubscription,
                toolTipLabel: props.disableSubscription
                  ? "Cannot subscribe while being subscribed to."
                  : undefined,
                toolTipPlace: "left",
                toolTipArrow: true
              }
            ]
          }
        ]}
      />
      <Box marginTop={"15px"}>
        <OrganizationDropDown
          label={"Select organization"}
          currentValue={state.subscribeToOrganization || ""}
          isDisabled={state.isSubscribed !== "active"}
          onChange={(v) => {
            props.onChange({
              fieldKey: ["subscribeToOrganization"],
              fieldType: FieldType.SingleSelect,
              value: v
            });
          }}
          buildOption={props.buildOption}
        />
      </Box>
      <Box marginTop={"15px"}>
        <CloneConfigurationCheckbox
          isDisabled={state.isSubscribed !== "active"}
          selectConfiguration={props.selectConfiguration}
          onChangeSelectConfiguration={props.onChangeSelectConfiguration}
        />
      </Box>
    </Box>
  );
};
