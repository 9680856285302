import { Box, Flex } from "@chakra-ui/react";
import { AggregationType, FieldStatus, FieldType } from "@elphi/types";
import lodash, { get } from "lodash";
import { EMPTY } from "../../constants/common";
import { AllEventsMode } from "../../redux/v2/audit-event";
import { AuditLogBox } from "../audit-log/AuditLogBox";
import { fieldStatusToFieldColor } from "../task/field-selection/d2v.utils";
import {
  areAllValuesInOptionsValidation,
  isAnyValuesInOptionsValidation
} from "../utils/validationUtils";
import { AttachedAggregationFieldComponet } from "./AttachedAggregationFieldComponent";
import { AttachedFieldStatusComponet } from "./AttachedFieldStatusComponent";
import { InputBuilderSpecs, OnChangeInput } from "./FormBuilder";
import { EntityFormFieldSpecs } from "./field-specs/fields.types";
import {
  getFieldStatusFromState,
  getValueFromState
} from "./field-specs/objectKeyValidation.types";
import { BaseInputFormatter } from "./formatters/formatter.types";
import { formatterMap } from "./formatters/inputs.formatter";

export const buildInputs = <T extends object>(r: {
  id?: string;
  state: T;
  prefixPathToValue?: string[];
  fieldSpecs: EntityFormFieldSpecs<object>;
  onChange: ((v: OnChangeInput) => void) | undefined;
  specs: {
    isAggregation?: AggregationType;
    path: any; //ObjectKeyValidation<T,P>;
    validation?: (v: any) => boolean;
    isHidden?: boolean;
    isReadOnly?: any;
    isDisabled?: boolean;
    formatter?: BaseInputFormatter<FieldType, any, any>;
    isRequired?: boolean;
  }[];
  hideAttachedComponent?: boolean;
}): InputBuilderSpecs[] => {
  const {
    state,
    fieldSpecs,
    onChange,
    hideAttachedComponent,
    prefixPathToValue
  } = r;
  return r.specs.map((spec) => {
    const focusState = get(state, [...spec.path, "focused"]);

    const aggregationFocusedField =
      focusState === "override"
        ? "override"
        : focusState === "calculated"
        ? "calculated"
        : [
            AggregationType.Aggregation,
            AggregationType.ThirdPartyAggregation,
            undefined
          ].includes(spec?.isAggregation)
        ? "calculated"
        : "thirdParty";
    const selectedFieldPath = spec.isAggregation
      ? [...spec.path, aggregationFocusedField]
      : spec.path;

    const value = getValueFromState({
      state,
      fieldPath: prefixPathToValue
        ? [...prefixPathToValue, ...(selectedFieldPath || [])]
        : selectedFieldPath
    });
    const status: FieldStatus | undefined = getFieldStatusFromState({
      state,
      fieldPath: spec.path as any
    });
    const fieldSpec = (get(fieldSpecs, selectedFieldPath) ||
      {}) as InputBuilderSpecs;

    const fieldStatusAttachment = {
      fieldPath: spec.path,
      prefix: [],
      state,
      isReadOnly: spec.isReadOnly,
      isDisabled: spec.isDisabled,
      onChange: onChange || (() => {})
    };
    const id = get(state, "id") || EMPTY;
    return {
      id: r.id,
      fieldPath: spec.path,
      fieldStatus: status,
      formatter: spec.formatter || formatterMap[fieldSpec.fieldType],
      labelPosition: "up",
      ...fieldSpec,
      isValid:
        spec.validation &&
        spec.validation(value) &&
        additionalValidations(fieldSpec, value),
      currentValue: value,
      customColor: status && fieldStatusToFieldColor[status],
      isReadOnly: isReadOnly({ status }) || spec.isReadOnly,
      isDisabled: spec.isDisabled,
      isHidden: spec.isHidden,
      shouldMarkEmpty: spec.isAggregation && !value,
      isRequired: spec.isRequired,
      attachedComponent: hideAttachedComponent ? null : (
        <Flex>
          <Box>
            {spec.isAggregation ? (
              <AttachedAggregationFieldComponet
                {...fieldStatusAttachment}
                aggregationType={spec.isAggregation}
              />
            ) : null}
          </Box>
          <Box>
            <AttachedFieldStatusComponet {...fieldStatusAttachment} />
          </Box>
          {spec.path && (
            <Box paddingTop={"6px"} paddingLeft={"2px"}>
              <AuditLogBox
                type={AllEventsMode.Field}
                path={spec.path}
                title={fieldSpec.label}
                aggregationType={spec.isAggregation}
                entityId={String(id)}
              />
            </Box>
          )}
        </Flex>
      )
    };
  });
};
export const isReadOnly = (r: { status?: FieldStatus }) => {
  return r.status === FieldStatus.Approved;
};

export type AdditionalValidationsInputType = Pick<
  InputBuilderSpecs,
  "fieldType" | "options" | "isRequired" | "componentOverride"
>;

export const additionalValidations = (
  input: AdditionalValidationsInputType,
  value: any
) => {
  if (input.componentOverride) {
    return true;
  }
  switch (input.fieldType) {
    case FieldType.SingleSelect:
    case FieldType.Boolean:
      return isAnyValuesInOptionsValidation(
        [value],
        input.options,
        input.isRequired
      );
    case FieldType.MultiSelect: {
      return areAllValuesInOptionsValidation(
        value,
        input.options,
        input.isRequired
      );
    }
    default:
      return true;
  }
};

export const fieldStatusComponentFieldSpread = <T extends object>(r: {
  state: T;
  prefix: string[];
  fieldPath: string[];
  isReadOnly?: boolean;
}) => {
  const { state, prefix, fieldPath } = r;
  const metaFieldPath = [...prefix, "fieldMeta", ...fieldPath, "status"];
  const status = lodash.get(state, metaFieldPath);

  const fieldStatusCommonFields: Pick<
    InputBuilderSpecs,
    "isReadOnly" | "customColor" | "fieldStatus"
  > = {
    isReadOnly: isReadOnly({ status }) || r.isReadOnly,
    customColor: fieldStatusToFieldColor[status],
    fieldStatus: status
  };
  return fieldStatusCommonFields;
};
