import { LOSUser } from "@elphi/types";
import { responseHandler } from "../apis/rtk/response.handler";
import { adminApi } from "../redux/v2/admin/admin.service";

export const useAdminHooks = () => {
  const [activateUsers, activateUsersResponse] =
    adminApi.useActivateUsersMutation();
  const [disableUsers, disableUsersResponse] =
    adminApi.useDisableUsersMutation();
  const [setUsersStatus, setUsersStatusResponse] =
    adminApi.useSetUsersStatusMutation();
  const [addUsers, addUsersResponse] = adminApi.useAddUsersMutation();

  const activateUsersHandler = async (r: { ids: string[] }) => {
    return await activateUsers(r).then(responseHandler);
  };
  const disableUsersHandler = async (r: { ids: string[] }) => {
    return await disableUsers(r).then(responseHandler);
  };
  const setUsersStatusHandler = async (r: {
    ids: string[];
    status: LOSUser["status"];
  }) => {
    return await setUsersStatus(r).then(responseHandler);
  };
  const addUsersHandler = async (r: { users: LOSUser[] }) => {
    return await addUsers(r).then(responseHandler);
  };
  return {
    activateUsersHandler,
    activateUsersResponse,
    addUsersHandler,
    addUsersResponse,
    disableUsersHandler,
    disableUsersResponse,
    setUsersStatusHandler,
    setUsersStatusResponse
  };
};
