import { getFocusedData } from "@elphi/aggregations/src/utils/aggregation.utils";
import { Deal, LoanProgramType } from "@elphi/types";
import { InputBuilderSpecs, Section } from "../../form-builder/FormBuilder";

export const loanProgramTypeIsNotEmpty = (
  loanProgramType: LoanProgramType | undefined
) => !!loanProgramType;

export const hideFixNFlip = (state: Partial<Deal> | undefined) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return state === undefined
    ? false
    : loanProgramType &&
        loanProgramTypeIsNotEmpty(loanProgramType) &&
        ![
          LoanProgramType.FixNFlip,
          LoanProgramType.NewConstruction,
          LoanProgramType.BridgePlus
        ].includes(loanProgramType);
};

export const hideFixNFlipNoBridge = (state: Partial<Deal> | undefined) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return state === undefined
    ? false
    : loanProgramType &&
        loanProgramTypeIsNotEmpty(loanProgramType) &&
        ![LoanProgramType.FixNFlip, LoanProgramType.NewConstruction].includes(
          loanProgramType
        );
};

const hasMatchingLoanProgramType = (
  state: Partial<Deal> | undefined,
  loanProgramTypesList: LoanProgramType[]
) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return state === undefined
    ? false
    : !!loanProgramType &&
        loanProgramTypeIsNotEmpty(loanProgramType) &&
        loanProgramTypesList.includes(loanProgramType);
};

export const isNewConstruction = (state: Partial<Deal> | undefined) => {
  return hasMatchingLoanProgramType(state, [LoanProgramType.NewConstruction]);
};

export const isFixNFlip = (state: Partial<Deal> | undefined) => {
  return hasMatchingLoanProgramType(state, [LoanProgramType.FixNFlip]);
};

export const isRental30Premier = (state: Partial<Deal> | undefined) => {
  return hasMatchingLoanProgramType(state, [LoanProgramType.Rental30Premier]);
};

export const isEmptyLoanProgramType = (state: Partial<Deal> | undefined) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return !loanProgramTypeIsNotEmpty(loanProgramType);
};

export const hideFixNFlipOrEmpty = (state: Partial<Deal> | undefined) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return hideFixNFlip(state) || !loanProgramTypeIsNotEmpty(loanProgramType);
};

export const hideRental30 = (state: Partial<Deal> | undefined) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return state === undefined
    ? false
    : loanProgramType &&
        loanProgramTypeIsNotEmpty(loanProgramType) &&
        ![LoanProgramType.Rental30, LoanProgramType.Rental30Premier].includes(
          loanProgramType
        );
};

export const hideRental30OrEmpty = (state: Partial<Deal> | undefined) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return hideRental30(state) || !loanProgramTypeIsNotEmpty(loanProgramType);
};

export const hideRental30Bridge = (state: Partial<Deal> | undefined) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return state === undefined
    ? false
    : loanProgramType &&
        loanProgramTypeIsNotEmpty(loanProgramType) &&
        ![
          LoanProgramType.Rental30,
          LoanProgramType.Rental30Premier,
          LoanProgramType.BridgePlus
        ].includes(loanProgramType);
};

export const hideBridgePlus = (state: Partial<Deal> | undefined) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return state === undefined
    ? false
    : loanProgramType &&
        loanProgramTypeIsNotEmpty(loanProgramType) &&
        ![LoanProgramType.BridgePlus].includes(loanProgramType);
};

export const addGeneralSpecsToSections = (r: {
  sections: Section[];
  specs: Partial<InputBuilderSpecs>;
}): Section[] => {
  r.sections.forEach((s) => {
    s.inputs.forEach((i) => (i.isReadOnly = true));
  });
  return r.sections;
};
