import { Box } from "@chakra-ui/react";
import {
  DATES_FORMATS,
  MAX_SHOW_DEAL_CALENDARS,
  elphiDate
} from "@elphi/utils";
import { useEffect } from "react";
import { ElphiLoader } from "../../common-components";
import { Calendar } from "../../common-components/calendar";
import { DateRange } from "../../common-components/calendar/Calendar";
import { auth } from "../../firebase/firebaseConfig";
import useDealHooks from "../../hooks/deal.hooks";
import { useDealCalendarHooks } from "../../hooks/dealCalendar.hooks";
import useWindowDimensions from "../../hooks/windowDimensions";
import { DaysToolTipLabel } from "./deal-calendar-day/DaysToolTipLabel";
import { DealCalendarDay } from "./deal-calendar-day/DealCalendarDay";
import { DealCalendarFooterDay } from "./deal-calendar-day/DealCalendarFooterDay";
import { DealCalendarSidebar } from "./deal-calendar-sidebar/DealCalendarSidebar";
export type DealCalendarProps = {
  myDealsFlag: boolean;
};

const OFFSET_HEIGHT = 200;

export const DealCalendar = ({ myDealsFlag }: DealCalendarProps) => {
  const {
    selectedCalendarViewDate,
    dates,
    selectedDateType: dateType,
    calendarCurrentViewDates,
    setCalendarCurrentViewDates,
    getDateRangeApi,
    setHoverDate,
    getDateRangeResponse,
    setIsToolTipLoaded,
    isToolTipLoaded
  } = useDealCalendarHooks();
  const { dealTableFilter } = useDealHooks();
  const { heightOffset } = useWindowDimensions();

  const onInitMonth = async ({ startDate, endDate }: DateRange) => {
    setCalendarCurrentViewDates({
      startDate,
      endDate
    });
    const from = elphiDate(startDate).format(DATES_FORMATS.YYYY_MM_DD);
    const to = elphiDate(endDate).format(DATES_FORMATS.YYYY_MM_DD);
    await getDateRangeApi({
      from,
      to,
      dateType,
      filters: myDealsFlag
        ? { ...dealTableFilter, ...{ selectedUserId: auth?.currentUser?.uid } }
        : dealTableFilter
    });
  };

  useEffect(() => {
    setIsToolTipLoaded({ isToolTipLoaded: !getDateRangeResponse.isFetching });
  }, [getDateRangeResponse.isFetching]);

  useEffect(() => {
    const { startDate, endDate } = calendarCurrentViewDates;
    !!startDate && !!endDate && onInitMonth({ startDate, endDate });
  }, [dateType, dealTableFilter, myDealsFlag]);

  const onHoverDate = async (currentDay: Date) => {
    const from = elphiDate(currentDay).format(DATES_FORMATS.YYYY_MM_DD);
    const dateDay = dates?.[dateType]?.[from] || {};
    setHoverDate({ hoverDate: currentDay });

    if (
      dateDay.totalDealsCalendar > MAX_SHOW_DEAL_CALENDARS &&
      dateDay.totalDealsCalendar !== dateDay.deals.length
    ) {
      getDateRangeApi({ from, dateType, filters: dealTableFilter });
    }
  };

  const handleMouseLeave = () => setHoverDate({ hoverDate: undefined });

  return (
    <Box pos={"relative"} onMouseLeave={handleMouseLeave}>
      <DealCalendarSidebar />
      {!isToolTipLoaded && !getDateRangeResponse?.data && <ElphiLoader />}
      <Calendar
        {...selectedCalendarViewDate}
        height={heightOffset(OFFSET_HEIGHT)}
        BodyComponent={DealCalendarDay}
        FooterComponent={DealCalendarFooterDay}
        DayToolTipLabelComponent={DaysToolTipLabel}
        onHoverDate={onHoverDate}
        onInitMonth={onInitMonth}
        startWeekInMonday
      />
    </Box>
  );
};
