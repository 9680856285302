import { Flex } from "@chakra-ui/react";
import {
  LargeBoxCell,
  MediumBoxCell,
  SmallBoxCell,
  TextCell
} from "../../../../custom/chakra/Cell";

export const OrganizationTableHeader = () => {
  return (
    <Flex w="100%" bgColor="lightblue">
      {[
        {
          size: "s",
          label: "index"
        },
        {
          size: "m",
          label: "id"
        },
        {
          size: "m",
          label: "name"
        },
        {
          size: "m",
          label: "description"
        },
        {
          size: "l",
          label: "created"
        },
        {
          size: "l",
          label: "modified"
        },
        {
          size: "m",
          label: "actions"
        }
      ].map((s, i) => {
        if (s.size === "s") {
          return (
            <SmallBoxCell key={i}>
              <TextCell>{s.label}</TextCell>
            </SmallBoxCell>
          );
        }
        if (s.size === "m") {
          return (
            <MediumBoxCell key={i}>
              <TextCell>{s.label}</TextCell>
            </MediumBoxCell>
          );
        }
        if (s.size === "l") {
          return (
            <LargeBoxCell key={i}>
              <TextCell>{s.label}</TextCell>
            </LargeBoxCell>
          );
        }
      })}
    </Flex>
  );
};
