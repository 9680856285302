import { Box, Flex } from "@chakra-ui/react";
import { Role } from "@elphi/types";
import { useRoleHooks } from "../../../hooks/role.hooks";
import useWindowDimensions from "../../../hooks/windowDimensions";
import { useRTKPagination } from "../../../redux/v2/hooks";
import { roleApi } from "../../../redux/v2/role";
import {
  LargeBoxCell,
  MediumBoxCell,
  SmallBoxCell,
  TextCell
} from "../../custom/chakra/Cell";
import { ElphiPaginationList } from "../../elphi-list/ElphiList";
import { useFormBuilderStateHandler } from "../../form-builder/InputBuilder";
import RoleSearch from "./RoleSearch";
import { RoleRow } from "./list/RoleRow";

export type RoleListProps = {
  onRoleEdit: (roleId: string) => void;
};

export const RoleList = (props: RoleListProps) => {
  const roleHooks = useRoleHooks();
  const { roleState } = roleHooks;
  const { heightOffsetInPx } = useWindowDimensions();
  const limit = 50;
  const paginationHandler = useRTKPagination({
    entityState: roleState,
    useLazyPaginateQuery: roleApi.useLazyPaginateQuery,
    options: {
      limit
    }
  });
  const { syncState, onChange, state } = useFormBuilderStateHandler<{
    [id: string]: Partial<Role>;
  }>({
    initialState: {}
  });
  return (
    <Box>
      <RoleSearch
        currentValue={roleState?.selectedId?.toString() || ""}
        onSelect={(v: string) => {
          roleHooks.setSelectedRole(v);
        }}
      />
      <ElphiPaginationList
        tableName={"Role"}
        header={
          <Flex w="100%" bgColor="lightblue">
            {[
              {
                size: "s",
                label: "index"
              },
              {
                size: "l",
                label: "id"
              },
              {
                size: "l",
                label: "label"
              },
              {
                size: "l",
                label: "description"
              },
              {
                size: "l",
                label: "permissions"
              },
              {
                size: "l",
                label: "created"
              },
              {
                size: "l",
                label: "modified"
              },
              {
                size: "m",
                label: "actions"
              }
            ].map((s, i) => {
              if (s.size === "s") {
                return (
                  <SmallBoxCell key={i}>
                    <TextCell>{s.label}</TextCell>
                  </SmallBoxCell>
                );
              } else if (s.size === "m") {
                return (
                  <MediumBoxCell key={i}>
                    <TextCell>{s.label}</TextCell>
                  </MediumBoxCell>
                );
              } else if (s.size === "l") {
                return (
                  <LargeBoxCell key={i}>
                    <TextCell>{s.label}</TextCell>
                  </LargeBoxCell>
                );
              }
            })}
          </Flex>
        }
        height={heightOffsetInPx(320)}
        hasMore={paginationHandler.pageData?.ids?.length < limit}
        items={
          !!roleState.selectedId
            ? [roleState.selectedId.toString()]
            : roleState.ids
        }
        next={paginationHandler.next}
        pageSize={10}
        rowBuilder={(roleId, index) => {
          return (
            <RoleRow
              roleId={roleId?.toString() || ""}
              index={index}
              roleHooks={roleHooks}
              syncState={syncState}
              onChange={onChange}
              state={state}
              onEdit={(v) => {
                props.onRoleEdit(v);
                roleHooks.setSelectedRole(v);
              }}
              key={index}
            />
          );
        }}
      />
    </Box>
  );
};
