import { Box, Button } from "@chakra-ui/react";
import { CloneConfigurationType } from "@elphi/types";
import { useEffect } from "react";
import elphiTheme from "../../../../../assets/themes/elphi.theme.default";
import { EMPTY } from "../../../../../constants/common";
import ModalContainer from "../../../../modal-container/ModalContainer";
import {
  buildDefaultOption,
  buildDisabledCloneOption
} from "../utils/Organization.utils";
import { CloneConfigurationCheckbox } from "./CloneConfigurationCheckbox";
import { OrganizationDropDown } from "./OrganizationDropDown";
import { useCloneConfigurationHooks } from "./clone.configuration.hooks";

export type CloneConfigurationModalProps = {
  show: boolean;
  onClose: () => void;
};

export const CloneConfigurationModal = (
  props: CloneConfigurationModalProps
) => {
  const {
    selectConfiguration,
    setSelectConfiguration,
    selectSourceOrg,
    setSelectSourceOrg,
    selectDestinationOrg,
    setSelectDestinationOrg,
    onResetModal,
    cloneConfigurationResponse,
    cloneConfigurationApiHandler
  } = useCloneConfigurationHooks();

  const handleOnSelectSourceOrganization = (v: string) => {
    if (v === selectDestinationOrg) {
      setSelectDestinationOrg(EMPTY);
    }
    setSelectSourceOrg(v);
  };

  const handleOnSelectDestOrganization = (v: string) => {
    setSelectDestinationOrg(v);
  };

  const canSubmit =
    !selectSourceOrg ||
    !selectDestinationOrg ||
    !selectConfiguration ||
    selectConfiguration.length === 0;

  const onChangeSelectConfiguration = (v: CloneConfigurationType[]) => {
    setSelectConfiguration(v);
  };

  useEffect(() => {
    onResetModal();
  }, [props.show]);

  return (
    <ModalContainer
      isShow={props.show}
      onCloseModal={props.onClose}
      header={"Clone configuration"}
      body={
        <>
          <Box>
            <OrganizationDropDown
              label={"Source Organization"}
              currentValue={selectSourceOrg}
              onChange={handleOnSelectSourceOrganization}
              buildOption={buildDefaultOption}
            />
          </Box>
          <Box marginTop={"15px"}>
            <OrganizationDropDown
              key={selectSourceOrg}
              label={"Destination Organization"}
              currentValue={selectDestinationOrg}
              isDisabled={!selectSourceOrg}
              onChange={handleOnSelectDestOrganization}
              buildOption={(o) => buildDisabledCloneOption(o, selectSourceOrg)}
            />
          </Box>
          <Box marginTop={"15px"}>
            <CloneConfigurationCheckbox
              isDisabled={!selectSourceOrg || !selectDestinationOrg}
              selectConfiguration={selectConfiguration}
              onChangeSelectConfiguration={onChangeSelectConfiguration}
            />
          </Box>
        </>
      }
      footer={
        <>
          <Button
            {...elphiTheme.components.light.button.primary}
            mr={"15px"}
            isDisabled={canSubmit}
            isLoading={cloneConfigurationResponse.isLoading}
            onClick={() => {
              cloneConfigurationApiHandler().then(() => props.onClose());
            }}
          >
            Submit
          </Button>

          <Button
            {...elphiTheme.components.light.button.secondary}
            onClick={props.onClose}
          >
            Cancel
          </Button>
        </>
      }
    />
  );
};
