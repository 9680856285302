import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import DocumentConfigurationTab from "./DocumentConfigurationTab";
import DocumentRuleTab from "./DocumentRuleTab";

const DocumentManagementPage = () => {
  const HEADER_HEIGHT = "40px"; //TABS HEIGHT
  return (
    <Box h="100%" w="100%" overflow="hidden">
      <Tabs
        h="100%"
        w="100%"
        position="sticky"
        top="0"
        isLazy
        lazyBehavior="unmount"
      >
        <TabList>
          <Tab>Document Configuration</Tab>
          <Tab>Rule Template</Tab>
        </TabList>
        <TabPanels w="100%" p="0" h={`calc(100% - ${HEADER_HEIGHT})`}>
          <TabPanel h="100%" w="100%" p="0">
            <DocumentConfigurationTab />
          </TabPanel>
          <TabPanel h="100%" w="100%" p="0">
            <DocumentRuleTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
export default DocumentManagementPage;
