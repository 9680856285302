import { Deal, DealMilestoneType, MilestoneData } from "@elphi/types";
import { MilestoneSelectionLiveStateContainer } from "./MilestoneSelectionLiveStateContainer";
import { MilestoneSelectionSnapshotContainer } from "./MilestoneSelectionSnapshotContainer";

export type MilestoneSelectionContainerProps<T extends DealMilestoneType> = {
  dealId: string;
  deal?: Deal;
  newMilestoneState?: string;
  setNewMilestoneState?: React.Dispatch<React.SetStateAction<string>>;
  prevMilestoneState?: string;
  setPrevMilestoneState?: React.Dispatch<React.SetStateAction<string>>;
  revertToLastMilestone?: () => void;
  onMilestoneChanged?: (params: MilestoneData<T>) => void;
};

export const MilestoneSelectionContainer = <T extends DealMilestoneType>(
  props: MilestoneSelectionContainerProps<T> & {
    snapshotId?: string | undefined;
  }
) => {
  return props.snapshotId ? (
    <MilestoneSelectionSnapshotContainer
      dealId={props.dealId}
      snapshotId={props.snapshotId!}
    />
  ) : (
    <MilestoneSelectionLiveStateContainer {...props} />
  );
};
