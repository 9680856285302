import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Spacer, useDisclosure } from "@chakra-ui/react";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { useTaskInstructionHooks } from "../../hooks/taskInstruction.hooks";
import { GroupModal } from "../custom/modal/GroupModal";
import {
  TaskInstructionFilters,
  TaskInstructionSearch,
  TaskInstructionTable
} from "../task/task-instruction";

export const TaskInstructionTab = () => {
  const { isOpen, onToggle } = useDisclosure();
  const { createTaskInstruction, createResponse } = useTaskInstructionHooks();
  return (
    <Box>
      <Flex>
        <Box width={"100%"}>
          <TaskInstructionSearch />
        </Box>
        <Spacer />
        <Box p={"10px"}>
          <Button
            aria-label="add-task-instruction-btn"
            onClick={onToggle}
            leftIcon={<AddIcon />}
            {...elphiTheme.components.light.button.primary}
          >
            Create Instructions
          </Button>
        </Box>
      </Flex>
      <Flex p={"10px"} direction={"column"}>
        <TaskInstructionFilters />
        <TaskInstructionTable />
      </Flex>
      <GroupModal
        header={"Create task instructions"}
        action={"create"}
        isShow={isOpen}
        onClose={onToggle}
        isLoading={createResponse.isLoading}
        onSubmit={createTaskInstruction}
      />
    </Box>
  );
};
