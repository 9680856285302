import {
  ActionType,
  AutoGenerateType,
  ElphiEntityType,
  ElphiTrxStatus,
  FieldType,
  KeyClosingTaskType,
  OrderStatusTaskFields,
  ServiceLinkTrxStatus,
  Task,
  TaskFlagType,
  TaskStatusType,
  TaskType,
  UserRoleType
} from "@elphi/types";
import { FundingShieldTrxStatus } from "@elphi/types/entities/task-types/integrations/wire-insurance-certificate";
import TaskConfigurationSearch from "../../../task/task-configuration/TaskConfigurationSearch";
import TaskRuleSearch from "../../../task/task-rule/TaskRuleSearch";
import { TaskTemplateSearch } from "../../../task/task-template/TaskTemplateSearch";
import { createOptionsFromEnum } from "../../../utils/formUtils";
import { createCustomComponentConfig } from "../../FormBuilder";
import { baseEntityFormFieldSpecs } from "../baseEntity.fields";
import { EntityFormFieldSpecs } from "../fields.types";
import { createSpecWithFieldMeta } from "../utils/fieldMeta.utils";

export type TaskWithOrderStatusFieldSpecs =
  EntityFormFieldSpecs<OrderStatusTaskFields>;

export const taskWithOrderStatusFieldSpecs: TaskWithOrderStatusFieldSpecs = {
  vendorName: {
    fieldKey: ["vendorName"],
    fieldType: FieldType.String,
    label: "Vendor Name"
  },
  vendorInformation: {
    fieldKey: ["vendorInformation"],
    fieldType: FieldType.String,
    label: "Vendor Information"
  },
  receivedDate: {
    fieldKey: ["receivedDate"],
    fieldType: FieldType.Date,
    label: "Received Date"
  },
  dueDate: {
    fieldKey: ["dueDate"],
    fieldType: FieldType.Date,
    label: "Due Date"
  },
  orderedDate: {
    fieldKey: ["orderedDate"],
    fieldType: FieldType.Date,
    label: "Ordered Date"
  },
  orderedFrom: {
    fieldKey: ["orderedFrom"],
    fieldType: FieldType.String,
    label: "Ordered From"
  },
  orderStatus: {
    fieldKey: ["orderStatus"],
    fieldType: FieldType.String,
    label: "Order Status"
  }
};

export const taskBaseSpec: EntityFormFieldSpecs<Task> = {
  ...baseEntityFormFieldSpecs,
  flagged: {
    fieldKey: ["flagged"],
    fieldType: FieldType.SingleSelect,
    label: "Flagged",
    options: [
      {
        label: "Flagged",
        value: TaskFlagType.Attention
      },
      {
        label: "Unflagged",
        value: TaskFlagType.None
      }
    ]
  },
  name: {
    fieldKey: ["name"],
    fieldType: FieldType.String,
    label: "Task name"
  },
  configurationId: {
    fieldKey: ["configurationId"],
    fieldType: FieldType.SingleSelect,
    label: "Configuration Id",
    componentOverride: createCustomComponentConfig({
      component: TaskConfigurationSearch
    })
  },
  templateId: {
    fieldKey: ["templateId"],
    fieldType: FieldType.SingleSelect,
    label: "Template Id",
    componentOverride: createCustomComponentConfig({
      component: TaskTemplateSearch
    })
  },
  ruleId: {
    fieldKey: ["ruleId"],
    fieldType: FieldType.SingleSelect,
    label: "Rule Id",
    componentOverride: createCustomComponentConfig({
      component: TaskRuleSearch
    })
  },
  entityId: {
    fieldKey: ["entityId"],
    fieldType: FieldType.String,
    label: "Entity Id"
  },
  entityType: {
    fieldKey: ["entityType"],
    fieldType: FieldType.SingleSelect,
    label: "Entity Type",
    options: createOptionsFromEnum(ElphiEntityType)
  },
  keyClosingTaskType: {
    fieldKey: ["keyClosingTaskType"],
    fieldType: FieldType.SingleSelect,
    label: "Key Closing Task Type",
    options: createOptionsFromEnum(KeyClosingTaskType)
  },
  generation: {
    fieldKey: ["generation"],
    fieldType: FieldType.String,
    label: "Generation"
  },
  autoGenerate: {
    fieldKey: ["autoGenerate"],
    fieldType: FieldType.SingleSelect,
    label: "Auto Generate",
    options: createOptionsFromEnum(AutoGenerateType)
  },
  comments: {
    fieldKey: ["comments"],
    fieldType: FieldType.String,
    label: "Comments"
  },
  index: {
    fieldKey: ["index"],
    fieldType: FieldType.String,
    label: "Index"
  },
  templateName: {
    fieldKey: ["templateName"],
    fieldType: FieldType.String,
    label: "Template Name"
  },
  taskStatus: {
    fieldKey: ["taskStatus"],
    fieldType: FieldType.SingleSelect,
    label: "Task Status",
    options: createOptionsFromEnum(TaskStatusType)
  },
  editableBy: {
    fieldKey: ["editableBy"],
    fieldType: FieldType.MultiSelect,
    label: "Editable By",
    options: createOptionsFromEnum(UserRoleType)
  },
  checklistOf: {
    fieldKey: ["checklistOf"],
    fieldType: FieldType.MultiSelect,
    label: "Checklist Of",
    options: createOptionsFromEnum(UserRoleType)
  },
  assignableBy: {
    fieldKey: ["assignableBy"],
    fieldType: FieldType.MultiSelect,
    label: "Assignable By",
    options: createOptionsFromEnum(UserRoleType)
  },
  availableActions: {
    fieldKey: ["availableActions"],
    fieldType: FieldType.MultiSelect,
    label: "Available Actions",
    options: createOptionsFromEnum(ActionType)
  },
  availableStatus: {
    fieldKey: ["availableStatus"],
    fieldType: FieldType.MultiSelect,
    label: "Available Status",
    options: createOptionsFromEnum(TaskStatusType)
  },
  elphiTrxStatus: {
    fieldKey: ["elphiTrxStatus"],
    fieldType: FieldType.SingleSelect,
    label: "Elphi Trx Status",
    options: createOptionsFromEnum(ElphiTrxStatus)
  },
  floodCheckTrxStatus: {
    fieldKey: ["floodCheckTrxStatus"],
    fieldType: FieldType.SingleSelect,
    label: "FloodCheck Trx Status",
    options: createOptionsFromEnum(ElphiTrxStatus)
  },
  serviceLinkTrxStatus: {
    fieldKey: ["serviceLinkTrxStatus"],
    fieldType: FieldType.SingleSelect,
    label: "ServiceLink Trx Status",
    options: createOptionsFromEnum(ServiceLinkTrxStatus)
  },
  fundingShieldTrxStatus: {
    fieldKey: ["fundingShieldTrxStatus"],
    fieldType: FieldType.SingleSelect,
    label: "FundingShield Trx Status",
    options: createOptionsFromEnum(FundingShieldTrxStatus)
  },
  vendorName: {
    fieldKey: ["vendorName"],
    fieldType: FieldType.String,
    label: "Vendor Name"
  },
  vendorInformation: {
    fieldKey: ["vendorInformation"],
    fieldType: FieldType.String,
    label: "Vendor Information"
  },
  receivedDate: {
    fieldKey: ["receivedDate"],
    fieldType: FieldType.Date,
    label: "Order Status - Received Date"
  },
  dueDate: {
    fieldKey: ["dueDate"],
    fieldType: FieldType.Date,
    label: "Order Status - Due Date"
  },
  orderedDate: {
    fieldKey: ["orderedDate"],
    fieldType: FieldType.Date,
    label: "Order Status - Ordered Date"
  },
  orderedFrom: {
    fieldKey: ["orderedFrom"],
    fieldType: FieldType.String,
    label: "Order Status - Ordered From"
  },
  orderStatus: {
    fieldKey: ["orderStatus"],
    fieldType: FieldType.String,
    label: "Order Status - order Status"
  },
  type: {
    fieldKey: ["type"],
    fieldType: FieldType.SingleSelect,
    label: "Task Type",
    options: createOptionsFromEnum(TaskType)
  }
};

export const taskSpec = createSpecWithFieldMeta<Task>({
  spec: taskBaseSpec,
  fieldsToPick: [
    "comments",
    "vendorName",
    "vendorInformation",
    "receivedDate",
    "dueDate",
    "orderedDate",
    "orderedFrom"
  ]
});
