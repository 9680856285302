import { Property } from "@elphi/types";
import { OnChangeInput, Section } from "../../../../form-builder/FormBuilder";
import { rentRangeOrderSection } from "./rentRangeOrderSection";
import { rentRangeStatusSection } from "./rentRangeStatusSection";
import { rentRangeVerifySection } from "./rentRangeVerifySection";

export const rentRangeSections = (
  state: Partial<Property>,
  onChange: (v: OnChangeInput) => void
): Section[] => {
  return [
    rentRangeOrderSection(state, onChange),
    rentRangeVerifySection(state, onChange),
    rentRangeStatusSection(state, onChange)
  ];
};
