import { Flex, IconButton, Link, Text, Tooltip } from "@chakra-ui/react";
import {
  Deal,
  DealCalendarType,
  LoanPartyEntityRoleType,
  LoanPartyIndividualRoleType,
  formatNumberWithCommas
} from "@elphi/types";
import { truncate, values } from "lodash";
import { EMPTY } from "../../../constants/common";
import { WorksheetTab } from "../../../features/worksheet/worksheet.types";
import { useDealCalendarHooks } from "../../../hooks/dealCalendar.hooks";
import { useOrgHooks } from "../../../hooks/org.hooks";
import PathVariables from "../../../routes/pathVariables";
import { NavigateToDeal } from "../../deal/table/v2/DealTableRow";
import {
  ChecklistPink100Icon,
  DirectionsIcon,
  DollarGreen100Icon,
  HandHouseBlue100Icon,
  HouseOrange100Icon,
  HumanCyan100Icon,
  HumanPurple100Icon,
  MenuRightIcon
} from "../../icons";
import { getURLSearchParams } from "../../utils/navigationUtils";
import { getFullName, getPartyIdsByRoleTypes } from "../dealCalendar.utils";

export type DayToolTipLabelProps = {
  index: number;
  dealCalendar: DealCalendarType;
  date: Date;
};

export const DayToolTipLabel = ({
  dealCalendar,
  date,
  index
}: DayToolTipLabelProps) => {
  const { selectedOrgId } = useOrgHooks();
  const { setSideBarDealCalendar, setHoverDate } = useDealCalendarHooks();

  const truncateNames = (data: string[]) => {
    const firstItemId = values(data)?.[0];
    const itemsWithOutFirstIds = values(data).slice(1);
    const firstItem = dealCalendar?.parties?.[firstItemId];
    const itemsWithOutFirst = itemsWithOutFirstIds?.map(
      (partyId) => dealCalendar.parties?.[partyId]
    );

    const names = itemsWithOutFirst?.map(getFullName);

    const fullName = firstItem ? getFullName(firstItem) : EMPTY;

    const shortenedFullName =
      fullName.length > 12 ? `${truncate(fullName, { length: 12 })}` : fullName;

    const itemCount = names.length;

    const partiesPipelinePath = `../../${selectedOrgId?.toString()}${
      PathVariables.PartiesPipeline
    }?party=${firstItem?.partyId}`;

    return (
      <Flex cursor={"pointer"} flexDir="row">
        <Tooltip label={fullName && `new tab to: ${fullName}`}>
          <Link href={firstItem?.partyId && partiesPipelinePath} isExternal>
            <Text fontSize={"14px"}>{shortenedFullName || "--"}</Text>
          </Link>
        </Tooltip>
        <Tooltip label={names?.length > 0 && `${names.join(", ")}`}>
          <Text fontSize={"14px"}>
            {itemCount > 0 ? ` + ${itemCount}` : EMPTY}
          </Text>
        </Tooltip>
      </Flex>
    );
  };

  const changeRouteToChecklist = () => {
    const queryParams = getURLSearchParams({ title: WorksheetTab.Checklist });
    const queryString = queryParams.toString();

    return `/${selectedOrgId}${PathVariables.WorksheetPath}/${dealCalendar.id}/checklist?${queryString}`;
  };

  const debounceShowSideBar = () => {
    setSideBarDealCalendar(undefined);
    setTimeout(() => {
      setHoverDate({ hoverDate: undefined });
      setSideBarDealCalendar({
        dealCalendarId: dealCalendar.id,
        date
      });
    }, 200);
  };

  const getBorrowersNames = () => {
    const borrowerIds = getPartyIdsByRoleTypes(dealCalendar?.parties || {}, [
      LoanPartyEntityRoleType.PrimaryBorrower,
      LoanPartyEntityRoleType.Borrower
    ]);
    return truncateNames(borrowerIds);
  };

  const getSponsorsNames = () => {
    const borrowerIds = getPartyIdsByRoleTypes(dealCalendar?.parties || {}, [
      LoanPartyIndividualRoleType.PrimarySponsor,
      LoanPartyIndividualRoleType.Sponsor
    ]);
    return truncateNames(borrowerIds);
  };

  return (
    <Flex
      key={index}
      flexDir={"column"}
      justify={"space-between"}
      padding={"6px"}
      borderRadius={"8px"}
      bgColor={"white"}
      h={"135px"}
      mb={"12px"}
    >
      <Flex justify={"space-between"} alignItems={"center"}>
        <Flex alignItems={"center"}>
          <HandHouseBlue100Icon w={"18px"} h={"18px"} mr={"3px"} />
          <NavigateToDeal
            selectedOrgId={selectedOrgId}
            rowData={{
              deal: {
                LoanIdentifier: dealCalendar.loanIdentifier,
                id: dealCalendar.id
              } as Partial<Deal>
            }}
          >
            <Text fontSize={"14px"}>{dealCalendar.loanIdentifier}</Text>
          </NavigateToDeal>
        </Flex>
        <Flex alignItems={"center"}>
          {getBorrowersNames()}
          <HumanPurple100Icon w={"18px"} h={"18px"} ml={"3px"} />
        </Flex>
      </Flex>
      <Flex justify={"space-between"} alignItems={"center"}>
        <Flex alignItems={"center"}>
          <ChecklistPink100Icon w={"18px"} h={"18px"} mr={"3px"} />
          <Tooltip label={`new tab to: checklist`}>
            <Link href={changeRouteToChecklist()} isExternal>
              <Text fontSize={"14px"}>Tasks</Text>
            </Link>
          </Tooltip>
        </Flex>
        <Flex alignItems={"center"}>
          {getSponsorsNames()}
          <HumanCyan100Icon w={"18px"} h={"18px"} ml={"3px"} />
        </Flex>
      </Flex>
      <Flex justify={"space-between"} alignItems={"center"}>
        <Flex alignItems={"center"}>
          <DollarGreen100Icon w={"18px"} h={"18px"} mr={"3px"} />
          <Text fontSize={"14px"}>
            {dealCalendar?.requestedLoanAmount
              ? `$${formatNumberWithCommas(
                  dealCalendar.requestedLoanAmount.toString()
                )}`
              : "--"}
          </Text>
        </Flex>
        <Flex alignItems={"center"}>
          <Text fontSize={"14px"}>{dealCalendar.loanProgramType || "--"}</Text>
          <HouseOrange100Icon w={"18px"} h={"18px"} ml={"3px"} />
        </Flex>
      </Flex>
      <Flex justify={"space-between"} alignItems={"center"}>
        <Flex alignItems={"center"}>
          <DirectionsIcon w={"18px"} h={"18px"} mr={"3px"} />
          <Text fontSize={"14px"}>{dealCalendar.milestone}</Text>
        </Flex>
        <Flex>
          <IconButton
            aria-label="sidebar"
            colorScheme="gray"
            variant="ghost"
            width={"10px"}
            height={"20px"}
            onClick={debounceShowSideBar}
            icon={<MenuRightIcon w={"24px"} h={"24px"} />}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
