import { Property } from "@elphi/types";
import { OnChangeInput, Section } from "../../../form-builder/FormBuilder";
import { propertySpecFields } from "../../../form-builder/field-specs/property/property.fields";
import { buildInputs } from "../../../form-builder/formBuilder.utils";
import {
  singleSelectValidation,
  stringValidation
} from "../../../utils/validationUtils";
import {
  parcelNumberFieldPath,
  propertyAddressLineTextFieldPath,
  propertyAddressUnitIdentifierFieldPath,
  propertyBlockIdentifierFieldPath,
  propertyCityNameFieldPath,
  propertyLotIdentifierFieldPath,
  propertyPostalCodeFieldPath,
  propertyStateCodeFieldPath,
  propertySubdivisionIdentifierFieldPath,
  usersEmailAddressFieldPath
} from "../../field-paths/property.fieldPaths";

export const serviceLinkFloodOrderSection = (
  state: Partial<Property>,
  onChange: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Integration Flood Cert: Order",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: propertySpecFields,
      specs: [
        {
          path: propertyAddressLineTextFieldPath,
          isRequired: true,
          validation: (x) => stringValidation(x, true)
        },
        {
          path: propertyAddressUnitIdentifierFieldPath,
          validation: stringValidation
        },
        {
          path: propertyCityNameFieldPath,
          isRequired: true,
          validation: (x) => stringValidation(x, true)
        },
        {
          path: propertyStateCodeFieldPath,
          isRequired: true,
          validation: (x) => singleSelectValidation(x, true)
        },
        {
          path: propertyPostalCodeFieldPath,
          isRequired: true,
          validation: (x) => stringValidation(x, true)
        },
        {
          path: parcelNumberFieldPath,
          validation: stringValidation
        },
        {
          path: propertyLotIdentifierFieldPath,
          validation: stringValidation
        },
        {
          path: propertyBlockIdentifierFieldPath,
          validation: stringValidation
        },
        {
          path: propertySubdivisionIdentifierFieldPath,
          validation: stringValidation
        },
        {
          path: usersEmailAddressFieldPath,
          validation: stringValidation
        }
      ]
    })
  };
};
