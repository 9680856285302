import { CreditScore } from "../../../../../../shared/types/entities/creditReport.types";
import { ObjectKeyValidation } from "../../form-builder/field-specs/objectKeyValidation.types";

export const expirationDateFieldPath: ObjectKeyValidation<
  CreditScore,
  ["aggregations", "expirationDate"]
> = ["aggregations", "expirationDate"];
export const experianDateFieldPath: ObjectKeyValidation<
  CreditScore,
  ["experian", "date"]
> = ["experian", "date"];
export const experianScoreFieldPath: ObjectKeyValidation<
  CreditScore,
  ["experian", "score"]
> = ["experian", "score"];
export const equifaxDateFieldPath: ObjectKeyValidation<
  CreditScore,
  ["equifax", "date"]
> = ["equifax", "date"];
export const equifaxScoreFieldPath: ObjectKeyValidation<
  CreditScore,
  ["equifax", "score"]
> = ["equifax", "score"];
export const transunionDateFieldPath: ObjectKeyValidation<
  CreditScore,
  ["transunion", "date"]
> = ["transunion", "date"];
export const transunionScoreFieldPath: ObjectKeyValidation<
  CreditScore,
  ["transunion", "score"]
> = ["transunion", "score"];
export const creditReportPullTypeFieldPath: ObjectKeyValidation<
  CreditScore,
  ["pullType"]
> = ["pullType"];
