import { Button } from "@chakra-ui/react";
import { PartyGroup, Status } from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { isEmpty, uniqBy } from "lodash";
import { useMemo } from "react";
import { FilterMenuOptions } from "../../../../common-components/menu-options/FilterMenuOptions";
import { useFilterMenuHook } from "../../../../common-components/menu-options/filter-menu-hook";
import {
  usePartyGroup,
  usePartyGroupFilter
} from "../../../../hooks/partyGroup.hooks";
import { PartyGroupSliceState } from "../../../../redux/v2/party-group";

const namePath: DotNestedKeys<PartyGroup> = "name";
export const PartyGroupFilters = () => {
  const filterMenuHook = useFilterMenuHook();
  const { partyGroupState, searchApi } = usePartyGroup();
  const { setCurrentFilter } = usePartyGroupFilter();
  const { clearFilters } = filterMenuHook;

  const handleClearClick = () => {
    clearFilters();
    setCurrentFilter({
      values: {
        name: [],
        status: []
      }
    });
  };

  const isDisable = !partyGroupState.filters?.current
    ? true
    : Object.keys(partyGroupState.filters?.current).every((key) =>
        isEmpty(partyGroupState.filters?.current[key])
      );

  const nameOptions = useMemo(() => {
    const partyGroupNames = uniqBy(
      Object.values(partyGroupState.entities),
      namePath
    );
    return partyGroupNames.map((pg: PartyGroup) => ({
      value: pg.name,
      label: pg.name
    }));
  }, [partyGroupState.entities]);

  const onFilterChange = (
    filter: PartyGroupSliceState["filters"]["current"]
  ) => {
    setCurrentFilter({
      values: {
        ...partyGroupState.filters.current,
        ...filter
      }
    });
  };
  return (
    <>
      <FilterMenuOptions
        customKey={"namesFilter"}
        maxHeight={"300px"}
        scrollHeight={"300px"}
        filterMenuHook={filterMenuHook}
        title={"Name"}
        options={nameOptions}
        searchApi={searchApi}
        showSearchBar={true}
        onChange={(items: string[]) => onFilterChange({ name: items })}
        selected={partyGroupState.filters.current.name}
        showClearAll
      />
      <FilterMenuOptions
        customKey={"statusFilter"}
        maxHeight={"300px"}
        scrollHeight={"300px"}
        filterMenuHook={filterMenuHook}
        title={"Status"}
        options={[
          { label: "Active", value: Status.Active },
          { label: "Disabled", value: Status.Disabled }
        ]}
        onChange={(items: string[]) => onFilterChange({ status: items })}
        selected={partyGroupState.filters.current.status}
      />
      <Button
        mr={1}
        background={"transparent"}
        fontSize={14}
        fontWeight={"normal"}
        onClick={handleClearClick}
        isDisabled={isDisable}
      >
        Clear filters
      </Button>
    </>
  );
};
