import {
  FirebaseFilter,
  Status,
  StatusCode,
  TaskInstruction
} from "@elphi/types";
import { PartialWithId } from "@elphi/types/services/service.types";
import { removeEmpty, removeEmptyValues } from "@elphi/utils/src/common.utils";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RTKResponse } from "../apis/rtk/response.types";
import { useElphiToast } from "../components/toast/toast.hook";
import { RootState } from "../redux/store";
import { usePaginationHooksV2 } from "../redux/v2/hooks/pagination.hooks.v2";
import {
  taskInstructionApi,
  taskInstructionSlice,
  TaskInstructionSliceState
} from "../redux/v2/task-instruction";
import { FilterCombinationState } from "../redux/v2/types/stateWithFilter.types";
import { useFilter } from "./filter.hooks";

export const LIMIT = 10;
export const useTaskInstructionHooks = () => {
  const dispatch = useDispatch();
  const { errorToast, successToast } = useElphiToast();
  const [searchApi, searchResponse] = taskInstructionApi.useLazySearchQuery();
  const [createApi, createResponse] = taskInstructionApi.useCreateMutation();
  const [updateApi, updateResponse] = taskInstructionApi.useUpdateMutation();
  const [updateBatchApi, updateBatchResponse] =
    taskInstructionApi.useUpdateBatchMutation();
  const [getInstructionsBatchApi, getInstructionsBatchResponse] =
    taskInstructionApi.useLazyGetBatchQuery();

  const setSelected = (id: string) =>
    dispatch(taskInstructionSlice.actions.selectedId({ id }));

  const taskInstructionState = useSelector(
    (state: RootState) => state.taskInstruction
  );
  const selectedInstruction = useSelector(
    (state: RootState) =>
      (state.taskInstruction.selectedId &&
        state.taskInstruction.entities[state.taskInstruction.selectedId]) ||
      undefined
  );

  const createTaskInstruction = async (r: Pick<TaskInstruction, "name">) => {
    return await createApi({
      ...r,
      status: Status.Disabled
    })
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.OK) {
          successToast({
            title: "Task instructions created",
            description: `Task instructions: ${res.data?.id}`
          });
        }
        if (res.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to create task instructions",
            description: res.data?.description
          });
        }
        return res;
      });
  };

  const updateTaskInstruction = async (r: PartialWithId<TaskInstruction>) => {
    return await updateApi(r)
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.OK) {
          successToast({
            title: "Task instructions updated",
            description: `Task instructions: ${res.data?.id}`
          });
        }
        if (res.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to update task instructions",
            description: res.data?.description
          });
        }
        return res;
      });
  };

  const cloneInstruction = async (r: Pick<TaskInstruction, "name">) => {
    return await createApi({
      ...r,
      status: Status.Disabled
    })
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.OK) {
          successToast({
            title: "Task instructions cloned",
            description: `Task instructions: ${res.data?.id}`
          });
        }
        if (res.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to clone instructions",
            description: res.data?.description
          });
        }
        return res;
      });
  };

  const getInstructionsBatch = async (ids: string[]) => {
    return await getInstructionsBatchApi(ids)
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((res) => {
        if (res.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed get instructions",
            description: res.data?.description
          });
        }
        return res;
      });
  };

  const updateTaskInstructionBatch = async (r: {
    [id: string]: Partial<TaskInstruction>;
  }) => {
    const groups = removeEmptyValues(r);
    if (!groups?.length) {
      return null;
    }

    return await updateBatchApi({ groups })
      .then(responseHandler)
      .then((res) => {
        if (res.status === StatusCode.OK) {
          successToast({
            title: "Task instructions updated",
            description: `total instructions updated: ${res.data?.batch?.length}`
          });
        }
        if (res.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to update batch",
            description: res.data?.description
          });
        }
        return res;
      });
  };

  return {
    setSelected,
    taskInstructionState,
    searchApi,
    searchResponse,
    selectedInstruction,
    createTaskInstruction,
    createResponse,
    cloneInstruction,
    updateTaskInstruction,
    updateResponse,
    updateTaskInstructionBatch,
    updateBatchResponse,
    getInstructionsBatch,
    getInstructionsBatchResponse
  };
};

export const useTaskInstructionFilterHooks = () => {
  const { taskInstructionState } = useTaskInstructionHooks();
  const { buildFireStoreFilters, setCombination, setFilterCombinationDetails } =
    useFilter<TaskInstruction, TaskInstructionSliceState>(taskInstructionState);
  const currentFilter = taskInstructionState.filters.current;
  const [filters, setFilters] = useState<FirebaseFilter[]>([]);

  useEffect(() => {
    const filters = buildFireStoreFilters();
    setFilters(filters);
  }, [taskInstructionState.filters.currentCombination]);

  const setCurrentFilter = (filter: {
    values: TaskInstructionSliceState["filters"]["current"];
  }) => {
    setCombination(
      filter.values,
      taskInstructionSlice.actions.setFilterAndCombination
    );
  };

  const currentCombination = useSelector((state: RootState) => {
    const filters = state.taskInstruction.filters;
    return filters.combinations[filters.currentCombination];
  });

  const filteredItems = useMemo(() => {
    const names = new Set(currentFilter.name);
    const statuses = new Set(currentFilter.status);

    const filtered = taskInstructionState.ids
      .map((id) => {
        const entity = taskInstructionState.entities[id];
        if (!entity) {
          return undefined;
        }

        const { name, status } = entity;
        if (
          (names.size === 0 || (names && names.has(name))) &&
          (statuses.size === 0 || (status && statuses.has(status)))
        ) {
          return {
            ...entity
          };
        }
        return undefined;
      })
      .filter(removeEmpty);

    return filtered;
  }, [currentFilter, taskInstructionState.entities]);

  useEffect(() => {
    setFilterCombinationDetails(
      { ids: filteredItems.map((x) => x.id) },
      taskInstructionSlice.actions.setFilterCombinationDetails
    );
  }, [filteredItems]);

  const setPagingInfo = (info: Partial<FilterCombinationState[string]>) => {
    setFilterCombinationDetails(
      info,
      taskInstructionSlice.actions.setFilterCombinationDetails
    );
  };

  return {
    setCurrentFilter,
    filters,
    currentCombination,
    setPagingInfo
  };
};

export const useTaskInstructionTableHooks = () => {
  const { filters, currentCombination, setPagingInfo } =
    useTaskInstructionFilterHooks();
  const { nextPage, paginateApiResponse } = usePaginationHooksV2({
    paginationProvider: taskInstructionApi.useLazyPaginateV2Query,
    limit: LIMIT,
    input: {
      cursor: currentCombination.cursor,
      options: {
        filter: filters
      }
    },
    currentCursor: currentCombination.cursor,
    setHasMore: (hasMore) => {
      setPagingInfo({
        hasMore
      });
    },
    setCursor: (cursor) => {
      setPagingInfo({
        cursor
      });
    }
  });

  return {
    nextPage,
    paginateApiResponse,
    filters,
    currentCombination
  };
};
