import { CreditScore } from "@elphi//types/entities/creditReport.types";
import { AggregationFocusType, CreditReportPullType } from "@elphi/types";
import { createOptionsFromEnumAllowUndefined } from "../../../utils/formUtils";
import { FieldType } from "../../fieldFormat.types";
import { baseEntityFormFieldSpecs } from "../baseEntity.fields";
import { EntityFormFieldSpecs, SpecsBaseOmit } from "../fields.types";
import { createSpecWithFieldMeta } from "../utils/fieldMeta.utils";

export type CreditReportFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<CreditScore>
>;
export const creditReportFieldBaseSpecs: CreditReportFieldSpecs = {
  ...baseEntityFormFieldSpecs,
  aggregations: {
    expirationDate: {
      calculated: {
        fieldType: FieldType.Date,
        label: "Credit Report Expiration Date",
        fieldKey: ["aggregations", "expirationDate", "calculated"]
      },
      override: {
        fieldType: FieldType.Date,
        label: "Credit Report Expiration Date",
        fieldKey: ["aggregations", "expirationDate", "override"]
      },
      focused: {
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        fieldKey: ["aggregations", "expirationDate", "focused"],
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    }
  },
  experian: {
    date: {
      thirdParty: {
        fieldType: FieldType.Date,
        label: "Experian Credit Report Date",
        fieldKey: ["experian", "date", "thirdParty"]
      },
      override: {
        fieldType: FieldType.Date,
        label: "Experian Credit Report Date",
        fieldKey: ["experian", "date", "override"]
      },
      focused: {
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        fieldKey: ["experian", "date", "focused"],
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    score: {
      thirdParty: {
        fieldType: FieldType.CreditScore,
        label: "Experian Score",
        fieldKey: ["experian", "score", "thirdParty"]
      },
      override: {
        fieldType: FieldType.CreditScore,
        label: "Experian Score",
        fieldKey: ["experian", "score", "override"]
      },
      focused: {
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        fieldKey: ["experian", "score", "focused"],
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    }
  },
  equifax: {
    date: {
      thirdParty: {
        fieldType: FieldType.Date,
        label: "Equifax Credit Report Date",
        fieldKey: ["equifax", "date", "thirdParty"]
      },
      override: {
        fieldType: FieldType.Date,
        label: "Equifax Credit Report Date",
        fieldKey: ["equifax", "date", "override"]
      },
      focused: {
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        fieldKey: ["equifax", "date", "focused"],
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    score: {
      thirdParty: {
        fieldType: FieldType.CreditScore,
        label: "Equifax Score",
        fieldKey: ["equifax", "score", "thirdParty"]
      },
      override: {
        fieldType: FieldType.CreditScore,
        label: "Equifax Score",
        fieldKey: ["equifax", "score", "override"]
      },
      focused: {
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        fieldKey: ["equifax", "score", "focused"],
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    }
  },
  transunion: {
    date: {
      thirdParty: {
        fieldType: FieldType.Date,
        label: "TransUnion Credit Report Date",
        fieldKey: ["transunion", "date", "thirdParty"]
      },
      override: {
        fieldType: FieldType.Date,
        label: "TransUnion Credit Report Date",
        fieldKey: ["transunion", "date", "override"]
      },
      focused: {
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        fieldKey: ["transunion", "date", "focused"],
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    score: {
      thirdParty: {
        fieldType: FieldType.CreditScore,
        label: "TransUnion Score",
        fieldKey: ["transunion", "score", "thirdParty"]
      },
      override: {
        fieldType: FieldType.CreditScore,
        label: "TransUnion Score",
        fieldKey: ["transunion", "score", "override"]
      },
      focused: {
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        fieldKey: ["transunion", "score", "focused"],
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    }
  },
  pullType: {
    fieldType: FieldType.SingleSelect,
    label: "Hard/Soft Pull",
    fieldKey: ["pullType"],
    options: createOptionsFromEnumAllowUndefined(CreditReportPullType)
  }
};

export const creditReportFieldSpecs = createSpecWithFieldMeta({
  spec: creditReportFieldBaseSpecs
});
