import { Deal, DealPartyRelation } from "@elphi/types";
import { useDispatch, useSelector } from "react-redux";
import { AppConfig } from "../../../../config/appConfig";
import useDealHooks from "../../../../hooks/deal.hooks";
import { useDealPartyRelationHooks } from "../../../../hooks/dealpartyrelation.hooks";
import { useDealUserRelationHooks } from "../../../../hooks/dealuser.hooks";
import { usePartyHooks } from "../../../../hooks/party.hooks";
import { usePropertyHooks } from "../../../../hooks/property.hooks";
import { RootState } from "../../../../redux/store";
import { dealSlice } from "../../../../redux/v2/deal";
import { getListFromDictionary } from "../../../../utils/batchUtils";
import { useFormBuilderStateHandler } from "../../../form-builder/InputBuilder";
import { PartyTableState } from "../../../party/table/v2/PartyTable";
import { useElphiToast } from "../../../toast/toast.hook";
export type DealTableState = {
  deals: { [id: string]: { id: string } & Partial<Deal> };
  dealPartyRelations: {
    [id: string]: { id: string } & Partial<DealPartyRelation>;
  };
};

export const useDealTableState = () => {
  const { errorToast, successToast } = useElphiToast();
  const { updateBatch: updateDealBatch } = useDealHooks();
  const { updateBatch: updateDealPartyBatch } = useDealPartyRelationHooks();
  const { partyState } = usePartyHooks();
  const { dealState, dealTableFilter } = useDealHooks();
  const { propertyState } = usePropertyHooks();
  const { setDealTableCursor, getDealTableCursor, selectedDealTableData } =
    useDealHooks();
  const { dealUserRelationState } = useDealUserRelationHooks();

  const dispatch = useDispatch();

  const setCurrentCursorId = (cursorId: string) => {
    dispatch(dealSlice.actions.setDealTableCurrentCursorId({ id: cursorId }));
  };
  const setCurrentCursorHasMore = (hasMore: boolean) => {
    dispatch(dealSlice.actions.setDealTableCurrentCursorIdHasMore(hasMore));
  };
  const currentCursorIdHasMore = useSelector(
    (state: RootState) =>
      state.deal.dealTable.cursor.hasMore[state.deal.dealTable.cursor.currentId]
  );
  const currentCursorId = useSelector(
    (state: RootState) => state.deal.dealTable.cursor.currentId
  );
  const currentCursor = useSelector(
    (state: RootState) =>
      state.deal?.dealTable?.cursor?.agnostic?.[
        state?.deal?.dealTable?.cursor?.currentId || "none"
      ] || ""
  );
  const handleUpdateDealPartyBatch = async (
    dealTableState: Partial<DealTableState>
  ) => {
    if (!dealTableState.dealPartyRelations) return null;
    const listRelations = getListFromDictionary(
      dealTableState?.dealPartyRelations
    );
    if (!listRelations.length) return null;
    const filteredRelations = listRelations.filter(
      (d): d is DealPartyRelation => !!d
    );
    return await updateDealPartyBatch({ relations: filteredRelations }).then(
      (r) => {
        if (r.status === 200) {
          successToast({
            title: "Deal Party Relations Updated",
            description: `total relations updated: ${listRelations.length}`
          });
        }
        r.status === 400 &&
          errorToast({
            title: "Failed to update batch",
            description: r.data.description
          });
        return r;
      }
    );
  };
  const handleUpdateDealBatch = async (
    tableState: Partial<PartyTableState>
  ) => {
    if (!tableState.deals) return null;
    const listDeals = getListFromDictionary(tableState?.deals);
    if (!listDeals.length) return null;
    const filteredDeals = listDeals.filter((d): d is Deal => !!d);
    return await updateDealBatch({ deals: filteredDeals }).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "Deals Updated",
          description: `total deals updated: ${listDeals.length}`
        });
      }
      r.status === 400 &&
        errorToast({
          title: "Failed to update batch",
          description: r.data.description
        });
      return r;
    });
  };
  const {
    onChange: dealPartyRelationOnChange,
    state: localDealPartyRelationState,
    syncState: syncDealPartyRelationState
  } = useFormBuilderStateHandler({
    initialState: {
      dealPartyRelations: {}
    } as { dealPartyRelations: { [id: string]: DealPartyRelation } },
    callback: handleUpdateDealPartyBatch,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  const {
    state: localDealState,
    syncState: syncDealState,
    onChange: dealOnChange
  } = useFormBuilderStateHandler({
    initialState: { deals: {} } as { deals: { [id: string]: Deal } },
    callback: handleUpdateDealBatch,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  return {
    currentCursorIdHasMore,
    setCurrentCursorHasMore,
    currentCursor,
    dealTableFilter,
    selectedDealTableData,
    currentCursorId,
    setCurrentCursorId,
    localDealState,
    localPartyState: partyState,
    localDealPartyRelationState,
    localPropertyState: propertyState,
    dealState,
    dealUserRelationState,
    setDealTableCursor,
    getDealTableCursor,
    dealPartyRelationOnChange,
    dealOnChange,
    syncDealState,
    syncDealPartyRelationState
  };
};
