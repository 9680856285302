import { Deal, Task } from "@elphi/types";
import { OnChangeInput, Section } from "../../../form-builder/FormBuilder";
import { wireInsuranceOrderSection } from "./wireInsuranceOrderSection";
import { wireInsuranceOrderStatusSection } from "./wireInsuranceOrderStatusSection";
import { wireInsuranceVerifySection } from "./wireInsuranceVerifySection";

export const wireInsuranceSection = (
  state: Partial<Deal>,
  onChange: (v: OnChangeInput) => void,
  selectedTask: Task
): Section[] => {
  return [
    wireInsuranceOrderSection(state, onChange, selectedTask),
    wireInsuranceVerifySection(state, onChange),
    wireInsuranceOrderStatusSection(state, onChange)
  ];
};
