import { Property } from "@elphi/types";
import { propertySpecFields } from "../../../../form-builder/field-specs/property/property.fields";
import { OnChangeInput, Section } from "../../../../form-builder/FormBuilder";
import { buildInputs } from "../../../../form-builder/formBuilder.utils";
import { stringValidation } from "../../../../utils/validationUtils";
import {
  parcelNumberFieldPath,
  propertyAddressLineTextFieldPath,
  propertyAddressUnitIdentifierFieldPath,
  propertyCityNameFieldPath,
  propertyCountyNameFieldPath,
  propertyPostalCodeFieldPath,
  propertyStateCodeFieldPath
} from "../../../field-paths/property.fieldPaths";

export const siteXOrderSection = (
  state: Partial<Property>,
  onChange: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Property Report: Order",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: propertySpecFields,
      specs: [
        {
          path: propertyAddressLineTextFieldPath,
          isRequired: true,
          validation: (v) => stringValidation(v, true)
        },
        {
          path: propertyAddressUnitIdentifierFieldPath,
          validation: stringValidation
        },
        {
          path: propertyCityNameFieldPath,
          isRequired: true,
          validation: (v) => stringValidation(v, true)
        },
        {
          path: propertyStateCodeFieldPath,
          isRequired: true,
          validation: (v) => stringValidation(v, true)
        },
        {
          path: propertyPostalCodeFieldPath,
          isRequired: true,
          validation: (v) => stringValidation(v, true)
        },
        {
          path: propertyCountyNameFieldPath,
          validation: stringValidation
        },
        {
          path: parcelNumberFieldPath,
          validation: stringValidation
        }
      ]
    })
  };
};
