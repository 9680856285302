import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Spacer, useDisclosure } from "@chakra-ui/react";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { useFieldGroupHooks } from "../../hooks/fieldGroup.hooks";
import { GroupModal } from "../custom/modal/GroupModal";
import { FieldGroupSearch } from "./FieldGroupSearch";
import { FieldGroupFilters } from "./table/FieldGroupFilters";
import { FieldGroupTable } from "./table/FieldGroupTable";

export const FieldGroups = () => {
  const { isOpen: isOpenCreateModal, onToggle: onToggleCreateModal } =
    useDisclosure();
  const { createGroup, createResponse } = useFieldGroupHooks();
  return (
    <Box>
      <Flex>
        <Box width={"100%"}>
          <FieldGroupSearch />
        </Box>
        <Spacer />
        <Box p={"10px"}>
          <Button
            aria-label="add-field-group-btn"
            onClick={onToggleCreateModal}
            leftIcon={<AddIcon />}
            {...elphiTheme.components.light.button.primary}
          >
            Create Field Group
          </Button>
        </Box>
      </Flex>
      <Flex p={"10px"} direction={"column"}>
        <FieldGroupFilters />
        <FieldGroupTable />
      </Flex>
      <GroupModal
        header={"Create field group"}
        action={"create"}
        isShow={isOpenCreateModal}
        onClose={onToggleCreateModal}
        isLoading={createResponse.isLoading}
        onSubmit={createGroup}
      />
    </Box>
  );
};
