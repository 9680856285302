import {
  EntityToEntityRelation,
  EntityToIndividualRelation,
  PartyRelation,
  PartyRelationType
} from "../partyRelation.types";

export const getPartyRelationTemplate = (t: PartyRelationType | undefined) => {
  if (!t) return {} as PartyRelation;
  const entityToEntityRelation: EntityToEntityRelation = {
    type: PartyRelationType.EntityToEntity,
    id: "",
    index: "",
    createdAt: "",
    modifiedAt: "",
    parentId: "",
    childId: "",
    ownershipPercentage: "",
    relationRoleType: []
  };
  const entityToIndividualRelation: EntityToIndividualRelation = {
    type: PartyRelationType.EntityToIndividual,
    id: "",
    index: "",
    createdAt: "",
    modifiedAt: "",
    parentId: "",
    childId: "",
    ownershipPercentage: "",
    relationRoleType: []
  };
  switch (t) {
    case PartyRelationType.EntityToEntity:
      return entityToEntityRelation;
    case PartyRelationType.EntityToIndividual:
      return entityToIndividualRelation;
  }
};
