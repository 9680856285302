import { BoxProps } from "@chakra-ui/react";
import { DealMilestoneType } from "@elphi/types";

export type DealMilestoneColor = {
  [P in DealMilestoneType]: BoxProps["bgColor"];
};
export const dealMilestoneColor: DealMilestoneColor = {
  "Pre-Submission Deal": "grey.100",
  "New Deal": "blue.100",
  "Initial Deal Review": "blue.300",
  Processing: "yellow.400",
  "Final Deal Review": "purple.100",
  "Final Deal Review Approved": "purple.300",
  "Final Quote Issued": "teal.100",
  "Final Quote Accepted": "teal.300",
  "Final Closing Updates Requested": "cyan.100",
  "Clear to Close": "cyan.300",
  "Loan Package Sent": "pink.200",
  "Loan Package Received": "pink.400",
  Funded: "green.100",
  "Close of Escrow": "green.200",
  "File Complete": "green.400",
  Suspended: "red.400",
  "Dead Deal": "grey"
};
