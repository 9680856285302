import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import {
  DocumentConfiguration,
  getDocumentConfigurationTemplate
} from "@elphi/types";
import { responseHandler } from "../../../apis/rtk/response.handler";
import elphiTheme from "../../../assets/themes/elphi.theme.default";
import { useDocumentConfigurationHooks } from "../../../hooks/documentConfiguration.hooks";
import { documentConfigurationApi } from "../../../redux/v2/document-configuration/documentConfiguration.service";
import FormBuilder from "../../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../../form-builder/InputBuilder";
import { FieldType } from "../../form-builder/fieldFormat.types";
import { useElphiToast } from "../../toast/toast.hook";

const CreateDocumentConfigurationModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [createConfigurationApi, createConfigurationResponse] =
    documentConfigurationApi.useCreateMutation();
  const { selectedDocumentConfiguration, setSelectedDocumentConfiguration } =
    useDocumentConfigurationHooks();
  const { onChange, state } = useFormBuilderStateHandler({
    initialState: getDocumentConfigurationTemplate()
  });

  const toastHandler = (
    d:
      | {
          status: 400;
          data: {
            error: any;
            description: string;
          };
        }
      | {
          status: 200;
          data: {
            id: string;
          };
        }
  ) => {
    if (d.status === 200) {
      successToast({
        title: "New Configuration Created",
        description: `document configuration: ${d.data.id}`
      });

      onClose();
    } else if (d.status === 400) {
      errorToast({
        title: "Failed to create configuration",
        description: d?.data?.description || "something went wrong"
      });
    }
  };
  const { successToast, errorToast } = useElphiToast();
  const createConfigurationHandler = () => {
    createConfigurationApi({
      ...getDocumentConfigurationTemplate(),
      name: state.name
    } as DocumentConfiguration)
      .then(responseHandler)
      .then((r) => {
        if (r.status === 200) {
          setSelectedDocumentConfiguration(r.data.id);
        }
        return r;
      })
      .then(toastHandler);
  };
  const cloneConfigurationHandler = () => {
    if (!selectedDocumentConfiguration) return;
    createConfigurationApi({
      ...selectedDocumentConfiguration,
      name: state.name
    } as DocumentConfiguration)
      .then(responseHandler)
      .then((r) => {
        if (r.status === 200) {
          setSelectedDocumentConfiguration(r.data.id);
        }
        return r;
      })
      .then(toastHandler);
  };
  return (
    <Box>
      <Box>
        <Button
          float={"right"}
          {...elphiTheme.components.light.button.primary}
          onClick={onOpen}
        >
          Create Configuration
        </Button>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"} width="100%">
          <ModalHeader>Document Configuration</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedDocumentConfiguration && (
              <Text>Cloning: {selectedDocumentConfiguration.name}</Text>
            )}
            <FormBuilder
              customKey="createConfigurationFrom"
              elphiView="form"
              onChange={onChange}
              sections={[
                {
                  inputs: [
                    {
                      label: "name",
                      labelPosition: "up",
                      fieldType: FieldType.String,
                      fieldKey: ["name"],
                      currentValue: state.name,
                      isValid: state.name > ""
                    }
                  ]
                }
              ]}
            />
            <Flex float="right">
              <Box p="5px">
                <Button
                  isLoading={createConfigurationResponse.isLoading}
                  onClick={cloneConfigurationHandler}
                  isDisabled={!state.name || !selectedDocumentConfiguration}
                  float="right"
                  {...elphiTheme.components.light.button.secondary}
                >
                  Clone
                </Button>
              </Box>
              <Box p="5px">
                <Button
                  isLoading={createConfigurationResponse.isLoading}
                  onClick={createConfigurationHandler}
                  isDisabled={!!selectedDocumentConfiguration || !state.name}
                  float="right"
                  {...elphiTheme.components.light.button.primary}
                >
                  Submit New
                </Button>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CreateDocumentConfigurationModal;
