import { DeleteIcon } from "@chakra-ui/icons";
import { Box, IconButton, Text, Tooltip } from "@chakra-ui/react";
import { DealUser, FieldType, LOSUser } from "@elphi/types";
import { EntityId, EntityState } from "@reduxjs/toolkit";
import { omit, uniq } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { AppConfig } from "../../config/appConfig";
import { EMPTY } from "../../constants/common";
import { auth } from "../../firebase/firebaseConfig";
import useDealHooks from "../../hooks/deal.hooks";
import { useLOSUserHooks } from "../../hooks/losuser.hooks";
import { useRoleHooks } from "../../hooks/role.hooks";
import {
  SnapshotDataStateFormat,
  useSnapshotHooks
} from "../../hooks/snapshot.hooks";
import { RootState } from "../../redux/store";
import { LOSUserSliceState } from "../../redux/v2/los-user/losUser.slice";
import { fromFirebaseIdToLabel } from "../../utils/common";
import { removeNulls } from "../../utils/filter.utils";
import RoleSearch from "../admin/role-permission/RoleSearch";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";
import { ElphiTable } from "../table/ElphiTableComponent";
import { ElphiCellType, ElphiTableProps } from "../table/table.types";
import { multiDropDownComponent } from "../task/task-template/v2/TaskTemplateTableRow";
import { DeleteDealUserRelationModal } from "./DeleteDealUser";
import { useUserTableHooks } from "./userTable.hooks";
export type UserTableState = {
  users: { [id: string]: { id: string } & Partial<LOSUser> };
};
type UserTableProps = {
  dealId: EntityId;
};

export const UserTableContainer = (
  props: UserTableProps & { snapshotId?: string }
) => {
  return props.snapshotId ? (
    <UserTableSnapshotContainer {...props} snapshotId={props.snapshotId} />
  ) : (
    <UserTableLiveStateContainer {...props} />
  );
};
export const UserTableLiveStateContainer = (props: UserTableProps) => {
  const userState = useSelector((state: RootState) => state.losUser);
  const dealUserRelationState = useSelector(
    (state: RootState) => state.dealUserRelation
  );
  const { getLOSUser } = useLOSUserHooks();
  const { getDealUserRelations, getDealUserRelationsResponse } = useDealHooks();

  useEffect(() => {
    if (auth.currentUser && props.dealId) {
      getDealUserRelations([String(props.dealId)], true);
    }
  }, [props.dealId, auth.currentUser]);

  const getUserRowData = useCallback(
    (currentUserId: EntityId) => {
      if (userState.entities[currentUserId] === undefined) {
        getLOSUser(String(currentUserId), true);
      }
      return userState.entities[currentUserId];
    },
    [userState]
  );

  const pageDataMemo = useMemo(() => {
    const currentDealEntities = Object.values(
      dealUserRelationState?.entities
    )?.filter?.((entity) => entity && entity?.dealId === props.dealId);
    const userIdsInDeal =
      currentDealEntities.map((entity) => entity && entity.userId) ?? [];
    return currentDealEntities

      .filter((entity) =>
        userIdsInDeal.includes(String(entity && entity.userId))
      )
      .map((entity) => {
        return entity && getUserRowData(entity.userId);
      })
      .filter(removeNulls);
  }, [dealUserRelationState, userState]);

  const isLoading =
    getDealUserRelationsResponse.isUninitialized ||
    getDealUserRelationsResponse.isFetching ||
    getDealUserRelationsResponse.isLoading;

  return (
    <UserTable
      pageDataMemo={pageDataMemo}
      isLoading={isLoading}
      userState={userState}
      dealId={props.dealId}
      dealUserRelationState={dealUserRelationState}
      isReadOnly={false}
    />
  );
};
export const UserTableSnapshotContainer = (
  props: UserTableProps & { snapshotId: string }
) => {
  const { snapshotDataState, getSnapshotDataResponse } = useSnapshotHooks();
  const snapshot = snapshotDataState({
    snapshotId: props.snapshotId
  });

  const pageDataMemo = useMemo(() => {
    if (!snapshot.dealUserState?.entities || !snapshot.dealUserState?.entities)
      return [];
    const currentDealEntities = Object.values(
      snapshot.dealUserState?.entities
    )?.filter?.((entity) => entity && entity?.dealId === props.dealId);
    const userIdsInDeal =
      currentDealEntities.map((entity) => entity && entity.userId) ?? [];
    return currentDealEntities
      .filter((entity) =>
        userIdsInDeal.includes(String(entity && entity.userId))
      )
      .map((entity) => {
        return snapshot.losUserState?.entities[entity.userId];
      })
      .filter(removeNulls);
  }, [snapshot.dealUserState, snapshot.losUserState]);

  return (
    <>
      {snapshot?.losUserState && snapshot?.dealUserState && (
        <UserTable
          isLoading={getSnapshotDataResponse.isLoading}
          dealId={props.dealId}
          userState={snapshot?.losUserState}
          dealUserRelationState={snapshot.dealUserState}
          pageDataMemo={pageDataMemo}
          isReadOnly={true}
        />
      )}
    </>
  );
};
export const UserTable = (
  props: UserTableProps & {
    userState: LOSUserSliceState;
    dealUserRelationState:
      | EntityState<DealUser>
      | SnapshotDataStateFormat<DealUser>;
    isLoading: boolean;
    pageDataMemo: LOSUser[];
    isReadOnly: boolean;
  }
) => {
  const {
    pageDataMemo,
    isLoading,
    dealUserRelationState,
    userState,
    dealId,
    isReadOnly
  } = props;
  const { rolePaginationHandler, getRoleBatchApi, roleState } = useRoleHooks();
  const [selectedUserId, setSelectedUserId] = useState(EMPTY);
  const { handleUpdateDealUserBatch } = useUserTableHooks();
  const [allInitialSelectedRolesLoaded, setAllInitialSelectedRolesLoaded] =
    useState(false);
  const [deletedDealUserRelations, setDeletedDealUserRelations] = useState<
    string[]
  >([]);

  const {
    syncState,
    onChange,
    state: dealUserFormState,
    setState: setDealUserFormState
  } = useFormBuilderStateHandler<{
    [id: string]: { id: string } & Partial<DealUser>;
  }>({
    initialState: {},
    callback: handleUpdateDealUserBatch,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  useEffect(() => {
    const deletedDealUserRelations = Object.keys(dealUserFormState).filter(
      (id) => !dealUserRelationState.entities[id]
    );
    setDeletedDealUserRelations(deletedDealUserRelations);
    syncState({
      state: dealUserRelationState.entities,
      shouldSync: true
    });
  }, [dealUserRelationState.entities]);

  useEffect(() => {
    const formStateExcludeDeleteion = omit(
      dealUserFormState,
      ...deletedDealUserRelations
    );
    setDealUserFormState(formStateExcludeDeleteion);
  }, [deletedDealUserRelations]);

  useEffect(() => {
    if (allInitialSelectedRolesLoaded) return;
    const selectedRolesIds: string[] = uniq(
      Object.keys(dealUserFormState)?.flatMap((dealUserId) => {
        return getDealUserRoleIds({
          dealUserId,
          dealUserFormState
        });
      })
    );

    const missingRoles = selectedRolesIds.filter(
      (item) => !roleState.ids.includes(item)
    );
    if (missingRoles.length) {
      getRoleBatchApi(missingRoles, true).then(() => {
        setAllInitialSelectedRolesLoaded(true);
      });
    }
  }, [dealUserFormState]);

  const userTableFullDataRows: ElphiTableProps["rows"] = useMemo(() => {
    return (
      pageDataMemo?.flatMap((rowData, i) => {
        if (!rowData) {
          return {
            index: String(i),
            cells: [
              {
                index: 0,
                data: <Text align={"center"}>{i + 1}</Text>,
                type: ElphiCellType.Element,
                maxWidth: "100px",
                minWidth: "100px"
              },
              {
                index: 1,
                data: <Text align={"center"}></Text>,
                type: ElphiCellType.Element,
                maxWidth: "150px",
                minWidth: "150px"
              },
              {
                index: 2,
                data: <Text align={"center"}></Text>,
                type: ElphiCellType.Element,
                maxWidth: "150px",
                minWidth: "150px"
              },
              {
                index: 3,
                data: <Text align={"center"}></Text>,
                type: ElphiCellType.Element,
                maxWidth: "150px",
                minWidth: "150px"
              },
              {
                index: 4,
                data: <Text align={"center"}></Text>,
                type: ElphiCellType.Element,
                maxWidth: "150px",
                minWidth: "150px"
              },
              {
                index: 5,
                data: <Text align={"center"}></Text>,
                type: ElphiCellType.Element,
                maxWidth: "180px",
                minWidth: "180px"
              },
              {
                index: 6,
                data: <Text align={"center"}></Text>,
                type: ElphiCellType.Element,
                maxWidth: "150px",
                minWidth: "150px"
              }
            ],
            minHeight: "53px",
            maxHeight: "53px"
          };
        }
        const dealUserId = buildDealUserId({ dealId, userId: rowData.id });
        const dealUserRoleIds = getDealUserRoleIds({
          dealUserId,
          dealUserFormState
        });
        return {
          index: rowData.index || String(i),
          cells: [
            {
              index: 0,
              data: <Text align={"center"}>{i + 1}</Text>,
              type: ElphiCellType.Element,
              maxWidth: "100px",
              minWidth: "100px"
            },
            {
              index: 1,
              data: <Text align={"center"}>{rowData?.name}</Text>,
              type: ElphiCellType.Element,
              maxWidth: "150px",
              minWidth: "150px"
            },
            {
              index: 2,
              data: <Text align={"center"}>{rowData?.email}</Text>,
              type: ElphiCellType.Element,
              maxWidth: "150px",
              minWidth: "150px"
            },
            {
              index: 3,
              data: <Text align={"center"}>{rowData?.title}</Text>,
              type: ElphiCellType.Element,
              maxWidth: "150px",
              minWidth: "150px"
            },
            {
              index: 4,
              data: (
                <Box textAlign={"center"}>
                  {rowData?.roles?.map?.((role, i) => (
                    <Box key={i}>{fromFirebaseIdToLabel(role?.label)}</Box>
                  ))}
                </Box>
              ),
              type: ElphiCellType.Element,
              maxWidth: "150px",
              minWidth: "150px"
            },
            {
              index: 5,
              data: (
                <Box>
                  <RoleSearch
                    customComponent={multiDropDownComponent}
                    currentValue={dealUserRoleIds}
                    fieldType={FieldType.MultiSelect}
                    fetchMore={rolePaginationHandler.next}
                    onSelect={(items: string[]) => {
                      !isReadOnly &&
                        onChange({
                          fieldKey: [dealUserId, "roleIds"],
                          fieldType: FieldType.MultiSelect,
                          value: items
                        });
                    }}
                    hideSelectedOptions={false}
                  />
                </Box>
              ),
              type: ElphiCellType.Element,
              maxWidth: "180px",
              minWidth: "180px"
            },
            {
              index: 6,
              data: (
                <Text align={"center"}>
                  <Tooltip label={`remove ${rowData.name} from this deal`}>
                    <IconButton
                      aria-label="delete-deal-user-relation"
                      bg="white"
                      size="md"
                      color="red.400"
                      onClick={() =>
                        !isReadOnly && rowData && setSelectedUserId(rowData?.id)
                      }
                      icon={<DeleteIcon cursor={"pointer"} />}
                    />
                  </Tooltip>
                </Text>
              ),
              type: ElphiCellType.Element,
              maxWidth: "150px",
              minWidth: "150px"
            }
          ],
          minHeight: "53px",
          maxHeight: "53px"
        };
      }) || []
    );
  }, [userState, dealUserFormState]);

  const userTableHeader: ElphiTableProps["header"] = useMemo(() => {
    return [
      {
        index: 0,
        data: "Row Index",
        type: ElphiCellType.String,
        maxWidth: "50px",
        minWidth: "50px"
      },
      {
        index: 1,
        data: "Name",
        type: ElphiCellType.String,
        maxWidth: "150px",
        minWidth: "150px"
      },
      {
        index: 2,
        data: "Email",
        type: ElphiCellType.String,
        maxWidth: "150px",
        minWidth: "150px"
      },
      {
        index: 3,
        data: "Title",
        type: ElphiCellType.String,
        maxWidth: "150px",
        minWidth: "150px"
      },
      {
        index: 4,
        data: "Role",
        type: ElphiCellType.String,
        maxWidth: "150px",
        minWidth: "150px"
      },
      {
        index: 5,
        data: "Deal Roles",
        type: ElphiCellType.String,
        maxWidth: "180px",
        minWidth: "180px"
      },
      {
        index: 6,
        data: "Action",
        type: ElphiCellType.String,
        maxWidth: "150px",
        minWidth: "150px"
      }
    ];
  }, []);

  const userTableProps: ElphiTableProps = useMemo(() => {
    return {
      header: userTableHeader,
      rows: userTableFullDataRows,
      isLoading: isLoading,
      rowsCount: userTableFullDataRows.length,
      footer: (
        <Box>
          <Text fontSize={"larger"}>
            Total assigned users: {userTableFullDataRows.length}
          </Text>
        </Box>
      )
    };
  }, [userTableFullDataRows, isLoading]);

  return (
    <>
      <ElphiTable
        minHeight={window.innerHeight > 1000 ? "800px" : "600px"}
        maxHeight={window.innerHeight > 1000 ? "800px" : "600px"}
        header={userTableProps.header}
        rows={userTableProps.rows}
        isLoading={isLoading}
        footer={userTableProps.footer}
        rowsCount={1} //{userTableProps.rowsCount || PAGE_SIZE}
      />
      {selectedUserId && (
        <DeleteDealUserRelationModal
          dealId={props.dealId && props.dealId}
          userId={selectedUserId}
          isOpen={!!selectedUserId}
          onClose={() => {
            setSelectedUserId(EMPTY);
          }}
        />
      )}
    </>
  );
};

const buildDealUserId = (r: { dealId: EntityId; userId: string }) => {
  const { dealId, userId } = r;
  return `${dealId}_${userId}`;
};

const getDealUserRoleIds = (r: {
  dealUserId: string;
  dealUserFormState: { [id: string]: { id: string } & Partial<DealUser> };
}) => {
  const { dealUserId, dealUserFormState } = r;
  return dealUserFormState[dealUserId]?.roleIds || [];
};

export default UserTable;
