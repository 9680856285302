import { Box, Flex, Text } from "@chakra-ui/react";
import { AggregationType, BooleanSelectType, Deal, Task } from "@elphi/types";
import { WireInsuranceFile } from "@elphi/types/entities/task-types/integrations/wire-insurance-certificate/funding-shield/fundingShield.types";
import { getFocusedData } from "@elphi/utils";
import { isEmpty } from "lodash";
import { fileTypes } from "../../../../firebase/constants";
import {
  InputsFormBuilder,
  OnChangeInput
} from "../../../form-builder/FormBuilder";
import { dealFieldSpecs } from "../../../form-builder/field-specs/deal/deal.fields";
import { buildInputs } from "../../../form-builder/formBuilder.utils";
import { TaskFileManagerBody } from "../../../task/TaskFileManager";
import { stringValidation } from "../../../utils/validationUtils";
import { wireInsuranceCertificateFundingShieldStateNYIndicator } from "../../field-paths/deal.fieldPaths";

export type WireInsuranceFilesProps = {
  state: Partial<Deal>;
  onChange: (v: OnChangeInput) => void;
  selectedTask: Task;
};

export const WireInsuranceFiles = ({
  selectedTask,
  onChange,
  state
}: WireInsuranceFilesProps) => {
  const inputs = buildInputs({
    state,
    onChange,
    fieldSpecs: dealFieldSpecs,
    specs: [
      {
        isAggregation: AggregationType.Aggregation,
        isReadOnly:
          state.aggregations?.Integrations?.WireInsuranceCertificate
            ?.FundingShield.StateNYIndicator?.focused !== "override",
        path: wireInsuranceCertificateFundingShieldStateNYIndicator,
        validation: stringValidation
      }
    ]
  });

  const stateNYIndicator = getFocusedData(
    state.aggregations?.Integrations?.WireInsuranceCertificate?.FundingShield
      .StateNYIndicator
  );
  return (
    <Box borderTopWidth={"2px"} borderTopColor={"grey.100"} mt={5}>
      <Box mt={5} flexDir={"column"} justifyContent={"space-between"}>
        <Box>
          <Box>
            <InputsFormBuilder inputs={inputs} onChange={onChange} />
          </Box>
        </Box>
        <Flex mt={2} flexWrap={"wrap"}>
          <Box w={300}>
            <Text pl="3px" fontWeight={"bold"}>
              Upload Errors & Omissions Policy
            </Text>
            <TaskFileManagerBody
              isSingleFile
              hideCheckAll
              hideUploadedNumber
              selectedTask={selectedTask}
              types={fileTypes.PDF}
              fileKey={WireInsuranceFile.uploadErrorsAndOmissionsPolicy}
            />
          </Box>
          <Box w={300} ml={5}>
            <Text pl="3px" fontWeight={"bold"}>
              Upload Wire Instructions
            </Text>
            <TaskFileManagerBody
              isSingleFile
              hideCheckAll
              hideUploadedNumber
              selectedTask={selectedTask}
              types={fileTypes.PDF}
              fileKey={WireInsuranceFile.uploadWiresInstructions}
            />
          </Box>
          {(isEmpty(stateNYIndicator) ||
            stateNYIndicator === BooleanSelectType.false) && (
            <Box w={300} ml={5}>
              <Text pl="3px" fontWeight={"bold"}>
                Upload Closing Protection Letter
              </Text>
              <TaskFileManagerBody
                isSingleFile
                hideCheckAll
                hideUploadedNumber
                selectedTask={selectedTask}
                types={fileTypes.PDF}
                fileKey={WireInsuranceFile.uploadClosingProtectionLetter}
              />
            </Box>
          )}
          {(isEmpty(stateNYIndicator) ||
            stateNYIndicator === BooleanSelectType.true) && (
            <Box w={300} ml={5}>
              <Text pl="3px" fontWeight={"bold"}>
                Upload Crimes Policy
              </Text>
              <TaskFileManagerBody
                isSingleFile
                hideCheckAll
                hideUploadedNumber
                selectedTask={selectedTask}
                types={fileTypes.PDF}
                fileKey={WireInsuranceFile.uploadCrimesPolicy}
              />
            </Box>
          )}
        </Flex>
      </Box>
    </Box>
  );
};
