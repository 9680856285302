import { Box, Button, Flex } from "@chakra-ui/react";
import { FieldType, PartyGroup } from "@elphi/types";
import { set } from "lodash";
import { useEffect, useState } from "react";
import elphiTheme from "../../../../assets/themes/elphi.theme.default";
import { EMPTY } from "../../../../constants/common";
import FormBuilder, { OnChangeInput } from "../../../form-builder/FormBuilder";
import ModalContainer from "../../../modal-container/ModalContainer";

export const PartyGroupModal = (props: {
  isShow: boolean;
  onClose: () => void;
  partyGroup?: Partial<PartyGroup>;
  acknowledgeAction: (
    r: Pick<PartyGroup, "name" | "description">
  ) => Promise<void>;

  isLoading?: boolean;
  headerText: string;
  subHeaderText?: string;
  acknowledgeButtonText: string;
}) => {
  const initialState = {
    name: EMPTY,
    description: EMPTY
  };
  const [state, setState] =
    useState<Pick<PartyGroup, "name" | "description">>(initialState);

  useEffect(() => {
    setState({
      name: props.partyGroup?.name || EMPTY,
      description: props.partyGroup?.description || EMPTY
    });
  }, [props.partyGroup]);

  const handleSubmit = () => {
    props.acknowledgeAction(state).finally(() => {
      handleModalClose();
    });
  };

  const handleChange = (e: OnChangeInput) => {
    const diff = set(state, e.fieldKey, e.value);
    setState((prev) => ({ ...prev, ...diff }));
  };

  const handleModalClose = () => {
    setState(initialState);
    props.onClose();
  };

  return (
    <ModalContainer
      isShow={props.isShow}
      onCloseModal={handleModalClose}
      header={props.headerText}
      customStyles={{ footer: { width: "100%" } }}
      body={
        <>
          {props.subHeaderText && <Box as="p">{props.subHeaderText}</Box>}
          <FormBuilder
            customKey={"partyGroupForm"}
            elphiView="form"
            onChange={handleChange}
            sections={[
              {
                inputs: [
                  {
                    label: "Name",
                    labelPosition: "up",
                    fieldType: FieldType.String,
                    fieldKey: ["name"],
                    currentValue: state.name,
                    isRequired: true
                  },
                  {
                    label: "Description",
                    labelPosition: "up",
                    fieldType: FieldType.String,
                    fieldKey: ["description"],
                    currentValue: state.description
                  }
                ]
              }
            ]}
          />
        </>
      }
      footer={
        <Flex width="100%" justifyContent={"space-between"}>
          <Box p="5px">
            <Button
              isLoading={props.isLoading}
              onClick={handleSubmit}
              isDisabled={!state.name}
              float="right"
              {...elphiTheme.components.light.button.primary}
            >
              {props.acknowledgeButtonText}
            </Button>
          </Box>
          <Box p="5px">
            <Button
              onClick={handleModalClose}
              float="right"
              {...elphiTheme.components.light.button.secondary}
            >
              Cancel
            </Button>
          </Box>
        </Flex>
      }
    />
  );
};
