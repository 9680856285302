import { PartyRelation } from "@elphi/types";
import { ObjectKeyValidation } from "../../form-builder/field-specs/objectKeyValidation.types";

export const partyRelationRoleTypeFieldPath: ObjectKeyValidation<
  PartyRelation,
  ["relationRoleType"]
> = ["relationRoleType"];

export const ownershipPercentageFieldPath: ObjectKeyValidation<
  PartyRelation,
  ["ownershipPercentage"]
> = ["ownershipPercentage"];
