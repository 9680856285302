import { useDisclosure } from "@chakra-ui/react";
import { ChangeReasonMilestones, Deal, MilestoneData } from "@elphi/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import firebase from "../../../../firebase/firebaseConfig";
import { ChangeReasonModalComponent } from "./DeadDealChangeReasonModalComponent";
import { ChangeReasonViewDetailsComponent } from "./DeadDealChangeReasonViewDetailsComponent";

export type DealState = {
  [x: string]: { id: string } & Partial<Deal>;
};
export const ChangeReasonLiveStateContainer = (props: {
  newDealMilestone: ChangeReasonMilestones;
  lastDealMilestone: string;
  dealId: string;
  deal: Deal | undefined;
  milestoneChanged: {
    isLoading: boolean;
    formState: DealState;
    syncState: <D extends object>(r: {
      shouldSync: boolean;
      state: D | undefined;
      statePath?: (() => string[] | undefined) | undefined;
    }) => void;
    onMilestoneChanged: (params: MilestoneData<ChangeReasonMilestones>) => void;
  };
  revertToLastMilestone: () => void;
}) => {
  const [genericReasonState, setGenericReasonState] = useState("");
  const [specificReasonState, setSpecificReasonState] = useState("");
  const {
    isOpen: changeReasonModalIsOpen,
    onOpen: openChangeReasonModal,
    onClose: closeChangeReasonModal
  } = useDisclosure();

  useEffect(() => {
    if (props.deal) {
      props.milestoneChanged.syncState({
        shouldSync: !!props.deal,
        state: props.deal,
        statePath: () => {
          if (props.deal) {
            return [props.deal.id];
          }
        }
      });
    }
  }, [props.deal, props.dealId]);

  useEffect(() => {
    if (
      !!props.lastDealMilestone &&
      props.newDealMilestone !== props.lastDealMilestone
    ) {
      openChangeReasonModal();
    }
  }, [props.newDealMilestone]);

  const onChangeHandler = useCallback(() => {
    const params: MilestoneData<ChangeReasonMilestones> = {
      genericReason: genericReasonState,
      specificReason: specificReasonState,
      type: props.newDealMilestone,
      timestamp: firebase.firestore.Timestamp.now().toDate().toISOString()
    };
    props.milestoneChanged.onMilestoneChanged(params);
  }, [props.dealId, genericReasonState, specificReasonState]);

  const sharedProps = useMemo(() => {
    return {
      dealId: props.dealId,
      deal: props.deal,
      newDealMilestone: props.newDealMilestone,
      lastDealMilestone: props.lastDealMilestone,
      form: {
        state: props.milestoneChanged.formState,
        isLoading: props.milestoneChanged.isLoading,
        onChange: onChangeHandler,
        genericReasonState,
        setGenericReasonState,
        specificReasonState,
        setSpecificReasonState
      }
    };
  }, [
    props.dealId,
    props.milestoneChanged.formState,
    props.milestoneChanged.isLoading,
    onChangeHandler,
    genericReasonState,
    specificReasonState,
    props.newDealMilestone,
    props.lastDealMilestone
  ]);

  return (
    <>
      <ChangeReasonViewDetailsComponent
        {...sharedProps}
        options={{
          disabledButton: changeReasonModalIsOpen
        }}
        onButtonClicked={openChangeReasonModal}
      />
      <ChangeReasonModalComponent
        {...sharedProps}
        disclosureModal={{
          isOpen: changeReasonModalIsOpen,
          onClose: closeChangeReasonModal
        }}
        revertToLastMilestone={props.revertToLastMilestone}
      />
    </>
  );
};
