import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { DevFeatureFlag } from "@elphi/utils";
import { TABLE_MIN_WIDTH } from "../../../constants/common";
import { useStatsigGate } from "../../../hooks/statsig.hooks";
import { PartyGroup } from "../party-group/PartyGroup";
import { PartyValidationConfigurationPage } from "../party-validation-configuration/PartyValidationConfigurationPage";

type TabPanelProps = { shouldShow: boolean; children?: JSX.Element };
const CustomTabPanel = (props: TabPanelProps) => (
  <TabPanel h="100%" w="100%" p="20px" fontSize="1rem" fontWeight="bold">
    {props.shouldShow ? props.children : "Coming soon..."}
  </TabPanel>
);

export const OrganizationManagementTabs = () => {
  const { value } = useStatsigGate(
    DevFeatureFlag.ESD_2826_Organization_Management
  );

  return (
    <Box h="100%" w="100%" overflow="hidden">
      <Tabs
        h="100%"
        w="100%"
        position="sticky"
        top="0"
        isLazy={true}
        lazyBehavior="unmount"
      >
        <TabList>
          <Tab>Labels</Tab>
          <Tab>Party Groups</Tab>
          <Tab>Party Validation Settings</Tab>
        </TabList>
        <TabPanels h={`calc(100% - ${TABLE_MIN_WIDTH})`} w="100%" p="0">
          <CustomTabPanel shouldShow={false} />
          <CustomTabPanel shouldShow={value}>
            <PartyGroup />
          </CustomTabPanel>
          <CustomTabPanel shouldShow={value}>
            <PartyValidationConfigurationPage />
          </CustomTabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
