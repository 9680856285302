import { ElphiEntityType, FieldType, LabelValue } from "@elphi/types";
import { NOT_AVAILABLE } from "../../../constants/common";
import { DNDRow } from "../../drag-and-drop/dnd.types";
import { fieldObject } from "../../utils/field-selection/builders/field-selection-rule.builder";

export enum FieldGroupDNDRowType {
  TARGET = "target",
  SOURCE = "source"
}

export type FieldGroupDNDRow = DNDRow<{
  entityType: ElphiEntityType;
  label: string;
  path: string;
  type?: FieldType;
  options?: LabelValue[];
  rowType: FieldGroupDNDRowType;
  from?: string[];
  to?: string[];
}>;

export const excludeFieldsFromDataSet = (
  selectedFields: { fieldPath: string; entityType: ElphiEntityType }[]
) => {
  return dataSet.filter((x) => {
    return !selectedFields.some(
      (v) => `${v.entityType}-${v.fieldPath}` === x.id
    );
  });
};
export const buildTargetFields = (
  selectedFields: {
    fieldPath: string;
    entityType: ElphiEntityType;
    from?: string[];
    to?: string[];
  }[]
) => {
  return selectedFields.map((v) => {
    const id = `${v.entityType}-${v.fieldPath}`;
    const data = dataMap.get(id);
    return {
      id,
      entityType: v.entityType,
      path: v.fieldPath,
      label: data?.label || NOT_AVAILABLE,
      type: data?.type,
      options: data?.options,
      rowType: FieldGroupDNDRowType.TARGET,
      from: data && v?.from,
      to: data && v?.to
    };
  });
};

const task = fieldObject.build(ElphiEntityType.task).map((x) => {
  return {
    id: `${ElphiEntityType.task}-${x.value}`,
    entityType: ElphiEntityType.task,
    label: x.label,
    path: x.value,
    type: x.type,
    options: x.options,
    rowType: FieldGroupDNDRowType.SOURCE
  };
});

const party = fieldObject.build(ElphiEntityType.party).map((x) => {
  return {
    id: `${ElphiEntityType.party}-${x.value}`,
    entityType: ElphiEntityType.party,
    label: x.label,
    path: x.value,
    type: x.type,
    options: x.options,
    rowType: FieldGroupDNDRowType.SOURCE
  };
});

const deal = fieldObject.build(ElphiEntityType.deal).map((x) => {
  return {
    id: `${ElphiEntityType.deal}-${x.value}`,
    entityType: ElphiEntityType.deal,
    label: x.label,
    path: x.value,
    type: x.type,
    options: x.options,
    rowType: FieldGroupDNDRowType.SOURCE
  };
});

const property = fieldObject.build(ElphiEntityType.property).map((x) => {
  return {
    id: `${ElphiEntityType.property}-${x.value}`,
    entityType: ElphiEntityType.property,
    label: x.label,
    path: x.value,
    type: x.type,
    options: x.options,
    rowType: FieldGroupDNDRowType.SOURCE
  };
});

const asset = fieldObject.build(ElphiEntityType.asset).map((x) => {
  return {
    id: `${ElphiEntityType.asset}-${x.value}`,
    entityType: ElphiEntityType.asset,
    label: x.label,
    path: x.value,
    type: x.type,
    options: x.options,
    rowType: FieldGroupDNDRowType.SOURCE
  };
});

const statement = fieldObject.build(ElphiEntityType.statement).map((x) => {
  return {
    id: `${ElphiEntityType.statement}-${x.value}`,
    entityType: ElphiEntityType.statement,
    label: x.label,
    path: x.value,
    type: x.type,
    options: x.options,
    rowType: FieldGroupDNDRowType.SOURCE
  };
});

const serviceProvider = fieldObject
  .build(ElphiEntityType.serviceProvider)
  .map((x) => {
    return {
      id: `${ElphiEntityType.serviceProvider}-${x.value}`,
      entityType: ElphiEntityType.serviceProvider,
      label: x.label,
      path: x.value,
      type: x.type,
      options: x.options,
      rowType: FieldGroupDNDRowType.SOURCE
    };
  });

const dealParty = fieldObject.build(ElphiEntityType.dealParty).map((x) => {
  return {
    id: `${ElphiEntityType.dealParty}-${x.value}`,
    entityType: ElphiEntityType.dealParty,
    label: x.label,
    path: x.value,
    type: x.type,
    options: x.options,
    rowType: FieldGroupDNDRowType.SOURCE
  };
});

const dealProperty = fieldObject
  .build(ElphiEntityType.dealProperty)
  .map((x) => {
    return {
      id: `${ElphiEntityType.dealProperty}-${x.value}`,
      entityType: ElphiEntityType.dealProperty,
      label: x.label,
      path: x.value,
      type: x.type,
      options: x.options,
      rowType: FieldGroupDNDRowType.SOURCE
    };
  });

const partyRelation = fieldObject
  .build(ElphiEntityType.partyRelation)
  .map((x) => {
    return {
      id: `${ElphiEntityType.partyRelation}-${x.value}`,
      entityType: ElphiEntityType.partyRelation,
      label: x.label,
      path: x.value,
      type: x.type,
      options: x.options,
      rowType: FieldGroupDNDRowType.SOURCE
    };
  });
export const dataMap: Map<string, FieldGroupDNDRow> = new Map(
  [
    ...task,
    ...party,
    ...deal,
    ...property,
    ...asset,
    ...statement,
    ...dealParty,
    ...dealProperty,
    ...partyRelation,
    ...serviceProvider
  ].map((x) => [x.id, x])
);

export const dataSet = Array.from(dataMap.values());
