import { FilterContainerIcon } from "../../components/icons";
import { CustomMenu, CustomMenuProps } from "./CustomMenu";

type CustomFilterMenuProps = {
  showFilterIcon: boolean;
} & CustomMenuProps;

export const CustomFilterMenu = ({
  icon,
  title = "",
  children,
  showFilterIcon,
  ...menuButtonProps
}: CustomFilterMenuProps) => {
  return (
    <CustomMenu
      title={title}
      icon={icon}
      rightTitleIcon={
        showFilterIcon ? (
          <FilterContainerIcon ml={"5px"} w={"22px"} h={"22px"} />
        ) : undefined
      }
      {...menuButtonProps}
    >
      {children}
    </CustomMenu>
  );
};
