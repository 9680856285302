import { createSelector } from "@reduxjs/toolkit";
import { EMPTY } from "../../../constants/common";
import { RootState } from "../../store";
import { createdAtMillis } from "../utils/sort.utils";
import { DealSliceState } from "./deal.slice";

const selectDeal = (state: RootState) => state.deal;

export const sortByIndex = createSelector(
  selectDeal,
  (deal: DealSliceState) => {
    return (
      [...deal?.ids]?.sort?.((ida, idb) => {
        const indexA = ida && deal.entities?.[ida]?.index;
        const indexB = idb && deal.entities?.[idb]?.index;
        return indexA || "1" < (indexB || "2") ? 1 : -1;
      }) || []
    );
  }
);

export const sortByLoanIdentifier = createSelector(
  selectDeal,
  (deal: DealSliceState) => {
    return (
      [...deal?.ids]?.sort?.((ida, idb) => {
        const sortA = ida && deal.entities?.[ida]?.LoanIdentifier;
        const sortB = idb && deal.entities?.[idb]?.LoanIdentifier;
        return sortA || "1" < (sortB || "2") ? 1 : -1;
      }) || []
    );
  }
);

export const sortFilteredIds = createSelector(
  selectDeal,
  (_: any, filteredIds: string[]) => filteredIds,
  (deal: DealSliceState, filteredIds) =>
    [...filteredIds]?.sort?.((ida, idb) => {
      const sortA = ida && deal.entities?.[ida]?.createdAt;
      const sortB = idb && deal.entities?.[idb]?.createdAt;
      return createdAtMillis(sortA, 1) < createdAtMillis(sortB, 2) ? 1 : -1;
    }) || []
);

export const sortFilteredReadonlyClosingDate = createSelector(
  selectDeal,
  (_: any, filteredIds: string[]) => filteredIds,
  (deal: DealSliceState, filteredIds) =>
    [...filteredIds]?.sort?.((ida, idb) => {
      const sortA =
        (ida &&
          deal.entities?.[ida]?.readonlyFields?.["EstimatedClosingDate"]) ||
        EMPTY;
      const sortB =
        (idb &&
          deal.entities?.[idb]?.readonlyFields?.["EstimatedClosingDate"]) ||
        EMPTY;
      return sortA.localeCompare(sortB);
    }) || []
);
