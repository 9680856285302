import { PropertyFields } from "@elphi/types";
import { ObjectKeyValidation } from "../../form-builder/field-specs/objectKeyValidation.types";

export const inspectionInitialRequestDateFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInspection", "InspectionInitialRequestDate"]
> = ["PropertyInspection", "InspectionInitialRequestDate"];

export const inspectionRequestOrderedDateFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInspection", "InspectionRequestOrderedDate"]
> = ["PropertyInspection", "InspectionRequestOrderedDate"];

export const inspectionReportDueDateFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInspection", "InspectionReportDueDate"]
> = ["PropertyInspection", "InspectionReportDueDate"];

export const inspectionOrderTypeFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInspection", "InspectionOrderType"]
> = ["PropertyInspection", "InspectionOrderType"];

export const inspectorFullNameFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInspection", "InspectorFullName"]
> = ["PropertyInspection", "InspectorFullName"];

export const inspectionOrderReceivedDateFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInspection", "InspectionOrderReceivedDate"]
> = ["PropertyInspection", "InspectionOrderReceivedDate"];

export const inspectionOrderProcessedDateFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInspection", "InspectionOrderProcessedDate"]
> = ["PropertyInspection", "InspectionOrderProcessedDate"];

export const inspectionDocumentsReceivedDateFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInspection", "InspectionDocumentsReceivedDate"]
> = ["PropertyInspection", "InspectionDocumentsReceivedDate"];

export const inspectionInitialDelayReasonStatusTypeFieldPath: ObjectKeyValidation<
  PropertyFields,
  ["PropertyInspection", "InspectionInitialDelayReasonStatusType"]
> = ["PropertyInspection", "InspectionInitialDelayReasonStatusType"];
