import { Box, Flex, Skeleton, SkeletonText, Tooltip } from "@chakra-ui/react";
import {
  AutoGenerateType,
  DocumentConfiguration,
  DocumentEntityType,
  DocumentPackageType,
  FieldType,
  StatusCode
} from "@elphi/types";
import { useEffect, useState } from "react";
import { printDateTime } from "../../../../firebase/firebase.utils";
import { useDocumentRuleHooks } from "../../../../hooks/documentRule.hooks";
import { BaseButtonIconDelete } from "../../../button-icon/ButtonIcon";
import { TextCell } from "../../../custom/chakra/Cell";
import StyledInputBuilder, {
  ValueContainer,
  useFormBuilderStateHandler
} from "../../../form-builder/InputBuilder";
import ModalContainer from "../../../modal-container/ModalContainer";
import { ItemsMenuCopyWidget } from "../../../party/table/v2/ItemsMenuCopyWidget";
import {
  SIZE_FIELD,
  TableRowSizeComponent
} from "../../../table/TableRowSizeComponent";
import { useElphiToast } from "../../../toast/toast.hook";
import { createOptionsFromEnum } from "../../../utils/formUtils";
import DocumentRuleSearch from "../../document-rule/DocumentRuleSearch";
import { documentTemplateMap } from "../constants/documentTemplates";
import { useDocumentConfigurationTableHooks } from "./DocumentConfigurationTable";

export type DocumentConfigurationRowProps = {
  index: string | number;
  documentConfigurationId: string;
  documentConfigurationHooks: ReturnType<
    typeof useDocumentConfigurationTableHooks
  >["documentConfigurationHooks"];
  documentRuleState: ReturnType<
    typeof useDocumentRuleHooks
  >["documentRuleState"];
  getRuleBatchResponse: { isSuccess: boolean };
  syncState: ReturnType<typeof useFormBuilderStateHandler>["syncState"];
  onChange: ReturnType<typeof useFormBuilderStateHandler>["onChange"];
  state: {
    [id: string]: Partial<DocumentConfiguration>;
  };
};

export const DocumentConfigurationRow = ({
  index,
  syncState,
  documentConfigurationId,
  documentConfigurationHooks,
  onChange,
  state,
  documentRuleState,
  getRuleBatchResponse
}: DocumentConfigurationRowProps) => {
  const {
    documentConfigurationState,
    deleteConfigurationResponse,
    deleteConfiguration
  } = documentConfigurationHooks;
  const { successToast, errorToast } = useElphiToast();
  const [deleteId, setDeleteId] = useState("");

  useEffect(() => {
    setDeleteId("");
    syncState({
      state: documentConfigurationState.entities?.[documentConfigurationId],
      shouldSync:
        !!documentConfigurationState.entities?.[documentConfigurationId],
      statePath: () => {
        return [documentConfigurationId.toString()];
      }
    });
  }, [documentConfigurationState.entities?.[documentConfigurationId]]);

  const deleteConfigurationHandler = async (id: string) => {
    await deleteConfiguration(id).then((r) => {
      if (r.status === StatusCode.OK) {
        successToast({
          title: "Configuration Deleted",
          description: `configuration: ${r.data?.id}`
        });
      } else if (r.status === StatusCode.BadRequest) {
        errorToast({
          title: "Failed to delete configuration",
          description: r.data?.description
        });
      }
    });
  };

  const data = documentConfigurationState.entities[documentConfigurationId]!;

  return (
    <Box>
      {data && (
        <Flex w="100%" key={index} justify="space-evenly">
          <TableRowSizeComponent
            size={SIZE_FIELD.S}
            withBorderRightWidth={false}
          >
            <TextCell>{index}</TextCell>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.M}
            withBorderRightWidth={false}
          >
            <TextCell>
              {data?.createdAt && printDateTime(data.createdAt)}
            </TextCell>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.L}
            withBorderRightWidth={false}
          >
            <Box>
              <StyledInputBuilder
                currentValue={state?.[documentConfigurationId]?.name}
                fieldType={FieldType.String}
                onChange={(e) => {
                  onChange({
                    fieldKey: [documentConfigurationId, "name"],
                    fieldType: FieldType.String,
                    value: e.target.value
                  });
                }}
                label=""
                isValid={true}
              />
            </Box>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.L}
            withBorderRightWidth={false}
          >
            <Box>
              <StyledInputBuilder
                currentValue={state?.[documentConfigurationId]?.filePath}
                fieldType={FieldType.SingleSelect}
                options={Object.entries(documentTemplateMap).map(
                  ([label, value]) => ({
                    label: label,
                    value: value
                  })
                )}
                onChange={(e) => {
                  onChange({
                    fieldKey: [documentConfigurationId, "filePath"],
                    fieldType: FieldType.SingleSelect,
                    value: e.target.value
                  });
                }}
                label="path/to/file"
                isValid={!!state?.[documentConfigurationId]?.filePath?.length}
              />
            </Box>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.L}
            withBorderRightWidth={false}
          >
            <Box>
              <StyledInputBuilder
                chakraStyles={{
                  valueContainer: (provided) => ({
                    ...provided,
                    fontSize: "12px"
                  })
                }}
                hideSelectedOptions={false}
                currentValue={state?.[documentConfigurationId]?.documentEntity}
                options={createOptionsFromEnum(DocumentEntityType)}
                fieldType={FieldType.SingleSelect}
                onChange={(e) => {
                  onChange({
                    fieldKey: [documentConfigurationId, "documentEntity"],
                    fieldType: FieldType.SingleSelect,
                    value: e.target.value
                  });
                }}
                label=""
                isValid={true}
              />
            </Box>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.L}
            withBorderRightWidth={false}
          >
            <Box>
              <StyledInputBuilder
                chakraStyles={{
                  valueContainer: (provided) => ({
                    ...provided,
                    fontSize: "12px"
                  })
                }}
                hideSelectedOptions={false}
                customComponent={{
                  ValueContainer: ValueContainer
                }}
                currentValue={
                  state?.[documentConfigurationId]?.documentPackageType
                }
                options={[
                  {
                    label: DocumentPackageType.LongTerm,
                    value: DocumentPackageType.LongTerm
                  },
                  {
                    label: DocumentPackageType.ShortTerm,
                    value: DocumentPackageType.ShortTerm
                  }
                ]}
                fieldType={FieldType.MultiSelect}
                onChange={(e) => {
                  onChange({
                    fieldKey: [documentConfigurationId, "documentPackageType"],
                    fieldType: FieldType.MultiSelect,
                    value: e.target.value
                  });
                }}
                label=""
                isValid={true}
              />
            </Box>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.L}
            withBorderRightWidth={false}
          >
            <Box>
              <StyledInputBuilder
                chakraStyles={{
                  valueContainer: (provided) => ({
                    ...provided,
                    fontSize: "12px"
                  })
                }}
                hideSelectedOptions={false}
                currentValue={state?.[documentConfigurationId]?.autoGenerate}
                options={createOptionsFromEnum(AutoGenerateType)}
                fieldType={FieldType.SingleSelect}
                onChange={(e) => {
                  onChange({
                    fieldKey: [documentConfigurationId, "autoGenerate"],
                    fieldType: FieldType.SingleSelect,
                    value: e.target.value
                  });
                }}
                label=""
                isValid={true}
              />
            </Box>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.L}
            withBorderRightWidth={false}
          >
            <Box w="280px">
              {!data.documentRuleTemplateId ||
              getRuleBatchResponse.isSuccess ||
              documentRuleState.entities[data.documentRuleTemplateId] ? (
                <DocumentRuleSearch
                  onSelect={(id) => {
                    onChange({
                      fieldKey: [
                        documentConfigurationId,
                        "documentRuleTemplateId"
                      ],
                      fieldType: FieldType.Switch,
                      value: id
                    });
                  }}
                  currentValue={
                    state?.[documentConfigurationId]?.documentRuleTemplateId!
                  }
                />
              ) : (
                <Skeleton height="10px">
                  <SkeletonText />
                </Skeleton>
              )}
            </Box>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.M}
            withBorderRightWidth={false}
          >
            <Box>
              <StyledInputBuilder
                currentValue={state?.[documentConfigurationId]?.status}
                fieldType={FieldType.Switch}
                onChange={(e) => {
                  onChange({
                    fieldKey: [documentConfigurationId, "status"],
                    fieldType: FieldType.Switch,
                    value: e.target.value
                  });
                }}
                label=""
              />
            </Box>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.S}
            withBorderRightWidth={false}
          >
            <Flex alignItems={"center"}>
              <Tooltip label={"system identifiers"} placement="top">
                <Box pr="2px">
                  <ItemsMenuCopyWidget
                    items={[
                      {
                        label: "elphi-document-configuration-id",
                        value: data.id
                      }
                    ]}
                  />
                </Box>
              </Tooltip>

              <BaseButtonIconDelete
                onClick={() => setDeleteId(documentConfigurationId)}
                isLoading={deleteConfigurationResponse.isLoading}
              />
            </Flex>
          </TableRowSizeComponent>
        </Flex>
      )}
      <ModalContainer
        isShow={!!deleteId && !!state?.[deleteId]}
        onCloseModal={() => setDeleteId("")}
        header={`Are you sure you want to delete ${state?.[documentConfigurationId]?.name}?`}
        submit={{
          onConfirm: () => deleteConfigurationHandler(documentConfigurationId)
        }}
      ></ModalContainer>
    </Box>
  );
};
