import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  MenuList,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Text
} from "@chakra-ui/react";
import { FieldType } from "@elphi/types";
import { useEffect, useState } from "react";
import StyledInputBuilder from "../../components/form-builder/InputBuilder";
import { moneyFormatter } from "../../components/form-builder/formatters/inputs.formatter";
import { CustomFilterMenu } from "../custom-menu/CustomFilterMenu";

type MenuRangeSliderProps = {
  title: string;
  min: number;
  max: number;
  defaultValue?: number[];
  value?: string[] | number[];
  onChange: (values: number[]) => void;
};

export const MenuRangeSlider = ({
  title,
  min,
  max,
  defaultValue,
  onChange
}: MenuRangeSliderProps) => {
  const [minRange, setMinRange] = useState(min);
  const [maxRange, setMaxRange] = useState(max);
  const [value, setValue] = useState([min, max]);

  useEffect(() => {
    setMinRange(min);
    setMaxRange(max);
    const minValue = min;
    const maxValue = max;
    setValue([minValue, maxValue]);
  }, [min, max]);

  const onChangeMin = (e) => {
    setValue([e.target.value, value[1]]);
    onChange([e.target.value, value[1]]);
  };

  const onChangeMax = (e) => {
    setValue([value[0], e.target.value]);
    onChange([value[0], e.target.value]);
  };

  const onSelectAll = () => {
    setValue([min, max]);
    onChange([min, max]);
  };

  const onChangeRange = (values: number[]) => {
    setValue(values);
  };

  return (
    <CustomFilterMenu
      title={title}
      icon={<ChevronDownIcon w={4} h={4} />}
      showFilterIcon={value?.[0] !== minRange || value?.[1] !== maxRange}
    >
      <MenuList zIndex={5} minW="400px">
        <Flex flexDirection={"column"} p="15px">
          <RangeSlider
            aria-label={["min", "max"]}
            defaultValue={defaultValue}
            onChange={onChangeRange}
            onChangeEnd={onChange}
            value={value}
            min={minRange}
            max={maxRange}
          >
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>
            <RangeSliderThumb index={0} />
            <RangeSliderThumb index={1} />
          </RangeSlider>
          <Flex justifyContent="space-between" flexDir={"row"} marginY={"20px"}>
            <Flex flexDir={"column"} w="177px">
              <Text fontSize={"12px"} fontWeight={"bold"} pb={"3px"}>
                Minimum
              </Text>
              <StyledInputBuilder
                currentValue={value[0].toString()}
                fieldType={FieldType.Money}
                label={"Minimum"}
                onChange={onChangeMin}
                formatter={moneyFormatter}
              />
            </Flex>
            <Flex flexDir={"column"} w="177px">
              <Text fontSize={"12px"} fontWeight={"bold"} pb={"3px"}>
                Maximum
              </Text>
              <StyledInputBuilder
                currentValue={value[1].toString()}
                fieldType={FieldType.Money}
                label={"Maximum"}
                onChange={onChangeMax}
                formatter={moneyFormatter}
              />
            </Flex>
          </Flex>
          <Flex>
            <Box>
              <Button
                bgColor={"transparent"}
                fontWeight={"medium"}
                color={"blue.600"}
                fontSize={14}
                onClick={onSelectAll}
              >
                Select all
              </Button>
            </Box>
          </Flex>
        </Flex>
      </MenuList>
    </CustomFilterMenu>
  );
};
