import { DeleteIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Box, Checkbox, Flex } from "@chakra-ui/react";
import { FieldType, Status } from "@elphi/types";
import { isNil } from "lodash";
import { useEffect, useState } from "react";
import MenuComponent from "../../../../common-components/menu-options/Menu";
import { NOT_AVAILABLE } from "../../../../constants/common";
import { usePartyGroup } from "../../../../hooks/partyGroup.hooks";
import StyledInputBuilder from "../../../form-builder/InputBuilder";
import { NotificationModal } from "../modal/NotificationModal";

enum Operation {
  MakeAvailable = "Make available",
  MakeUnAvailable = "Make unavailable",
  Delete = "Delete"
}

export const BulkOperations = () => {
  const [isChecked, setIsChecked] = useState(Status.Disabled);
  const [isShow, setIsShow] = useState(false);
  const [currentOp, setCurrentOp] = useState<Operation | undefined>(undefined);

  const {
    partyGroupState,
    selectAllPartyGroup,
    unSelectAllPartyGroup,
    updatePartyGroupSelectionsBatch,
    totalPartyGroup,
    partyGroupIdsToUpdate
  } = usePartyGroup();

  useEffect(() => {
    if (!isShow) {
      setCurrentOp(undefined);
    }
  }, [isShow]);

  const { isAllChecked, selectedIds, excludedIds } = partyGroupState;
  const selectedPartyGroups = isAllChecked
    ? totalPartyGroup
      ? totalPartyGroup - excludedIds.length
      : NOT_AVAILABLE
    : partyGroupIdsToUpdate.length;

  const selectedCount = `(${selectedPartyGroups}/${totalPartyGroup})`;
  const isIndeterminateCheck = isAllChecked && !!excludedIds.length;
  const handeSelectAll = (isChecked) => {
    isChecked && !isIndeterminateCheck
      ? selectAllPartyGroup()
      : unSelectAllPartyGroup();
  };

  const updatePartyGroupActivity = async () => {
    const status =
      isChecked === Status.Disabled ? Status.Active : Status.Disabled;
    await updatePartyGroupSelectionsBatch({ status });
    setIsChecked(status);
  };

  const operationToActionMap = {
    [Operation.MakeAvailable]: updatePartyGroupActivity,
    [Operation.MakeUnAvailable]: updatePartyGroupActivity
  };

  const toggleAvailability = () => {
    const opName =
      isChecked === Status.Active
        ? Operation.MakeUnAvailable
        : Operation.MakeAvailable;
    setCurrentOp(opName);
    setIsShow(true);
  };

  return (
    <>
      <Flex p="5px 10px" alignItems={"center"} gap={"8px"}>
        <Checkbox
          isChecked={isAllChecked && !excludedIds.length}
          isIndeterminate={isIndeterminateCheck}
          onChange={(e) => handeSelectAll(e.target.checked)}
        />
        <Box fontWeight={"400"}>Check All {selectedCount}</Box>
        <MenuComponent
          icon={<HamburgerIcon />}
          items={[
            {
              inputBuilder: (
                <StyledInputBuilder
                  currentValue={isChecked}
                  fieldType={FieldType.Switch}
                  isDisabled={!selectedIds.length}
                  onChange={toggleAvailability}
                />
              ),
              label: `${
                isChecked === Status.Active
                  ? Operation.MakeUnAvailable
                  : Operation.MakeAvailable
              }`,
              stayOpenAfterSelect: true
            },
            {
              label: Operation.Delete,
              icon: <DeleteIcon />,
              isDisabled: true,
              tooltipText: "Not supported yet"
            }
          ]}
        />
      </Flex>
      <NotificationModal
        headerText="Confirm Bulk Operation"
        acknowledgeAction={currentOp && operationToActionMap[currentOp]}
        isShow={isShow && !isNil(totalPartyGroup)}
        onClose={() => {
          setIsShow(false);
        }}
      >
        <Flex direction={"column"} gap={"24px"}>
          <Box as="p">
            You are about to{" "}
            <Box as="span" fontWeight={700}>
              {currentOp}
            </Box>{" "}
            <Box as="span" fontWeight={700}>
              {" "}
              {partyGroupIdsToUpdate.length}{" "}
            </Box>{" "}
            items.
          </Box>
          <Box as="p" fontWeight={700}>
            Are you sure you want to proceed?
          </Box>
        </Flex>
      </NotificationModal>
    </>
  );
};
