import { LockIcon, UnlockIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure
} from "@chakra-ui/react";
import { ElphiEntityType, FirebaseFilter } from "@elphi/types";
import React, { useEffect } from "react";
import { printDateTime } from "../../firebase/firebase.utils";
import { auth } from "../../firebase/firebaseConfig";
import { useSnapshotHooks } from "../../hooks/snapshot.hooks";
import { useRTKPagination } from "../../redux/v2/hooks";
import { snapshotApi } from "../../redux/v2/snapshot";

export const SnapshotModal = (props: {
  isOpen: boolean;
  onClose: () => void;
  pagination: ReturnType<typeof useRTKPagination>;
  setSelectedSnapshot: ReturnType<
    typeof useSnapshotHooks
  >["setSelectedSnapshot"];
  snapshotState: ReturnType<typeof useSnapshotHooks>["snapshotState"];
}) => {
  const { snapshotState, setSelectedSnapshot, pagination } = props;
  const { next, pageData, pageResponse } = pagination;
  useEffect(() => {
    if (
      auth.currentUser &&
      pageResponse.isUninitialized &&
      !pageResponse.isSuccess &&
      !pageResponse.isLoading &&
      !pageResponse.isFetching
    )
      next();
  }, [auth.currentUser]);
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent minWidth={"600px"}>
        <ModalHeader>Snapshots</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDir={"column"}>
            {!pageData.ids.length && <Text>no snapshot yet...</Text>}
            {pageData.ids.map((id, i) => {
              const currentSnapshot = snapshotState.entities[id];
              return (
                <Box
                  key={i}
                  p="5px"
                  cursor="pointer"
                  onClick={() => {
                    if (currentSnapshot?.id) {
                      const newId =
                        currentSnapshot.id === snapshotState.selectedId
                          ? ""
                          : currentSnapshot.id;
                      setSelectedSnapshot(newId);
                    }
                  }}
                >
                  <Box
                    p="5px"
                    boxShadow={"md"}
                    bgColor={
                      snapshotState.selectedId === currentSnapshot?.id
                        ? "lightblue"
                        : "gray.100"
                    }
                  >
                    {/* <Text>snapshot: {currentSnapshot?.id}</Text>
                                        <Text>deal: {currentSnapshot?.dealId}</Text> */}
                    <Flex>
                      <Text fontSize="16px" fontWeight={"bold"}>
                        type:
                      </Text>
                      <Text pl="10px" fontSize="16px">
                        {currentSnapshot?.entityType}
                      </Text>
                    </Flex>
                    <Flex pt="10px">
                      <Text fontSize="16px" fontWeight={"bold"}>
                        milestone:
                      </Text>
                      <Text pl="10px" fontSize="16px">
                        {currentSnapshot?.milestone}
                      </Text>
                    </Flex>
                    <Flex pt="10px">
                      <Text fontSize="16px" fontWeight={"bold"}>
                        created at:
                      </Text>
                      <Text pl="10px" fontSize="16px">
                        {currentSnapshot?.createdAt &&
                          printDateTime(currentSnapshot.createdAt)}
                      </Text>
                    </Flex>
                    <Flex pt="10px">
                      <Text fontSize="16px" fontWeight={"bold"}>
                        file size:
                      </Text>
                      <Text pl="10px" fontSize="16px">
                        {currentSnapshot?.fileInfo.size}
                      </Text>
                    </Flex>

                    {/* {currentSnapshot?.fileInfo && (
                      <Flex pt="10px">
                        <Box>
                          <FileInfoWidget file={currentSnapshot?.fileInfo} />
                        </Box>
                      </Flex>
                    )} */}
                    {/* <Text>
                      modified at:
                      {currentSnapshot?.modifiedAt &&
                        printDateTime(currentSnapshot.modifiedAt!)}
                    </Text> */}
                    {/* storage:
                                        <Text>{currentSnapshot?.fileInfo.provider}</Text>
                                        <Text>{currentSnapshot?.fileInfo.data.folder}</Text>
                                        <Text>{currentSnapshot?.fileInfo.data.fileName}</Text>
                                        <Text>{currentSnapshot?.fileInfo.data.fileId}</Text> */}
                  </Box>
                </Box>
              );
            })}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export const SnapshotModalContainer = (props: { dealId?: string }) => {
  const PAGE_LIMIT = 20;
  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    snapshotState,
    setSelectedSnapshot,
    selectedSnapshot,
    getSnapshotData
  } = useSnapshotHooks();
  const filter: FirebaseFilter | undefined = props.dealId
    ? {
        fieldPath: "dealId",
        opStr: "==",
        value: props.dealId
      }
    : undefined;
  const pagination = useRTKPagination({
    useLazyPaginateQuery: snapshotApi.useLazyPaginateQuery,
    entityState: snapshotState,
    pageFilter: (v) =>
      v.entityType === ElphiEntityType.deal && v.dealId === props.dealId,
    options: {
      limit: PAGE_LIMIT,
      filter
    }
  });
  useEffect(() => {
    if (selectedSnapshot?.id && !selectedSnapshot?.snapshot) {
      getSnapshotData({ id: selectedSnapshot?.id });
    }
  }, [selectedSnapshot]);
  const isCurrentSnapshot =
    selectedSnapshot?.entityType === ElphiEntityType.deal &&
    props.dealId === selectedSnapshot?.dealId;
  return (
    <Box>
      <SnapshotModal
        snapshotState={snapshotState}
        isOpen={isOpen}
        onClose={onClose}
        setSelectedSnapshot={setSelectedSnapshot}
        pagination={pagination}
      />
      <Tooltip
        aria-label="worksheet-sidebar"
        label={"snapshot view"}
        placement="right"
      >
        <Box>
          <SnapshotModalIcon
            onOpen={onOpen}
            icon={
              isCurrentSnapshot ? (
                <LockIcon w="25px" h="25px" color="yellow.700" />
              ) : (
                <UnlockIcon w="20px" h="20px" />
              )
            }
            color={isCurrentSnapshot ? "black" : "black"}
            bgColor={isCurrentSnapshot ? "blue.100" : "transparent"}
          />
        </Box>
      </Tooltip>
    </Box>
  );
};

export const SnapshotModalIcon = (props: {
  onOpen: () => void;
  icon: React.ComponentProps<typeof IconButton>["icon"];
  bgColor?: React.ComponentProps<typeof IconButton>["bgColor"];
  color?: React.ComponentProps<typeof IconButton>["color"];
}) => {
  return (
    <IconButton
      bgColor={props.bgColor}
      aria-label="snapshot-btn"
      color={props.color}
      icon={props.icon}
      onClick={props.onOpen}
    />
  );
};
