import { RolodexConfigurationFieldType } from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { flatten } from "flat";
import { endsWith, get, isArray } from "lodash";
import { NOT_AVAILABLE } from "../../../../../../constants/common";
import { EntityFormFieldSpecs } from "../../../../../form-builder/field-specs/fields.types";
import {
  branchServiceProviderFieldsSpecs,
  companyServiceProviderFieldsSpecs,
  repServiceProviderFieldsSpecs
} from "../../../../../form-builder/field-specs/service-provider/serviceProvider.fields";
import { presetFieldsMap } from "../modal/modal.utils";

const buildFieldsSpecsOptions = <T extends object>(
  specs: EntityFormFieldSpecs<T>,
  type: RolodexConfigurationFieldType
) => {
  try {
    return Object.keys(flatten(specs, { safe: true }))
      .map((key) => {
        if (endsWith(key, "label")) {
          const label = get(specs, key, NOT_AVAILABLE);
          const baseKeyPath = key.replace(/\.label$/, "");
          const fieldKey = get(specs, `${baseKeyPath}.fieldKey`, []);
          const path = isArray(fieldKey)
            ? fieldKey.join(".")
            : String(fieldKey);
          return {
            label,
            value: path,
            isDisabled: !!presetFieldsMap?.[type]?.[path]
          };
        }
      })
      .filter(removeEmpty);
  } catch (error) {
    console.error("rolodex configuration build options", error);
  }
};

export const companyOptions = buildFieldsSpecsOptions(
  companyServiceProviderFieldsSpecs,
  RolodexConfigurationFieldType.CompanyFields
);
export const branchOptions = buildFieldsSpecsOptions(
  branchServiceProviderFieldsSpecs,
  RolodexConfigurationFieldType.BranchFields
);
export const repOptions = buildFieldsSpecsOptions(
  repServiceProviderFieldsSpecs,
  RolodexConfigurationFieldType.RepresentativeFields
);

export const configurationFieldOptions = {
  [RolodexConfigurationFieldType.CompanyFields]: companyOptions,
  [RolodexConfigurationFieldType.BranchFields]: branchOptions,
  [RolodexConfigurationFieldType.RepresentativeFields]: repOptions
} as const;
