import { Property, PropertyFields } from "@elphi/types";
import { propertySpecFields } from "../../form-builder/field-specs/property/property.fields";

import { OnChangeInput, Section } from "../../form-builder/FormBuilder";
import { buildInputs } from "../../form-builder/formBuilder.utils";

import {
  dateValidation,
  multiSelectValidation,
  singleSelectValidation,
  stringValidation
} from "../../utils/validationUtils";
import {
  inspectionDocumentsReceivedDateFieldPath,
  inspectionInitialDelayReasonStatusTypeFieldPath,
  inspectionInitialRequestDateFieldPath,
  inspectionOrderProcessedDateFieldPath,
  inspectionOrderReceivedDateFieldPath,
  inspectionOrderTypeFieldPath,
  inspectionReportDueDateFieldPath,
  inspectionRequestOrderedDateFieldPath,
  inspectorFullNameFieldPath
} from "../field-paths/constructionManagement.fieldPaths";

export const generalSection = (
  state: Partial<PropertyFields>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "General",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: propertySpecFields,
      specs: [
        {
          path: inspectionInitialRequestDateFieldPath,
          validation: dateValidation
        },
        {
          path: inspectionRequestOrderedDateFieldPath,
          validation: dateValidation
        },
        { path: inspectionReportDueDateFieldPath, validation: dateValidation },
        {
          path: inspectionOrderTypeFieldPath,
          validation: singleSelectValidation
        },
        { path: inspectorFullNameFieldPath, validation: stringValidation },
        {
          path: inspectionOrderReceivedDateFieldPath,
          validation: dateValidation
        },
        {
          path: inspectionOrderProcessedDateFieldPath,
          validation: dateValidation
        },
        {
          path: inspectionDocumentsReceivedDateFieldPath,
          validation: dateValidation
        },
        {
          path: inspectionInitialDelayReasonStatusTypeFieldPath,
          validation: multiSelectValidation
        }
      ]
    })
  };
};

export const applicationConstructionManagementSections = (r: {
  state: Partial<Property>;
  onChange: (v: OnChangeInput) => void;
}): Section[] => {
  const { state, onChange } = r;

  return [generalSection(state, onChange)];
};
