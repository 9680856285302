import { Box, Text } from "@chakra-ui/react";
import { ChangeReasonMilestones, Deal } from "@elphi/types";
import { useMemo, useState } from "react";
import { ChangeReasonViewDetailsComponent } from "./DeadDealChangeReasonViewDetailsComponent";

export const ChangeReasonSnapshotContainer = (props: {
  newDealMilestone: ChangeReasonMilestones;
  lastDealMilestone: string;
  dealId: string;
  deal: Deal | undefined;
  onMilestoneChanged?: (milestone: string) => void;
  revertToLastMilestone: () => void;
}) => {
  const [genericReasonState, setGenericReasonState] = useState("");
  const [specificReasonState, setSpecificReasonState] = useState("");
  const { newDealMilestone, lastDealMilestone } = props;
  const sharedProps = useMemo(() => {
    return {
      dealId: props.dealId,
      deal: props.deal,
      newDealMilestone,
      lastDealMilestone,
      form: {
        state: {},
        isLoading: false,
        onChange: () => {},
        genericReasonState,
        setGenericReasonState,
        specificReasonState,
        setSpecificReasonState
      }
    };
  }, [props.dealId, genericReasonState, specificReasonState, props.deal]);

  return (
    <>
      <Box bgColor={"black"} h="20px" mb="20px">
        <Text align="center" fontSize="15" color={"white"}>
          {newDealMilestone} snapshot
        </Text>
      </Box>
      <ChangeReasonViewDetailsComponent
        {...sharedProps}
        options={{
          disabledButton: true
        }}
        onButtonClicked={() => {}}
      />
    </>
  );
};
