import { Box, Flex } from "@chakra-ui/react";
import {
  AutoGenerateOptions,
  DocumentConfiguration,
  DocumentPackageType,
  FieldType,
  FirebaseFilter,
  StatusCode
} from "@elphi/types";
import { removeEmptyValues } from "@elphi/utils/src/common.utils";
import { EntityId } from "@reduxjs/toolkit";
import { useEffect, useMemo, useState } from "react";
import { AppConfig } from "../../../../config/appConfig";
import { auth } from "../../../../firebase/firebaseConfig";
import { useAuthStateChangeHook } from "../../../../hooks/authStateChange.hooks";
import { useDocumentConfigurationHooks } from "../../../../hooks/documentConfiguration.hooks";
import { useDocumentRuleHooks } from "../../../../hooks/documentRule.hooks";
import useWindowDimensions from "../../../../hooks/windowDimensions";
import { documentConfigurationApi } from "../../../../redux/v2/document-configuration";
import { documentRuleApi } from "../../../../redux/v2/document-rule";
import { useRTKPagination } from "../../../../redux/v2/hooks";
import { ElphiPaginationList } from "../../../elphi-list/ElphiList";
import StyledInputBuilder, {
  ValueContainer,
  useFormBuilderStateHandler
} from "../../../form-builder/InputBuilder";
import { FilterCellComponent } from "../../../table/FilterCellComponent";
import {
  SIZE_FIELD,
  TableRowSizeComponent
} from "../../../table/TableRowSizeComponent";
import {
  CellsFilterStateFlat,
  useFilterHook
} from "../../../table/hooks/filter.hook";
import { ElphiCellType, ElphiTableProps } from "../../../table/table.types";
import { useElphiToast } from "../../../toast/toast.hook";
import { filterChildrenStyle, filterStyle } from "../../../utils/filter.utils";
import { createOptionsFromEnum } from "../../../utils/formUtils";
import DocumentRuleSearch from "../../document-rule/DocumentRuleSearch";
import { documentTemplateMap } from "../constants/documentTemplates";
import { DocumentConfigurationRow } from "./DocumentConfigurationRow";
import { filterState } from "./documentConfiguration.utils";
export const useDocumentConfigurationTableHooks = () => {
  const documentConfigurationHooks = useDocumentConfigurationHooks();
  const { documentRuleState } = useDocumentRuleHooks();
  const paginationHandler = useRTKPagination({
    entityState: documentConfigurationHooks.documentConfigurationState,
    useLazyPaginateQuery: documentConfigurationApi.useLazyPaginateQuery
  });
  const { updateBatch, searchDocumentConfigurationApi } =
    documentConfigurationHooks;
  const { errorToast, successToast } = useElphiToast();

  const updateDocumentConfigurationBatch = async (
    r: Partial<{
      [id: string]: DocumentConfiguration;
    }>
  ) => {
    const documents = removeEmptyValues(r);
    if (!documents?.length) return null;

    return await updateBatch({ documents }).then((r) => {
      if (r.status === StatusCode.OK) {
        successToast({
          title: "Configuration Updated",
          description: `total configurations updated: ${r.data?.batch?.length}`
        });
      } else if (r.status === StatusCode.BadRequest) {
        errorToast({
          title: "Failed to update batch",
          description:
            r.data?.error?.data?.error?.payload?.description ||
            r.data?.description
        });
      }
      return r;
    });
  };
  return {
    documentConfigurationHooks,
    paginationHandler,
    documentRuleState,
    updateDocumentConfigurationBatch,
    searchDocumentConfigurationApi
  };
};

export const DocumentConfigurationTable = () => {
  const pageSize = 10;
  const limit = 50;
  const { heightOffsetInPx } = useWindowDimensions();
  const {
    paginationHandler,
    documentConfigurationHooks,
    documentRuleState,
    updateDocumentConfigurationBatch,
    searchDocumentConfigurationApi
  } = useDocumentConfigurationTableHooks();
  const { documentConfigurationState, selectedDocumentConfiguration } =
    documentConfigurationHooks;

  const [getRuleBatchApi, getRuleBatchResponse] =
    documentRuleApi.useLazyGetBatchQuery();

  const [documentRuleTemplateId, setDocumentRuleTemplateId] =
    useState<string>();
  const [filePath, setFilePath] = useState<string>();
  const [autoGenerate, setAutoGenerate] = useState<string>();
  const [documentPackageType, setDocumentPackageType] = useState<string[]>();

  useAuthStateChangeHook({
    onAuthStateChange: (_) => {
      if (
        paginationHandler.pageResponse.isUninitialized &&
        !paginationHandler.pageResponse.isSuccess &&
        !paginationHandler.pageResponse.isLoading &&
        !paginationHandler.pageResponse.isFetching &&
        auth.currentUser
      ) {
        paginationHandler.next();
      }
    },
    deps: []
  });

  const isLoading = paginationHandler.pageResponse.isLoading;

  useEffect(() => {
    if (auth.currentUser) {
      const rules = paginationHandler.pageData.ids
        .map(
          (id) => paginationHandler.pageData.entities[id].documentRuleTemplateId
        )
        .filter((v) => !!v && !documentRuleState.entities[v]);
      rules.length && getRuleBatchApi(rules, true);
    }
  }, [auth.currentUser, paginationHandler.pageData]);

  useEffect(() => {
    if (auth.currentUser && selectedDocumentConfiguration) {
      !documentRuleState.entities[
        selectedDocumentConfiguration.documentRuleTemplateId
      ] &&
        getRuleBatchApi([selectedDocumentConfiguration.documentRuleTemplateId]);
    }
  }, [auth.currentUser, selectedDocumentConfiguration]);

  const { syncState, onChange, state } = useFormBuilderStateHandler<{
    [id: string]: Partial<DocumentConfiguration>;
  }>({
    initialState: {},
    callback: updateDocumentConfigurationBatch,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  const [filteredTask, setFilteredTask] = useState<{
    filter?: FirebaseFilter | FirebaseFilter[];
  }>();
  const [filteredTaskIds, setFilteredTaskIds] = useState<EntityId[]>([]);

  const {
    setStateDebouncer: tableFilterSetter,
    setStateInputDebouncer: tableInputSetter,
    state: tableFilterState,
    selectedOrderState: selectedOrderState,
    setSelectedOrderState: setSelectedOrderState,
    getFilteredAndSortDataIds
  } = useFilterHook<
    DocumentConfiguration,
    CellsFilterStateFlat<DocumentConfiguration>
  >({
    state: filterState,
    options: {
      debounceRate: 100
    },
    searchApi: searchDocumentConfigurationApi,
    onNextFilter: (filter) => {
      setFilteredTask(filter);
      if (filter) {
        paginationHandler.next(filter);
      } else {
        paginationHandler.reset();
      }
    }
  });

  useEffect(() => {
    const ids = getFilteredAndSortDataIds(documentConfigurationState);
    setFilteredTaskIds(ids);
  }, [documentConfigurationState.ids, tableFilterState]);

  const tableHeaders: ElphiTableProps["header"] = useMemo(() => {
    return [
      {
        index: 0,
        type: ElphiCellType.String,
        size: SIZE_FIELD.S,
        data: ""
      },
      {
        index: 1,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.M,
        data: (
          <FilterCellComponent<DocumentConfiguration>
            data={[]}
            disabled
            tooltipLabel="Not Supported Yet"
            fieldKey={"createdAt"}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showOrderState={false}
            showSelectAll={false}
            customStyles={filterStyle}
          />
        )
      },
      {
        index: 2,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.L,
        data: (
          <FilterCellComponent<DocumentConfiguration>
            data={
              Object.values(
                documentConfigurationState.entities
              ) as DocumentConfiguration[]
            }
            fieldKey={"name"}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showSelectAll={false}
            customStyles={filterStyle}
          />
        )
      },
      {
        index: 3,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.L,
        data: (
          <FilterCellComponent<DocumentConfiguration>
            data={[]}
            selectedValue={filePath}
            fieldKey={"filePath"}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showOrderState={false}
            showSelectAll={false}
            customStyles={filterStyle}
          >
            <StyledInputBuilder
              currentValue={filePath}
              fieldType={FieldType.SingleSelect}
              options={Object.entries(documentTemplateMap).map(
                ([label, value]) => ({
                  label: label,
                  value: value
                })
              )}
              onChange={(e) => {
                setFilePath(e.target.value);
              }}
              label="path/to/file"
            />
          </FilterCellComponent>
        )
      },
      {
        index: 4,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.L,
        data: (
          <FilterCellComponent<DocumentConfiguration>
            data={
              Object.values(
                documentConfigurationState.entities
              ) as DocumentConfiguration[]
            }
            fieldKey={"documentEntity"}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showOrderState={false}
            showSelectAll={false}
            customStyles={filterStyle}
          />
        )
      },
      {
        index: 5,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.L,
        data: (
          <FilterCellComponent<DocumentConfiguration>
            data={[]}
            fieldKey={"documentPackageType"}
            selectedValue={documentPackageType}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showOrderState={false}
            showSelectAll={false}
            customStyles={filterStyle}
          >
            <Box>
              <StyledInputBuilder
                chakraStyles={{
                  valueContainer: (provided) => ({
                    ...provided,
                    fontSize: "12px"
                  })
                }}
                hideSelectedOptions={false}
                customComponent={{
                  ValueContainer: ValueContainer
                }}
                currentValue={documentPackageType}
                options={[
                  {
                    label: DocumentPackageType.LongTerm,
                    value: DocumentPackageType.LongTerm
                  },
                  {
                    label: DocumentPackageType.ShortTerm,
                    value: DocumentPackageType.ShortTerm
                  }
                ]}
                fieldType={FieldType.MultiSelect}
                onChange={(e) => {
                  setDocumentPackageType(e.target.value);
                }}
                label="filter..."
                isValid={true}
              />
            </Box>
          </FilterCellComponent>
        )
      },
      {
        index: 6,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.L,
        data: (
          <FilterCellComponent<DocumentConfiguration>
            data={[]}
            fieldKey={"autoGenerate"}
            selectedValue={autoGenerate}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showOrderState={false}
            showSelectAll={false}
            customStyles={filterStyle}
          >
            <Box>
              <StyledInputBuilder
                chakraStyles={{
                  valueContainer: (provided) => ({
                    ...provided,
                    fontSize: "12px"
                  })
                }}
                hideSelectedOptions={false}
                currentValue={autoGenerate}
                options={createOptionsFromEnum(AutoGenerateOptions)}
                fieldType={FieldType.SingleSelect}
                onChange={(e) => {
                  setAutoGenerate(e.target.value);
                }}
                label="filter..."
                isValid={true}
              />
            </Box>
          </FilterCellComponent>
        )
      },
      {
        index: 7,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.L,
        data: (
          <FilterCellComponent<DocumentConfiguration>
            data={[]}
            selectedValue={documentRuleTemplateId}
            fieldKey={"documentRuleTemplateId"}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showOrderState={false}
            showSelectAll={false}
            customStyles={filterChildrenStyle}
          >
            <DocumentRuleSearch
              onSelect={(id) => {
                setDocumentRuleTemplateId(id);
              }}
              currentValue={documentRuleTemplateId || ""}
            />
          </FilterCellComponent>
        )
      },
      {
        index: 8,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.M,
        data: (
          <FilterCellComponent<DocumentConfiguration>
            data={
              Object.values(
                documentConfigurationState.entities
              ) as DocumentConfiguration[]
            }
            fieldKey={"status"}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showSelectAll={false}
            showOrderState={false}
            customStyles={filterStyle}
          />
        )
      },
      {
        index: 9,
        type: ElphiCellType.String,
        size: SIZE_FIELD.S,
        data: ""
      }
    ];
  }, [
    paginationHandler?.pageData?.entities,
    documentRuleTemplateId,
    filePath,
    autoGenerate,
    documentPackageType,
    tableFilterState
  ]);

  return (
    <Flex width="100%">
      <ElphiPaginationList
        tableName={"DocumentConfiguration"}
        isLoading={isLoading}
        header={
          <TableRowSizeComponent
            row={tableHeaders}
            withBorderRightWidth={false}
            justify="space-evenly"
          />
        }
        height={heightOffsetInPx(320)}
        hasMore={paginationHandler.pageData?.ids?.length < limit}
        items={
          !!documentConfigurationState.selectedId
            ? [documentConfigurationState.selectedId.toString()]
            : filteredTaskIds
        }
        next={() => paginationHandler.next(filteredTask)}
        pageSize={pageSize}
        rowBuilder={(documentConfigurationId, index) => {
          return (
            <DocumentConfigurationRow
              documentConfigurationId={
                documentConfigurationId?.toString() || ""
              }
              index={(Number(index) + 1).toString()}
              syncState={syncState}
              documentConfigurationHooks={documentConfigurationHooks}
              onChange={onChange}
              state={state}
              key={index}
              documentRuleState={documentRuleState}
              getRuleBatchResponse={getRuleBatchResponse}
            />
          );
        }}
      />
    </Flex>
  );
};
