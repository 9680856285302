import { Box, Text } from "@chakra-ui/react";
import { AggregationType, DealCalendarType } from "@elphi/types";
import { values } from "lodash";
import { useEffect } from "react";
import { EMPTY } from "../../../../../constants/common";
import useDealHooks from "../../../../../hooks/deal.hooks";
import { useDealFormHandler } from "../../../../../hooks/dealform.hooks";
import { estimatedClosingDateFieldPath } from "../../../../application/field-paths/closing.fieldPaths";
import { UpdateMilestoneComponent } from "../../../../deal/UpdateMilestoneComponent";
import FormBuilder, { Section } from "../../../../form-builder/FormBuilder";
import { dealFieldSpecs } from "../../../../form-builder/field-specs/deal/deal.fields";
import { buildInputs } from "../../../../form-builder/formBuilder.utils";
import DealUserAssignmentContainer from "../../../../los-user/DealUserAssignmentContainer";
import { dateValidation } from "../../../../utils/validationUtils";

export type DealDataSectionProps = {
  dealCalendar: DealCalendarType;
};

export const DealDataSection = ({ dealCalendar }: DealDataSectionProps) => {
  const { setSelectedDeal, getDeal } = useDealHooks();
  const { dealState, dealOnChange } = useDealFormHandler(dealCalendar.id!);

  useEffect(() => {
    getDeal(dealCalendar.id!, true);
    setSelectedDeal(dealCalendar.id!);
    return () => {
      setSelectedDeal(EMPTY);
    };
  }, [dealCalendar.id]);

  const section: Section = {
    inputs: buildInputs({
      state: dealState,
      onChange: dealOnChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            dealState.aggregations?.EstimatedClosingDate?.focused !==
            "override",
          path: estimatedClosingDateFieldPath,
          validation: dateValidation
        }
      ]
    })
  };

  return (
    <Box>
      <Box>
        <Text pl="3px" fontWeight="bold">
          Milestone
        </Text>
        <UpdateMilestoneComponent
          dealId={dealCalendar.id!}
          options={{
            showTitle: false,
            showSnapshot: false,
            boxContainer: {
              pl: 0,
              mt: 0
            }
          }}
        />
      </Box>
      <Box ml="-10px">
        <FormBuilder
          customKey="dealDataSection"
          onChange={dealOnChange}
          sections={[section]}
          elphiView="form"
          size={{ minW: "200px" }}
        />
      </Box>
      <Box>
        <Text pl="3px" fontWeight="bold">
          User Assignment
        </Text>
        <DealUserAssignmentContainer
          userAssignmentIds={values(dealCalendar?.assignedUsers)}
          dealId={dealCalendar.id!}
          loanIdentifier={dealCalendar?.loanIdentifier}
        />
      </Box>
    </Box>
  );
};
