import { TObject, TProperties, Type } from "@sinclair/typebox";
import {
  difference,
  flatMap,
  get,
  intersection,
  isNil,
  isPlainObject,
  merge,
  set
} from "lodash";
import { BaseEntity } from "../../db";
import { FieldStatus, Meta } from "../../entities";
import { DotNestedKeys } from "../../utils/flatten";

const statusField: DotNestedKeys<Meta> = "status";
const OVERRIDE = "override";
const commonFieldsToOmit: DotNestedKeys<BaseEntity<object>>[] = [
  "cascaded",
  "createdAt",
  "modifiedAt",
  "deleted",
  "fieldMeta",
  "id",
  "index",
  "isCloned",
  "keywords"
];

export enum FieldMetaTarget {
  Template = "template",
  Schema = "schema"
}

//Schema
const createObjectFromPath = (path: string[]): TObject<TProperties> => {
  if (!path.length) {
    return Type.Object({
      [statusField]: Type.Enum(FieldStatus)
    });
  }
  const [currentKey, ...rest] = path;
  return Type.Object({ [currentKey]: createObjectFromPath(rest) });
};

const convertPathsToSchemaFields = (paths: string[]): TObject<TProperties> => {
  return paths.reduce((schema, currentPath) => {
    const pathSegments = currentPath.split(".");
    const [rootKey, ...restPathSegments] = pathSegments;
    const nestedObject = { [rootKey]: createObjectFromPath(restPathSegments) };
    return merge(schema, nestedObject);
  }, {} as TObject<TProperties>);
};

//Template
const getFieldMetaFromTemplate = <T extends BaseEntity<object>>(
  paths: string[]
): T["fieldMeta"] => {
  return paths.reduce((acc, path) => {
    set(acc, path, { [statusField]: "" });
    return acc;
  }, {});
};

const extractObjectPaths = (obj: object, prefix = ""): string[] => {
  return flatMap(obj, (value, key) => {
    const fullPath = prefix ? `${prefix}.${key}` : key;
    const overrideValue = get(value, OVERRIDE);
    const fieldKey = get(value, "fieldKey");
    if (isPlainObject(value) && isNil(overrideValue) && isNil(fieldKey)) {
      return extractObjectPaths(value, fullPath);
    }
    return fullPath;
  });
};

export const getRelevantFields = <T extends object>(config: {
  obj: T;
  fieldsToOmit?: DotNestedKeys<T>[];
  fieldsToPick?: DotNestedKeys<T>[];
}) => {
  const { obj: entityTemplate, fieldsToPick, fieldsToOmit = [] } = config;
  const paths = extractObjectPaths(entityTemplate);
  const selectedPaths = fieldsToPick
    ? intersection(fieldsToPick, paths)
    : paths;
  const relevantPaths = difference(selectedPaths, [
    ...fieldsToOmit,
    ...commonFieldsToOmit
  ]);

  return relevantPaths;
};

export const buildFieldMetadata = <
  T extends Partial<BaseEntity<object>>,
  TOutput extends TObject<TProperties> | T["fieldMeta"]
>(config: {
  entityTemplate: T;
  fieldsToOmit?: DotNestedKeys<T>[];
  fieldsToPick?: DotNestedKeys<T>[];
  target: FieldMetaTarget;
}): TOutput => {
  const { entityTemplate, target, fieldsToPick, fieldsToOmit = [] } = config;
  const relevantPaths = getRelevantFields({
    obj: entityTemplate,
    fieldsToOmit,
    fieldsToPick
  });
  switch (target) {
    case FieldMetaTarget.Schema:
      const schemaFields = convertPathsToSchemaFields(relevantPaths);
      return Type.Object(schemaFields) as TOutput;
    case FieldMetaTarget.Template:
      return getFieldMetaFromTemplate(relevantPaths) as TOutput;
    default:
      throw new Error(`target: ${target} is not supported`);
  }
};
