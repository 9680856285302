import { CopyIcon, DeleteIcon } from "@chakra-ui/icons";
import MenuComponent from "../../common-components/menu-options/Menu";
import { MoreHorizontalIcon } from "../icons";

export const MenuRowAction = (props: { onClone: () => void }) => {
  return (
    <MenuComponent
      icon={<MoreHorizontalIcon width={6} height={6} />}
      items={[
        {
          icon: <CopyIcon />,
          label: "Clone",
          onSelect: () => props.onClone()
        },
        {
          icon: <DeleteIcon />,
          label: "Delete",
          isDisabled: true,
          tooltipText: "Not supported yet"
        }
      ]}
    />
  );
};
