import { Box, Flex } from "@chakra-ui/react";
import {
  AutoGenerateOptions,
  ElphiEntityType,
  FieldType,
  FirebaseFilter,
  KeyClosingTaskType,
  StatusCode,
  TaskConfiguration
} from "@elphi/types";
import { removeEmpty, removeEmptyValues } from "@elphi/utils/src/common.utils";
import { EntityId } from "@reduxjs/toolkit";
import { uniq } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { AppConfig } from "../../../../config/appConfig";
import { EMPTY } from "../../../../constants/common";
import { auth } from "../../../../firebase/firebaseConfig";
import { useAuthStateChangeHook } from "../../../../hooks/authStateChange.hooks";
import { useTaskConfigurationHooks } from "../../../../hooks/taskConfiguration.hooks";
import { useTaskInstructionHooks } from "../../../../hooks/taskInstruction.hooks";
import { useTaskRuleHooks } from "../../../../hooks/taskRule.hooks";
import { useTaskTemplateHooks } from "../../../../hooks/taskTemplate.hooks";
import useWindowDimensions from "../../../../hooks/windowDimensions";
import { useRTKPagination } from "../../../../redux/v2/hooks";
import {
  taskConfigurationApi,
  taskConfigurationSlice
} from "../../../../redux/v2/task-configuration";
import { taskRuleApi } from "../../../../redux/v2/task-rule";
import { taskTemplateApi } from "../../../../redux/v2/task-template";
import { ElphiPaginationList } from "../../../elphi-list/ElphiList";
import StyledInputBuilder, {
  useFormBuilderStateHandler
} from "../../../form-builder/InputBuilder";
import { FilterCellComponent } from "../../../table/FilterCellComponent";
import {
  SIZE_FIELD,
  TableRowSizeComponent
} from "../../../table/TableRowSizeComponent";
import {
  CellsFilterStateFlat,
  useFilterHook
} from "../../../table/hooks/filter.hook";
import { ElphiCellType, ElphiTableProps } from "../../../table/table.types";
import { useElphiToast } from "../../../toast/toast.hook";
import { filterChildrenStyle, filterStyle } from "../../../utils/filter.utils";
import { createOptionsFromEnum } from "../../../utils/formUtils";
import { TaskInstructionSearch } from "../../task-instruction/TaskInstructionSearch";
import TaskRuleSearch from "../../task-rule/TaskRuleSearch";
import { TaskTemplateSearch } from "../../task-template/TaskTemplateSearch";
import { TaskConfigurationRow } from "./TaskConfigurationRow";
import { filterState } from "./taskConfiguration.utils";
export const useTaskConfigurationTableHooks = () => {
  const dispatch = useDispatch();

  const taskConfigurationHooks = useTaskConfigurationHooks();
  const paginationHandler = useRTKPagination({
    entityState: taskConfigurationHooks.taskConfigurationState,
    useLazyPaginateQuery: taskConfigurationApi.useLazyPaginateQuery
  });
  const { updateBatch, searchTaskConfigurationApi } = taskConfigurationHooks;
  const { errorToast, successToast } = useElphiToast();
  const { taskRuleState } = useTaskRuleHooks();
  const { taskTemplateState } = useTaskTemplateHooks();

  const setCurrentCursorHasMore = (hasMore: boolean) => {
    dispatch(
      taskConfigurationSlice.actions.setTaskConfigurationTableCurrentCursorIdHasMore(
        hasMore
      )
    );
  };
  const setCurrentCursorId = (cursorId: string) => {
    dispatch(
      taskConfigurationSlice.actions.setTaskConfigurationTableCurrentCursorId({
        id: cursorId
      })
    );
  };

  const updateTaskConfigurationBatch = async (
    r: Partial<{
      [id: string]: TaskConfiguration;
    }>
  ) => {
    const tasks = removeEmptyValues(r);
    if (!tasks?.length) return null;

    return await updateBatch({ tasks }).then((r) => {
      if (r.status === StatusCode.OK) {
        successToast({
          title: "Configuration Updated",
          description: `total configurations updated: ${r.data?.batch?.length}`
        });
      } else if (r.status === StatusCode.BadRequest) {
        errorToast({
          title: "Failed to update batch",
          description: r.data?.description
        });
      }
      return r;
    });
  };
  return {
    taskConfigurationHooks,
    paginationHandler,
    taskRuleState,
    taskTemplateState,
    updateTaskConfigurationBatch,
    setCurrentCursorHasMore,
    setCurrentCursorId,
    searchTaskConfigurationApi
  };
};

export const TaskConfigurationTable = () => {
  const pageSize = 10;
  const limit = 50;

  const { heightOffsetInPx } = useWindowDimensions();
  const {
    paginationHandler,
    taskConfigurationHooks,
    updateTaskConfigurationBatch,
    taskRuleState,
    taskTemplateState
  } = useTaskConfigurationTableHooks();

  const [getRuleBatchApi, getRuleBatchResponse] =
    taskRuleApi.useLazyGetBatchQuery();
  const [getTemplateBatchApi, getTemplateBatchResponse] =
    taskTemplateApi.useLazyGetBatchQuery();

  const {
    taskConfigurationState,
    selectedTaskConfiguration,
    searchTaskConfigurationApi
  } = taskConfigurationHooks;

  const {
    getInstructionsBatch,
    getInstructionsBatchResponse,
    taskInstructionState
  } = useTaskInstructionHooks();

  const [filteredTask, setFilteredTask] = useState<{
    filter?: FirebaseFilter | FirebaseFilter[];
  }>();
  const [filteredTaskIds, setFilteredTaskIds] = useState<EntityId[]>([]);
  const [taskTemplateId, setTaskTemplateId] = useState<string>();
  const [taskRuleTemplateId, setTaskRuleTemplateId] = useState<string>();
  const [autoGenerate, setAutoGenerate] = useState<string>();
  const [keyClosingTask, setKeyClosingTask] = useState<string>();
  const [internalTaskInstructionId, setInternalTaskInstructionId] =
    useState<string>();
  const [externalTaskInstructionId, setExternalTaskInstructionId] =
    useState<string>();

  const { syncState, onChange, state } = useFormBuilderStateHandler<{
    [id: string]: Partial<TaskConfiguration>;
  }>({
    initialState: {},
    callback: updateTaskConfigurationBatch,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  useEffect(() => {
    if (auth.currentUser) {
      const rules = Object.values(paginationHandler.pageData.entities)
        .map((e) => e.taskRuleTemplateId)
        .filter((v) => !!v && !taskRuleState.entities[v]);

      const templates = Object.values(paginationHandler.pageData.entities)
        .map((e) => e.taskTemplateId)
        .filter((v) => !!v && !taskTemplateState.entities[v]);

      const instructions = uniq(
        Object.values(paginationHandler.pageData.entities)
          .flatMap((e) => [e?.internalInstructionId, e?.externalInstructionId])
          .filter(removeEmpty)
      );

      rules.length && getRuleBatchApi(rules, true);
      templates.length && getTemplateBatchApi(templates, true);
      instructions.length && getInstructionsBatch(instructions);
    }
  }, [auth.currentUser, paginationHandler.pageData]);

  useEffect(() => {
    if (auth.currentUser && selectedTaskConfiguration) {
      !taskRuleState.entities[selectedTaskConfiguration.taskRuleTemplateId] &&
        getRuleBatchApi([selectedTaskConfiguration.taskRuleTemplateId]);
      !taskTemplateState.entities[selectedTaskConfiguration.taskTemplateId] &&
        getTemplateBatchApi([selectedTaskConfiguration.taskTemplateId]);
      const instructions = uniq([
        selectedTaskConfiguration?.internalInstructionId,
        selectedTaskConfiguration?.externalInstructionId
      ]).filter(removeEmpty);
      instructions.length && getInstructionsBatch(instructions);
    }
  }, [auth.currentUser, selectedTaskConfiguration]);

  useAuthStateChangeHook({
    onAuthStateChange: (_) => {
      if (
        paginationHandler.pageResponse.isUninitialized &&
        !paginationHandler.pageResponse.isSuccess &&
        !paginationHandler.pageResponse.isLoading &&
        !paginationHandler.pageResponse.isFetching &&
        auth.currentUser
      ) {
        paginationHandler.next();
      }
    },
    deps: []
  });

  const isLoading =
    paginationHandler.pageResponse.isFetching ||
    paginationHandler.pageResponse.isLoading;

  const {
    setStateDebouncer: tableFilterSetter,
    setStateInputDebouncer: tableInputSetter,
    state: tableFilterState,
    selectedOrderState: selectedOrderState,
    setSelectedOrderState: setSelectedOrderState,
    getFilteredAndSortDataIds
  } = useFilterHook<TaskConfiguration, CellsFilterStateFlat<TaskConfiguration>>(
    {
      state: filterState,
      options: {
        debounceRate: 100
      },
      searchApi: searchTaskConfigurationApi,
      onNextFilter: (filter) => {
        setFilteredTask(filter);
        if (filter) {
          paginationHandler.next(filter);
        } else {
          paginationHandler.reset();
        }
      }
    }
  );

  useEffect(() => {
    const ids = getFilteredAndSortDataIds(taskConfigurationState);
    setFilteredTaskIds(ids);
  }, [taskConfigurationState.ids, tableFilterState]);

  const [taskEntity, setTaskEntity] = useState<string[]>();

  const tableHeaders: ElphiTableProps["header"] = useMemo(() => {
    return [
      {
        index: 0,
        type: ElphiCellType.String,
        size: SIZE_FIELD.S,
        data: ""
      },
      {
        index: 1,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.M,
        data: (
          <FilterCellComponent<TaskConfiguration>
            data={[]}
            fieldKey={"createdAt"}
            disabled
            tooltipLabel="Not Supported Yet"
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showOrderState={false}
            showSelectAll={false}
            customStyles={filterStyle}
          />
        )
      },
      {
        index: 2,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.L,
        data: (
          <FilterCellComponent<TaskConfiguration>
            data={
              Object.values(
                taskConfigurationState.entities
              ) as TaskConfiguration[]
            }
            fieldKey={"name"}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showSelectAll={false}
            customStyles={filterStyle}
          />
        )
      },
      {
        index: 3,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.L,
        data: (
          <FilterCellComponent<TaskConfiguration>
            data={[]}
            fieldKey={"autoGenerate"}
            selectedValue={autoGenerate}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showOrderState={false}
            showSelectAll={false}
            customStyles={filterStyle}
          >
            <Box>
              <StyledInputBuilder
                chakraStyles={{
                  valueContainer: (provided) => ({
                    ...provided,
                    fontSize: "12px"
                  })
                }}
                hideSelectedOptions={false}
                currentValue={autoGenerate}
                options={createOptionsFromEnum(AutoGenerateOptions)}
                fieldType={FieldType.SingleSelect}
                onChange={(e) => {
                  setAutoGenerate(e.target.value);
                }}
                label="filter..."
                isValid={true}
              />
            </Box>
          </FilterCellComponent>
        )
      },
      {
        index: 4,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.L,
        data: (
          <FilterCellComponent<TaskConfiguration>
            data={[]}
            selectedValue={taskEntity}
            fieldKey={"taskEntity"}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showOrderState={false}
            showSelectAll={false}
            customStyles={filterStyle}
          >
            <Box>
              <StyledInputBuilder
                chakraStyles={{
                  valueContainer: (provided) => ({
                    ...provided,
                    fontSize: "12px"
                  })
                }}
                hideSelectedOptions={false}
                currentValue={taskEntity}
                options={createOptionsFromEnum(ElphiEntityType)}
                fieldType={FieldType.SingleSelect}
                onChange={(e) => {
                  setTaskEntity(e.target.value);
                }}
                label={EMPTY}
                isValid={true}
              />
            </Box>
          </FilterCellComponent>
        )
      },
      {
        index: 5,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.L,
        data: (
          <FilterCellComponent<TaskConfiguration>
            data={[]}
            fieldKey={"keyClosingTaskType"}
            selectedValue={keyClosingTask}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showOrderState={false}
            showSelectAll={false}
            customStyles={filterStyle}
          >
            <Box>
              <StyledInputBuilder
                chakraStyles={{
                  valueContainer: (provided) => ({
                    ...provided,
                    fontSize: "12px"
                  })
                }}
                hideSelectedOptions={false}
                currentValue={keyClosingTask}
                options={createOptionsFromEnum(KeyClosingTaskType)}
                fieldType={FieldType.SingleSelect}
                onChange={(e) => {
                  setKeyClosingTask(e.target.value);
                }}
                label="filter..."
                isValid={true}
              />
            </Box>
          </FilterCellComponent>
        )
      },
      {
        index: 6,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.L,
        data: (
          <FilterCellComponent<TaskConfiguration>
            data={[]}
            selectedValue={taskTemplateId}
            fieldKey={"taskTemplateId"}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showOrderState={false}
            showSelectAll={false}
            customStyles={filterChildrenStyle}
          >
            <TaskTemplateSearch
              onSelect={(id) => {
                setTaskTemplateId(id);
              }}
              currentValue={taskTemplateId || ""}
            />
          </FilterCellComponent>
        )
      },
      {
        index: 7,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.L,
        data: (
          <FilterCellComponent<TaskConfiguration>
            data={[]}
            selectedValue={taskRuleTemplateId}
            fieldKey={"taskRuleTemplateId"}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showOrderState={false}
            showSelectAll={false}
            customStyles={filterChildrenStyle}
          >
            <TaskRuleSearch
              onSelect={(id) => {
                setTaskRuleTemplateId(id);
              }}
              currentValue={taskRuleTemplateId || ""}
            />
          </FilterCellComponent>
        )
      },
      {
        index: 8,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.L,
        data: (
          <FilterCellComponent<TaskConfiguration>
            data={[]}
            selectedValue={internalTaskInstructionId}
            fieldKey={"internalInstructionId"}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showOrderState={false}
            showSelectAll={false}
            customStyles={filterChildrenStyle}
          >
            <TaskInstructionSearch
              onSelect={(id) => {
                setInternalTaskInstructionId(id);
              }}
              selectedValue={internalTaskInstructionId || EMPTY}
              label={"Select"}
            />
          </FilterCellComponent>
        )
      },
      {
        index: 9,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.L,
        data: (
          <FilterCellComponent<TaskConfiguration>
            data={[]}
            selectedValue={externalTaskInstructionId}
            fieldKey={"externalInstructionId"}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showOrderState={false}
            showSelectAll={false}
            customStyles={filterChildrenStyle}
          >
            <TaskInstructionSearch
              onSelect={(id) => {
                setExternalTaskInstructionId(id);
              }}
              selectedValue={externalTaskInstructionId || EMPTY}
              label={"Select"}
            />
          </FilterCellComponent>
        )
      },
      {
        index: 10,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.M,
        data: (
          <FilterCellComponent<TaskConfiguration>
            data={[]}
            fieldKey={"dataToVerify"}
            disabled
            tooltipLabel="Not Supported Yet"
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showOrderState={false}
            showSelectAll={false}
            customStyles={filterStyle}
          />
        )
      },
      {
        index: 11,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.M,
        data: (
          <FilterCellComponent<TaskConfiguration>
            data={[]}
            fieldKey={"dataToOrder"}
            disabled
            tooltipLabel="Not Supported Yet"
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showOrderState={false}
            showSelectAll={false}
            customStyles={filterStyle}
          />
        )
      },
      {
        index: 12,
        type: ElphiCellType.Element,
        size: SIZE_FIELD.M,
        data: (
          <FilterCellComponent<TaskConfiguration>
            data={
              Object.values(
                taskConfigurationState.entities
              ) as TaskConfiguration[]
            }
            fieldKey={"status"}
            inputSetter={tableInputSetter}
            setter={tableFilterSetter}
            state={tableFilterState}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
            showSelectAll={false}
            showOrderState={false}
            customStyles={filterStyle}
          />
        )
      },
      {
        index: 13,
        type: ElphiCellType.String,
        size: SIZE_FIELD.S,
        data: ""
      }
    ];
  }, [
    taskConfigurationState.entities,
    tableFilterState,
    taskTemplateId,
    taskRuleTemplateId,
    taskEntity,
    autoGenerate,
    keyClosingTask,
    internalTaskInstructionId,
    externalTaskInstructionId
  ]);

  return (
    <Flex width="100%">
      <ElphiPaginationList
        tableName={"TaskConfiguration"}
        isLoading={isLoading}
        header={
          <TableRowSizeComponent
            row={tableHeaders}
            withBorderRightWidth={false}
            justify="space-evenly"
          />
        }
        height={heightOffsetInPx(320)}
        hasMore={paginationHandler.pageData?.ids?.length < limit}
        items={
          !!taskConfigurationState.selectedId
            ? [taskConfigurationState.selectedId.toString()]
            : filteredTaskIds
        }
        next={() => paginationHandler.next(filteredTask)}
        pageSize={pageSize}
        rowBuilder={(taskConfigurationId, index) => {
          return (
            <TaskConfigurationRow
              taskConfigurationId={taskConfigurationId?.toString() || ""}
              index={(Number(index) + 1).toString()}
              syncState={syncState}
              taskConfigurationHooks={taskConfigurationHooks}
              onChange={onChange}
              state={state}
              key={index}
              getRuleBatchResponse={getRuleBatchResponse}
              getTemplateBatchResponse={getTemplateBatchResponse}
              getInstructionsBatchResponse={getInstructionsBatchResponse}
              taskRuleState={taskRuleState}
              taskTemplateState={taskTemplateState}
              taskInstructionState={taskInstructionState}
            />
          );
        }}
      />
    </Flex>
  );
};
