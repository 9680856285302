import { IndividualParty, Task } from "@elphi/types";

import { CreditScore } from "@elphi/types/entities/creditReport.types";
import { PartyStructureState } from "../../../hooks/partyform.hooks";
import {
  dueDateFieldPath,
  orderedDateFieldPath,
  orderedFromFieldPath,
  orderStatusFieldPath,
  receivedDateFieldPath,
  vendorInformationFieldPath,
  vendorNameFieldPath
} from "../../application/field-paths/task.fieldPaths";
import {
  creditReportOrderSection,
  creditReportSection
} from "../../application/sections/creditReport.sections";
import { taskWithOrderStatusFieldSpecs } from "../../form-builder/field-specs/task/task.fields";
import {
  InputBuilderSpecs,
  OnChangeInput
} from "../../form-builder/FormBuilder";
import { buildInputs } from "../../form-builder/formBuilder.utils";
import { dateValidation, stringValidation } from "../../utils/validationUtils";
//import { TaskFormState } from "../TaskForm";

export const taskFormSections = (
  task: { id: string } & Partial<Task>,
  onChange: (v: OnChangeInput) => void,
  isDisabled?: boolean
) => {
  return [orderStatusSection({ task, onChange, isDisabled })];
};

export type CreditReportIntegrationSections = {
  individual: { id: string } & Partial<IndividualParty>;
  task: { id: string } & Partial<Task>;
  creditScore?: { id: string & Partial<CreditScore> };
  onChange: (v: OnChangeInput) => void;
  state: PartyStructureState;
  creditScoreOnChange: (v: OnChangeInput) => void;
};
export const creditReportBorrowerSections = (
  r: CreditReportIntegrationSections
) => {
  return [creditReportOrderSection(r.individual, r.onChange)];
};
export const creditReportSections = (r: CreditReportIntegrationSections) => {
  return [creditReportSection(r?.creditScore || {}, r.creditScoreOnChange)];
};

export const dataNeededToSubmitAnOrderSection = () => {
  let inputs: InputBuilderSpecs[] = [];
  return {
    header: "Data Needed to Submit Order",
    inputs: inputs
  };
};

export const orderStatusSection = (r: {
  task: { id: string } & Partial<Task>;
  onChange: (v: OnChangeInput) => void;
  isDisabled?: boolean;
}) => {
  const { task, onChange, isDisabled } = r;
  return {
    header: "Order Status",
    inputs: buildInputs({
      state: task,
      onChange,
      specs: [
        {
          path: vendorNameFieldPath,
          validation: stringValidation,
          isDisabled
        },
        {
          path: vendorInformationFieldPath,
          validation: stringValidation,
          isDisabled
        },
        {
          path: receivedDateFieldPath,
          validation: dateValidation,
          isDisabled
        },
        {
          path: dueDateFieldPath,
          validation: dateValidation,
          isDisabled
        },
        {
          path: orderedDateFieldPath,
          validation: dateValidation,
          isDisabled
        },
        {
          path: orderedFromFieldPath,
          validation: stringValidation,
          isDisabled
        },
        {
          path: orderStatusFieldPath,
          validation: stringValidation,
          isDisabled
        }
      ],
      fieldSpecs: taskWithOrderStatusFieldSpecs
    })
  };
};

export const dataToVerifySection = () => {
  let inputs: InputBuilderSpecs[] = [];
  return {
    header: "Data to Verify",
    inputs: inputs
  };
};
