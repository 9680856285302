import { Divider } from "@chakra-ui/react";
import { Asset } from "@elphi/types";
import {
  accountAssetFieldSpecs,
  assetFieldSpecs
} from "../../form-builder/field-specs/asset/asset.fields";
import { OnChangeInput, Section } from "../../form-builder/FormBuilder";
import { buildInputs } from "../../form-builder/formBuilder.utils";
import {
  lengthInRangeValidation,
  singleSelectValidation,
  stringValidation
} from "../../utils/validationUtils";
import {
  accountNamePath,
  accountTypePath,
  financialInstitutionPath,
  fullNameOnAccountPath,
  last4DigitsPath,
  typeFieldPath
} from "../field-paths/asset.fieldPaths";

export const assetInfoSection = (r: {
  state: Pick<Asset, "type">;
  onChange?: (v: OnChangeInput) => void;
  options?: { isReadOnly?: boolean; hideAttachedComponent?: boolean };
}): Section => {
  const { state, onChange, options } = r;
  return {
    header: "Asset Info",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: assetFieldSpecs,
      specs: [
        {
          path: typeFieldPath,
          validation: singleSelectValidation,
          isReadOnly: options?.isReadOnly
        }
      ],
      hideAttachedComponent: options?.hideAttachedComponent
    })
  };
};
export const createAccountSection = (r: {
  state: Partial<Asset>;
  onChange?: (v: OnChangeInput) => void;
  options: { hideAttachedComponent: boolean };
}): Section => {
  const { state, onChange, options } = r;
  return {
    header: <Divider />,
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: accountAssetFieldSpecs,
      specs: [
        { path: fullNameOnAccountPath, validation: stringValidation },
        { path: accountNamePath, validation: stringValidation },
        { path: accountTypePath, validation: singleSelectValidation },
        { path: financialInstitutionPath, validation: stringValidation },
        {
          path: last4DigitsPath,
          validation: lengthInRangeValidation(3, 4)
        }
      ],
      hideAttachedComponent: options.hideAttachedComponent
    })
  };
};
