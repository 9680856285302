import { Box, Button, Flex, Spacer, Stack, useBoolean } from "@chakra-ui/react";
import { FieldType } from "@elphi/types";
import { useRef } from "react";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { auth } from "../../firebase/firebaseConfig";

import { usePropertyHooks } from "../../hooks/property.hooks";
import { CreatePropertyModal } from "./AddProperty";
import PropertyPageForm from "./PropertyPageForm";
import { PropertySearch } from "./PropertySearch";
// import { PropertyTableContainer } from "./table/PropertyTableContainer";
import { ExpandAllButton } from "../../common-components/expandAllButton/ExpandAllButton";
import { useAuthStateChangeHook } from "../../hooks/authStateChange.hooks";
import { useQueryParamsHooks } from "../../hooks/queryParams.hooks";
import { propertyApi } from "../../redux/v2/property";
import {
  NavigationItemType,
  NavigationPages,
  NavigationPath
} from "../../shared/types/navigation.types";
import PropertyScrollableSections from "./PropertyScrollableSections";

const PropertyPage = () => {
  const { selectedProperty, setSelectedProperty } = usePropertyHooks();
  const navigationPath: NavigationPath = [
    { type: NavigationItemType.Page, value: NavigationPages.Properties }
  ];
  const [getProperty] = propertyApi.useLazyGetQuery();
  const params = useQueryParamsHooks();
  const paramsPropertyId = params.get("property") || "";

  useAuthStateChangeHook({
    onAuthStateChange: (_) => {
      if (auth.currentUser && paramsPropertyId) {
        getProperty(paramsPropertyId);
        setSelectedProperty(paramsPropertyId);

        //** clear url queryPara, if extracted partyId from query param */
        window.history.replaceState(null, "", window.location.pathname);
      }
    },
    deps: [paramsPropertyId]
  });

  const [flag, setFlag] = useBoolean();
  const elementRef = useRef(null);

  return (
    <Box h={"100%"} w={"100%"} overflow="auto">
      <Stack w={"100%"}>
        <Flex w={"100%"} position="sticky" zIndex={5} top={0} bgColor="white">
          <Box maxW={"500px"} minW={"400px"}>
            <PropertySearch
              fieldType={FieldType.SingleSelect}
              currentValue={(selectedProperty && selectedProperty.id) || ""}
              onSelect={(v: string) => {
                setSelectedProperty(v);
              }}
              label={"search property"}
              labelPosition={"placeHolder"}
            />
          </Box>
          <Box>
            <Button
              {...elphiTheme.components.light.button[
                flag ? "generate" : "primary"
              ]}
              onClick={() => {
                setFlag.toggle();
              }}
              mt={"10px"}
            >
              {flag ? "Go To All Properties" : "Go to My Properties"}
            </Button>
          </Box>
          <Spacer />
          <Box pt="10px" pr="10px">
            <CreatePropertyModal />
          </Box>
        </Flex>
        {selectedProperty && (
          <Box>
            <ExpandAllButton navigationPath={navigationPath} />
          </Box>
        )}
        <Box>
          <PropertyScrollableSections
            elementRef={elementRef}
            height={600}
            selectedProperty={selectedProperty}
            navigationPath={selectedProperty ? navigationPath : undefined}
            userId={
              flag && auth.currentUser?.uid ? auth.currentUser.uid : undefined
            }
          />
          {selectedProperty && (
            <Box>
              <PropertyPageForm navigationPath={navigationPath} />
            </Box>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default PropertyPage;
