import { Box, Flex } from "@chakra-ui/react";
import { merge } from "lodash";
import { useMemo } from "react";
import { useTaskRuleHooks } from "../../../hooks/taskRule.hooks";
import { ruleFormula } from "../../rule-builder/formulaPrinter.utils";
import RuleTreeBuilder, {
  RuleNodeDataType,
  TreePrint
} from "../../rule-builder/tree/RuleTreeBuilder";
import { createTreeNodesFromRule } from "../../rule-builder/tree/serialization.utils";
import useTree from "../../tree/tree.hooks";
import { ElphiNode } from "../../tree/types/Tree.types";
import { TaskRuleBuilderModal } from "./TaskRuleBuilderModal";
import TaskRuleSearch from "./TaskRuleSearch";

const TaskRuleBuilder = () => {
  const { selectedTaskRule, setSelectedTaskRule } = useTaskRuleHooks();

  const initialState: ElphiNode<RuleNodeDataType> = useMemo(() => {
    if (selectedTaskRule) {
      const rule = merge({}, selectedTaskRule.rule);
      return createTreeNodesFromRule(rule);
    }
    return {
      id: "$",
      children: [],
      data: {} as any,
      nodeKey: "$",
      label: "$"
    };
  }, [selectedTaskRule]);

  const { elphiTreeOperations, treeState, setTreeState } =
    useTree<RuleNodeDataType>({
      initialState
    });

  const treeRule = (node: ElphiNode<RuleNodeDataType>) => {
    return {
      ...node.data,
      children: node.children.length
        ? node.children.map((c) => treeRule(c))
        : undefined
    };
  };

  const treePrint: TreePrint = useMemo(() => {
    const node = treeRule(treeState);
    const ruleJsonString = JSON.stringify(node);
    return { ruleJsonString, formula: ruleFormula(node), rule: node };
  }, [treeState]);

  return (
    <Flex direction="column" h="100%">
      <Box>
        <TaskRuleSearch
          currentValue={!!selectedTaskRule ? selectedTaskRule.id : ""}
          onSelect={setSelectedTaskRule}
        />
      </Box>
      <Flex overflowY="auto" flexGrow={1} w="100%">
        <RuleTreeBuilder
          selectedRule={selectedTaskRule}
          customComponent={() => {
            return (
              <Box p="10px">
                <TaskRuleBuilderModal treePrint={treePrint} />
              </Box>
            );
          }}
          elphiTreeOperations={elphiTreeOperations}
          treePrint={treePrint}
          treeState={treeState}
          setTreeState={setTreeState}
        />
      </Flex>
    </Flex>
  );
};
export default TaskRuleBuilder;
