import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Spacer, useDisclosure } from "@chakra-ui/react";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { useAuditEventGroupHooks } from "../../hooks/auditEventGroup.hooks";
import { GroupModal } from "../custom/modal/GroupModal";
import { AuditEventGroupSearch } from "./AuditEventGroupSearch";
import { AuditEventGroupFilters } from "./table/AuditEventGroupFilters";
import { AuditEventGroupTable } from "./table/AuditEventGroupTable";

export const AuditEventGroup = () => {
  const { isOpen: isOpenCreateModal, onToggle: onToggleCreateModal } =
    useDisclosure();
  const { createGroup, createResponse } = useAuditEventGroupHooks();
  return (
    <Box>
      <Flex>
        <Box width={"100%"}>
          <AuditEventGroupSearch />
        </Box>
        <Spacer />
        <Box p={"10px"}>
          <Button
            aria-label="add-audit-event-group-btn"
            onClick={onToggleCreateModal}
            leftIcon={<AddIcon />}
            {...elphiTheme.components.light.button.primary}
          >
            Create audit event group
          </Button>
        </Box>
      </Flex>
      <Flex p={"10px"} direction={"column"}>
        <AuditEventGroupFilters />
        <AuditEventGroupTable />
      </Flex>
      <GroupModal
        header={"Create audit event group"}
        action={"create"}
        isShow={isOpenCreateModal}
        onClose={onToggleCreateModal}
        isLoading={createResponse.isLoading}
        onSubmit={createGroup}
      />
    </Box>
  );
};
