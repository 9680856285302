import { Organization } from "@elphi/types";
import { useCallback } from "react";
import { useOrgHooks } from "../../../../hooks/org.hooks";
import { getRankedData } from "../../../../utils/ranked.utils";
import { SearchComponentProps } from "../../../SearchComponent";
import { SearchHandler } from "../../../search/SearchHandler";
import { OptionType } from "./utils/Organization.utils";

export type OrganizationSearchProps = {
  buildOption: (o: Organization) => OptionType;
} & Pick<
  SearchComponentProps,
  "onSelect" | "currentValue" | "isDisabled" | "customComponent" | "fieldType"
>;
export const OrganizationSearch = (props: OrganizationSearchProps) => {
  const { orgState, searchOrgs, rankedSort, searchResponse, dataRank } =
    useOrgHooks();

  const customFilter: SearchComponentProps["filterOption"] = (
    option,
    searchText
  ) => {
    if (!searchText) {
      return true;
    }
    const org = orgState?.entities?.[option.data.value];
    if (!org?.id) {
      return false;
    }
    return (
      getRankedData(searchText, org, dataRank) > 0.5 ||
      searchText === org.id.toLowerCase()
    );
  };
  const filterOption = useCallback(customFilter, [orgState?.entities]);

  return (
    <SearchHandler
      {...props}
      version={"v2"}
      label={"Search organization"}
      labelPosition={"placeHolder"}
      searchApi={searchOrgs}
      searchResponse={searchResponse}
      state={orgState}
      buildOption={props.buildOption}
      rankedSort={rankedSort}
      filterOption={filterOption}
    />
  );
};
