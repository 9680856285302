import {
  AggregationType,
  FloodCertificateVendorType,
  IntegrationType,
  Property
} from "@elphi/types";
import { OnChangeInput, Section } from "../../../form-builder/FormBuilder";
import { propertySpecFields } from "../../../form-builder/field-specs/property/property.fields";
import { buildInputs } from "../../../form-builder/formBuilder.utils";
import {
  booleanValidation,
  dateValidation,
  singleSelectValidation,
  stringValidation
} from "../../../utils/validationUtils";
import {
  NFIPLegalCommunityNameFieldPath,
  NFIPLegalCommunityNumberFieldPath,
  NFIPMapCommunityNameFieldPath,
  NFIPMapCommunityNumberFieldPath,
  NFIPMapPanelNumberFieldPath,
  TSDNCommentsFieldPath,
  determinationDateFieldPath,
  floodCheckStatusFieldPath,
  floodMapDateFieldPath,
  floodZoneFieldPath,
  orderNumberFieldPath,
  orderStatusFieldPath,
  propertyMSAMDFieldPath,
  requestedInformationFieldPath,
  resultDescriptionFieldPath,
  specialFloodHazardAreaIndicatorFieldPath
} from "../../field-paths/property.fieldPaths";

export const serviceLinkFloodVerifySection = (
  state: Partial<Property>,
  onChange: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Integration Flood Cert: Verify",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: propertySpecFields,
      specs: [
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.FloodCertificate
            ]?.[FloodCertificateVendorType.ServiceLink]
              ?.SpecialFloodHazardAreaIndicator?.focused !== "override",
          path: specialFloodHazardAreaIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.FloodCertificate
            ]?.[FloodCertificateVendorType.ServiceLink]?.FloodCheckStatus
              ?.focused !== "override",
          path: floodCheckStatusFieldPath,
          validation: singleSelectValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.FloodCertificate
            ]?.[FloodCertificateVendorType.ServiceLink]?.OrderNumber
              ?.focused !== "override",
          path: orderNumberFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.FloodCertificate
            ]?.[FloodCertificateVendorType.ServiceLink]?.OrderStatus
              ?.focused !== "override",
          path: orderStatusFieldPath,
          validation: singleSelectValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.FloodCertificate
            ]?.[FloodCertificateVendorType.ServiceLink]?.NFIPMapCommunityNumber
              ?.focused !== "override",
          path: NFIPMapCommunityNumberFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.FloodCertificate
            ]?.[FloodCertificateVendorType.ServiceLink]
              ?.NFIPLegalCommunityNumber?.focused !== "override",
          path: NFIPLegalCommunityNumberFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.FloodCertificate
            ]?.[FloodCertificateVendorType.ServiceLink]?.NFIPMapCommunityName
              ?.focused !== "override",
          path: NFIPMapCommunityNameFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.FloodCertificate
            ]?.[FloodCertificateVendorType.ServiceLink]?.NFIPLegalCommunityName
              ?.focused !== "override",
          path: NFIPLegalCommunityNameFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.FloodCertificate
            ]?.[FloodCertificateVendorType.ServiceLink]?.NFIPMapPanelNumber
              ?.focused !== "override",
          path: NFIPMapPanelNumberFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.FloodCertificate
            ]?.[FloodCertificateVendorType.ServiceLink]?.FloodMapDate
              ?.focused !== "override",
          path: floodMapDateFieldPath,
          validation: dateValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.FloodCertificate
            ]?.[FloodCertificateVendorType.ServiceLink]?.FloodZone?.focused !==
            "override",
          path: floodZoneFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.FloodCertificate
            ]?.[FloodCertificateVendorType.ServiceLink]?.DeterminationDate
              ?.focused !== "override",
          path: determinationDateFieldPath,
          validation: dateValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.FloodCertificate
            ]?.[FloodCertificateVendorType.ServiceLink]?.PropertyMSAMD
              ?.focused !== "override",
          path: propertyMSAMDFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.FloodCertificate
            ]?.[FloodCertificateVendorType.ServiceLink]?.RequestedInformation
              ?.focused !== "override",
          path: requestedInformationFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.FloodCertificate
            ]?.[FloodCertificateVendorType.ServiceLink]?.TSDNComments
              ?.focused !== "override",
          path: TSDNCommentsFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.FloodCertificate
            ]?.[FloodCertificateVendorType.ServiceLink].ResultDescription
              ?.focused !== "override",
          path: resultDescriptionFieldPath,
          validation: stringValidation
        }
      ]
    })
  };
};
