import { FieldType, Property, PropertyInsurancePolicy } from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { orderBy } from "lodash";
import { InputBuilderFieldSpecs } from "../../../../../form-builder/field-specs/fields.types";
import { BaseInputFormatter } from "../../../../../form-builder/formatters/formatter.types";

export const entitiesPath: DotNestedKeys<PropertyInsurancePolicy> = "entities";

export const buildInput = (r: {
  fieldSpec?: InputBuilderFieldSpecs<any>;
  isReadOnly?: boolean;
  isRequired?: boolean;
  isHidden?: boolean;
  validation: (v: any, isRequired?: boolean) => boolean;
  formatter?: BaseInputFormatter<FieldType, any, any>;
}) => {
  const { fieldSpec, isReadOnly, validation, formatter, isRequired, isHidden } =
    r;

  return {
    path: fieldSpec?.fieldKey,
    validation: (v) => validation(v, isRequired),
    isHidden,
    isRequired,
    isReadOnly,
    formatter
  };
};

export const orderPropertiesByDealId = (r: {
  properties: Property[];
  dealId: string;
}) => {
  const { properties, dealId } = r;
  return orderBy(
    properties,
    [(property) => property.dealRelation?.at(0) === dealId],
    ["desc"]
  );
};
