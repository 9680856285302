import { Statement } from "@elphi/types";
import { ObjectKeyValidation } from "../../form-builder/field-specs/objectKeyValidation.types";

export const statusFieldPath: ObjectKeyValidation<Statement, ["status"]> = [
  "status"
];

export const latestStatementFieldPath: ObjectKeyValidation<
  Statement,
  ["latestStatement"]
> = ["latestStatement"];
export const startFieldPath: ObjectKeyValidation<Statement, ["start"]> = [
  "start"
];
export const endFieldPath: ObjectKeyValidation<Statement, ["end"]> = ["end"];
export const statementBalanceFieldPath: ObjectKeyValidation<
  Statement,
  ["statementBalance"]
> = ["statementBalance"];

export const unverifiedDepositsFieldPath: ObjectKeyValidation<
  Statement,
  ["unverifiedDeposits"]
> = ["unverifiedDeposits"];
export const percentConsideredFieldPath: ObjectKeyValidation<
  Statement,
  ["percentConsidered"]
> = ["percentConsidered"];

export const qualifyingBalanceFieldPath: ObjectKeyValidation<
  Statement,
  ["aggregations", "qualifyingBalance"]
> = ["aggregations", "qualifyingBalance"];
