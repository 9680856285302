export const rowIndexStyles = {
  maxWidth: "100px",
  minWidth: "100px"
};

export const actionStyles = {
  maxWidth: "150px",
  minWidth: "150px"
};

export const createdAtStyles = {
  maxWidth: "175px",
  minWidth: "175px"
};

export const dealIdStyles = {
  maxWidth: "150px",
  minWidth: "150px"
};

export const borrowingEntityStyles = {
  maxWidth: "150px",
  minWidth: "150px"
};

export const loanProgramStyles = {
  maxWidth: "360px",
  minWidth: "360px"
};

export const userAssignmentStyles = {
  maxWidth: "280px",
  minWidth: "280px"
};

export const closingDateStyles = {
  maxWidth: "280px",
  minWidth: "320px"
};

export const milestoneStyles = {
  maxWidth: "170px",
  minWidth: "170px"
};

export const lenderStyles = {
  maxWidth: "120px",
  minWidth: "120px"
};
