import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import OrganizationPage from "./organization/OrganizationPage";

const HEADER_HEIGHT = "80px";

export const PlatformTabs = () => {
  return (
    <Box h="100%" w="100%" overflow="hidden">
      <Tabs
        h="100%"
        w="100%"
        position="sticky"
        top="0"
        isLazy={true}
        lazyBehavior="unmount"
      >
        <TabList>
          <Tab>Organizations</Tab>
        </TabList>
        <TabPanels h={`calc(100% - ${HEADER_HEIGHT})`} w="100%" p="0">
          <TabPanel h="100%" w="100%" p="0">
            <OrganizationPage />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
