import {
  AggregationFocusType,
  AssetType,
  BaseEntity,
  DealPartyRelationType,
  IntegrationType,
  PartyRelationType,
  PartyType,
  ServiceProviderType,
  TaskType,
  getAssetTemplate,
  getBaseInsurancePolicy,
  getCreditReportTemplate,
  getDealPartyTemplate,
  getDealPropertyTemplate,
  getDealTemplate,
  getPartyRelationTemplate,
  getPartyTemplate,
  getPropertyTemplate,
  getServiceProviderTemplate,
  getStatementTemplate
} from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { get, includes, merge } from "lodash";
import { NOT_AVAILABLE } from "../../../constants/common";
import {
  dealToEntityFieldSpecs,
  dealToIndividualFieldSpecs
} from "../../form-builder/field-specs/deal-party/deal-party.fields";
import {
  entityToEntityRelationFieldSpecs,
  entityToIndividualRelationFieldSpecs
} from "../../form-builder/field-specs/party-relation/party-relation.fields";
import {
  entityPartyFieldSpecs,
  individualPartyFieldSpecs
} from "../../form-builder/field-specs/party/party.fields";
import {
  fullBranchServiceProviderFieldsSpecs,
  fullCompanyServiceProviderFieldsSpecs,
  fullRepServiceProviderFieldsSpecs
} from "../../form-builder/field-specs/service-provider/serviceProvider.fields";
import { createTemplateWithFieldMeta } from "../../form-builder/field-specs/utils/fieldMeta.utils";
import { getTaskTemplate } from "../../task/task-template/templates/task.template";

const removeFields = <T extends object>(e: T, keysToRemove: string[]): T => {
  keysToRemove.forEach((k) => {
    delete e[k];
  });
  return e;
};
const removeBaseFields = <T extends object>(e: T): T => {
  const baseFields: DotNestedKeys<BaseEntity<object>>[] = ["index"];
  return removeFields(e, baseFields);
};

const individualFields = createTemplateWithFieldMeta({
  entityTemplate: removeBaseFields(getPartyTemplate(PartyType.Individual))
});
const entityFields = createTemplateWithFieldMeta({
  entityTemplate: removeBaseFields(getPartyTemplate(PartyType.Entity))
});

const companyServiceProviderFields = createTemplateWithFieldMeta({
  entityTemplate: removeBaseFields(
    getServiceProviderTemplate(ServiceProviderType.Company)
  )
});
const branchServiceProviderFields = createTemplateWithFieldMeta({
  entityTemplate: removeBaseFields(
    getServiceProviderTemplate(ServiceProviderType.Branch)
  )
});
const repServiceProviderFields = createTemplateWithFieldMeta({
  entityTemplate: removeBaseFields(
    getServiceProviderTemplate(ServiceProviderType.Representative)
  )
});
export const serviceProviderFields = merge(
  {},
  companyServiceProviderFields,
  branchServiceProviderFields,
  repServiceProviderFields
);

const dealEntityFields = createTemplateWithFieldMeta({
  entityTemplate: removeBaseFields(
    getDealPartyTemplate(DealPartyRelationType.DealEntity)
  )
});
const dealIndividualFields = createTemplateWithFieldMeta({
  entityTemplate: removeBaseFields(
    getDealPartyTemplate(DealPartyRelationType.DealIndividual)
  )
});
const entityToEntityFields = createTemplateWithFieldMeta({
  entityTemplate: removeBaseFields(
    getPartyRelationTemplate(PartyRelationType.EntityToEntity)
  )
});
const entityToIndividualFields = createTemplateWithFieldMeta({
  entityTemplate: removeBaseFields(
    getPartyRelationTemplate(PartyRelationType.EntityToIndividual)
  )
});

const wireInsuranceTaskTemplate = getTaskTemplate({
  type: TaskType.Integration,
  integrationType: IntegrationType.WireInsuranceCertificate
});
const rentalDataTaskTemplate = getTaskTemplate({
  type: TaskType.Integration,
  integrationType: IntegrationType.RentalData
});
const floodCertificateTaskTemplate = getTaskTemplate({
  type: TaskType.Integration,
  integrationType: IntegrationType.FloodCertificate
});

export const taskFields = createTemplateWithFieldMeta({
  entityTemplate: merge(
    wireInsuranceTaskTemplate,
    rentalDataTaskTemplate,
    floodCertificateTaskTemplate
  )
});

export const dealFields = createTemplateWithFieldMeta({
  entityTemplate: removeBaseFields(getDealTemplate())
});
export const creditScoreFields = createTemplateWithFieldMeta({
  entityTemplate: removeBaseFields(getCreditReportTemplate())
});
export const propertyFields = createTemplateWithFieldMeta({
  entityTemplate: removeBaseFields(getPropertyTemplate())
});
export const partyFields = merge({}, individualFields, entityFields);
export const dealPartyFields = merge(
  {},
  dealEntityFields,
  dealIndividualFields
);
export const partyRelationFields = merge(
  {},
  entityToEntityFields,
  entityToIndividualFields
);
export const dealPropertyFields = createTemplateWithFieldMeta({
  entityTemplate: removeBaseFields(getDealPropertyTemplate())
});

export const insurancePolyFields = createTemplateWithFieldMeta({
  entityTemplate: removeFields(removeBaseFields(getBaseInsurancePolicy()), [
    "entityIds",
    "dealIds",
    "entities",
    "deals",
    "branchRepId"
  ])
});

export const partyFieldSpecs = merge(
  {},
  entityPartyFieldSpecs,
  individualPartyFieldSpecs
);
export const dealPartyFieldSpecs = merge(
  {},
  dealToIndividualFieldSpecs,
  dealToEntityFieldSpecs
);
export const partyRelationFieldSpecs = merge(
  {},
  entityToEntityRelationFieldSpecs,
  entityToIndividualRelationFieldSpecs
);

export const serviceProviderFieldSpecs = merge(
  {},
  fullCompanyServiceProviderFieldsSpecs,
  fullBranchServiceProviderFieldsSpecs,
  fullRepServiceProviderFieldsSpecs
);

export const assetFields = createTemplateWithFieldMeta({
  entityTemplate: removeFields(
    removeBaseFields(getAssetTemplate(AssetType.Account)),
    ["statements"]
  )
}); // only one account type atm so this suffices
export const statementFields = createTemplateWithFieldMeta({
  entityTemplate: removeFields(removeBaseFields(getStatementTemplate()), [
    "assetId"
  ])
});

export const buildLabel = (r: {
  object: object;
  key: string;
  shouldKeepFullPath?: boolean;
}) => {
  const { object: o, key: k } = r;
  const label = get(o, `${k}.label`, NOT_AVAILABLE);
  if (r.shouldKeepFullPath) {
    return `${label}:${k}`;
  }

  const fieldPath = k.split(".");
  const pathWithoutFocus = fieldPath.slice(0, -1).join(".");
  return hasDefaultFocus(k) ? `${label}:${pathWithoutFocus}` : `${label}:${k}`;
};

export const hasDefaultFocus = (key: string) => {
  const fieldPath = key.split(".");
  const isThirdParty = includes(fieldPath, AggregationFocusType.ThirdParty);
  const isCalculated = includes(fieldPath, AggregationFocusType.Calculated);
  return isThirdParty || isCalculated;
};
