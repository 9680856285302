import { Box, useDisclosure } from "@chakra-ui/react";
import { StatusCode, TaskInstruction } from "@elphi/types";
import { useEffect, useState } from "react";
import { RichTextEditor } from "../../../common-components";
import { RichTextEditorValue } from "../../../common-components/rich-text-editor/richText.types";
import { AppConfig } from "../../../config/appConfig";
import { EMPTY } from "../../../constants/common";
import { auth } from "../../../firebase/firebaseConfig";
import { useAuthStateChangeHook } from "../../../hooks/authStateChange.hooks";
import {
  LIMIT,
  useTaskInstructionHooks,
  useTaskInstructionTableHooks
} from "../../../hooks/taskInstruction.hooks";
import useWindowDimensions from "../../../hooks/windowDimensions";
import { baseCellStyle } from "../../custom/chakra/Cell";
import { GroupModal } from "../../custom/modal/GroupModal";
import { ElphiPaginationList } from "../../elphi-list/ElphiList";
import { useFormBuilderStateHandler } from "../../form-builder/InputBuilder";
import ModalContainer from "../../modal-container/ModalContainer";
import { ElphiCellType, ElphiTableProps } from "../../table/table.types";
import {
  SIZE_FIELD,
  TableRowSizeComponent
} from "../../table/TableRowSizeComponent";
import { TaskInstructionRow } from "./TaskInstructionRow";

export const TaskInstructionTable = () => {
  const { heightOffsetInPx } = useWindowDimensions();
  const { isOpen: isOpenCloneModal, onToggle: onToggleCloneModal } =
    useDisclosure();
  const { isOpen: isOpenConfigureModal, onToggle: onToggleConfigureModal } =
    useDisclosure();
  const {
    taskInstructionState,
    updateTaskInstructionBatch,
    cloneInstruction,
    createResponse
  } = useTaskInstructionHooks();
  const { nextPage, paginateApiResponse, filters, currentCombination } =
    useTaskInstructionTableHooks();
  const [selectedRow, setSelectedRow] = useState<TaskInstruction>();

  useAuthStateChangeHook({
    onAuthStateChange: (_) => {
      if (auth.currentUser) {
        nextPage();
      }
    },
    deps: [filters]
  });

  useEffect(() => {
    const selected = taskInstructionState.entities[selectedRow?.id || EMPTY];
    if (selected) {
      setSelectedRow(selected);
    }
  }, [taskInstructionState.entities[selectedRow?.id || EMPTY]]);

  const { syncState, state, onChange } = useFormBuilderStateHandler<{
    [id: string]: Partial<TaskInstruction>;
  }>({
    initialState: {},
    callback: updateTaskInstructionBatch,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  const handleOnCloneClick = (id: string) => {
    setSelectedRow(taskInstructionState.entities[id]);
    onToggleCloneModal();
  };

  const handleOnConfigureClick = (id: string) => {
    setSelectedRow(taskInstructionState.entities[id]);
    onToggleConfigureModal();
  };

  const handleOnSaveInstructions = (editorValue: RichTextEditorValue) => {
    const { content, htmlContent } = editorValue;
    if (!selectedRow) {
      return;
    }
    updateTaskInstructionBatch({
      [selectedRow.id]: { htmlFormat: htmlContent, editorJsonFormat: content }
    }).then((r) => {
      if (r?.status === StatusCode.OK) {
        onToggleConfigureModal();
      }
    });
  };

  return (
    <>
      <ElphiPaginationList
        height={heightOffsetInPx(320)}
        tableName={"task-instruction"}
        isLoading={paginateApiResponse.isLoading}
        rowStyle={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none"
        }}
        header={
          <TableRowSizeComponent
            bgColor="gray.100"
            row={headerCells}
            withBorderRightWidth={false}
            justify="space-between"
          />
        }
        pageSize={LIMIT}
        items={
          !taskInstructionState.selectedId
            ? currentCombination.ids
            : [taskInstructionState.selectedId]
        }
        next={nextPage}
        hasMore={paginateApiResponse.currentData?.page?.length === LIMIT}
        options={{
          showEndMessage: false,
          showWatching: false,
          showLoader: true
        }}
        rowBuilder={(id, index) => {
          const data = taskInstructionState.entities[id];
          if (!data) {
            return <></>;
          }
          return (
            <TaskInstructionRow
              index={Number(index) + 1}
              data={data}
              onChange={onChange}
              syncState={syncState}
              state={state}
              onClone={handleOnCloneClick}
              onConfigure={handleOnConfigureClick}
            />
          );
        }}
      />
      {selectedRow && (
        <GroupModal
          key={`clone-${selectedRow.id}-${isOpenCloneModal}`}
          header={"Clone instructions"}
          action={"clone"}
          isShow={isOpenCloneModal}
          onClose={onToggleCloneModal}
          isLoading={createResponse.isLoading}
          onSubmit={cloneInstruction}
          cloneGroup={selectedRow}
        >
          <Box as="p">{`Clone ${selectedRow.id} - ${selectedRow.name}`}</Box>
        </GroupModal>
      )}
      {selectedRow && (
        <ModalContainer
          maxWidth={900}
          header={"Configure Instructions"}
          isShow={isOpenConfigureModal}
          onCloseModal={onToggleConfigureModal}
          body={
            <Box pb={2}>
              <RichTextEditor
                mode={"edit"}
                onSave={handleOnSaveInstructions}
                onCancel={onToggleConfigureModal}
                openedPlaceholder={"Type instructions"}
                closedPlaceholder={"Type instructions"}
                text={selectedRow?.editorJsonFormat}
              />
            </Box>
          }
        />
      )}
    </>
  );
};

const headerCells: ElphiTableProps["header"] = [
  {
    index: 0,
    data: "index",
    type: ElphiCellType.String,
    size: SIZE_FIELD.T,
    ...baseCellStyle
  },
  {
    index: 1,
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    data: "Name",
    ...baseCellStyle
  },
  {
    index: 2,
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    data: "Instructions",
    ...baseCellStyle
  },
  {
    index: 3,
    type: ElphiCellType.String,
    size: SIZE_FIELD.S,
    data: "Status",
    ...baseCellStyle
  }
];
