import { Box, Flex } from "@chakra-ui/react";
import { Task } from "@elphi/types";
import { useEffect, useState } from "react";
import { useIntegrationCenterHooks } from "../../hooks/integrationCenter.hooks";
import { TaskCard } from "./task-card/TaskCard";
import { TaskCardInfo } from "./taskCards.types";

type TaskCardsProps = {
  tasks: Task[];
  dealIdentifier: string;
};
export const TaskCards = ({ tasks, dealIdentifier }: TaskCardsProps) => {
  const [taskCards, setTaskCards] = useState<TaskCardInfo[]>([]);
  const { generateTaskCards } = useIntegrationCenterHooks();

  useEffect(() => {
    const currentsTasks = generateTaskCards(tasks);
    setTaskCards(currentsTasks);
  }, [tasks]);

  return (
    <Flex flexDir={"column"}>
      {taskCards.map((taskCard, index) => (
        <Box my={"2px"} key={index}>
          <TaskCard taskCard={taskCard} dealIdentifier={dealIdentifier} />
        </Box>
      ))}
    </Flex>
  );
};
