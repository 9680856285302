import { EditIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Tooltip } from "@chakra-ui/react";
import { Role } from "@elphi/types";
import { useEffect } from "react";
import { printDateTime } from "../../../../firebase/firebase.utils";
import { useRoleHooks } from "../../../../hooks/role.hooks";
import {
  LargeBoxCell,
  MediumBoxCell,
  SmallBoxCell,
  TextCell
} from "../../../custom/chakra/Cell";
import { useFormBuilderStateHandler } from "../../../form-builder/InputBuilder";
import { ItemsMenuCopyWidget } from "../../../party/table/v2/ItemsMenuCopyWidget";

export type RoleRowProps = {
  index: string | number;
  roleId: string;
  onEdit: (roleId: string) => void;
  roleHooks: ReturnType<typeof useRoleHooks>;
  syncState: ReturnType<typeof useFormBuilderStateHandler>["syncState"];
  onChange: ReturnType<typeof useFormBuilderStateHandler>["onChange"];
  state: {
    [id: string]: Partial<
      Pick<Role, "id" | "permissions" | "description" | "label">
    >;
  };
};
export const RoleRow = (props: RoleRowProps) => {
  const { roleId, index, roleHooks, syncState, state } = props;
  const { roleState } = roleHooks;
  useEffect(() => {
    syncState({
      state: roleState.entities?.[roleId],
      shouldSync: !!roleState.entities?.[roleId],
      statePath: () => {
        return [roleId.toString()];
      }
    });
  }, [roleState.entities?.[roleId]]);

  return (
    <Flex w="100%" key={index}>
      <SmallBoxCell>
        <TextCell>{index}</TextCell>
      </SmallBoxCell>
      <LargeBoxCell>
        <Flex>
          <Tooltip label={roleId}>
            <TextCell>{roleId.toString()}</TextCell>
          </Tooltip>
          <Box>
            <ItemsMenuCopyWidget
              items={[
                {
                  label: "elphi-role-id",
                  value: roleId
                }
              ]}
            />
          </Box>
        </Flex>
      </LargeBoxCell>
      <LargeBoxCell>
        <TextCell>{roleState?.entities?.[roleId]?.label}</TextCell>
      </LargeBoxCell>
      <LargeBoxCell>
        <TextCell>{roleState?.entities?.[roleId]?.description}</TextCell>
      </LargeBoxCell>
      <LargeBoxCell>
        <Flex w="100%" justifyContent={"space-between"}>
          <Box>
            {roleState?.entities?.[roleId]?.permissions?.map((p, i) => {
              return (
                <Box key={i}>
                  <TextCell key={i}>{p}</TextCell>
                </Box>
              );
            })}
          </Box>
        </Flex>
      </LargeBoxCell>
      <LargeBoxCell>
        <TextCell>
          {printDateTime(roleState?.entities?.[roleId]?.createdAt)}
        </TextCell>
      </LargeBoxCell>
      <LargeBoxCell>
        <TextCell>
          {printDateTime(roleState?.entities?.[roleId]?.modifiedAt)}
        </TextCell>
      </LargeBoxCell>
      <MediumBoxCell>
        <Tooltip
          aria-label="edit-role"
          label={`edit ${state?.[roleId]?.label}`}
        >
          <Box>
            <IconButton
              disabled={!roleId}
              bgColor="transparent"
              aria-label="edit-role"
              icon={<EditIcon />}
              size="m"
              onClick={() => props.onEdit(roleId)}
            />
          </Box>
        </Tooltip>
      </MediumBoxCell>
    </Flex>
  );
};
