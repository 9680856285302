import {
  AggregationType,
  FloodCertificateVendorType,
  IntegrationType,
  Property
} from "@elphi/types";
import { propertySpecFields } from "../../../form-builder/field-specs/property/property.fields";
import { OnChangeInput, Section } from "../../../form-builder/FormBuilder";
import { buildInputs } from "../../../form-builder/formBuilder.utils";
import {
  dateValidation,
  singleSelectValidation,
  stringValidation
} from "../../../utils/validationUtils";
import {
  floodCheckStatusFieldPath,
  orderNumberFieldPath,
  orderStatusFieldPath,
  requestedInformationFieldPath,
  resultDescriptionFieldPath,
  returnDateFieldPath
} from "../../field-paths/property.fieldPaths";

export const serviceLinkFloodStatusSection = (
  state: Partial<Property>,
  onChange: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Integration Flood Cert: Order Status",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: propertySpecFields,
      specs: [
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.FloodCertificate
            ]?.[FloodCertificateVendorType.ServiceLink]?.OrderNumber
              ?.focused !== "override",
          path: orderNumberFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.FloodCertificate
            ]?.[FloodCertificateVendorType.ServiceLink]?.FloodCheckStatus
              ?.focused !== "override",
          path: floodCheckStatusFieldPath,
          validation: singleSelectValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.FloodCertificate
            ]?.[FloodCertificateVendorType.ServiceLink]?.OrderStatus
              ?.focused !== "override",
          path: orderStatusFieldPath,
          validation: singleSelectValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.FloodCertificate
            ]?.[FloodCertificateVendorType.ServiceLink]?.RequestedInformation
              ?.focused !== "override",
          path: requestedInformationFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.FloodCertificate
            ]?.[FloodCertificateVendorType.ServiceLink]?.ResultDescription
              ?.focused !== "override",
          path: resultDescriptionFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.Integrations?.[
              IntegrationType.FloodCertificate
            ]?.[FloodCertificateVendorType.ServiceLink]?.ReturnDate?.focused !==
            "override",
          path: returnDateFieldPath,
          validation: dateValidation
        }
      ]
    })
  };
};
