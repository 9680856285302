export * from "@elphi/types/entities/fieldFormat.types";
// export enum FieldType {
//   Number = "number",
//   String = "string",
//   Array = "array",
//   Money = "money",
//   SingleSelect = "single-select",
//   MultiSelect = "multi-select",
//   Percentage = "percentage",
//   Date = "date",
//   Phone = "phone",
//   Email = "email",
//   RichText = "rich-text",
//   RadioSelect = "radio-select",
//   Integer = "integer",
//   Boolean = "boolean",
//   Switch = "switch"
// }

// export enum BooleanSelectType {
//   true = "Yes",
//   false = "No"
// }
// export enum SwitchValueType {
//   Active = "active",
//   Disabled = "disabled"
// }
// export type BaseFieldFormat<TType extends FieldType, TValue> = {
//   type: TType;
//   value: TValue;
// };

// export type DefaultField = StringField;
// export type StringField = BaseFieldFormat<FieldType.String, string>;
// export type RadioSelectField = BaseFieldFormat<FieldType.RadioSelect, string>;
// export type RichText = BaseFieldFormat<FieldType.RichText, string>;
// export type PhoneField = BaseFieldFormat<FieldType.Phone, string>;
// export type EmailField = BaseFieldFormat<FieldType.Email, string>;
// export type NumberField = BaseFieldFormat<FieldType.Number, number>;
// export type ListField<T> = BaseFieldFormat<FieldType.Array, Array<T>>;
// export type MoneyField = BaseFieldFormat<FieldType.Money, number>;
// export type SingleSelectField<T> = BaseFieldFormat<
//   FieldType.SingleSelect,
//   { optionsKey: string; value: Array<T> }
// >;
// export type MultiSelectField<T> = BaseFieldFormat<
//   FieldType.MultiSelect,
//   { optionsKey: string; value: Array<T> }
// >;
// export type PercentageField = BaseFieldFormat<FieldType.Percentage, number>;

// export type DateField = BaseFieldFormat<FieldType.Date, string>;
