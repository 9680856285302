import {
  BranchServiceProvider,
  BranchServiceProviderFields,
  CompanyServiceProvider,
  CompanyServiceProviderFields,
  FieldType,
  RepresentativeServiceProviderFields,
  RolodexConfiguration,
  RolodexEntityFields,
  RolodexServiceProvider,
  ServiceProviderType
} from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { omit, pick } from "lodash";
import {
  BranchServiceProviderRequest,
  CompanyServiceProviderRequest,
  RepServiceProviderRequest
} from "../../../../../../redux/v2/rolodex";
import {
  OnChangeInput,
  Section
} from "../../../../../form-builder/FormBuilder";
import { InputBuilderFieldSpecs } from "../../../../../form-builder/field-specs/fields.types";
import {
  branchServiceProviderFieldsSpecs,
  companyServiceProviderFieldsSpecs,
  repServiceProviderFieldsSpecs
} from "../../../../../form-builder/field-specs/service-provider/serviceProvider.fields";
import { buildInputs } from "../../../../../form-builder/formBuilder.utils";
import { BaseInputFormatter } from "../../../../../form-builder/formatters/formatter.types";
import { einInputFormatter } from "../../../../../form-builder/formatters/inputs.formatter";
import {
  dateValidation,
  einValidation,
  emailValidation,
  multiSelectValidation,
  numberValidation,
  singleSelectValidation,
  stringValidation
} from "../../../../../utils/validationUtils";

const getSelectedFields = (fields?: RolodexEntityFields) => {
  return new Map(
    Object.values(fields || [])?.map((field) => [
      field.fieldPath,
      field.isRequired
    ])
  );
};

const isHidden = (r: {
  fields: Map<string, boolean>;
  fieldSpec?: InputBuilderFieldSpecs<any>;
}) => {
  if (!r?.fieldSpec?.fieldKey) {
    return true;
  }
  const key = r.fieldSpec.fieldKey.join(".");
  return !r.fields.has(key);
};

const isRequired = (r: {
  fields: Map<string, boolean>;
  fieldSpec?: InputBuilderFieldSpecs<any>;
}) => {
  if (!r?.fieldSpec?.fieldKey) {
    return false;
  }
  const key = r.fieldSpec.fieldKey.join(".");
  if (r.fields.has(key)) {
    return !!r.fields.get(key);
  }
  return false;
};

const buildInput = (r: {
  fieldSpec?: InputBuilderFieldSpecs<any>;
  selectedFields: Map<string, boolean>;
  isReadOnly: boolean;
  validation: (v: any, isRequired?: boolean) => boolean;
  formatter?: BaseInputFormatter<FieldType, any, any>;
}) => {
  const { fieldSpec, selectedFields, isReadOnly, validation, formatter } = r;

  const isFieldRequired = isRequired({
    fields: selectedFields,
    fieldSpec
  });

  return {
    path: fieldSpec?.fieldKey,
    validation: (v) => validation(v, isFieldRequired),
    isHidden: isHidden({
      fields: selectedFields,
      fieldSpec
    }),
    isRequired: isFieldRequired,
    isReadOnly,
    formatter
  };
};

type ServiceProviderSection = {
  state: Partial<RolodexServiceProvider>;
  onChange: (v: OnChangeInput) => void;
  configuration?: RolodexConfiguration;
  isReadOnly: boolean;
  showAttached: boolean;
};

const companyServiceProviderSection = (r: ServiceProviderSection): Section => {
  const { state, onChange, configuration, isReadOnly, showAttached } = r;

  if (!configuration) {
    return { inputs: [] };
  }

  const selectedFields = getSelectedFields(
    configuration?.fields?.companyFields
  );

  const name = buildInput({
    fieldSpec: companyServiceProviderFieldsSpecs.name,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const status = buildInput({
    fieldSpec: companyServiceProviderFieldsSpecs.status,
    selectedFields,
    isReadOnly,
    validation: singleSelectValidation
  });

  const ein = buildInput({
    fieldSpec: companyServiceProviderFieldsSpecs?.ein,
    selectedFields,
    isReadOnly,
    validation: einValidation,
    formatter: einInputFormatter
  });

  const experienceLevel = buildInput({
    fieldSpec: companyServiceProviderFieldsSpecs?.experienceLevel,
    selectedFields,
    isReadOnly,
    validation: numberValidation
  });

  const notes = buildInput({
    fieldSpec: companyServiceProviderFieldsSpecs?.notes,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const crmId = buildInput({
    fieldSpec: companyServiceProviderFieldsSpecs?.crmId,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  return {
    inputs: buildInputs<Partial<RolodexServiceProvider>>({
      hideAttachedComponent: !showAttached,
      state: showAttached ? state : omit(state, "fieldMeta"),
      onChange,
      fieldSpecs: companyServiceProviderFieldsSpecs,
      specs: [name, status, ein, experienceLevel, notes, crmId]
    })
  };
};

const branchServiceProviderSection = (r: ServiceProviderSection): Section => {
  const { state, onChange, configuration, isReadOnly, showAttached } = r;

  if (!configuration) {
    return { inputs: [] };
  }

  const selectedFields = getSelectedFields(configuration?.fields?.branchFields);

  const name = buildInput({
    fieldSpec: branchServiceProviderFieldsSpecs.name,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const ein = buildInput({
    fieldSpec: branchServiceProviderFieldsSpecs?.ein,
    selectedFields,
    isReadOnly,
    validation: einValidation,
    formatter: einInputFormatter
  });

  const status = buildInput({
    fieldSpec: branchServiceProviderFieldsSpecs.status,
    selectedFields,
    isReadOnly,
    validation: singleSelectValidation
  });

  const coverageArea = buildInput({
    fieldSpec: branchServiceProviderFieldsSpecs?.coverageArea,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const email = buildInput({
    fieldSpec: branchServiceProviderFieldsSpecs?.email,
    selectedFields,
    isReadOnly,
    validation: emailValidation
  });

  const lendingPartnerType = buildInput({
    fieldSpec: branchServiceProviderFieldsSpecs?.lendingPartnerType,
    selectedFields,
    isReadOnly,
    validation: singleSelectValidation
  });

  const nmlsLicenseNumber = buildInput({
    fieldSpec: branchServiceProviderFieldsSpecs?.nmlsLicenseNumber,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const numberOfEmployees = buildInput({
    fieldSpec: branchServiceProviderFieldsSpecs?.numberOfEmployees,
    selectedFields,
    isReadOnly,
    validation: numberValidation
  });

  const phoneNumber = buildInput({
    fieldSpec: branchServiceProviderFieldsSpecs?.phoneNumber,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const wireAccountNumber = buildInput({
    fieldSpec: branchServiceProviderFieldsSpecs?.wireAccountNumber,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const wireNameOnAccount = buildInput({
    fieldSpec: branchServiceProviderFieldsSpecs?.wireNameOnAccount,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const wireRoutingNumber = buildInput({
    fieldSpec: branchServiceProviderFieldsSpecs?.wireRoutingNumber,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const addressLineText = buildInput({
    fieldSpec: branchServiceProviderFieldsSpecs?.Address?.AddressLineText,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const addressUnitIdentifier = buildInput({
    fieldSpec: branchServiceProviderFieldsSpecs?.Address?.AddressUnitIdentifier,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const cityName = buildInput({
    fieldSpec: branchServiceProviderFieldsSpecs?.Address?.CityName,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const countyName = buildInput({
    fieldSpec: branchServiceProviderFieldsSpecs?.Address?.CountyName,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const postalCode = buildInput({
    fieldSpec: branchServiceProviderFieldsSpecs?.Address?.PostalCode,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const stateName = buildInput({
    fieldSpec: branchServiceProviderFieldsSpecs?.Address?.StateName,
    selectedFields,
    isReadOnly,
    validation: singleSelectValidation
  });

  const stateCode = buildInput({
    fieldSpec: branchServiceProviderFieldsSpecs?.Address?.StateCode,
    selectedFields,
    isReadOnly,
    validation: singleSelectValidation
  });

  const experienceLevel = buildInput({
    fieldSpec: branchServiceProviderFieldsSpecs?.experienceLevel,
    selectedFields,
    isReadOnly,
    validation: numberValidation
  });

  const notes = buildInput({
    fieldSpec: branchServiceProviderFieldsSpecs?.notes,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const crmId = buildInput({
    fieldSpec: branchServiceProviderFieldsSpecs?.crmId,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  return {
    inputs: buildInputs<Partial<RolodexServiceProvider>>({
      hideAttachedComponent: !showAttached,
      state: showAttached ? state : omit(state, "fieldMeta"),
      onChange,
      fieldSpecs: branchServiceProviderFieldsSpecs,
      specs: [
        name,
        status,
        ein,
        addressLineText,
        addressUnitIdentifier,
        cityName,
        countyName,
        stateName,
        stateCode,
        postalCode,
        phoneNumber,
        email,
        nmlsLicenseNumber,
        numberOfEmployees,
        coverageArea,
        lendingPartnerType,
        wireAccountNumber,
        wireNameOnAccount,
        wireRoutingNumber,
        experienceLevel,
        notes,
        crmId
      ]
    })
  };
};

const representativeServiceProviderSection = (
  r: ServiceProviderSection
): Section => {
  const { state, onChange, configuration, isReadOnly, showAttached } = r;

  if (!configuration) {
    return { inputs: [] };
  }

  const selectedFields = getSelectedFields(
    configuration?.fields?.representativeFields
  );

  const firstName = buildInput({
    fieldSpec: repServiceProviderFieldsSpecs.firstName,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const lastName = buildInput({
    fieldSpec: repServiceProviderFieldsSpecs.lastName,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const middleName = buildInput({
    fieldSpec: repServiceProviderFieldsSpecs?.middleName,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const status = buildInput({
    fieldSpec: repServiceProviderFieldsSpecs.status,
    selectedFields,
    isReadOnly,
    validation: singleSelectValidation
  });

  const birthDate = buildInput({
    fieldSpec:
      repServiceProviderFieldsSpecs?.birthDate as InputBuilderFieldSpecs<any>,
    selectedFields,
    isReadOnly,
    validation: dateValidation
  });

  const phoneNumber = buildInput({
    fieldSpec: repServiceProviderFieldsSpecs?.phoneNumber,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const nmlsLicenseNumber = buildInput({
    fieldSpec: repServiceProviderFieldsSpecs?.nmlsLicenseNumber,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const licenseNumber = buildInput({
    fieldSpec: repServiceProviderFieldsSpecs?.licenseNumber,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const driversLicenseNumber = buildInput({
    fieldSpec: repServiceProviderFieldsSpecs?.driversLicenseNumber,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const addressLineText = buildInput({
    fieldSpec: repServiceProviderFieldsSpecs?.Address?.AddressLineText,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const addressUnitIdentifier = buildInput({
    fieldSpec: repServiceProviderFieldsSpecs?.Address?.AddressUnitIdentifier,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const cityName = buildInput({
    fieldSpec: repServiceProviderFieldsSpecs?.Address?.CityName,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const countyName = buildInput({
    fieldSpec: repServiceProviderFieldsSpecs?.Address?.CountyName,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const postalCode = buildInput({
    fieldSpec: repServiceProviderFieldsSpecs?.Address?.PostalCode,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const stateName = buildInput({
    fieldSpec: repServiceProviderFieldsSpecs?.Address?.StateName,
    selectedFields,
    isReadOnly,
    validation: singleSelectValidation
  });

  const stateCode = buildInput({
    fieldSpec: repServiceProviderFieldsSpecs?.Address?.StateCode,
    selectedFields,
    isReadOnly,
    validation: singleSelectValidation
  });

  const businessRegistrationStates = buildInput({
    fieldSpec: repServiceProviderFieldsSpecs?.businessRegistrationStates,
    selectedFields,
    isReadOnly,
    validation: multiSelectValidation
  });

  const email = buildInput({
    fieldSpec: repServiceProviderFieldsSpecs?.email,
    selectedFields,
    isReadOnly,
    validation: emailValidation
  });

  const experienceLevel = buildInput({
    fieldSpec: repServiceProviderFieldsSpecs?.experienceLevel,
    selectedFields,
    isReadOnly,
    validation: numberValidation
  });

  const notes = buildInput({
    fieldSpec: repServiceProviderFieldsSpecs?.notes,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  const crmId = buildInput({
    fieldSpec: repServiceProviderFieldsSpecs?.crmId,
    selectedFields,
    isReadOnly,
    validation: stringValidation
  });

  return {
    inputs: buildInputs<Partial<RolodexServiceProvider>>({
      hideAttachedComponent: !showAttached,
      state: showAttached ? state : omit(state, "fieldMeta"),
      onChange,
      fieldSpecs: repServiceProviderFieldsSpecs,
      specs: [
        firstName,
        middleName,
        lastName,
        status,
        addressLineText,
        addressUnitIdentifier,
        cityName,
        countyName,
        stateName,
        stateCode,
        postalCode,
        email,
        birthDate,
        phoneNumber,
        nmlsLicenseNumber,
        licenseNumber,
        driversLicenseNumber,
        businessRegistrationStates,
        experienceLevel,
        notes,
        crmId
      ]
    })
  };
};

export const providerFormSectionBuilder = (
  r: ServiceProviderSection
): Section => {
  const { state, configuration } = r;
  const { type } = state;
  if (!type || !configuration) {
    return { inputs: [] };
  }
  if (type === ServiceProviderType.Company) {
    return companyServiceProviderSection(r);
  }
  if (type === ServiceProviderType.Branch) {
    return branchServiceProviderSection(r);
  }
  if (type === ServiceProviderType.Representative) {
    return representativeServiceProviderSection(r);
  }
  return { inputs: [] };
};

export const companyServiceProviderFields: DotNestedKeys<CompanyServiceProviderFields>[] =
  ["ein", "name", "status", "notes", "crmId", "experienceLevel"];

export const branchServiceProviderFields: DotNestedKeys<BranchServiceProviderFields>[] =
  [
    "ein",
    "name",
    "status",
    "Address",
    "coverageArea",
    "email",
    "lendingPartnerType",
    "nmlsLicenseNumber",
    "numberOfEmployees",
    "phoneNumber",
    "wireAccountNumber",
    "wireNameOnAccount",
    "wireRoutingNumber",
    "notes",
    "experienceLevel",
    "crmId"
  ];
export const repServiceProviderFields: DotNestedKeys<RepresentativeServiceProviderFields>[] =
  [
    "status",
    "Address",
    "email",
    "nmlsLicenseNumber",
    "phoneNumber",
    "birthDate",
    "businessRegistrationStates",
    "driversLicenseNumber",
    "firstName",
    "lastName",
    "licenseNumber",
    "middleName",
    "notes",
    "experienceLevel",
    "crmId"
  ];

export const getCompanyTemplate = (
  r: Partial<CompanyServiceProvider>
): CompanyServiceProviderRequest => {
  const props = ["id", "domainConfigurationId"].concat(
    companyServiceProviderFields
  );
  return pick(r, props);
};

export const getBranchTemplate = (
  r: Partial<BranchServiceProvider>
): BranchServiceProviderRequest => {
  const props = ["id", "companyId"].concat(branchServiceProviderFields);
  return pick(r, props);
};

export const getRepTemplate = (
  r: Partial<RepresentativeServiceProviderFields>
): RepServiceProviderRequest => {
  const props = ["id", "companyId", "skipped"].concat(repServiceProviderFields);
  return pick(r, props);
};

export const sanitizeSpace = (q: string) => decodeURIComponent(q);

export const getRepFullName = (q: string) => {
  const sanitizedName = sanitizeSpace(q);
  const fullName = sanitizedName.split(" ");
  return fullName.length === 2
    ? {
        firstName: fullName[0],
        lastName: fullName[1]
      }
    : {
        firstName: sanitizedName
      };
};
