import {
  PolicyEntityBaseData,
  PropertyBuildings,
  PropertyCoveragesFields,
  PropertyInsurancePolicyCoverageType
} from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { propertyInsurancePolicyCoveragesFieldBaseSpecs } from "../../../../../../form-builder/field-specs/insurance-policy/property";
import { stringValidation } from "../../../../../../utils/validationUtils";
import { PropertyInsurancePolicyState } from "../../types/insurancePolicySteps.types";
import { buildInput } from "../../utils/formUtils";

export const buildingsPath: DotNestedKeys<PropertyBuildings> = "buildings";
export const coveragesTypesToFieldsMapping: {
  [key in PropertyInsurancePolicyCoverageType]: DotNestedKeys<
    Required<Pick<PolicyEntityBaseData<PropertyCoveragesFields>, "coverages">>
  >[];
} = {
  [PropertyInsurancePolicyCoverageType.Hazard]: [
    "coverages.hazardCoverageAmount",
    "coverages.hazardReplacementCoverage"
  ],
  [PropertyInsurancePolicyCoverageType.Flood]: [
    "coverages.floodCoverageAmount"
  ],
  [PropertyInsurancePolicyCoverageType.Liability]: [
    "coverages.liabilityCoverageAmount"
  ],
  [PropertyInsurancePolicyCoverageType.BuildersRisk]: [
    "coverages.buildersRiskCoverageAmount"
  ],

  [PropertyInsurancePolicyCoverageType.RentLoss]: [
    "coverages.rentLossCoverageAmount"
  ],

  [PropertyInsurancePolicyCoverageType.Earthquake]: [
    "coverages.earthquakeCoverageAmount"
  ],

  [PropertyInsurancePolicyCoverageType.Wind]: ["coverages.windCoverageAmount"],
  [PropertyInsurancePolicyCoverageType.CondoMasterHazard]: [
    "coverages.condoMasterHazardCoverageAmount"
  ],

  [PropertyInsurancePolicyCoverageType.HO6]: ["coverages.HO6CoverageAmount"],
  [PropertyInsurancePolicyCoverageType.CondoMasterFlood]: [
    "coverages.condoMasterFloodCoverageAmount"
  ],
  [PropertyInsurancePolicyCoverageType.CAFairPlan]: [
    "coverages.CAFairPlanCoverageAmount"
  ],
  [PropertyInsurancePolicyCoverageType.ExcessFlood]: ["coverages.excessFlood"],
  [PropertyInsurancePolicyCoverageType.Lava]: ["coverages.lavaCoverageAmount"]
};

export const buildCoverageInput = (
  r: Parameters<typeof buildInput>["0"] & {
    visibleFields: string[];
  }
) => {
  const { fieldSpec, visibleFields } = r;

  return buildInput({
    ...r,
    isHidden: !visibleFields.includes(fieldSpec?.fieldKey.join("."))
  });
};

export type CoveragesSectionProps = {
  propertyId: string;
  state: PropertyInsurancePolicyState;
  isReadOnly: boolean;
};

export const getPropertyCoveragesSection = (props: CoveragesSectionProps) => {
  const { state, isReadOnly } = props;
  const selectedCoverageTypes = state?.coverageTypes || [];
  const visibleFields = selectedCoverageTypes
    .map((c) => {
      const pathArr = coveragesTypesToFieldsMapping[c];
      return pathArr.map((p) => p);
    })
    .flat();
  const cAFairPlanCoverageAmount = buildCoverageInput({
    fieldSpec:
      propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
        .CAFairPlanCoverageAmount!,
    visibleFields,
    isReadOnly,
    validation: stringValidation
  });

  const hO6CoverageAmount = buildCoverageInput({
    fieldSpec:
      propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
        .HO6CoverageAmount!,
    visibleFields,
    isReadOnly,
    validation: stringValidation
  });

  const buildersRiskCoverageAmount = buildCoverageInput({
    fieldSpec:
      propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
        .buildersRiskCoverageAmount!,
    visibleFields,
    isReadOnly,
    validation: stringValidation
  });

  const condoMasterFloodCoverageAmount = buildCoverageInput({
    fieldSpec:
      propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
        .condoMasterFloodCoverageAmount!,
    visibleFields,
    isReadOnly,
    validation: stringValidation
  });

  const condoMasterHazardCoverageAmount = buildCoverageInput({
    fieldSpec:
      propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
        .condoMasterHazardCoverageAmount!,
    visibleFields,
    isReadOnly,
    validation: stringValidation
  });

  const earthquakeCoverageAmount = buildCoverageInput({
    fieldSpec:
      propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
        .earthquakeCoverageAmount!,
    visibleFields,
    isReadOnly,
    validation: stringValidation
  });

  const excessFlood = buildCoverageInput({
    fieldSpec:
      propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!.excessFlood!,
    visibleFields,
    isReadOnly,
    validation: stringValidation
  });
  const floodCoverageAmount = buildCoverageInput({
    fieldSpec:
      propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
        .floodCoverageAmount!,
    visibleFields,
    isReadOnly,
    validation: stringValidation
  });
  const hazardCoverageAmount = buildCoverageInput({
    fieldSpec:
      propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
        .hazardCoverageAmount!,
    visibleFields,
    isReadOnly,
    validation: stringValidation
  });

  const hazardReplacementCoverage = buildCoverageInput({
    fieldSpec:
      propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
        .hazardReplacementCoverage!,
    visibleFields,
    isReadOnly,
    validation: stringValidation
  });

  const lavaCoverageAmount = buildCoverageInput({
    fieldSpec:
      propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
        .lavaCoverageAmount!,
    visibleFields,
    isReadOnly,
    validation: stringValidation
  });
  const liabilityCoverageAmount = buildCoverageInput({
    fieldSpec:
      propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
        .liabilityCoverageAmount!,
    visibleFields,
    isReadOnly,
    validation: stringValidation
  });
  const rentLossCoverageAmount = buildCoverageInput({
    fieldSpec:
      propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
        .rentLossCoverageAmount!,
    visibleFields,
    isReadOnly,
    validation: stringValidation
  });
  const windCoverageAmount = buildCoverageInput({
    fieldSpec:
      propertyInsurancePolicyCoveragesFieldBaseSpecs.coverages!
        .windCoverageAmount!,
    visibleFields,
    isReadOnly,
    validation: stringValidation
  });

  return {
    cAFairPlanCoverageAmount,
    hO6CoverageAmount,
    buildersRiskCoverageAmount,
    condoMasterFloodCoverageAmount,
    condoMasterHazardCoverageAmount,
    earthquakeCoverageAmount,
    excessFlood,
    floodCoverageAmount,
    hazardCoverageAmount,
    hazardReplacementCoverage,
    lavaCoverageAmount,
    liabilityCoverageAmount,
    rentLossCoverageAmount,
    windCoverageAmount
  };
};
