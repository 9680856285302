//
import React, { useState } from "react";
//
import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, useBoolean } from "@chakra-ui/react";
import { LOSUser } from "@elphi/types";
import { useEffect } from "react";
import elphiTheme from "../../../assets/themes/elphi.theme.default";
import { auth } from "../../../firebase/firebaseConfig";
import { useAdminHooks } from "../../../hooks/admin.hooks";
import { useLOSUserHooks } from "../../../hooks/losuser.hooks";
import useWindowDimensions from "../../../hooks/windowDimensions";
import { useRTKPagination } from "../../../redux/v2/hooks";
import { losUserApi } from "../../../redux/v2/los-user";
import {
  LargeBoxCell,
  MediumBoxCell,
  SmallBoxCell,
  TextCell
} from "../../custom/chakra/Cell";
import { ElphiPaginationList } from "../../elphi-list/ElphiList";
import { useFormBuilderStateHandler } from "../../form-builder/InputBuilder";
import LOSUserSearch from "../../los-user/UserSearch";
import { CreateUserModal, UpdateUserModal } from "./UserPage";
import { UserRow } from "./list/UserRow";

export type AdminUserPageProps = {};

export const useAdminUserHooks = () => {
  const adminUserHooks = useLOSUserHooks();
  const { setUsersStatusHandler } = useAdminHooks();
  const paginationHandler = useRTKPagination({
    entityState: adminUserHooks.losUserState,
    useLazyPaginateQuery: losUserApi.useLazyPaginateQuery
  });

  return {
    adminUserHooks,
    paginationHandler,
    setUsersStatusHandler
  };
};

export const AdminUserPage: React.FC = (_: AdminUserPageProps) => {
  const { paginationHandler, adminUserHooks, setUsersStatusHandler } =
    useAdminUserHooks();
  const { losUserState } = adminUserHooks;
  const limit = 50;
  const { heightOffsetInPx } = useWindowDimensions();
  const [showCreateUserModal, { on: onCreateUser, off: offCreateUser }] =
    useBoolean();
  const [showUserEditModal, { on: onUserEdit, off: offUserEdit }] =
    useBoolean();

  const { syncState, onChange, state } = useFormBuilderStateHandler<{
    [id: string]: Partial<LOSUser>;
  }>({
    initialState: {}
  });

  useEffect(() => {
    if (auth.currentUser && paginationHandler.pageResponse.isUninitialized) {
      paginationHandler.next();
    }
  }, [auth.currentUser]);

  const [selectedUserId, setSelectedUserId] = useState("");
  return (
    <Box overflow={"scroll"}>
      <Flex>
        <Box p="10px">
          <Button
            aria-label="create-user-btn"
            onClick={onCreateUser}
            leftIcon={<AddIcon />}
            {...elphiTheme.components.light.button.primary}
          >
            Add User
          </Button>
        </Box>
      </Flex>
      <CreateUserModal isOpen={showCreateUserModal} onClose={offCreateUser} />
      {losUserState?.entities?.[selectedUserId] && (
        <UpdateUserModal
          isOpen={showUserEditModal}
          onClose={offUserEdit}
          user={{
            id: selectedUserId,
            ...losUserState.entities?.[selectedUserId]
          }}
        />
      )}
      <LOSUserSearch
        currentValue={losUserState?.selectedId?.toString() || ""}
        onSelect={(v: string) => {
          adminUserHooks.setSelectedLOSUser(v);
        }}
      />
      <ElphiPaginationList
        tableName={"AdminUser"}
        header={
          <Flex w="100%" bgColor="lightblue">
            {[
              {
                size: "s",
                label: "index"
              },
              {
                size: "m",
                label: "id"
              },
              {
                size: "m",
                label: "first"
              },
              {
                size: "m",
                label: "middle"
              },
              {
                size: "m",
                label: "last"
              },
              {
                size: "l",
                label: "email"
              },
              {
                size: "l",
                label: "title"
              },
              {
                size: "l",
                label: "roles"
              },
              {
                size: "l",
                label: "created"
              },
              {
                size: "l",
                label: "modified"
              },
              {
                size: "l",
                label: "last sign-in"
              },
              {
                size: "s",
                label: "status"
              },
              {
                size: "s",
                label: "active"
              },
              {
                size: "m",
                label: "actions"
              }
            ].map((s, i) => {
              if (s.size === "s") {
                return (
                  <SmallBoxCell key={i}>
                    <TextCell>{s.label}</TextCell>
                  </SmallBoxCell>
                );
              } else if (s.size === "m") {
                return (
                  <MediumBoxCell key={i}>
                    <TextCell>{s.label}</TextCell>
                  </MediumBoxCell>
                );
              } else if (s.size === "l") {
                return (
                  <LargeBoxCell key={i}>
                    <TextCell>{s.label}</TextCell>
                  </LargeBoxCell>
                );
              }
            })}
          </Flex>
        }
        height={heightOffsetInPx(320)}
        hasMore={paginationHandler.pageData?.ids?.length < limit}
        items={
          !!losUserState.selectedId
            ? [losUserState.selectedId.toString()]
            : losUserState.ids
        }
        next={paginationHandler.next}
        pageSize={10}
        rowBuilder={(userId, index) => {
          return (
            <UserRow
              userId={userId?.toString() || ""}
              index={index}
              setUsersStatus={setUsersStatusHandler}
              adminUserHooks={adminUserHooks}
              syncState={syncState}
              onChange={onChange}
              state={state}
              onEdit={(v) => {
                setSelectedUserId(v);
                onUserEdit();
              }}
              key={index}
            />
          );
        }}
      />
    </Box>
  );
};
