import {
  BaseInsurancePolicy,
  InsurancePolicyEntityType,
  PropertyInsurancePolicy,
  getPropertyInsurancePolicyTemplate
} from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { keys, merge } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { EMPTY } from "../../../../../../constants/common";
import { useInsurancePolicyHooks } from "../../../../../../hooks/insurance-policy/insurancePolicy.hooks";
import { usePropertyInsurancePolicyHooks } from "../../../../../../hooks/insurance-policy/propertyInsurancePolicy.hooks";
import { OnChangeInput } from "../../../../../form-builder/FormBuilder";
import { basePropertyInsurancePolicyFieldSpecs } from "../../../../../form-builder/field-specs/insurance-policy/property";
import { buildInputs } from "../../../../../form-builder/formBuilder.utils";
import { InsurancePolicySearch } from "../../search/InsurancePolicySearch";
import { StepForm } from "../components/StepForm";
import { PropertyInsurancePolicyState } from "../types/insurancePolicySteps.types";
import { getPolicySectionInputs } from "./formInputs";

const NEW_POLICY_ID = "newPolicy";
const dealsPath: DotNestedKeys<BaseInsurancePolicy<InsurancePolicyEntityType>> =
  "deals";
const insurancePolicyTemplate = getPropertyInsurancePolicyTemplate();

type PolicyStepProps = {
  onChange: (value: OnChangeInput) => void;
  state: PropertyInsurancePolicyState;
  setState: (value: PropertyInsurancePolicyState) => void;
  domainConfigurationId: string;
  setStepValidity: (isValid: boolean) => void;
  filter?: (propertyInsurancePolicy: PropertyInsurancePolicy) => boolean;
};

export const PolicyStep = (props: PolicyStepProps) => {
  const { onChange, state, domainConfigurationId, setStepValidity } = props;
  const [searchValue, setSearchValue] = useState(EMPTY);
  const { selectedDeal, propertyInsurancePolicyState } =
    usePropertyInsurancePolicyHooks();
  const { getInsurancePolicyFromState, selectPolicy } =
    useInsurancePolicyHooks();
  const policySectionInputs = getPolicySectionInputs();
  const createNewPolicy = (r: {
    policyNumber: string;
  }): Partial<PropertyInsurancePolicy> => {
    props.setState({});
    return {
      policyNumber: r.policyNumber
    };
  };

  const handleSelect = (selectedOptionId: string) => {
    const dealId = selectedDeal?.id;
    const basePolicyInformation = {
      domainConfigurationId
    };
    if (dealId) {
      basePolicyInformation[dealsPath] = {
        [dealId]: {
          loanIdentifier: selectedDeal?.LoanIdentifier
        }
      };
    }

    const policy =
      selectedOptionId === NEW_POLICY_ID
        ? createNewPolicy({
            policyNumber: searchValue
          })
        : getInsurancePolicyFromState({ id: selectedOptionId }) || {};

    const newState = merge(
      {},
      insurancePolicyTemplate,
      basePolicyInformation,
      policy
    );
    props.setState(newState);
    selectPolicy({
      id: selectedOptionId !== NEW_POLICY_ID ? selectedOptionId : undefined
    });
  };

  useEffect(() => {
    if (propertyInsurancePolicyState.selectedId) {
      handleSelect(propertyInsurancePolicyState.selectedId.toString());
    }
  }, [propertyInsurancePolicyState.selectedId]);

  const inputs = buildInputs({
    id: "propertyInsurancePolicyForm",
    state,
    fieldSpecs: basePropertyInsurancePolicyFieldSpecs,
    onChange,
    hideAttachedComponent: true,
    specs: policySectionInputs
  });

  const requiredInputs = useMemo(
    () => inputs.filter((i) => i.isRequired),
    [inputs]
  );

  useEffect(() => {
    const isValid = requiredInputs.every((i) => i.isValid);
    setStepValidity(isValid);
  }, [requiredInputs]);

  return (
    <>
      <InsurancePolicySearch
        currentValue={searchValue}
        createNewPolicyOptionId={NEW_POLICY_ID}
        onSelect={handleSelect}
        onInputChange={setSearchValue}
        domainConfigurationId={props.domainConfigurationId}
        entityType={InsurancePolicyEntityType.Property}
        label="Add Policy Number"
        filter={props.filter}
      />
      {!!keys(state)?.length && (
        <StepForm
          customKey="insurancePolicyForm"
          onChange={onChange}
          sections={[{ inputs: inputs }]}
        />
      )}
    </>
  );
};
